import './fleet-asset-status-tile.scss';
import * as template from "./fleet-asset-status-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileKpis, TileLegend, TileList } from "../../common/tile/types";
import { FleetAssetStatusTileOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { DeviceStateEnum } from 'invipo/clients/gpscockpit-client/types';
import { FleetStatusDetail } from '../fleet-status-detail/fleet-status-detail';

export class FleetAssetStatusTile extends Tile<FleetAssetStatusTileOptions> {

    // Properties
    public kpis: TileKpis;
    public legend: TileLegend;
    public list: TileList;

    constructor(context: InvipoContext, options?: FleetAssetStatusTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        this.showLoader()

        // Load data
        const assets = await this.context.gpsCockpit.getAssetsWithState();

        this.hideLoader()

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.FleetAssetStatusTile.total",
                    value: Helpers.toNumber(assets.length)
                }
            ]
        };

        const counts = assets.reduce((acc, asset) => {
            const state = asset.state?.calculatedDeviceState?.deviceState ?? DeviceStateEnum.Unknown
            if (!acc[state]) acc[state] = 0;
            acc[state]++;

            return acc;
        }, [])

        // Builld items legend
        this.legend = {
            flat: false,
            symbols: [
                {
                    metrics: "State",
                    symbol: "IgnitionOn",
                    label: "enums.FleetAssetStatus.IgnitionOn",
                    count: counts[DeviceStateEnum.IgnitionOn] ?? 0,
                    percent: counts[DeviceStateEnum.IgnitionOn] / (assets.length || 1) * 100,
                    color: "#0fd170",
                    selectable: true
                },
                {
                    metrics: "State",
                    symbol: "Idling",
                    label: "enums.FleetAssetStatus.Idling",
                    count: counts[DeviceStateEnum.Idling] ?? 0,
                    percent: counts[DeviceStateEnum.Idling] / (assets.length || 1) * 100,
                    color: "#fcb836",
                    selectable: true
                },
                {
                    metrics: "State",
                    symbol: "IgnitionOff",
                    label: "enums.FleetAssetStatus.IgnitionOff",
                    count: counts[DeviceStateEnum.IgnitionOff] ?? 0,
                    percent: counts[DeviceStateEnum.IgnitionOff] / (assets.length || 1) * 100,
                    color: "#ff0038",
                    selectable: true
                },
                {
                    metrics: "State",
                    symbol: "NotCommunicating",
                    label: "enums.FleetAssetStatus.NotCommunicating",
                    count: counts[DeviceStateEnum.NotCommunicating] ?? 0,
                    percent: counts[DeviceStateEnum.NotCommunicating] / (assets.length || 1) * 100,
                    color: "#7d00ff",
                    selectable: true
                },
                {
                    metrics: "State",
                    symbol: "ExternalPowerLost",
                    label: "enums.FleetAssetStatus.ExternalPowerLost",
                    count: counts[DeviceStateEnum.ExternalPowerLost] ?? 0,
                    percent: counts[DeviceStateEnum.ExternalPowerLost] / (assets.length || 1) * 100,
                    color: "#c128b9",
                    selectable: true
                },
                {
                    metrics: "State",
                    symbol: "Unknown",
                    label: "enums.FleetAssetStatus.Unknown",
                    count: counts[DeviceStateEnum.Unknown] ?? 0,
                    percent: counts[DeviceStateEnum.Unknown] / (assets.length || 1) * 100,
                    color: "#999999",
                    selectable: true
                }
            ]
        };

    }

    public selectSymbol(symbol: string, label: string): void {
        const state: DeviceStateEnum = DeviceStateEnum[symbol as any] as any;

        console.log(state, symbol);

        // Detail form
        let form = new FleetStatusDetail(this.context, {
            style: "Light",
            title: label,
            overlay: true,
            closable: true,
            state
        });

        // Refresh on submit
        form.onClose = async () => {
            // Reload table
            await this.load();
        }

        // Show form
        form.attach();
    }
}
