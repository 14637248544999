import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";

export class ItemLineLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: "ItemTooltip",
            layer: {
                id: `item-line`,
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": 8,
                    "line-color": "#008efa",
                    "line-opacity": 1
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items, use query to filter
        let items = await this.context.invipo.getItems();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No position?
            if (!item.geometry?.location) {
                continue;
            }

            // Result feature with properties
            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.options.tooltip,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    itemModel: item.model,
                    pointer: true
                },
                geometry: item.geometry.segment
            })
        }

        return json;
    }

}
