import { Feature, FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { PublicTransportVehicleLayer } from "./public-transport-vehicle-layer";

export class PublicTransportVehicleDelayCircleLayer extends PublicTransportVehicleLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            //tooltip: true,
            card: "PublicVehicleCard",
            layer: {
                id: `public-transport-vehicle-delay-circle-layer`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });
    }

    public toFeature(vehicle: any): Feature<Point> {
        // Select color from range
        let range = Helpers.range(0, METRICS.transit.public.delay.colors.length - 1, METRICS.transit.public.delay.range[0], METRICS.transit.public.delay.range[1], vehicle.deviation || 0);
        let color = METRICS.transit.public.delay.colors[Math.round(range)];

        return {
            type: "Feature",
            properties: {
                tooltip: `${this.context.locale.getMessage(`enums.PublicVehicleType.${vehicle.type}`)} ${vehicle.line}\n${vehicle.deviation != null ? `${Helpers.toNumber(vehicle.deviation)} ${this.context.locale.getMessage("units.min")}` : ""}`,
                card: this.options.card,
                cardId: vehicle.vehicleId,
                vehicleId: vehicle.vehicleId,
                vehicleType: vehicle.type,
                vehicleLine: vehicle.line,
                vehicleDeviation: vehicle.deviation,
                vehicleSpeed: vehicle.speed,
                vehicleAgency: vehicle.agency,
                vehicleService: vehicle.service,
                color: color
            },
            geometry: vehicle.position
        }
    }

    public async load(): Promise<FeatureCollection> {
        // Load items
        let vehicles = await this.context.invipo.getDataset("public-vehicles");

        //Sort vehicles by deviation
        vehicles.data.sort((a, b): number => {
            return a.deviation - b.deviation;
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let vehicle of vehicles.data) {
            json.features.push(this.toFeature(vehicle))
        }

        return json;
    }
}
