import "./city-list.scss";
import * as header from "./city-list.header.hbs";
import * as toolbar from "./city-list.toolbar.hbs";
import * as search from "./city-list.search.hbs";
import { CityListOptions } from "./types";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Position } from "geojson";
import { LngLatBounds } from "mapbox-gl";
import { Templates } from "../../../../hiyo/templates";
import { Helpers } from "../../../../hiyo/helpers";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";

export abstract class CityList<T extends CityListOptions = CityListOptions> extends MuklitComponent<InvipoContext, T> {

    // Private listeners
    private keyListener = (e: KeyboardEvent) => this.key(e);

    // Event handling methods
    public onPositionNavigate(position: Position, item?: InvipoItem): void {}
    public onBoundsNavigate(bounds: LngLatBounds, area?: InvipoArea): void {}

    protected constructor(context: InvipoContext, template: any, options: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("city-list-header", header);
        Templates.registerPartial("city-list-toolbar", toolbar);
        Templates.registerPartial("city-list-search", search);
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean) {
        // Super call
        super.attach(target, before, skipLoad);

        // Add keyup listener on document to close self on escape key
        document.addEventListener("keyup", this.keyListener);
    }

    public key(e: KeyboardEvent): void {
        // Will not clsoe self when other dialog is overlaping
        if (document.querySelector("body div.muklit-overlay")) {
            return;
        }

        // ESC pressed so we can hide?
        if (e.key == "Escape") {
            this.close();
        }
    }

    public searchKey(key: number) {
        // Read value
        let value = this.element.querySelector<HTMLInputElement>("input").value;

        // Enter
        if (key == 13) {
            // Something selected?
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear input
            this.clearSearch();
            return;
        }

        // Empty class?
        this.query("div.search").classList.toggle("search-empty", !value?.length);

        // Search for match
        this.search(value);
    }

    public clearSearch(): void {
        // Clear input
        this.query<HTMLInputElement>("div.search input").value = "";

        // Add empty class
        this.query("div.search").classList.toggle("search-empty", true);

        // Reset searched results
        this.search(null);
    }

    public search(term: string): void {
        // Query all items and search based on data-content
        this.queryAll("div.content div.item").forEach((e: HTMLElement) => {
            // Show or hide item based on the element text
            e.classList.toggle("item-hidden", term != null && !Helpers.contains(e.innerText, term));
        })
    }

    public close(): void {
        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Detach from document
        this.detach();

        // Remove all listeners on document level
        document.removeEventListener("keyup", this.keyListener);

        // OnClose handler;
        this.onClose();
    }

    public async extraLoad(): Promise<void> {
        // To overload in subclass to manage extra loading
    };

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Extra loading
        await this.extraLoad();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.update();
    }
}
