import "./envi-detail.scss";
import * as template from "./envi-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { EnviDetailOptions } from "./types";
import { Tabs } from "../../../../muklit/components/tabs/tabs";
import { DataTable } from "../../../../muklit/components/data-table/data-table";
import { Helpers } from "../../../../hiyo/helpers";
import { Templates } from "../../../../hiyo/templates";
import { TabsItem } from "../../../../muklit/components/tabs/types";

export class EnviDetail extends Detail<InvipoContext, EnviDetailOptions> {

    // Components
    public tabs: Tabs;
    public forecast: DataTable;

    constructor(context: InvipoContext, options: EnviDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createForecast();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.forecast, "forecast-table");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "General",
                    label: "components.EnviDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "Forecast",
                    label: "components.EnviDetail.forecast",
                    content: "div.content-forecast"
                }
            ]
        });
    }

    private createForecast(): void {
        // Create component
        this.forecast = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            autosort: true,
            height: "100%",
            rows: {
                id: "timestamp"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    width: 160,
                    formatter: (value: any, data: any) => {
                        return `<div class="cell cell-left">${this.context.locale.getMessage(`components.EnviDetail.${new Date(value).getDate() == new Date().getDate() ? "today" : "tomorrow"}At`, Helpers.toShortTimeString(value))}</div>`;
                    },
                    descendent: true
                },
                {
                    name: "road.surface.scid",
                    type: "String",
                    property: "road.surface.scid",
                    label: "tables.columns.roadSurface",
                    width: 120,
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return;
                        }
                        return `<div class="cell">${Templates.renderPartial("surface-status", {
                            style: "Light",
                            status: value
                        })}</div>`;
                    },
                },
                {
                    name: "road.temperature",
                    type: "String",
                    property: "road.temperature",
                    label: "tables.columns.roadTemperature",
                    width: 120,
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return;
                        }
                        return `<div class="cell cell-center">${Helpers.toNumber(value, 1, 1)} &deg; C</div>`
                    },
                },
                {
                    name: "air.temperature",
                    type: "String",
                    property: "air.temperature",
                    label: "tables.columns.airTemperature",
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return;
                        }
                        return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &deg; C</div>`
                    },
                }

            ]
        });
    }

    async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        let item = await this.context.invipo.getItem(this.options.envi.item.id);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Enable or disable forecast tab
        this.tabs.options.tabs.find(x => x.name == "Forecast").disabled = !item.forecast?.envi;

        // Set forecast data if available
        this.forecast.setData(item.forecast?.envi);

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
