import "./garbage-subdomain.scss";
import * as template from "./garbage-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { GarbageSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { MapLayer } from "../../../../muklit/components/basic-map/map-layer";
import { GarbageLevelHeatmapLayer } from "../../../layers/waste/garbage-level-heatmap-layer";
import { GarbageLevelLabelLayer } from "../../../layers/waste/garbage-level-label-layer";
import { GarbageTemperatureCircleLayer } from "../../../layers/waste/garbage-temperature-circle-layer";
import { GarbageTemperatureLabelLayer } from "../../../layers/waste/garbage-temperature-label-layer";

export class GarbageSubdomain extends CitySubdomain<GarbageSubdomainOptions> {

    constructor(context: InvipoContext, options?: GarbageSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Level",
                    itemClass: "GarbageBin",
                    layers: [
                        new GarbageLevelHeatmapLayer(context),
                        new GarbageLevelLabelLayer(context)
                    ]
                },
                {
                    name: "Temperature",
                    itemClass: "GarbageBin",
                    layers: [
                        new GarbageTemperatureCircleLayer(context),
                        new GarbageTemperatureLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // List of layers
        let layers: MapLayer[] = [];

        // Layers for some metrics only
        if (this.metrics.name == "Level" || this.metrics.name == "Temperature") {
            //layers.push(new ItemCircleLayer(this.context, "GarbageBin"));
        }

        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.bins",
            subtitle: "components.GarbageSubdomain.bins",
            layers: layers
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public async extraLoad(): Promise<void> {
        // Metrics name
        let name = Helpers.toKebabCase(this.metrics.name);

        this.legend = {
            description: `components.GarbageSubdomain.${name}Description`,
            range: {
                range: METRICS.waste.garbage[name].range,
                colors: METRICS.waste.garbage[name].colors,
                count: 0,
                value: 0
            },
            symbols: []
        }
    }
}
