import "./ticket-manager.scss";
import * as template from "./ticket-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TicketManagerOptions } from "./types";
import { TicketDetail } from "../ticket-detail/ticket-detail";
import { TicketDetailOptions } from "../ticket-detail/types";
import { TicketBoard } from "../ticket-board/ticket-board";
import { Filter } from "muklit/components/filter/filter";
import { AccessTicketForm } from "../access-ticket-form/access-ticket-form";
import { TicketTable } from "../ticket-table/ticket-table";
import { TicketOverview } from "../ticket-overview/ticket-overview";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";

export class TicketManager extends MuklitComponent<InvipoContext, TicketManagerOptions> {

    // Components
    public filter: Filter;
    public board: TicketBoard;
    public table: TicketTable;
    public overview: TicketOverview;
    public detail: TicketDetail;

    constructor(context: InvipoContext, options?: TicketManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createFilter();
        this.createBoard();
        this.createTable();
        this.createOverview();

        // Register components that will be automatically attached
        this.registerComponent(this.filter, "filter");
        this.registerComponent(this.board, "board");
        this.registerComponent(this.table, "table");
        this.registerComponent(this.overview, "overview");
    }

    public onAttach(): void {
        // Update tab content visibility
        this.toggle(this.filter.options.tabs.find((tab: any) => tab.selected)?.name ?? "board");
    }


    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createFilter(): void {
        // Create component
        this.filter = new Filter(this.context, {
            style: "Light",
            title: "components.TicketManager.title",
            tabs: [
                {
                    name: "overview",
                    label: "components.TicketManager.overview"
                },
                {
                    name: "board",
                    label: "components.TicketManager.board",
                    selected: true
                },
                {
                    name: "table",
                    label: "components.TicketManager.table",
                }
            ],
            items: [
                {
                    name: "Add",
                    label: "common.add"
                },
                {
                    name: "Reload",
                    label: "common.reload"
                }
            ]
        });

        this.filter.onTabSelect = (tab: any) => {
            this.toggle(tab.name);
        }
    }

    private createBoard(): void {
        // Create component
        this.board = new TicketBoard(this.context);

        // Open ticket detail
        this.board.onTicketSelect = async (ticket: any) => {
            await this.openDetail(ticket);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new TicketTable(this.context);

        // Open ticket detail
        this.table.onTicketSelect = async (ticket: any) => {
            await this.openDetail(ticket);
        }
    }

    private createOverview(): void {
        // Create component
        this.overview = new TicketOverview(this.context);
    }

    private toggle (tab: string) {
        // Update visibility of tab contents
        this.queryAll(`div.content`).forEach((content: HTMLElement) => {
            // is the current element the selected one?
            const selected = content.classList.contains(tab);

            // Update visibility
            if (!content.dataset.display) {
                content.dataset.display = getComputedStyle(content).display;
            }
            content.style.display = selected ? content.dataset.display : "none";
        });
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: TicketDetailOptions;

        // Offense?
        if (data.type == "Offense") {
            options = {
                style: "Light",
                title: data.title || this.context.locale.getMessage("components.TicketManager.offense.title", data.offenseId),
                subtitle: data.data?.access?.area?.name || "components.TicketManager.types.Offense",
                ticketId: data.id,
                ticketType: data.type,
                closable: true,
                menu: [
                    {
                        name: "Delete",
                        label: "labels.delete",
                        escalated: true
                    }
                ]
            };
        }

        // Task?
        if (data.type == "Task") {
            options = {
                style: "Light",
                title: data.title,
                subtitle: "components.TicketManager.types.Task",
                ticketId: data.id,
                ticketType: data.type,
                closable: true
            };
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TicketDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = (item: MenuItem)=> {
            // Archive action?
            if (item.name == "Delete") {
                this.detail.archive();
            }

            // Delete action?
            if (item.name == "Delete") {
                this.detail.delete();
            }
        }

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.board.load();
            await this.table.load();
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.board.unselectAll();
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public async load(): Promise<void> {
        return super.load();
    }

}
