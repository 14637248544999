import "./fleet-driver-detail.scss";
import * as template from "./fleet-driver-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetDriverDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { Driver, DriverScore } from "../../../clients/gpscockpit-client/types";
import { Tabs } from "muklit/components/tabs/tabs";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export class FleetDriverDetail extends Detail<InvipoContext, FleetDriverDetailOptions> {

    // Properties
    public driver: Driver;
    public scoring: DriverScore;

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: FleetDriverDetailOptions) {
        super(context, template, options);

        Handlebars.registerHelper("asDriverDuration", (t: number = 0) => {
            const h = Math.floor(t / 3600);
            const m = Math.floor((t - h * 3600) / 60);
            const s = Math.floor(t - h * 3600 - m * 60);

            return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
        });
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
    }

    private createTabs(): void {
        this.tabs = new Tabs(this.context, {
            style: this.options.style,
            tabs: [
                {
                    name: "general",
                    label: "components.FleetDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "scoring",
                    label: "components.FleetDetail.scoring",
                    content: "div.content-scoring"
                },
            ]
        });

        this.registerComponent(this.tabs, "tabs");
    }

    public async load(): Promise<void> {
        this.showLoader();

        this.driver = this.options.driver;

        const to = new Date();
        let from = new Date();
        from.setMonth(from.getMonth() - 12);

        const scoring = await this.context.gpsCockpit.getDriverScoring(this.driver.id, this.driver.accountId, from, to);
        console.log(scoring);
        this.scoring = scoring[0];

        this.hideLoader();

        this.invalidate(true);
    }

}
