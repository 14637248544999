import "./camera-card.scss";
import * as template from "./camera-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CameraCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class CameraCard extends CityCard<CameraCardOptions> {

    constructor(context: InvipoContext, options: CameraCardOptions) {
        super(context, template, options);
    }
}
