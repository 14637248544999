import "./cits-vehicle-browser.scss";
import * as template from "./cits-vehicle-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { DateInput } from "muklit/components/date-input/date-input";
import { Select } from "muklit/components/select/select";
import { CitsVehicleBrowserOptions } from "./types";
import { TextInput } from "muklit/components/text-input/text-input";
import { InvipoHelpers } from "../../../invipo-helpers";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";
import { FilterItem } from "../../../../muklit/components/filter/types";

export class CitsVehicleBrowser extends MuklitComponent<InvipoContext, CitsVehicleBrowserOptions> {

    // Components
    public table: FilterTable;

    constructor(context: InvipoContext, options?: CitsVehicleBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/cits-vehicles-history`,
            http: this.context.invipo.http,
            filter: {
                title: "components.CitsVehicleBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from"
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.CitsVehicleType"))
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "role",
                                label: "forms.fields.role",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.CitsVehicleRole"))
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "lpn",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.CitsVehicleType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        sortable: true,
                        ellipsis: true,
                        width: 200
                    },
                    {
                        name: "role",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.CitsVehicleRole.${data.role}`);
                        },
                        label: "tables.columns.role",
                        sortable: true,
                        ellipsis: true,
                        width: 200
                    },
                    {
                        name: "lpn",
                        type: "String",
                        property: "lpn",
                        label: "tables.columns.plate",
                        sortable: true,
                        minWidth: 150
                    },
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "CitsVehiclesHistoryCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getUrl(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
