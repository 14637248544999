import './fleet-previous-day-tile.scss';
import * as template from "./fleet-previous-day-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetPreviousDayTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis } from "../../common/tile/types";
import { Helpers } from "../../../../hiyo/helpers";

export class FleetPreviousDayTile extends Tile<FleetPreviousDayTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: FleetPreviousDayTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        const data = await this.context.gpsCockpit.getDashboardLocationCount();
        data.sort((a, b) => a.summaryDate > b.summaryDate ? 1 : -1);

        const last = data[data.length - 1];
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.FleetPreviousDayTile.distanceDriven",
                    value: `${Helpers.toNumber(last.distanceInMeters / 1000)} ${this.context.locale.getMessage("units.km")}`
                },
                {
                    label: "components.FleetPreviousDayTile.activeVehicles",
                    value: Helpers.toNumber(last.deviceMovingCount)
                }
            ]
        }
    }

}
