import "./fleet-trip-detail.scss";
import * as template from "./fleet-trip-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetTripDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { Asset, Trip, TripDetail } from "../../../clients/gpscockpit-client/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { FleetTripLineLayer } from "invipo/layers/transit/fleet-trip-line-layer";
import { FleetTripPointFillLayer } from "invipo/layers/transit/fleet-trip-point-fill-layer";

export class FleetTripDetail extends Detail<InvipoContext, FleetTripDetailOptions> {

    public trip: Trip;
    public tripDetail: TripDetail[];
    public asset: Asset;

    public map: BasicMap;

    constructor(context: InvipoContext, options: FleetTripDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 0,
            maxZoom: 20
        });

        this.registerComponent(this.map, 'map');

        // TODO: add layers - start point, endpoint, route polyline
    }

    public async load(): Promise<void> {
        this.showLoader();

        this.trip = this.options.trip;
        this.asset = await this.context.gpsCockpit.getAsset(this.options.trip.assetId);
        this.tripDetail = await this.context.gpsCockpit.getTrip(this.options.trip.id);

        console.log("FleetTripDetail.load", this.options.trip, this.asset, this.trip, this.tripDetail);

        this.hideLoader();

        this.invalidate(true);

        this.map.removeLayers();
        this.map.addLayer(new FleetTripLineLayer(this.context, this.tripDetail));
        this.map.addLayer(new FleetTripPointFillLayer(this.context, this.tripDetail));

        this.map.fitAll();
    }

}
