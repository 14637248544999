// Imports
import { Log, LogColor } from "./log";
import { View } from "./view";
import { Component } from "./component";
import { HiyoObject } from "./hiyo-object";
import { Context } from "./context";
import { Templates } from "./templates";
import { Helpers } from "./helpers";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export abstract class Application<T extends Context = Context> extends HiyoObject<T> {

    // Properties
    public views: View<T>[];
    public currentView: View<T>;

    // Event handling methods
    public onCreate(): void {};
    public onViewChange(view: View): void {};

    protected constructor(context: T, views?: View<T>[]) {
        super(context);

        Log.i(`Hiyo application ${this.constructor.name} created. Welcome.`, LogColor.Magenta);

        this.views = views || [];

        // Self to context
        this.context.application = this;

        // Register helpers
        this.registerHelpers();

        // History listener
        window.addEventListener("popstate", (e: PopStateEvent) => {
            // View id inside?
            if (e.state?.viewId && e.state?.viewId != this.currentView?.id) {
                this.changeView(e.state?.viewId, true);
            }
        });

        // Empty document.body for start
        document.body.innerHTML = '';

        // onCrete event
        this.onCreate();

        // Put itself into Window context (window.app from browser console)
        (<any>window).app = this;
    }

    public registerView(view: View<T>): void {
        this.views.push(view);
    }

    public registerHelpers(): void {
        // All static helpers
        Templates.registerStaticHelpers();

        // Component render
        Handlebars.registerHelper("render", (c: Component) => {
            if (!c) {
                Log.w(`{{render <component>}} has invalid parameter`);
            }
            return c.render();
        });

        // Localization
        Handlebars.registerHelper("message", (key: string | any, ...args: any) => {
            // Empty message?
            if (!key) {
                return;
            }

            // Not string key means that key must be evaluated from tag's body
            if (typeof key != "string") {
                key = key.fn(this);
            }
            else {
                // Remove last arguments, hHandlebars context
                args.pop();
            }

            return this.context.locale.getMessage(key, ...args);
        });

        // Has user role(s)
        Handlebars.registerHelper("permission", (permissions: string, options: any) => {
            let required = permissions.split(",");

            // Check for permission
            if (this.context.checkPermission(required)) {
                return options.fn(this);
            }

            return options.inverse(this);
        });
    }

    public run(viewId?: string): void {
        Log.i(`Running application ${this.constructor.name}, muhehehe! `, LogColor.Magenta);

        // Attach all registered components
        for (let key in this.components) {
            this.components[key].attach();
        }

        // Get view from URL
        let urlViewId = new URLSearchParams(window.location.search).get("v");

        // Get view from storage
        let storedViewId = localStorage.getItem(`hiyo.${this.name}.viewId`);

        // Change to view by priority
        this.changeView(viewId || urlViewId || storedViewId || this.views[0]?.id);
    }

    public restart(): void {
        Log.i(`Restarting application, angry or happy?`, LogColor.Magenta);

        // Invalidate all single registered components
        for (let key in this.components) {
            const component = this.components[key];
            component.invalidate()
        }

        // Invalidate View’s components
        if (this.currentView) {
            this.currentView.invalidate();
        }
    }

    public changeView(id: string, noHistory?: boolean): void {
        // No views defined?
        if (!this.views || this.views.length == 0) {
            Log.w(`Application has no views, use registerView(view) to add a view first`);
            return;
        }

        // Find by id
        let view = this.views.find(x => x.id == id);

        // Not found?
        if (!view) {
            Log.w(`View ${id} not found`);
            return;
        }

        // Already on the same view?
        if (this.currentView == view) {
            Log.w(`Could not change to view ${view.id}, already active`);
            return;
        }

        // Leave view
        if (this.currentView) {
            this.currentView.leave();
        }

        // Assign new view
        this.currentView = view;

        // Store to local storage
        localStorage.setItem(`hiyo.${this.name}.viewId`, id);

        // Enter view
        this.currentView.enter();

        // Add record to history
        if (!noHistory) {
            // Do not dismiss previous params!
            let params = new URLSearchParams(window.location.search);

            // Set view as v param
            params.set("v", id);

            // Push new state
            history.pushState({ viewId: id }, id, `?${params.toString()}`);
        }

        // OnViewChange handler
        this.onViewChange(this.currentView);
    }

}
