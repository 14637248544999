import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "muklit/components/basic-map/map-layer";
import { Helpers } from "../../../hiyo/helpers";
import { InvipoItem } from "../../clients/invipo-client/types";

export class ItemLabelLayer extends MapLayer<InvipoContext> {

    // Properties
    public items: InvipoItem[];

    public constructor(context: InvipoContext, items?: InvipoItem[]) {
        super(context, {
            dynamic: true,
            layer: {
                id: `item-${Helpers.createUuid()}-label`,
                type: "symbol",
                minzoom: ZOOM_LABEL,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1,
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 1.8],
                    "text-anchor": "top",
                    "text-allow-overlap": false,
                    "text-max-width": 30
                }
            }
        });

        // Assign properties
        this.items = items;
    }

    public async load(): Promise<any> {
        // Load or reuse items
        let items = this.items || await this.context.invipo.getItems();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No location?
            if (!item.geometry?.location) {
                continue;
            }

            json.features.push({
                type: "Feature",
                properties: {
                    card: "ItemCard",
                    detail: "CityDetail",
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    label: `${item.name}\n${item.model || item.producer || ""}`
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
