import "./drawer-menu.scss";
import * as template from "./drawer-menu.hbs";
import { Component } from "hiyo/component";
import { InvipoContext } from "../../../context/invipo-context";
import { DrawerMenuOptions, DrawerGroup, DrawerItem } from "./types";
import { Log } from "hiyo/log";

export class DrawerMenu extends Component<InvipoContext, DrawerMenuOptions> {

    // Event handling methods
    public onSelect(item: DrawerItem, group?: DrawerGroup): void {};

    constructor(context: InvipoContext, options: DrawerMenuOptions) {
        super(context, template, options);
    }

    public select(groupName: string, itemName: string): void {
        let group = this.options.groups.find(x => x.name == groupName);

        // Group not found?
        if (!group) {
            Log.w(`Unknown group ${groupName} to select in ${this.id}`);
            return;
        }

        let item = group.items.find(x => x.name == itemName);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${itemName} to select in ${this.id}`);
            return;
        }

        // Already selected?
        if (item.selected) {
            return;
        }

        // Deselect all groups and items
        this.options.groups.forEach(x => {
            x.selected = false;
            x.items.forEach(y => {
                y.selected = false;
            })
        });

        // Select current
        group.selected = true;
        item.selected = true;

        // Redraw
        this.update();

        // OnItemSelect handler
        this.onSelect(item, group);
    }

}
