import { Select } from "muklit/components/select/select";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemSelectOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";

export class ItemSelect extends Select<InvipoContext, ItemSelectOptions> {

    public items: InvipoItem[];

    public async load(): Promise<void> {
        // Load items
        this.items = await this.context.invipo.getItems(`class=${this.options.itemClass}&sort=name:asc`);

        // Filter active?
        if (this.options.itemFilter) {
            this.items = this.items.filter(x => this.options.itemFilter(x));
        }

        // Reset menu options
        this.options.items = []

        for (let item of this.items) {
            // Add item
            this.options.items.push({
                name: item.id,
                label: item.name
            });
        }

        // Value rearrangement
        if (this.options.value && typeof this.options.value == "string") {
            let item = this.options.items.find(x => x.name == this.options.value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[item.name] = item.label;
            }
        }

        // Gone while loading?
        if (!this.isAttached()) {
            return;
        }

        // Reattach select to render it properly
        this.update();
    }

}
