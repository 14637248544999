import "./image-detail.scss";
import * as template from "./image-detail.hbs";
import { Detail } from "muklit/components/detail/detail";
import { InvipoContext } from "../../../context/invipo-context";
import { ImageDetailOptions } from "./types";

export class ImageDetail extends Detail<InvipoContext, ImageDetailOptions> {

    // Properties
    public dragging: boolean;
    public moving: boolean;
    public previousX: number;
    public previousY: number;

    constructor(context: InvipoContext, options: ImageDetailOptions) {
        super(context, template, options);
    }

    public setDragging(dragging: boolean): void {
        this.dragging = dragging;

        // Cursor switch
        this.query("div.image").style.cursor = this.dragging ? "grabbing" : "grab";
    }

    public mouseDown(e: MouseEvent): void {
        // Stop propagation
        e.preventDefault();

        // Keep position
        this.previousX = e.clientX;
        this.previousY = e.clientY;

        // Reset mooving
        this.moving = false;

        this.setDragging(true);
    }

    public mouseUp(e: MouseEvent): void {
        // If not dragged, we will zoom in/out
        if (!this.moving) {
            this.element.classList.toggle("invipo-image-detail-zoomed", !this.element.classList.contains("invipo-image-detail-zoomed"));
        }

        this.setDragging(false);
    }

    public mouseLeave(e: MouseEvent): void {
        this.setDragging(false);
    }

    public mouseMove(e: MouseEvent): void {
        // Mouse not down?
        if (!this.dragging) {
            return;
        }

        // Stop propagation
        e.preventDefault();

        // Set moving
        this.moving = true;

        // Image container
        let image = this.query("div.image");

        // Scroll by move
        image.scrollTo({
            left: image.scrollLeft + (this.previousX - e.clientX),
            top: image.scrollTop + (this.previousY - e.clientY)
        })

        // Store last positions
        this.previousX = e.clientX;
        this.previousY = e.clientY;
    }
    public setUrl(url: string): void {
        // Assign to options
        this.options.url = url;

        // Change image source
        this.query<HTMLImageElement>("div.image img").src = url;
    }

    public close = (back?: boolean) => {
        // It is important to close all running HTTP streams
        // by repalcing image source before close
        let image = this.query<HTMLImageElement>("div.image img");
        image.src = "";

        // Call super method
        super.close(back);
    }

    public async load(): Promise<void> {
        // Image element
        let image = this.query<HTMLImageElement>("div.image img");

        // Image not loaded?
        if (!image.complete || image.naturalHeight == 0) {
            // Show loader
            this.showLoader();

            // Hide loader when image is loaded
            image.onload = () => {
                this.hideLoader();
            }
        }
    }
}
