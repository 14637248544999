import "./inbox-detail.scss";
import * as template from "./inbox-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { InboxDetailOptions } from "./types";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class InboxDetail extends Detail<InvipoContext, InboxDetailOptions> {

    // Components
    public tabs: Tabs;

    // Event handling methods
    public onImageSelect(image: any): void {}

    constructor(context: InvipoContext, options: InboxDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "General",
                    label: "details.tabs.message",
                    content: "div.content-message",
                    selected: true
                },
                {
                    name: "Details",
                    label: "details.tabs.details",
                    content: "div.content-details"
                }
            ]
        });
    }

    public selectImage(i: number): void {
        // OnImageSelect handler
        this.onImageSelect(this.options.inbox.event.extras.images[i]);
    }
}
