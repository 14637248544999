import "./fleet-asset-card.scss";
import * as template from "./fleet-asset-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { Helpers } from "hiyo/helpers";
import { FleetAssetCardOptions } from "./types";
import { Asset, DeviceState, StateLookup } from "invipo/clients/gpscockpit-client/types";

export class FleetAssetCard extends CityCard<FleetAssetCardOptions> {

    // Properties
    public asset: Asset

    constructor(context: InvipoContext, options: FleetAssetCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        this.asset = JSON.parse(this.options.asset);

        if (this.asset.state?.odometer?.gpsOdometer) {
            this.asset.state.odometer.gpsOdometer /= 1000;
        }
        if (this.asset.state?.odometer?.canBusOdometer) {
            this.asset.state.odometer.canBusOdometer /= 1000;
        }
    }
}
