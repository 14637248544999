import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";

export class ItemCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: "ItemTooltip",
            layer: {
                id: "item-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 2,
                        12, 4,
                        18, 6,
                        22, 9
                    ],
                    "circle-color": [
                        "case",
                        ["==", ["get", "itemLocked"], true], "#aaaaaa",
                        "#008efa"
                    ],
                    "circle-opacity": 1,
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 2,
                        12, 3,
                        18, 4,
                        22, 5
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0.75
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items, use query to filter
        let items = await this.context.invipo.getItems();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No position?
            if (!item.geometry?.location) {
                continue;
            }

            // Result feature with properties
            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.options.tooltip,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    itemModel: item.model,
                    itemLocked: item.locked,
                    pointer: true
                },
                geometry: item.geometry.location
            })
        }

        return json;
    }

}
