import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";

export class PublicTransportStopCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            //tooltip: true,
            card: "PublicTransportStopCard",
            layer: {
                id: "public-transport-stop-circle-layer",
                type: "circle",
                minzoom: 14,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 2,
                        22, 4
                    ],
                    "circle-color": "#ffffff",
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 1,
                        22, 3
                    ],
                    "circle-stroke-color": "#161616",
                    "circle-stroke-opacity": 0, // 0 -> 0.75
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.8
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // Load items
        let stops = await this.context.invipo.getManagement("transit/public/stops");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let stop of stops) {
            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: `${stop.name}${stop.platform ? `\n${stop.platform}` : ""}`,
                    //card: this.options.card,
                    //cardId: stop.id,
                    stopId: stop.id,
                    stopName: stop.name,
                    stopPlatform: stop.platform
                },
                geometry: {
                    type: "Point",
                    coordinates: stop.position.coordinates
                }
            })

        }

        return json;
    }
}
