import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoHelpers } from "../../invipo-helpers";

export class ParkingSignCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: true,
            card: "ParkingSignCard",
            detail: "CityDetail",
            layer: {
                id: "parking-sign-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-color": "#8b88ff",
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.4
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.9
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems("class=ParkingSign");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Build unique position list
        let cluster = InvipoHelpers.toItemCluster(items);

        // Push features from positions
        for (let items of Object.values(cluster)) {
            // Add feature
            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: items.length > 1 ? `${this.context.locale.getMessage("classes.ParkingSign")}\n${items.length}×` : items[0].name,
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: items.map(x => { return x.id }).join(","),
                    itemId: items.map(x => { return x.id }).join(","),
                    itemName: items.length == 1 ? items[0].name : null,
                    itemClass: items.length == 1 ? items[0].class : null,
                    scale: items.length == 1 ? 0.7 : 1
                },
                geometry: items[0].geometry.location // Take first item, all have same positions!
            })
        }

        return json;
    }


}
