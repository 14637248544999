import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "hiyo/helpers";
import { DEFAULT_LANE_CAPACITY } from "../../components/traffic/traffic-counting-subdomain/traffic-counting-subdomain";
import { METRICS } from "../../components/city/city-subdomain/types";

export class TrafficVolumeSegmentLabelLayer extends MapLayer<InvipoContext> {

    // Properties
    public itemId: string;

    public constructor(context: InvipoContext, itemId?: string) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "traffic-volume-segment-label",
                type: "symbol",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 14,
                    "text-line-height": 1.2,
                    "text-offset": [0, 1.8],
                    "text-anchor": "center",
                    "text-allow-overlap": false,
                    "text-max-width": 15
                }
            }
        });

        // Assign properties
        this.itemId = itemId;
    }

    public async load(): Promise<any> {
        // Items query
        let query = "class=TrafficCounter,WimStation";

        if (this.itemId) {
            query += `&id=${this.itemId}`;
        }

        // Load items
        let items = await this.context.invipo.getItems(query);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No segments defined
            if (!item.meta?.segments?.length) {
                continue;
            }

            // Iterate all segments
            for (let segment of item.meta.segments) {
                // Total volume, null means no data
                let volume = null;

                // Volume color, default is no data color
                let color = "#aaaaaa";

                // Find data for segment
                let data = (<any[]>item.data?.traffic)?.find(x => x.segment == segment.name);

                // Segment has data?
                if (data) {
                    // Calcualte segment volume as percentage of capacity
                    volume = (data.count || 0) * (60 / this.context.config.aggregations.traffic.interval) / (segment?.capacity || DEFAULT_LANE_CAPACITY) * 100;

                    // Color calculation from range
                    let range = Helpers.range(0, METRICS.traffic.counting.volume.colors.length - 1, METRICS.traffic.counting.volume.range[0], METRICS.traffic.counting.volume.range[1], volume);
                    color = METRICS.traffic.counting.volume.colors[Math.round(range)];
                }

                json.features.push({
                    type: "Feature",
                    properties: {
                        label: data ? `${segment.name}\n${Helpers.toVph(data.count || 0, this.context.config.aggregations.traffic.interval)} ${this.context.locale.getMessage(segment.type == "Bicycle" ? "units.bph" : "units.vph")}` : segment.name
                    },
                    geometry: {
                        type: "Point",
                        coordinates: segment.segment.coordinates[0]
                    }
                });
            }
        }

        return json;
    }
}
