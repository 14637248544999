import "./traffic-events-subdomain.scss";
import * as template from "./traffic-events-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficEventsSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { TrafficEventCircleLayer } from "../../../layers/traffic/traffic-event-circle-layer";
import { TrafficEventSymbolLayer } from "../../../layers/traffic/traffic-event-symbol-layer";
import { TrafficEventList } from "../traffic-event-list/traffic-event-list";
import { HiyoEvent } from "../../../../hiyo/event-broker";

export class TrafficEventsSubdomain extends CitySubdomain<TrafficEventsSubdomainOptions> {

    // Properties
    public events: any[];

    constructor(context: InvipoContext, options?: TrafficEventsSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Events",
                    layers: [
                        //new TrafficEventLineLayer(context),
                        new TrafficEventCircleLayer(context),
                        new TrafficEventSymbolLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public onAttach(): void {
        this.context.broker.subscribe(this, ["TrafficEventReceived", "TrafficEventRemoved"]);
    }

    public onDetach(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent) {
        // Reload
        await this.load();
    }

    public selectEvents(events?: any[], tags?: string[]): void {
        // Crate item list
        let list = new TrafficEventList(this.context, {
            style: "Light",
            title: "components.TrafficEventList.title",
            subtitle: "components.TrafficEventList.subtitle",
            action: "components.TrafficEventList.newMessage",
            tags: tags || [],
            layers: []
        });

        // Messages already loaded, will use them
        list.events = events || this.events;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string) {
        this.selectEvents(this.events.filter(x => x.type == symbol), [`enums.TrafficEventType.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Empty all data
        this.events = null;

        // Events metrics?
        if (this.metrics.name == "Events") {
            // Load traffic events data
            this.events = await this.context.invipo.getQuery("traffic-events-current");

            // Create events legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let i in METRICS.traffic.events.events.range) {
                let symbol = METRICS.traffic.events.events.range[i]
                let count = this.events.filter(x => x.type == symbol).length;

                this.legend.symbols.push({
                    symbol: symbol,
                    count: count,
                    label: `enums.TrafficEventType.${symbol}`,
                    percent: count / (this.events.length || 1) * 100,
                    color: METRICS.traffic.events.events.colors[i],
                    selectable: true
                });
            }
        }
    }

}
