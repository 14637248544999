import { AreaSpotLayer } from "../area-spot-layer";
import { InvipoContext } from "../../context/invipo-context";
import { ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";

export class AccessAreaLabelLayer extends AreaSpotLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "Access",
            dynamic: true,
            pointer: true,
            layer: {
                id: "access-area-label",
                type: "symbol",
                maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "areaName"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 14,
                    "text-offset": [0, -0.8],
                    "text-anchor": "bottom",
                    "text-max-width": 10,
                    "text-allow-overlap": false
                }
            }
        });
    }
}
