import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { WasteCollectionStatsOptions } from "./types";
import { WasteCollectionChart } from "../waste-collection-chart/waste-collection-chart";

export class WasteCollectionStats extends StatsDetail<WasteCollectionStatsOptions> {

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date(new Date().setHours(-24 * 30, 0, 0, 0)).toISOString(),
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            bright: true
                        }),
                        new AutocompleteSelect(this.context, {
                            style: "Light",
                            collection: "Waste",
                            name: "vehicle",
                            label: "forms.fields.vehicle",
                            placeholderText: "forms.placeholders.vehicle",
                            items: [],
                            type: "Vehicle",
                            multiselect: true,
                            bright: true
                        })
                        /*new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.all",
                            itemClass: "GarbageBin",
                            items: [],
                            multiselect: true,
                            bright: true
                        })*/
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.chart.filter(data);
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new WasteCollectionChart(this.context, {
            style: "Light",
            queryName: "WasteCollectionByDay",
            view: "Chart",
            title: "components.WasteCollectionStats.title",
            search: this.form.getData(),
            closable: this.options.closable
        });

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

}
