import { FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoItem } from "../../clients/invipo-client/types";

export class ItemSingleCircleLayer extends MapLayer<InvipoContext> {

    // Properties
    public itemId: string;

    public constructor(context: InvipoContext, itemId?: string) {
        super(context, {
            dynamic: true,
            tooltip: true,
            layer: {
                id: "item-single-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 4,
                        12, 5,
                        16, 8,
                        19, 10,
                        22, 12
                    ],
                    "circle-color": "#008efa",
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.8
            }
        });

        // Assign properties
        this.itemId = itemId;
    }

    public async load(): Promise<any> {
        let item = await this.context.invipo.getItem(this.itemId);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // No location?
        if (!item.geometry?.location) {
            return json;
        }

        json.features.push({
            type: "Feature",
            properties: {
                tooltip: `${item.name}\n${item.model ? item.model : item.producer ? item.producer : ""}`,
                //card: "ItemCard",
                card: "MonitoringCard",
                detail: "CityDetail",
                cardId: item.id,
                itemId: item.id,
                itemName: item.name,
                itemClass: item.class
            },
            geometry: item.geometry["location"]
        })

        return json;
    }
}
