import "./client-export-form.scss";
import * as template from "./client-export-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { Tabs } from "muklit/components/tabs/tabs";
import { ClientExportFormOptions } from "./types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import jsPDF from "jspdf";
import { Log } from "../../../../hiyo/log";
import { Select } from "../../../../muklit/components/select/select";

export class ClientExportForm extends Detail<InvipoContext, ClientExportFormOptions> {

    // Components
    public tabs: Tabs;
    public pdf: Form;
    public csv: Form;

    // Event handling methods
    public onPdfExport(orientation: string, title?: string): void {};
    public onCsvExport(name: string): void {};

    public constructor(context: InvipoContext, options: ClientExportFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createForms();

        // Register components that will be always attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: []
        });

        // PDF export tab
        if (this.options.exportPdf) {
            this.tabs.options.tabs.push(
                {
                    name: "Pdf",
                    label: "components.ClientExportForm.pdf",
                    content : "div.content-pdf"
                }
            )
        }

        // CSV export tab
        if (this.options.exportCsv) {
            this.tabs.options.tabs.push(
                {
                    name: "Csv",
                    label: "components.ClientExportForm.csv",
                    content : "div.content-csv"
                }
            )
        }

        // Select first tab
        if (this.tabs.options.tabs.length > 0) {
            this.tabs.options.tabs[0].selected = true;
        }
    }

    private createForms(): void {
        // PDF export form enabled?
        if (this.options.exportPdf) {
            this.pdf = new Form(this.context, {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new Select(this.context, {
                                style: "Light",
                                name: "orientation",
                                label: "forms.fields.orientation",
                                value: "Portrait",
                                items: [
                                    {
                                        name: "Portrait",
                                        label: "components.ClientExportForm.orientationPortrait",
                                        selected: true
                                    },
                                    {
                                        name: "Landscape",
                                        label: "components.ClientExportForm.orientationLandscape"
                                    }
                                ],
                                width: 280,
                                bright: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "title",
                                label: "forms.fields.title",
                                placeholderText: "forms.placeholders.documentTitle",
                                width: 280,
                                bright: true
                            })
                        ]
                    }
                ]
            });

            this.registerComponent(this.pdf, "pdf");
        }

        // CSV export form enabled?
        if (this.options.exportCsv) {
            this.csv = new Form(this.context, {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new TextInput(this.context, {
                                style: "Light",
                                name: "name",
                                label: "forms.fields.name",
                                placeholderText: "forms.placeholders.name",
                                value: `export-${new Date().getTime()}.csv`,
                                width: 280,
                                bright: true
                            })
                        ]
                    }
                ]
            });

            this.registerComponent(this.csv, "csv");
        }
    }

    public export(): void {
        let tab = this.tabs.options.tabs.find(x => x.selected);

        switch (tab?.name) {
            // PDF export?
            case "Pdf":
                let pdf = this.pdf.getData(true);
                this.onPdfExport(pdf.orientation, pdf.title);
                break;
            // CSV export?
            case "Csv":
                let csv = this.csv.getData(true);
                this.onCsvExport(csv.name);
                break;
            default:
                Log.w(`Unsupported export action ${tab?.name}`);
        }

        // Close
        this.close();
    }


}
