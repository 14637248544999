import "./parking-sensor-card.scss";
import * as template from "./parking-sensor-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { ParkingSensorCardOptions } from "./types";
import { CardHistoryValue } from "../../city/city-card/types";

export class ParkingSensorCard extends CityCard<ParkingSensorCardOptions> {

    // Properties
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: ParkingSensorCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery("parking-occupancy-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Any occupancy sattus wil block the sensor, no data means free
            this.history.push({
                timestamp: from.toISOString(),
                value: d?.occupancy || 100,
                percent: d?.occupancy || 100,
                color: d?.occupancy ? "#ff4d4d" : "#00e66a"
            });

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }

}
