import "./item-detail-form.scss";
import * as template from "./item-detail-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { Tabs } from "muklit/components/tabs/tabs";
import { TextInput } from "muklit/components/text-input/text-input";
import { Select } from "muklit/components/select/select";
import { ItemDetailFormOptions } from "./types";
import { DesignMap } from "muklit/components/design-map/design-map";
import { TabsItem } from "muklit/components/tabs/types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { InvipoItem } from "../../../clients/invipo-client/types";

export class ItemDetailForm extends Detail<InvipoContext, ItemDetailFormOptions> {

    // Properties
    public item: InvipoItem;

    // Components
    public tabs: Tabs;
    public form: Form;
    public map: DesignMap;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: ItemDetailFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs()
        this.createForm();
        this.createMap();

        // Register components that will be always attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.form, "form");
        this.registerComponent(this.map, "map");
    }

    public onAttach(): void {
        this.map.fitAll();
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: this.options.style,
            tabs: [
                {
                    name: "General",
                    label: "components.ItemDetail.general",
                    content: "div.content-general",
                    selected: this.options.tab == null || this.options.tab == "General"
                },
                {
                    name: "Map",
                    label: "components.ItemDetail.map",
                    content: "div.content-map",
                    selected: this.options.tab == "Map"
                },
            ]
        });

        this.tabs.onSelect = (item: TabsItem) => {
            // Resize map to fit div.content
            if (item.name == "Map") {
                this.map.resize();
            }
        }
    }

    private createForm(): void {
        // item detail form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Left",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "name",
                            label: "forms.fields.name",
                            placeholderText: "forms.placeholders.name",
                            width: 288,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "class",
                            label: "item.class",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.options.classes, "classes.", (a, b) => {
                                return this.context.locale.getMessage(a.label).localeCompare(this.context.locale.getMessage(b.label));
                            }),
                            width: 288,
                            bright: true,
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "producer",
                            label: "forms.fields.producer",
                            placeholderText: "forms.placeholders.producer",
                            width: 288,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "model",
                            label: "forms.fields.model",
                            placeholderText: "forms.placeholders.model",
                            width: 288,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Right",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "location",
                            label: "forms.fields.location",
                            placeholderText: "forms.placeholders.location",
                            width: 288,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "managementUrl",
                            label: "forms.fields.managementUrl",
                            placeholderText: "forms.placeholders.managementUrl",
                            width: 288,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public createMap () {
        // Create component
        this.map = new DesignMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom
        });
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let data = this.form.getData(true);

        // Merge item with options and form data to not lose anything while updating
        let updatedItem = {
            // All original properties
            name: this.item.name,
            class: this.item.class,
            parent: {
                id: this.item.parent?.id
            },
            producer: this.item.producer,
            model: this.item.model,
            managementUrl: this.item.managementUrl,
            location: this.item.location,
            schema: this.item.schema,
            tags: this.item.tags,
            group: this.item.group,

            // Merge with new geometry object
            geometry: this.map.getGeometry(),

            // Merge with new form data
            ...data,
        };

        // All nulls must be removed otherwise they will be stored inside database
        //Helpers.removeNulls(item);

        // Show loader
        this.showLoader();

        // Update existing user
        try {
            await this.context.invipo.updateItem(this.item.id, updatedItem);
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Close self
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.item = await this.context.invipo.getItem(this.options.itemId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Set data from options to form
        this.form.setData(this.item);

        // Set geometry if it has
        if (this.item.geometry) {
            this.map.setGeometry(this.item.geometry);
            this.map.fitAll();
        }
    }

}
