import "./calendar.scss";
import * as template from "./calendar.hbs";
import { CalendarOptions, CalendarWeek, CalendarDaySelectionState } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Context } from "hiyo/context";
import { Helpers } from "hiyo/helpers";

export class Calendar extends MuklitComponent<Context, CalendarOptions> {

    // Properties
    public weeks: CalendarWeek[];
    public dayLabels: string[];
    public viewMonth: Date; // current month displayed in the calendar

    public selectedFrom: Date; // output: from
    public selectedTo: Date; // output: to

    public year: number; // should be a getter
    public month: string; // should be a getter
    public disableNextMonth: boolean;


    // Event handling methods`
    public onDateSelect(from: string, to?: string): void {};

    constructor(context: Context, options: CalendarOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Set initial date range
        if (this.options.from) this.selectedFrom = new Date(this.options.from);
        if (this.options.to) this.selectedTo = new Date(this.options.to);

        // Select appropriate month
        if (this.selectedFrom) this.viewMonth = new Date(this.selectedFrom);
        else this.viewMonth = new Date();
        this.viewMonth.setDate(1);
        this.viewMonth.setHours(0,0,0,0);

        // Prepare view model
        this.createDayLabels()
    }

    public render(): string {
        // Options changed, update properties before rendering
        this.updateMonthYear();
        this.createWeeks();

        // Original render method
        return super.render();
    }


    public show(trigger: HTMLElement): void {
        // Already visible?
        if (this.isAttached()) {
            return;
        }

        // Attach to document
        this.attach();

        // Trigger element positions
        let rect = trigger.getBoundingClientRect();

        // Pivot point of the trigger
        let startX = 0;
        let startY = 0;

        // Get the trigger point
        if (this.options.start == "TopLeft") {
            startX = rect.left;
            startY = rect.top;
        }
        else if (this.options.start == "TopRight") {
            startX = rect.left + rect.width;
            startY = rect.top;
        }
        else if (this.options.start == "BottomLeft") {
            startX = rect.left;
            startY = rect.top +  rect.height;
        }
        else {
            startX = rect.left + rect.width;
            startY = rect.top + rect.height;
        }

        // Menu position
        let left = 0;
        let top = 0;

        // Assign menu position
        if (this.options.anchor == "TopLeft") {
            left = startX + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "TopRight") {
            left = startX - this.element.offsetWidth + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "BottomLeft") {
            left = startX + document.body.scrollLeft;
            top = startY - this.element.offsetHeight + document.body.scrollTop;
        }
        else {
            left = startX - this.element.offsetWidth + document.body.scrollLeft;
            top = startY - this.element.offsetHeight + document.body.scrollTop;
        }

        // Move position by offset
        if (this.options.offset?.length == 2) {
            left += this.options.offset[0];
            top += this.options.offset[1];
        }

        // Set position to style
        this.element.style.left = `${left}px`;
        this.element.style.top = `${top}px`;
    }

    public hide(): void {
        // Detach from document
        if (this.isAttached()) this.detach();
    }

    private createWeeks(): void {
        this.weeks = [];

        let y = this.viewMonth.getFullYear();
        let m = this.viewMonth.getMonth();

        // Range of visible month
        const startOfMonth = new Date(y, m);

        const endOfMonth = new Date(y, m + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);

        // Full range of visible calendar
        const startOfCalendar = new Date(startOfMonth);
        startOfCalendar.setDate(startOfCalendar.getDate() - startOfMonth.getUTCDay())

        const endOfCalendar = new Date(endOfMonth);
        if (endOfCalendar.getUTCDay() > 0) {
            endOfCalendar.setDate(endOfCalendar.getDate() + (6 - endOfMonth.getUTCDay()))
        }

        // Generate weeks
        const date = new Date(startOfCalendar);
        while (date < endOfCalendar) {
            let week: CalendarWeek = {
                number: 0,
                days: []
            };

            // Create and prepare selected days
            for (let i = 0; i < 7; i++) {
                week.days.push({
                    date: date.toISOString(),
                    number: date.getDate(),
                    past: date.getTime() < startOfMonth.getTime(),
                    future: date.getTime() > endOfMonth.getTime(),
                    today: new Date(date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0),
                    selected: this.getDaySelectionState(date), // false
                });
                date.setDate(date.getDate() + 1);
            }

            this.weeks.push(week);
        }
    }

    private createDayLabels(): void {
        // Find start of week
        const d = new Date();
        const day = d.getUTCDay();
        const diff = d.getDate() - day + (day == 0 ? -6:1);
        const date = new Date(d.setDate(diff));

        this.dayLabels = [];
        for (let i = 0; i < 7; i++) {
            this.dayLabels.push(Helpers.toWeekDayString(date).slice(0,1));
            date.setDate(date.getDate() + 1);
        }
    }

    private updateMonthYear(): void {
        // Update current month and year
        this.year = this.viewMonth.getFullYear();
        this.month = Helpers.toMonthString(this.viewMonth);

        // Disable movement to the future
        const now = new Date();
        this.disableNextMonth = this.viewMonth.getFullYear() >= now.getFullYear() && this.viewMonth.getMonth() >= now.getMonth();
    }

    private getDaySelectionState (date: Date): CalendarDaySelectionState {
        if (this.options.type === 'Range') {
            // Range mode: different highlight for start, end and mid range

            const start = this.selectedFrom ? new Date(this.selectedFrom).setHours(0, 0, 0, 0) : undefined;
            const end = this.selectedTo ? new Date(this.selectedTo).setHours(0, 0, 0, 0) : undefined;
            const time = date.getTime();

            if (time == start || end && time == end) {
                return 'Day';
            } else if (time > start && end && time < end) {
                return "Range";
            }
        } else {
            // Day mode: select a single day

            const start = this.selectedFrom?.setHours(0, 0, 0, 0);
            const time = date.getTime();

            if (time == start) return 'Day';
        }
    }

    public selectDay(date: string) {
        // Update selected date
        if (this.options.type === 'Range') {
            // Range mode: select two dates

            if (!this.selectedFrom || this.selectedTo) {
                // Select the first date of a range
                this.selectedTo = undefined;
                this.selectedFrom = new Date(date)
            } else {
                // Select the second date of a range
                this.selectedTo = new Date(date);
                if (this.selectedTo < this.selectedFrom) {
                    this.selectedTo = this.selectedFrom;
                    this.selectedFrom = new Date(date);
                }

                // Range includes the whole end day
                this.selectedTo.setHours(23, 59, 59, 999);
            }

            // Use end of day if end date of a range is empty
            if (!this.selectedTo) {
                const selectionEnd = new Date(this.selectedFrom);
                selectionEnd.setHours(23, 59, 59, 999);

                // Update component options
                this.options.from = this.selectedFrom.toISOString();
                this.options.to = selectionEnd?.toISOString();

                // OnDaySelect handler
                this.onDateSelect(this.options.from, this.options.to);
            } else {
                // Update component options
                this.options.from = this.selectedFrom.toISOString();
                this.options.to = this.selectedTo?.toISOString();

                // OnDaySelect handler
                this.onDateSelect(this.options.from, this.options.to);
            }
        } else {
            // Day mode: just update selection start
            this.selectedFrom = new Date(date);
            this.selectedTo = undefined;

            // Update component options
            this.options.from = this.selectedFrom.toISOString();
            this.options.to = undefined;

            // OnDaySelect handler
            this.onDateSelect(this.options.from, this.options.to);
        }

        if (this.isAttached()) this.update();
    }

    public previousMonth () {
        // Subtract one month
        this.viewMonth.setMonth(this.viewMonth.getMonth() - 1);

        this.update();
    }

    public nextMonth () {
        if (this.disableNextMonth) return;

        // Add one month
        this.viewMonth.setMonth(this.viewMonth.getMonth() + 1);

        this.update();
    }

    public setValue (from?: string, to?: string) {
        if (from > to) {
            this.options.from = to;
            this.options.to = from;
        } else {
            this.options.from = from;
            this.options.to = to;
        }

        this.selectedFrom = this.options.from ? new Date(this.options.from) : undefined;
        this.selectedTo = this.options.to ? new Date(this.options.to) : undefined;

        // Select appropriate month
        if (this.selectedFrom) this.viewMonth = new Date(this.selectedFrom);
        else this.viewMonth = new Date();
        this.viewMonth.setDate(1);
        this.viewMonth.setHours(0,0,0,0);

        if (this.isAttached()) this.update();
    }
}
