import "./traffic-signals-subdomain.scss";
import * as template from "./traffic-signals-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficSignalsSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { WarningLightStateCircleLayer } from "../../../layers/traffic/warning-light-state-circle-layer";
import { WarningLightStateLabelLayer } from "../../../layers/traffic/warning-light-state-label-layer";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class TrafficSignalsSubdomain extends CitySubdomain<TrafficSignalsSubdomainOptions> {

    constructor(context: InvipoContext, options?: TrafficSignalsSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Lights",
                    itemClass: "WarningLight",
                    layers: [
                        new WarningLightStateCircleLayer(context),
                        new WarningLightStateLabelLayer(context)
                        //new TrafficEventCircleLayer(context),
                        //new TrafficEventSymbolLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string): void {
        this.selectDevices(this.items.filter(x => symbol == "Unknown" ? !x.data?.control?.state : x.data?.control?.state == symbol), [`enums.WarningLightState.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Build legend
        this.legend = {
            range: null,
            symbols: []
        };

        for (let i in METRICS.traffic.signals.lights.range) {
            let symbol = METRICS.traffic.signals.lights.range[i]
            let count = this.items.filter(x => x.class == "WarningLight" && x.data?.control?.state ? x.data.control.state == symbol : symbol == "Unknown").length;

            // Add row to legend
            this.legend.symbols.push({
                metrics: "Lights",
                symbol: symbol,
                count: count,
                label: `enums.WarningLightState.${symbol}`,
                percent: count / (this.items.length || 1) * 100,
                color: METRICS.traffic.signals.lights.colors[i],
                selectable: true
            });
        }
    }

}
