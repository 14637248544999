import { FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "../../../hiyo/helpers";
import { InvipoItem } from "../../clients/invipo-client/types";

export const CLASS_COLORS: any = {
    "#008efa": ["Device"],
    "#d1c628": ["TrafficLightController", "TrafficCounter", "TrafficCamera", "WimStation", "WarningLight", "V2XUnit", "FullMatrixSign", "SvdSystem", "RlvdSystem", "BicyclePumpStation", "LineMatrixSign", "FullMatrixSign"], // Traffic
    "#199cc8": [], // Roads
    "#00e3a6": ["EnvironmentalSensor", "RoadWeatherStation"], // Envi
    "#fac800": ["EnergyMeter", "StreetLightController", "LoraGateway", "Battery"], // Energy
    "#ff7b43": ["BluetoothDetector", "Radar", "SecurityCamera", "Camera"], // Safety
    "#8b88ff": ["ParkingLot", "ParkingSensor", "ParkingSign"], // Parking
    "#0099ff": [], // Transit
    "#00ab94": ["GarbageBin"], // Waste
    "#5d9bba": ["WifiAccessPoint"] // Services
}

export class ItemCircleLayer extends MapLayer<InvipoContext> {

    // Properties
    public items: InvipoItem[];

    public constructor(context: InvipoContext, items?: InvipoItem[]) {
        super(context, {
            dynamic: true,
            tooltip: true,
            layer: {
                id: `item-${Helpers.createUuid()}-circle`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 4,
                        12, 5,
                        16, 8,
                        19, 10,
                        22, 12
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.8
            }
        });

        // Assign properties
        this.items = items;
    }

    public async load(): Promise<any> {
        // Load or reuse items
        let items = this.items || await this.context.invipo.getItems();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No location?
            if (!item.geometry?.location) {
                continue;
            }

            // Domain color
            let color = "#dddddd";

            // Is collor in class color map?
            for (let c in CLASS_COLORS) {
                if (CLASS_COLORS[c].includes(item.class)) {
                    color = c;
                }
            }

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: `${item.name}\n${item.model ? item.model : item.producer ? item.producer : ""}`,
                    //card: "ItemCard",
                    card: "MonitoringCard",
                    detail: "CityDetail",
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
