import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection, Geometry } from "geojson";
import { InvipoHelpers } from "../../invipo-helpers";
import { Helpers } from "../../../hiyo/helpers";

export class ParkingLotLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: true,
            card: "ParkingLotCard",
            detail: "CityDetail",
            layer: {
                id: "parking-lot-label",
                type: "symbol",
                minzoom: ZOOM_LABEL - 4,
                maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": ["get", "color"],
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems("class=ParkingLot");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Gemoetry and occupancy
            let geometry: Geometry = null;
            let occupancy = item.data?.occupancy?.overall?.occupancy;

            // Center from area if available
            if (item.geometry.area) {
                geometry = {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(item.geometry.area).getCenter().toArray()
                }
            }

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: item.name,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: "#161616",
                    label: occupancy != undefined ? `${occupancy} %` : "N/A"
                },
                geometry: geometry || item.geometry["location"]
            })
        }

        return json;
    }
}
