import "./parking-lot-overview.scss";
import * as template from "./parking-lot-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { ParkingLotOverviewOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ParkingOccupancyForm } from "../parking-occupancy-form/parking-occupancy-form";
import { Panel } from "../../common/panel/panel";
import { PanelChart } from "../../common/panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class ParkingLotOverview extends Panel<ParkingLotOverviewOptions> {

    // Properties
    public history: DetailHistoryValue[];
    public sensors: InvipoItem[];
    public hours: PanelChart;

    constructor(context: InvipoContext, options?: ParkingLotOverviewOptions) {
        super(context, template, options);
    }

    public openEdit(): void {
        // Dialog to confirm
        let form = new ParkingOccupancyForm(this.context, {
            style: "Light",
            title: "components.ParkingOccupancyForm.title",
            item: this.item,
            overlay: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Refresh item
            await this.load();
        }

        // Show dialog
        form.attach();
    }

    public async extraLoad(): Promise<void> {
        // Interval
        let from = new Date(new Date().setHours(0, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));

        // Speed data per hour
        let data = await this.context.invipo.getQuery("parking-occupancy-by-hour", `&item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build hour chart
        this.hours = {
            type: "Bar",
            size: "Tall",
            name: "Occupancy",
            label: "components.ParkingLotOverview.todayOccupancy",
            series: []
        }

        // Add hourly data to chart series
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = data.find(x => new Date(x.timestamp).getHours() == h);
            let symbol = "Unknown";

            // Has data?
            if (d?.occupancy) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(d.occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(d.occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }
            }

            let color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];

            // Has data?
            if (d) {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: `${d.occupancy} %`,
                            valueX: h.toString().padStart(2, "0"),
                            percent: d.occupancy,
                            label: `${d.occupancy} %`,
                            color: color,
                        }
                    ]
                );
            }
            // No data
            else {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }
    }

}
