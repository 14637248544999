import "./home-screen.scss";
import * as template from "./home-screen.hbs";
import { HomeScreenOptions, TechnologyClass } from "./types";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Info } from "../../../clients/invipo-client/types";
import { City } from "../../../views/city";
import { CityMap } from "../../city/city-map/city-map";

export class HomeScreen extends MuklitComponent<InvipoContext, HomeScreenOptions> {

    // Properties
    public classes: TechnologyClass[];
    public info: Info;

    constructor(context: InvipoContext, options?: HomeScreenOptions) {
        super(context, template, options);
    }

    public onCreate() {
        this.classes = [];

        // Push classes from evidence
        for (let c of this.context.options.classes) {
            this.classes.push({
                name: c,
                count: 0,
                limit: 50,
            })
        }
    }

    public selectDomain(i: number): void {
        // Find domains view
        let view = <City>this.context.application.views.find(x => x.name == "City");

        // Change application view to Domains
        this.context.application.changeView(view.id);

        // Switch domain
        let city = <CityMap>view.content;
        city.navigator.options.domain = this.context.options.city[i].domain;

        // Redraw city map
        city.invalidate();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load info and items
        this.info = await this.context.invipo.getInfo();
        let items = await this.context.invipo.getItems();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        for (let c of this.classes) {
            c.count = items.filter(x => x.class == c.name).length;
        }

        // Hide loader
        this.hideLoader();

        // Redraw
        this.update();
    }

}
