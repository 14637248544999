// Localization import
import * as messagesEn from "./assets/messages/messages.en.json";
import * as messagesDe from "./assets/messages/messages.de.json";
import * as messagesCs from "./assets/messages/messages.cs.json";
import * as messagesDa from "./assets/messages/messages.da.json";
import * as messagesEl from "./assets/messages/messages.el.json";
import * as messagesVn from "./assets/messages/messages.vn.json";

// Module imports
import { InvipoApplication } from "./invipo-application";
import { InvipoContext } from "./context/invipo-context";
import { Helpers } from "hiyo/helpers";
import { Log } from "../hiyo/log";
import { InvipoContextOptions } from "./context/types";
import { GpsCockpitClient } from "./clients/gpscockpit-client/gpscockpit-client";

// Async wrapper
(async () => {
    // Get frontend version from package.json
    let pjson = require("../../package.json");
    Log.i(`Frontend version=${pjson.version}`);

    // Load config from local invipo.json
    let config: InvipoContextOptions = await Helpers.loadJson("./invipo.json");

    // Invipo context
    const context = new InvipoContext({
        // Frontend version
        version: pjson.version,
        // Merge from config
        ...config,
        // Imported messages
        messages: {
            en: messagesEn,
            de: messagesDe,
            cs: messagesCs,
            da: messagesDa,
            el: messagesEl,
            vn: messagesVn
        }
    });

    // Entry point class
    const app = new InvipoApplication(context);

    // Just run!
    await app.preauthorize();

})();
