import { Log } from "./log";
import { HiyoObject } from "./hiyo-object";
import { Helpers } from "./helpers";

export class EventBroker {

    public readonly subscribers: { [id: string]: { [type: string]: HiyoObject } };
    public readonly events: HiyoEvent[];

    public constructor() {
        this.subscribers = {};
        this.events = [];
    }

    public subscribe(subscriber: HiyoObject, types: string[]): void {
        for (let t of types) {

            if (this.subscribers[subscriber.id] && this.subscribers[subscriber.id][t]) {
                Log.w(`EventBroker: ${subscriber.id} for event ${t} already subscribed`);
                continue;
            }

            // Not created yet
            if (!this.subscribers[subscriber.id]) {
                this.subscribers[subscriber.id] = {};
            }

            this.subscribers[subscriber.id][t] = subscriber;
        }

        Log.i(`EventBroker: ${subscriber.id} subscribed for ${types.join(",")}`);
    }

    public unsubscribe(subscriber: HiyoObject): void {
        if (!this.subscribers[subscriber.id]) {
            Log.w(`EventBroker: ${subscriber.id} not subscribed`);
            return;
        }

        delete this.subscribers[subscriber.id];

        Log.i(`EventBroker: ${subscriber.id} unsubscribed`);
    }

    public fire(event: HiyoEvent): void {
        // Add timestamp
        event.id = Helpers.newUid("Event");
        event.source = event.source || HiyoEventSource.Internal;

        // Add to history
        this.events.push(event);

        if (this.events.length >= 10000) {
            Log.w(`EventBroker: To many events in cache (${this.events.length}), cleaning now`);
            this.events.splice(0, this.events.length);
        }

        // How many?
        let count = 0;

        // Publish to all subscribers registered for this event type
        for (let id of Object.keys(this.subscribers)) {
            // Get subscriber by type, * for all types
            let subscriber = this.subscribers[id]["*"] || this.subscribers[id][event.type];

            if (subscriber) {
                count += 1;
                subscriber.onHandle(event);
            }
        }

        if (count > 0) {
            Log.d(`EventBroker: ${event.id} (${event.type}) fired to ${count} subscriber(s)`);
        }
    }

    public log(): void {
        Log.i(this.events);
    }
}

export interface HiyoEvent<T = any> {
    id?: string;
    source?: HiyoEventSource | HiyoObject;
    type: string;
    timestamp?: string;
    payload?: T;
}

export enum HiyoEventSource {
    Internal = "Internal",
    Websocket = "Websocket"
}
