import { FeatureCollection, Polygon } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { InvipoHelpers } from "../../invipo-helpers";

const MIN_ZOOM = 16;

export class PublicTransportPreferencePointSymbolLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "public-transport-preference-point-symbol-layer",
                type: "symbol",
                minzoom: MIN_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "icon-opacity": [
                        "case",
                        ["has", "heading"], 1,
                        0
                    ]
                },
                layout: {
                    "icon-image": "16-tools-arrow_up",
                    "icon-offset": [0, 4],
                    "icon-anchor": "bottom",
                    "icon-rotate": ["get", "heading"],
                    "icon-rotation-alignment": "map",
                    "icon-allow-overlap": false,
                    "icon-ignore-placement": true,
                }
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        let areas = await this.context.invipo.getAreas(`type=PreferenceDetectionPoint`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            let bounds = InvipoHelpers.toBounds(area.geometry);

            json.features.push({
                type: "Feature",
                properties: {
                    pointer: true,
                    heading: area.meta.heading || 0
                },
                geometry: {
                    type: "Point",
                    coordinates: bounds.getCenter().toArray()
                }
            });
        }

        return json;
    }
}
