import { BasicTrafficBrowserOptions } from "./types";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";
import { TrafficBrowser } from "../traffic-browser/traffic-browser";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";
import { FilterItem } from "../../../../muklit/components/filter/types";
import { AreaSelect } from "../../common/area-select/area-select";

export const CLASS_NAMES = "TrafficCounter,WimStation";

export class BasicTrafficBrowser extends TrafficBrowser<BasicTrafficBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-data?item.class=${CLASS_NAMES}`,
            http: this.context.invipo.http,
            filter: {
                title: "components.BasicTrafficBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from"
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to"
                            }),
                            new AreaSelect(this.context, {
                                style: "Light",
                                name: "area.id",
                                label: "forms.fields.area",
                                placeholderText: "forms.placeholders.all",
                                areaType: "TrafficVolume",
                                items: [],
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                itemClass: CLASS_NAMES,
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                groups: {
                    property: "interval.from",
                    formatter: (value: any, data: any) => {
                        return `${Helpers.toDateString(value)} ${Helpers.toShortTimeString(value)}`;
                    }
                },
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${Helpers.toShortTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}</div>`
                        },
                        label: "tables.columns.interval",
                        width: 220,
                        selected: true,
                        sortable: true,
                        extraSort: "item.name:desc",
                        descendent: true
                    },
                    {
                        name: "segment",
                        type: "String",
                        property: "segment",
                        label: "tables.columns.segment",
                        width: 120,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "count",
                        type: "Number",
                        property: "count",
                        label: "tables.columns.count",
                        width: 120,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "speed",
                        type: "Number",
                        property: "speed",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${value} km/h</div>`
                        },
                        label: "tables.columns.averageSpeed",
                        width: 120,
                        sortable: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "TrafficCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getUrl(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
