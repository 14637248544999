import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { InvipoHelpers } from "../../invipo-helpers";

export class ParkingSignLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            layer: {
                id: "parking-sign-label",
                type: "symbol",
                minzoom: ZOOM_LABEL,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 1.8],
                    "text-anchor": "top",
                    "text-allow-overlap": true,
                    "text-max-width": 10,
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems("class=ParkingSign");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Build unique position list
        let cluster = InvipoHelpers.toItemCluster(items);

        // Push features from items
        for (let items of Object.values(cluster)) {
            // Current occupancy
            json.features.push({
                type: "Feature",
                properties: {
                    type: "Location",
                    label: items.length > 1 ? `${this.context.locale.getMessage("classes.ParkingSign")}\n${items.length}×` : items[0].name
                },
                geometry: items[0].geometry["location"] // Take first item, all have same positions!
            })
        }

        return json;
    }
}
