import './fleet-distance-tile.scss';
import * as template from "./fleet-distance-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetDistanceTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis } from "../../common/tile/types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class FleetDistanceTile extends Tile<FleetDistanceTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: FleetDistanceTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        let data = await this.context.gpsCockpit.getDashboardLocationCount();
        data = data.sort((a, b) => a.summaryDate > b.summaryDate ? 1 : -1);

        const maxDay = data.reduce((a, b) => a.distanceInMeters > b.distanceInMeters ? a : b);
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.FleetDistanceTile.peakDistance",
                    value: `${Helpers.toNumber(maxDay.distanceInMeters / 1000)} ${this.context.locale.getMessage("units.km")}`
                },
                {
                    label: "components.FleetDistanceTile.peakDay",
                    value: Helpers.toDateString(maxDay.summaryDate)
                }
            ]
        }

        this.chart = {
            size: "30",
            data: []
        };

        for (let i = 0; i < data.length; i++) {
            const day = data[i];

            this.chart.data.push({
                timestamp: i == 0 || i == 30 ? day.summaryDate : "",
                value: day.distanceInMeters,
                percent: day.distanceInMeters / maxDay.distanceInMeters * 100,
                color: METRICS.traffic.counting.volume.colors[0],
                tooltip: `${Helpers.toDateString(day.summaryDate)}: ${Helpers.toNumber(day.distanceInMeters / 1000)} ${this.context.locale.getMessage("units.km")}`
            });
        }

    }

}
