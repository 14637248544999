import "./ticket-board.scss";
import * as template from "./ticket-board.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TicketBoardOptions } from "./types";
import { Ticket } from "../../../clients/invipo-client/types";
import { Log } from "../../../../hiyo/log";

export class TicketBoard extends MuklitComponent<InvipoContext, TicketBoardOptions> {

    // Properties
    public tickets: Ticket[];
    public dragged: HTMLElement;
    public columns: any[];

    // Event handling methods
    public onTicketSelect(ticket: any): void {}

    constructor(context: InvipoContext, options?: TicketBoardOptions) {
        super(context, template, options);
    }

    public dragTicket(e: DragEvent): void {
        // Remember dragged ticket
        this.dragged = <HTMLElement>e.target;
    }

    public async dropTicket(i: number, e: DragEvent): Promise<void> {
        Log.i(`Dropped ticket ${this.dragged.id} to column ${i}`);

        // Reset column hover
        this.query(`div.column-${i}`).classList.remove("column-selected");

        // Reassign to new column
        this.query(`div.column-${i} div.tickets`).appendChild(this.dragged);

        // Assign new column
        let ticket = this.tickets.find(x => x.id == this.dragged.id);
        ticket.column = this.context.config.tickets.columns[i];

        // Show loader
        this.showLoader();

        // Update column
        await this.context.invipo.updateTicketColumn(ticket.id, {
            column: this.context.config.tickets.columns[i]
        });

        // Hide loader
        this.hideLoader();

        // Selected ticket dropped?
        if (ticket.selected) {
            await this.onTicketSelect(ticket);
        }

        // Reset dragged ticket
        this.dragged = null;
    }

    public allowDrop(i: number, e: DragEvent): void {
        // Same column?
        if (this.tickets.find(x => x.id == this.dragged.id).column == this.context.config.tickets.columns[i]) {
            return;
        }

        // Reset all columns and select allowed
        this.queryAll("div.column").forEach((e: HTMLElement) => {
            e.classList.remove("column-selected");
        });
        this.query(`div.column-${i}`).classList.add("column-selected");

        // Allow to drop
        e.preventDefault();
    }

    public selectTicket(id: string): void {
        // Find current ticket
        let ticket = this.tickets.find(x => x.id == id);

        // Already selected?
        if (ticket.selected) {
            return;
        }

        // Unselect all
        this.unselectAll();

        // Select current ticket
        ticket.selected = true;
        document.getElementById(ticket.id).classList.add("ticket-selected");

        // OnTicketSelect handler
        this.onTicketSelect(ticket);
    }

    public unselectAll(): void {
        // Unselect all tickets
        this.tickets.forEach(x => x.selected = false);
        this.queryAll("div.ticket").forEach((e: HTMLElement) => {
            e.classList.remove("ticket-selected");
        });
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Keep selected ticket
        let selected = this.tickets?.find(x => x.selected == true);

        // Load data
        this.tickets = await this.context.invipo.getTickets();

        // Reselect ticket because we have new data
        if (selected) {
            this.tickets.find(x => x.id == selected.id).selected = true;
        }

        // Create data model by columns
        this.columns = [];

        for (let column of this.context.config.tickets.columns) {
            // Add column tickets
            this.columns.push({
                name: column,
                tickets: this.tickets.filter(x => x.column == column)
            })
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.update();
    }

}
