import "./wall-map.scss";
import * as template from "./wall-map.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { WallMapOptions } from "./types";
import { Component } from "hiyo/component";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { ItemStatusCircleLayer } from "../../../layers/monitoring/item-status-circle-layer";
import { Feature, GeoJsonProperties, Point } from "geojson";
import { MonitoringHud } from "../../monitoring/monitoring-hud/monitoring-hud";
import { MonitoringDetailOptions } from "../../monitoring/monitoring-detail/types";
import { MonitoringDetail } from "../../monitoring/monitoring-detail/monitoring-detail";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { TooltipLabelLayer } from "../../../layers/tooltip-label-layer";
import { Log } from "../../../../hiyo/log";
import { MonitoringCard } from "../../monitoring/monitoring-card/monitoring-card";

export class WallMap extends Component<InvipoContext, WallMapOptions> {

    // Components
    public map: BasicMap;
    public hud: MonitoringHud;
    public detail: MonitoringDetail;
    public tooltip: TooltipLabelLayer;
    public card: MonitoringCard;

    constructor(context: InvipoContext, options?: WallMapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createHud();
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Dark",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            // Merge with extra options
            ...this.options.mapOptions
        });

        // Show tooltip
        this.map.onFeatureEnter = (layer: string, feature: Feature, e: MouseEvent) => {
            // No tooltip defined?
            if (!feature.properties.tooltip) {
                return;
            }

            // Create tooltip layer
            this.tooltip = new TooltipLabelLayer(this.context, (<Point>feature.geometry).coordinates, feature.properties.tooltip, true);

            // Add tooltip to map
            this.map.addLayer(this.tooltip);
        }

        // Hide tooltip
        this.map.onFeatureLeave = (layer: string) => {
            // No tooltip displayed?
            if (!this.tooltip) {
                return;
            }

            // Remove tooltip from map
            this.map.removeLayer(this.tooltip.options.layer.id);
            this.tooltip = null;
        }

        // Select detail
        // This must be binded to "mousedown" event as it does not break detail hiding
        this.map.onFeatureMouseDown = async (layer: string, feature: Feature, event?: MouseEvent) => {
            await this.openCard(feature.properties, event);
        }

        // Add map layers
        this.map.addLayer(new ItemStatusCircleLayer(this.context, {
            layer: null,
            scale: this.options.scale,
            areaId: this.options.areaId,
            itemClass: this.options.itemClass,
            itemModel: this.options.itemModel,
            itemStatus: this.options.itemStatus
        }));

        // Register components
        this.registerComponent(this.map, "map");
    }

    private createHud(): void {
        // Create component
        this.hud = new MonitoringHud(this.context, {
            title: this.options.title,
            itemClass: this.options.itemClass,
            itemModel: this.options.itemModel,
            areaId: this.options.areaId,
            collapsed: false // true
        });

        // Register components
        this.registerComponent(this.hud, "hud");
    }

    public async openCard(properties: GeoJsonProperties, event?: MouseEvent): Promise<void> {
        // Card is already displayed?
        if (this.card?.isAttached() && this.card.options.itemId == properties.itemId) {
            if (!this.card.options.cluster) {
                // If no cluster, second click opens detail
                await this.openDetail(properties);
            }

            // Otherwise no action on second click
            return;
        }

        Log.i(`${this.id} opens monitoring card`);

        // Create detail
        this.card = new MonitoringCard(this.context, {
            style: "Dark",
            ...properties
        });

        // Handle item selection
        this.card.onItemSelect = (item: InvipoItem) => {
            // New set of properties
            properties = {
                type: properties.type,
                data: properties.data,
                card: `${item.class}Card`,
                detail: "CityDetail",
                itemId: item.id,
                itemName: item.name,
                itemClass: item.class
            }

            // Repin new detail to keep all bindings, callbacks etc.
            this.openCard(properties, event);
        }

        // Open data view
        this.card.onDetailView = async (content?: string) => {
            // TODO: content?
            await this.openDetail(properties);
        }

        // Show detail
        this.card.show(event?.x || (this.element.offsetWidth / 2 + 56), event?.y || (this.element.offsetHeight / 2));
    }

    private async openDetail(properties: GeoJsonProperties): Promise<void> {
        // Detail options
        let options: MonitoringDetailOptions = {
            style: "Dark",
            title: properties.itemName,
            subtitle: `classes.${properties.itemClass}`,
            itemId: properties.itemId,
            closable: true,
            localizable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MonitoringDetail(this.context, options);

        // OnClose handler
        this.detail.onClose = () => {
            this.map.resize();
        }

        // OnCenter handler
        this.detail.onCenter = (item: InvipoItem) => {
            this.map.pulseAt({
                type: "Monitoring",
                position: (<Point>item.geometry.location).coordinates,
            });

            this.map.flyTo({
                center: (<Point>item.geometry.location).coordinates,
                duration: 600
            });
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach(this.query("div.detail"));
        this.map.resize();
    }
}
