import { Select } from "muklit/components/select/select";
import { InvipoContext } from "../../../context/invipo-context";
import { AssetSelectOptions } from "./types";
import { Asset } from "../../../clients/gpscockpit-client/types";

export class AssetSelect extends Select<InvipoContext, AssetSelectOptions> {

    public assets: Asset[];

    public async load(): Promise<void> {
        // Load items
        this.assets = await this.context.gpsCockpit.getAssets();

        // Reset menu options
        this.options.items = []

        for (let asset of this.assets) {
            // Add item
            this.options.items.push({
                name: asset.id.toString(),
                label: `${asset.name} (${asset.brand} ${asset.model})`
            });
        }

        // Value rearrangement
        if (this.options.value && typeof this.options.value == "string") {
            let item = this.options.items.find(x => x.name == this.options.value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[item.name] = item.label;
            }
        }

        // Gone while loading?
        if (!this.isAttached()) {
            return;
        }

        // Reattach select to render it properly
        this.update();
    }

}
