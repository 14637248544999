import "./violations-subdomain.scss";
import * as template from "./violations-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ViolationsSubdomainOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { ViolationTypeCircleLayer } from "../../../layers/enforcement/violation-type-circle-layer";
import { ViolationTypeLabelLayer } from "../../../layers/enforcement/violation-type-label-layer";

export const VIOLATION_CLASSES: any = {
    "Speed": ["SvdSystem"],
    "RedLight": ["RlvdSystem"],
    "Weight": ["WimStation"],
    "Height": ["WimStation"],
    "Parking": ["ParkingLot", "ParkingSensor"],
}

export class ViolationsSubdomain extends CitySubdomain<ViolationsSubdomainOptions> {

    constructor(context: InvipoContext, options?: ViolationsSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Type",
                    itemClass: "SvdSystem,RlvdSystem",
                    layers: [
                        new ViolationTypeCircleLayer(context),
                        new ViolationTypeLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectSystems(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.systems",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items || this.items),
                new ItemLabelLayer(this.context, items || this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSymbol(symbol: string) {
        // Select devices
        this.selectSystems(this.items.filter(x => VIOLATION_CLASSES[symbol].includes(x.class)), [`enums.ViolationType.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Incidents metrics?
        if (this.metrics.name == "Type") {
            // Create messages legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let i in METRICS.traffic.violations.count.range) {
                let symbol = METRICS.traffic.violations.count.range[i]
                let count = this.items.filter(x => VIOLATION_CLASSES[symbol].includes(x.class)).length;

                // Only vioaltion types that have some items
                if (count == 0) {
                    continue;
                }

                this.legend.symbols.push({
                    symbol: symbol,
                    count: count,
                    label: `enums.ViolationType.${symbol}`,
                    percent: count / (this.items.length || 1) * 100,
                    color: METRICS.traffic.violations.count.colors[i],
                    selectable: true
                });
            }

        }
    }

}
