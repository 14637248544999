import "./item-general.scss";
import * as template from "./item-general.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { ItemGeneralOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { Feature, Point } from "geojson";

export class ItemGeneral extends MuklitComponent<InvipoContext, ItemGeneralOptions> {

    // Properties
    public item: InvipoItem;

    // Components
    public map: BasicMap;

    constructor(context: InvipoContext, options?: ItemGeneralOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom - 3,
            minZoom: 5,
            maxZoom: 20
        });

        // Zoom on click
        this.map.onFeatureClick = (layer: string, feature: Feature, event?: MouseEvent) => {
            this.map.flyTo({
                center: (<Point>feature.geometry).coordinates,
                zoom: 18
            })
        }

        // Register map layers
        //this.map.addLayer(new ItemCircleLayer(this.context, null, this.options.itemId));
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load item data
        this.item = await this.context.invipo.getItem(this.options.itemId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.invalidate(true);

        // Resize map
        this.map.resize();
    }
}
