import "./wifi-access-point-card.scss";
import * as template from "./wifi-access-point-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { WifiAccessPointCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { Helpers } from "../../../../hiyo/helpers";
import { CardHistoryValue } from "../../city/city-card/types";
import { METRICS } from "../../city/city-subdomain/types";

export class WifiAccessPointCard extends CityCard<WifiAccessPointCardOptions> {

    // Properties
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: WifiAccessPointCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Data default
        this.options.data = this.options.data || "connections";

        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery(`network-${this.options.data}-by-hour`, `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d[this.options.data],
                    percent: Helpers.range(0, 100, 0, METRICS.services.hotspots.connections.range[1], d[this.options.data]),
                    color: METRICS.services.hotspots.connections.colors[Math.round(Helpers.range(0, METRICS.services.hotspots.connections.colors.length - 1, METRICS.services.hotspots.connections.range[0], METRICS.services.hotspots.connections.range[1], d[this.options.data]))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }
}
