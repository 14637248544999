import './map-weather.scss';
import * as template from "./map-weather.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { MapWeatherOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

const RELOAD_TIMEOUT = 60;

export class MapWeather extends MuklitComponent<InvipoContext, MapWeatherOptions> {

    // Properties
    public weather: any;
    public history: any[];
    public temperature: any;
    public timer: any;

    constructor(context: InvipoContext, options?: MapWeatherOptions) {
        super(context, template, options);
    }

    public async load(): Promise<void> {
        // Clear timeout to prevent multiple calls
        clearTimeout(this.timer);

        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let weather = await this.context.invipo.getDataset("weather-data", `names=${this.context.options.home.name}&from=${from.toISOString()}&pageSize=1000&sort=timestamp:asc`);

        // Last weather
        this.weather = weather.data[weather.data.length - 1];

        // Temperature metrics
        this.temperature = METRICS.envi.air.temperature;

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let w = weather.data.find(x => x.timestamp >= from.toISOString());

            // Add history line
            if (w) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: w.air.temperature,
                    percent: Helpers.range(0, 100, METRICS.envi.air.temperature.range[0], METRICS.envi.air.temperature.range[1], w.air.temperature),
                    color: METRICS.envi.air.temperature.colors[Math.round(Helpers.range(0, METRICS.envi.air.temperature.colors.length - 1, METRICS.envi.air.temperature.range[0], METRICS.envi.air.temperature.range[1], w.air.temperature))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }

        // Component is gone?
        if (!this.isAttached()) {
            return;
        }

        // Update
        this.update();

        // Autorefresh
        this.timer = setTimeout(async () => {
            if (this.isAttached()) {
                await this.load();
            }
        }, RELOAD_TIMEOUT * 1000);
    }
}
