import "./krnap-dashboard.scss";
import * as template from "./krnap-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Component } from "hiyo/component";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { KrnapmDashboardOptions } from "./types";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";

export class KrnapDashboard extends Component<InvipoContext, KrnapmDashboardOptions> {

    // Components
    public map: BasicMap;
    public dataStorage: DataStorageTile;

    constructor(context: InvipoContext, options?: KrnapmDashboardOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createTiles();
    }

    public onAttach() {
        // Attach all tiles
        this.dataStorage.attach(this.query("div.column-system div.tiles"));
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 21
        });

        // Register component
        this.registerComponent(this.map, "map");
    }

    private createTiles(): void {
        // Create data storage tile
        this.dataStorage = new DataStorageTile(this.context, {
            style: "Light"
        });
    }
}
