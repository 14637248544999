import "./traffic-light-controller-overview.scss";
import * as template from "./traffic-light-controller-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { TrafficLightControllerOverviewOptions } from "./types";
import { DetailPanel } from "../../common/detail-panel/detail-panel";

export class TrafficLightControllerOverview extends DetailPanel<TrafficLightControllerOverviewOptions> {

    // Properties
    public history: any[];

    constructor(context: InvipoContext, options?: TrafficLightControllerOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history data
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let history = await this.context.invipo.getDataset("items-data-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let q = 0; q < 144; q++) {
            // Find hour in data
            let d = history.data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    state: d.item.data?.control?.state,
                    mode: d.item.data?.control?.mode,
                    plan: d.item.data?.control?.plan
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString()
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 1200000);
        }
    }

}
