import './technology-subdomain.scss';
import * as template from "./technology-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TechnologySubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { AreaList } from "../../city/area-list/area-list";
import { ItemList } from "../../city/item-list/item-list";
import { ItemStatusCircleLayer } from "../../../layers/infrastructure/item-status-circle-layer";
import { LegendSymbol, METRICS } from "../../city/city-subdomain/types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemAvailabilityCircleLayer } from "../../../layers/infrastructure/item-availability-circle-layer";
import { ItemAvailabilityLabelLayer } from "../../../layers/infrastructure/item-availability-label-layer";
import { AreaBoundaryLayer } from "../../../layers/infrastructure/area-boundary-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemClassCircleLayer } from "../../../layers/infrastructure/item-class-circle-layer";
import { CLASS_COLORS, ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class TechnologySubdomain extends CitySubdomain<TechnologySubdomainOptions> {

    constructor(context: InvipoContext, options?: TechnologySubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Type",
                    itemClass: context.options.classes.join(","),
                    areaType: "TrafficControl,TrafficVolume,ParkingOccupancy",
                    layers: [
                        new ItemClassCircleLayer(context)
                    ]
                },
                {
                    name: "Status",
                    itemClass: context.options.classes.join(","),
                    layers: [
                        new ItemStatusCircleLayer(context)
                    ]
                },
                {
                    name: "Availability",
                    itemClass: context.options.classes.join(","),
                    layers: [
                        new ItemAvailabilityCircleLayer(context),
                        new ItemAvailabilityLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.AreaList.subtitle",
            layers: [
                new AreaBoundaryLayer(this.context, this.areas)
            ]
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(items: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items || this.items),
                new ItemLabelLayer(this.context, items || this.items)
            ]
        });

        // Use custom items or loaded items
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSymbol(symbol: string) {
        // Technology metrics?
        if (this.metrics.name == "Type") {
            this.selectDevices(this.items.filter(x => x.class == symbol), [`classes.${symbol}`]);
        }

        // Status metrics?
        if (this.metrics.name == "Status") {
            this.selectDevices(this.items.filter(x => x.monitoringStatus?.status == symbol), [`monitoringStatus.${symbol}`]);
        }
    }

    public async extraLoad(): Promise<void> {
        // Technology metrics?
        if (this.metrics.name == "Type") {
            // Create state legend
            this.legend = {
                range: null,
                symbols: []
            };

            for (let c of this.context.options.classes) {
                let count = this.items.filter(x => x.class == c).length;

                // Domain color
                let color = "#dddddd";

                // Is color in class color map?
                for (let d in CLASS_COLORS) {
                    if (CLASS_COLORS[d].includes(c)) {
                        color = d;
                    }
                }

                // Add row to legend
                this.legend.symbols.push({
                    metrics: "State",
                    symbol: c,
                    count: count,
                    label: `classes.${c}`,
                    percent: count / (this.items.length || 1) * 100,
                    color: color,
                    selectable: true
                });
            }

            // Sort legend by color
            this.legend.symbols.sort((a: LegendSymbol, b: LegendSymbol): number => {
                return a.color.localeCompare(b.color);
            });
        }

        // Status metrics?
        if (this.metrics.name == "Status") {
            // Create state legend
            this.legend = {
                range: null,
                symbols: []
            };

            for (let i in METRICS.infrastructure.technology.status.range) {
                let symbol = METRICS.infrastructure.technology.status.range[i]
                let count = this.items.filter(x => x.monitoringStatus?.status == symbol).length;

                // Add row to legend
                this.legend.symbols.push({
                    metrics: "State",
                    symbol: symbol,
                    count: count,
                    label: `monitoringStatus.${symbol}`,
                    percent: count / (this.items.length || 1) * 100,
                    color: METRICS.infrastructure.technology.status.colors[i],
                    selectable: true
                });
            }
        }

        // Availability metrics?
        if (this.metrics.name == "Availability") {
            // Create state legend
            this.legend = {
                range: {
                    range: METRICS.infrastructure.technology.availability.range,
                    colors: METRICS.infrastructure.technology.availability.colors,
                    count: 0,
                    value: 0,
                    unit: "%"
                },
                symbols: []
            };

            // Calculate average
            for (let item of this.items) {
                // No data?
                if (!item.availability?.value) {
                    continue;
                }

                this.legend.range.value += item.availability.value;
                this.legend.range.count += 1;
            }

            // Set average and calculate relative position on range
            this.legend.range.value /= this.legend.range.count;
            this.legend.range.percent = this.legend.range.value;

        }
    }
}
