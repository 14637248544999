import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Asset, DeviceState } from "invipo/clients/gpscockpit-client/types";
import { METRICS } from "invipo/components/city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";

export class FleetFuelCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            card: "FleetAssetCard",
            detail: "FleetDetail",
            layer: {
                id: `fleet-fuel-circle-layer`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        const assets = await this.context.gpsCockpit.getAssetsWithState();

        // const assetData = await this.context.gpsCockpit.getAssets();
        // const assets: Record<number, Asset> = assetData.reduce((acc: any, asset) => {
        //     acc[asset.deviceId] = asset;
        //     return acc;
        // }, {});

        for (const asset of assets) {
            if (asset.state?.fuel) {
                json.features.push({
                    type: "Feature",
                    properties: {
                        tooltip: asset.name,
                        card: this.options.card,
                        cardId: asset.id,
                        detail: this.options.detail,
                        asset,
                        color: this.getColor(asset)
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [asset.state.currentPosition.longitude, asset.state.currentPosition.latitude]
                    }
                });
            }
        }

        return json;
    }

    public getColor(asset: Asset): string {
        let range = Helpers.range(0, METRICS.transit.fleet.fuel.colors.length - 1, METRICS.transit.fleet.fuel.range[0], METRICS.transit.fleet.fuel.range[1], asset.state?.fuel?.fuelLevelInPercentage ?? 0);
        let color = METRICS.transit.fleet.fuel.colors[Math.round(range)];

        return color;
    }
}
