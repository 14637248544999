import "./traffic-volume-report.scss";
import * as template from "./traffic-volume-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficVolumeReportOptions } from "./types";
import { Form } from "../../../../muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { Select } from "../../../../muklit/components/select/select";
import { RadioButton } from "../../../../muklit/components/radio-button/radio-button";
import { Button } from "../../../../muklit/components/button/button";
import { DetailHistoryValue, DetailKpiValue } from "../../common/detail-panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { MuklitComponent } from "../../../../muklit/components/muklit-component/muklit-component";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Dom } from "../../../../hiyo/dom";

export class TrafficVolumeReport extends MuklitComponent<InvipoContext, TrafficVolumeReportOptions> {

    // Properties
    public group: string;
    public history: DetailHistoryValue[][];
    public speed: DetailHistoryValue[];
    public categories: DetailKpiValue[];

    // Components
    public form: Form;
    public button: Button;

    public constructor(context: InvipoContext, options?: TrafficVolumeReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createButton();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.button, "button");
    }

    private createForm(): void {
        // Items select
        let item = new ItemSelect(this.context, {
            style: "Light",
            name: "items",
            label: "forms.fields.item",
            value: this.options.itemId,
            placeholderText: "forms.placeholders.all",
            itemClass: "TrafficCounter,WimStation",
            items: [],
            width: 320,
            bright: true,
            multiselect: true
        });

        // Segment select
        let segment = new Select(this.context, {
            style: "Light",
            name: "segment",
            label: "forms.fields.segment",
            //value: this.options.segment,
            placeholderText: "forms.placeholders.all",
            items: [],
            width: 320,
            bright: true,
            multiselect: true
        });

        // Group select
        let group = new RadioButton(this.context, {
            style: "Light",
            name: "group",
            items: [
                {
                    name: "Hour",
                    label: "components.TrafficVolumeReport.groups.Hour",
                    checked: true
                },
                {
                    name: "Day",
                    label: "components.TrafficVolumeReport.groups.Day",
                }
            ],
            width: 672
        });

        // From select
        let from = new DateInput(this.context, {
            style: "Light",
            name: "from",
            label: "forms.fields.from",
            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
            width: 320,
            bright: true,
            type: "Date"
        });

        // To select
        let to = new DateInput(this.context, {
            style: "Light",
            name: "to",
            label: "forms.fields.to",
            width: 320,
            bright: true,
            type: "Date",
            disabled: true
        });

        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        group,
                        item,
                        segment,
                        from,
                        to
                    ]
                }
            ]
        });

        // Rearrange form when group type is selected
        group.onSubmit = () => {
            // Form data
            let data = this.form.getData();

            // Get selected item
            let item = group.options.items.find(x => x.checked);

            // Disable date range when displaying data hourly
            to.setDisabled(item.name == "Hour");

            // Enable submit button
            this.button.setDisabled(!data.from || (data.group == "Day" && !data.to));
        }

        // Disable classes if items selected
        item.onSubmit = () => {
            // Form data
            let data = this.form.getData();

            // Reset segments
            segment.options.items = [];

            // Find all selected items
            for (let i of item.options.items.filter(x => x.selected)) {
                // Find item definition
                let found = item.items.find(x => x.name == i.label);

                // Add all segments
                for (let s of found.meta?.segments || []) {
                    segment.options.items.push({
                        name: s.name,
                        label: s.name
                    })
                }
            }

            // We must invalidate select to display new items
            segment.invalidate();

            // Enable submit button
            this.button.setDisabled(!data.from || (data.group == "Day" && !data.to));
        }

        this.form.onSubmit = (data) => {
            // Disable submit button
            this.button.setDisabled(!data.from || (data.group == "Day" && !data.to));
        }
    }

    private createButton(): void {
        // Crete component
        this.button = new Button(this.context, {
            style: "Light",
            type: "LabelOnly",
            size: "Medium",
            kind: "Primary",
            align: "Center",
            width: "120px",
            label: "labels.submit",
            disabled: true
        });

        // Reload report
        this.button.onClick = async () => {
            // Set self as disabled
            this.button.setDisabled(true);

            // Reload form
            await this.load();
        }
    }

    public async save(selector: string): Promise<void> {
        // Convert element to canvas
        let canvas = await InvipoHelpers.toCanvas(this.query(selector));

        // Download
        Dom.openLink(canvas.toDataURL("image/png"), `export-${Date.now()}.png`)
    }

    public export(selector: string): void {
        // Create link to download CVS on background
        let blob = new Blob([InvipoHelpers.toCsv(this.query(selector))], { type: "text/csv;charset=utf-8;" });
        let url = URL.createObjectURL(blob);

        // Download
        Dom.openLink(url, `export-${Date.now()}.csv`)
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // If itemId is presented in options, we must fill segment input
        // TODO: This is workaround!
        if (this.options.itemId) {
            let item = await this.context.invipo.getItem(this.options.itemId);
            let segment = this.form.getField<Select>("segment");

            // Fill only first time
            if (segment.options.items.length == 0) {
                // Add all segments
                for (let s of item.meta?.segments || []) {
                    segment.options.items.push({
                        name: s.name,
                        label: s.name
                    });
                }
            }
        }

        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form group to group
        this.group = form.group;

        // Query string
        let query = "";

        if (form.items) {
            query += `&item.id=${form.items}`;
        }
        if (form.segment) {
            query += `&segment=${encodeURIComponent(form.segment)}`;
        }

        // Result data
        let data: any[] = null;

        // Data by hour
        if (this.group == "Hour" && form.from) {
            // Interval
            let from = new Date(form.from);
            let to = new Date(new Date(form.from).setHours(24, 0, 0, 0));

            // Load data
            data = await this.context.invipo.getQuery("traffic-by-hour", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

            // Find the highest count for each category
            const maxPerCategory = data.reduce((s, x) => {
                for (const category of x.categories) {
                    if (!s[category.id]) {
                        s[category.id] = category.count;
                    }
                    else if (s[category.id] < category.count) {
                        s[category.id] = category.count;
                    }
                }
                return s;
            }, {});

            // Empty chart data
            this.history = [];
            this.speed = [];

            // Get max count to adjust chart optimal height
            let max = Math.max(...data.map(x => x.count)) * 1.1;

            for (let h = 0; h < 24; h++) {
                // Find hour in data
                let d = data.find(x => x.timestamp == from.toISOString());

                // Data value
                let values: DetailHistoryValue[] = [];

                // Data for hour exists?
                if (d) {
                    // Total counts
                    values.push({
                        data: "volume",
                        timestamp: d.timestamp,
                        value: d.count,
                        percent: Helpers.range(0, 100, 0, max, d.count),
                        color: "#00b4f5",
                        heatmap: "#00b4f5" + Math.round(Helpers.range(0, 100, 0, max, d.count) / 100 * 255).toString(16).padStart(2, "0")
                    });
                }
                else {
                    values.push({
                        data: "volume",
                        timestamp: from.toISOString()
                    });
                }

                // Add categories
                for (let category of this.context.config.categories) {
                    // Data for hour exists?
                    if (d) {
                        // Get category definition
                        let c: any = (<any[]>d.categories).find(x => x.id == category.id);

                        // Category count found?
                        if (c) {
                            values.push({
                                data: category.name,
                                timestamp: d.timestamp,
                                value: c.count,
                                percent: c.count / d.count * 100,
                                color: category.color,
                                heatmap: `${category.color}${Math.round((c.count / (2 * maxPerCategory[category.id])) * 255).toString(16).padStart(2, "0")}`
                            });
                        }
                        else {
                            values.push({
                                data: category.name,
                                timestamp: d.timestamp,
                                value: 0,
                                percent: 0,
                                color: category.color
                            });
                        }
                    }
                    else {
                        values.push({
                            data: category.name,
                            timestamp: from.toISOString()
                        });
                    }
                }

                // Add to history
                this.history.push(values)

                // Speed data
                if (d) {
                    // Total counts
                    this.speed.push({
                        data: "speed",
                        timestamp: d.timestamp,
                        value: d.speed,
                        percent: Helpers.range(0, 100, 0, 130, d.speed),
                        color: "#00b4f5",
                        heatmap: "#00b4f5" + Math.round(Helpers.range(0, 100, 0, 130, d.speed) / 100 * 255).toString(16).padStart(2, "0")
                    });
                }
                else {
                    this.speed.push({
                        data: "speed",
                        timestamp: from.toISOString()
                    });
                }

                // Move to next hour
                from.setTime(from.getTime() + 3600000);
            }
        }

        // Data by day
        if (this.group == "Day" && form.from && form.to) {
            // Interval
            let from = new Date(form.from);
            let to = new Date(form.to);

            // Load data
            data = await this.context.invipo.getQuery("traffic-by-day", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

            // Empty chart data
            this.history = [];

            // Get max count to adjust chart optimal height
            let max = Math.max(...data.map(x => x.count)) * 1.2;

            // Find the highest count for each category
            const maxPerCategory = data.reduce((s, x) => {
                for (const category of x.categories) {
                    if (!s[category.id]) {
                        s[category.id] = category.count;
                    }
                    else if (s[category.id] < category.count) {
                        s[category.id] = category.count;
                    }
                }
                return s;
            }, {});

            for (let d of data) {
                // Data value
                let values: DetailHistoryValue[] = [];

                // Total counts
                values.push({
                    data: "volume",
                    timestamp: d.timestamp,
                    value: d.count,
                    percent: Helpers.range(0, 100, 0, max, d.count),
                    color: "#00b4f5"
                });

                // Add categories
                for (let category of this.context.config.categories) {
                    // Get category definition
                    let c: any = (<any[]>d.categories).find(x => x.id == category.id);

                    // Category count found?
                    if (c) {
                        values.push({
                            data: category.name,
                            timestamp: d.timestamp,
                            value: c.count,
                            percent: c.count / d.count * 100,
                            color: category.color,
                            heatmap: `${category.color}${Math.round((c.count / (2 * maxPerCategory[category.id])) * 255).toString(16).padStart(2, "0")}`
                        });
                    }
                    else {
                        values.push({
                            data: category.name,
                            timestamp: d.timestamp,
                            value: 0,
                            percent: 0
                        });
                    }
                }

                // Add to history
                this.history.push(values)

                // Move to next hour
                from.setTime(from.getTime() + 3600000);
            }
        }

        // Calcualte categories
        this.categories = [];
        for (let category of this.context.config.categories) {
            // Category total count
            let count = 0;

            // iterate all rows
            for (let d of data) {
                let c: any = (<any[]>d.categories).find(x => x.id == category.id);

                // Add count or ignore if not found
                count += c?.count || 0;
            }

            // Push to the categories
            this.categories.push({
                label: category.name,
                color: category.color,
                value: count
            })
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.invalidate(true);
    }
}
