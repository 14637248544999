export interface GpsCockpitClientOptions {
    host: string;
    accessToken?: string;
}

export interface Asset {
    state?: DeviceState;

    id: number;
    name: string;
    code: string;
    plateNumber: string;
    vehicleType: number;
    brand: string;
    model: string;
    year: string;
    color: string;
    vin: string;
    comment: string;
    isCommentWarning: boolean,
    deviceId?: number;
    referenceId?: string;
    identificationTag?: string;
    assetGroups?: string;
    assetGroupIds?: number[];
    clientId?: string;
    lastPosition?: string;
    lastCommunication?: string;
    dateStart?: string;
    dateEnd?: string;
    assetTypeId: number;
    assetTypeName?: string;
    unitId?: string;
    calibrationPoints: number;
    lastCalibration: string;
    calibratedOdo?: number;
    engineHourLastCalibration?: string;
    engineHourCalibrationOdoOffset?: number;
    engineHourCalibrationPoints?: number;
    realEngineHourInSeconds?: number;
    tripEngineHourInSeconds?: number;
    iconId: number;
    assetDateStart: string;
    assetDateEnd: string;
    companyName: string;
    accountId: number;
    resellerId: number;
    resellerDescription: string;
    owner?: string;
    clientAssetCategory?: string;
    clientAssetSubCategory?: string;
    ownership?: string;
    fuelType?: string;
    capacity?: string;
    bodyCode?: string;
    engineSerialNumber?: string;
    engineType?: string;
    costCenter?: string;
    madeIn?: string;
    plateMunicipality?: string;
    dateAcquired?: string;
    dealerName?: string;
    purchasePrice?: number;
    currentValue?: number;
    totalDepreciation?: number;
    dateDepreciation?: string;
    dateSold?: string;
    soldTo?: string;
    soldPrice?: number;
    dateExpireInsurance?: string;
    registrationNumber?: string;
    dateExpireRegistration?: string;
    insurancePolicy?: string;
    permitOneExpirationDate?: string;
    permitOne?: string;
    permitTwo?: string;
    safetyCertificate?: string;
    safetyCertificateExpirationDate?: string;
    safetyTestedBy?: string;
    erpCode?: string;
    branding?: string;
    equipment?: string;
    garageId?: number;
    garageName?: string;
    permitTwoExpirationDate?: string;
    calibrationOdoOffset?: number;
    driver?: {
        id: number;
        name: string;
        firstName: string;
        lastName: string;
        email: string;
        dateOfBirth: string;
        code: string;
        mobilePhone: string;
        officePhone: string;
        homePhone: string;
        driverLicence: string;
        driverLicenceExpirationDate: string;
        identificationCardNumber: string;
        identificationCardExpirationDate: string;
        comments: string;
        qualification: string;
        erpCode: string;
        tag: string;
        isArchived: boolean;
        timestamp: string;
        accountId: number;
        companyName: string;
        activeAssignments: number;
        isActive: boolean;
        resellerId: number;
        resellerDescription: string;
        properties: {
            wasl: {
                referenceKey: string;
                identityNumber: string;
                dateOfBirthGregorian: string;
                mobileNumber: string;
                registerDateWasl: string;
                registerDateSfda: string;
                registerDateTow: string;
                registerDateSpecialityTransport: string;
                registerDateBusRental: string;
                registerDateEducationalTransport: string;
            },
            custom: [
                {
                    key: string;
                    value: string;
                }
            ]
        },
        driverGroups: string;
        assignmentStart: string;
        assignmentEnd: string;
        accountProperties: {
            wasl: {
                referenceKey: string;
                identityNumber: string;
                commercialRecordNumber: string;
                commercialRecordIssueDateHijri: string;
                emailAddress: string;
                phoneNumber: string;
                managerName: string;
                managerPhoneNumber: string;
                managerMobileNumber: string;
                registerDateWasl: string;
                registerDateSfda: string;
                registerDateTow: string;
                sfdaCompanyActivity: string;
                registerDateSpecialityTransport: string;
                registerDateBusRental: string;
                registerDateEducationalTransport: string;
            },
            custom: [
                {
                    key: string;
                    value: string;
                }
            ]
        },
        hasRegisterDateWasl: boolean;
        garageName: string;
        garageId: number;
    },
    properties?: {
        wasl: {
            referenceKey: string;
            sequenceNumber: string;
            plateType: number;
            imeiNumber: string;
            vehiclePlateNumber: string;
            vehiclePlateRightLetter: string;
            vehiclePlateMiddleLetter: string;
            vehiclePlateLeftLetter: string;
            registerDateWasl: string;
            registerDateSfda: string;
            registerDateTow: string;
            storingCategory: string;
            inquiryStatus: {
                inquiryTime: string;
                isVehicleValid: boolean;
                vehicleRejectionReason: string;
                registrationTime: string;
                vehicleStatus: string;
                actualTime: string;
                receivedTime: string;
            },
            registerDateSpecialityTransport: string;
            registerDateBusRental: string;
            registerDateEducationalTransport: string;
        },
        custom: [
            {
                key: string;
                value: string;
            }
        ]
    },
    accountProperties?: {
        wasl: {
            referenceKey: string;
            identityNumber: string;
            commercialRecordNumber: string;
            commercialRecordIssueDateHijri: string;
            emailAddress: string;
            phoneNumber: string;
            managerName: string;
            managerPhoneNumber: string;
            managerMobileNumber: string;
            registerDateWasl: string;
            registerDateSfda: string;
            registerDateTow: string;
            sfdaCompanyActivity: string;
            registerDateSpecialityTransport: string;
            registerDateBusRental: string;
            registerDateEducationalTransport: string;
        },
        custom: [
            {
                key: string;
                value: string;
            }
        ]
    },
    isActive: boolean;
    isArchived: boolean;
    assignedScheduleId?: number;
    emissionCO2?: number;
    emissionParticlesLight?: number;
    emissionParticlesHeavy?: number;
    urbanConsumption: number;
    extraUrbanConsumption: number;
    mixedConsumption?: number;
    currentAddress?: {
        assetId: number;
        address: string;
        city: string;
        country: string;
    },
    activeProject?: {
        id: number;
        name: string;
        description: string;
        timestamp: string;
        accountId: number;
        companyName: string;
        resellerId: number;
        resellerDescription: string;
        activeAssignments: number;
    },
    currentGeofences: string;
}

export interface AssetGroup {
    id: number;
    name: string;
    emailLoginName: string;
    itemCount: number;
    timestamp: string;
    companyName: string;
    accountId: number;
    systemGroup: false,
    firstName: string;
    lastName: string;
    userId: number;
    adminUserId: number;
    resellerId: number;
    resellerDescription: string;
    groupType: number;
    assetGroupItems: Array<{
        id: number;
        deviceId: number;
        assetName: string;
        plateNumber: string;
        assetCode: string;
        assetGroupId: number;
        iconId: number;
    }>
}

export interface Dashboard {
    deviceCount: number;
    deviceViewerCount: number;
    customerCount: number;
    driverCount: number;
    geofenceCount: number;
    deviceRecursiveCount: number;
    userCount: number;
    warehouseCount: number;
    tagCount: number;
    firstAssetDate: string;
}

export interface StateLookup {
    deviceStates: Record<string, DeviceState>;
    timestamp: string;
}

export interface DeviceState {
    id: number;
    currentPosition: {
        latitude: number;
        longitude: number;
        updateTimestamp: string;
        speed: number;
        heading: number;
        satellites: number;
    };
    odometer: {
        gpsOdometer: number;
        canBusOdometer: number;
        updateTimestamp: number;
    };
    insideGeofences: Record<string, string>; // id, timestamp
    humidity1: {
        humidityInPercent: number;
        updateTimestamp: number;
    };
    engineHour: {
        engineHourInSeconds: number;
        updateTimestamp: number;
    };
    ignition: {
        state: number;
        updateTimestamp: string;
        stateChangedTimestamp: string;
    };
    externalPower: {
        state: number;
        updateTimestamp: string;
        stateChangedTimestamp: string;
    };
    analog1: {
        value: number;
        updateTimestamp: string;
    };
    analog2: {
        value: number;
        updateTimestamp: string;
    };
    externalBattery: {
        batteryLevelInVoltage: number;
        updateTimestamp: string;
    };
    communicationState: {
        locationType: number;
        eventType: number;
        updateTimestamp: string;
        serverTimestamp: string;
        messageSequenceNumber: number;
    };
    deviceActivityStatus: {
        dlpDeviceState: number;
        updateTimestamp: string;
    };
    accelerometerState: {
        updateTimestamp: string;
    };
    calculatedIgnitionState: {
        isIgnitionOn: boolean;
        updateTimestamp: string;
        stateChangedTimestamp: string;
    };
    calculatedDeviceState: {
        deviceState: DeviceStateEnum;
        stateChangedTimestamp: string;
        updateTimestamp: string;
    };
    calculatedCommunicatingState: {
        isCommunicating: boolean;
        updateTimestamp: string;
    };
    calculatedExternalPowerState: {
        isExternalPowerOn: boolean;
        updateTimestamp: string;
    };
    idlingState: {
        isCurrentlyIdling: boolean;
    };
    virtualSensors: [
        {
            virtualSensorId: number;
            name: string;
            value: string;
            sensorOutputType: number;
            changedTimestamp: string;
            updatedTimestamp: string;
        },
        {
            virtualSensorId: number;
            name: string;
            value: string;
            sensorOutputType: number;
            changedTimestamp: string;
            updatedTimestamp: string;
        }
    ];
    humidity2: {
        humidityInPercent: number;
        updateTimestamp: string;
    },
    fuel: {
        fuelLevelInPercentage: number;
        fuelLevelRaw: number;
        updateTimestamp: string;
    },
    input1: {
        state: boolean;
        updateTimestamp: string;
        stateChangedTimestamp: string;
    },
    output1: {
        state: boolean;
        updateTimestamp: string;
        stateChangedTimestamp: string;
    },
    output2: {
        state: boolean;
        updateTimestamp: string;
    },
    backupBattery: {
        batteryLevelInVoltage: number;
        updateTimestamp: string;
    },
    rpmStatus: {
        rpm: number;
        updateTimestamp: string;
    },
    bleBatteryState3: {
        batteryLevelPercentage: number;
        updateTimestamp: string;
    },
}

export enum DeviceStateEnum {
    Unknown = 0,
    IgnitionOn = 1,
    IgnitionOff = 2,
    Idling = 3,
    NotCommunicating = 4,
    ExternalPowerLost = 5,
}

export class Trip {
    assetGroups: string; // JSON
    assetId: number;
    assetName: string;
    assetTypeId: number;
    assetTypeName: string;
    beginLatitude: number;
    beginLongitude: number;
    beginTS: string;
    calibratedOdoValueEnd: number;
    deviceId: number;
    deviceTypeId: number;
    driverId: number;
    driverName: string;
    endLatitude: number;
    endLongitude: number;
    endTS: string;
    engineHourRunningInSeconds: number;
    iconId: number;
    id: number;
    isHidden: boolean;
    messages: Array<{
        eventType: number;
        externalPowerSignal: boolean;
        externalPowerVoltage: number;
        hasGpsFix: boolean;
        ignitionSignal: boolean;
        input1Signal: boolean;
        latitude: number;
        locationType: number;
        longitude: number;
        rtcBasedTimestamp: string;
        serverTimestamp: string;
        timestamp: string;
    }>
    segmentDistanceInKilometers: number;
    tripMethod: number;
    vehicleType: number;
}

export interface TripDetail {
    locationType: number;
    timestamp: string;
    rtcBasedTimestamp: string;
    serverTimestamp: string;
    speed: number;
    latitude: number;
    longitude: number;
    heading: number;
    odometerOffset: number;
    eventType: number;
    fuelLevel: number;
    externalPowerSignal: boolean;
    input1Signal: boolean;
    input2Signal: boolean;
    input3Signal: boolean;
    output1Signal: boolean;
    output2Signal: boolean;
    hasGpsFix: boolean;
    externalPowerVoltage: number;
    analogInput1: number;
    analogInput2: number;
    rpm: number;
}

export interface Dashboard {
    deviceCount: number;
    deviceViewerCount: number;
    customerCount: number;
    driverCount: number;
    geofenceCount: number;
    deviceRecursiveCount: number;
    userCount: number;
    warehouseCount: number;
    tagCount: number;
    firstAssetDate: string;
}

export interface DashboardLocationCount {
    assetCount: number;
    delayMoreThan10: number;
    delayMoreThan60: number;
    delayMoreThan240: number;
    delayMoreThan960: number;
    deviceCommunicatingCount: number;
    deviceCount: number;
    deviceMovingCount: number;
    distanceInMeters: number;
    eventCount: number;
    fkDeviceId: number;
    ignitionCount: number;
    locationCount: number;
    summaryDate: string;
    tripCount: number;
}

export interface Driver {
    accountId: number;
    activeAssignments: number;
    code: string;
    comments: string;
    companyName: string;
    driverLicence: string;
    email: string;
    erpCode: string;
    firstName: string;
    hasRegisterDateWasl: boolean;
    homePhone: string;
    id: number;
    identificationCardNumber: string;
    isActive: boolean;
    isArchived: boolean;
    lastName: string;
    mobilePhone: string;
    name: string;
    officePhone: string;
    resellerDescription: string;
    resellerId: number;
    timestamp: string;
}

export interface DriverScore {
    accelCount: number;
    accellerationEventScore: number;
    accellerationEventScoreWeighted: number;
    accountId: number;
    assetCount: number;
    assignmentCount: number;
    brakingEventScore: number;
    brakingEventScoreWeighted: number;
    calculatorValue: number;
    corneringCount: number;
    corneringEventScore: number;
    corneringEventScoreWeighted: number;
    decelCount: number;
    driverGroups: string;
    driverId: number;
    driverName: string;
    durationSeatBeltViolationInSeconds: number;
    episodeAccelCount: number;
    episodeCorneringCount: number;
    episodeDecelCount: number;
    episodeRPMCount: number;
    idlingDurationInSeconds: number;
    idlingScore: number;
    idlingScoreWeighted: number;
    isActive: boolean;
    maxSpeed: number;
    pureDrivingDurationInSeconds: number;
    rpmCount: number;
    rpmEventScore: number;
    rpmEventScoreWeighted: number;
    score: number;
    seatBeltScore: number;
    seatBeltScoreWeighted: number;
    segmentsDistance: number;
    speedScore: number;
    speedScoreWeighted: number;
    speedingCount: number;
    speedingDurationInSeconds: number;
    totalDurationInSeconds: number;
    tripCount: number;
    vehicleType: number;
    workingHoursDurationInSeconds: number;
}
