import { ContentView } from "../content-view";

export class Desk extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("TicketManager");
    }

}
