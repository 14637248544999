import { ContentView } from "../content-view";
    
export class Stats extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("StatsOverview");
    }

}
