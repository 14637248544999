import "./message-detail.scss";
import * as template from "./message-detail.hbs";

import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { MessageDetailOptions } from "./types";
import { TrafficMessage } from "../cits-manager/types";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { LineString, Point } from "geojson";
import { TrafficMessageCircleLayer } from "../../../layers/traffic/traffic-message-circle-layer";
import { TrafficMessageSymbolLayer } from "../../../layers/traffic/traffic-message-symbol-layer";
import { ZOOM_LABEL } from "../../../../muklit/components/basic-map/map-layer";

export class MessageDetail extends Detail<InvipoContext, MessageDetailOptions> {

    // Components
    public map: BasicMap;
    public layer: TrafficMessageCircleLayer;

    // Event handling methods
    public onSelectDetail(message: TrafficMessage): void {}
    public onSelectDelete(message: TrafficMessage): void {}

    constructor(context: InvipoContext, options: MessageDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new TrafficMessageCircleLayer(this.context, <any>this.options.message));
        this.map.addLayer(new TrafficMessageSymbolLayer(this.context, <any>this.options.message));

        // Center map to first point in line
        if (this.options.message.position.type == "LineString") {
            this.map.setCenter((<LineString>this.options.message.position).coordinates[0]);
        }

        // Center map to coordinates
        if (this.options.message.position.type == "Point") {
            this.map.setCenter((<Point>this.options.message.position).coordinates);
        }
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: ZOOM_LABEL,
            minZoom: 5,
            maxZoom: 20
        });
    }

    public selectDetail(): void {
        // OnSelectDetail handler
        this.onSelectDetail(this.options.message);
    }

    public selectDelete(): void {
        // OnSelectDelete handler
        this.onSelectDelete(this.options.message);
    }
}
