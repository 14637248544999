import "./toggle.scss";
import * as template from "./toggle.hbs";
import { Context } from "hiyo/context";
import { ToggleOptions } from "./types";
import { Input } from "../input/input";

export class Toggle extends Input<Context, ToggleOptions> {

    // Event handling methods
    public onToggle(toggled: boolean): void {};

    constructor(context: Context, options: ToggleOptions) {
        super(context, template, options);

        // Default is false if not defined
        this.options.value = this.options.value || false;
    }

    public toggle(toggled?: boolean): void {
        // Set or toggle checked status
        this.options.value = toggled || !this.options.value;

        // Update
        //this.update();
        // Switch class (because of animation)
        this.element.classList.toggle("muklit-toggle-toggled-on", this.options.value);
        this.element.classList.toggle("muklit-toggle-toggled-off", !this.options.value);

        // Handlers
        this.onToggle(this.options.value);
    }

    public setValue(value: any, label?: string): void {
        // Sets value to options
        this.options.value = value;

        // Also change label if required
        this.options.label = label || this.options.label;

        // Reattach select to render it properly
        this.update();
    }

    public setLabel(label: string): void {
        // Cahnge label dynamically
        this.element.querySelector("div.label").innerHTML = this.context.locale.getMessage(label);
    }

}
