import { AreaSpotLayer } from "../area-spot-layer";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { InvipoHelpers } from "../../invipo-helpers";
import { DEFAULT_LANE_CAPACITY } from "../../components/traffic/traffic-counting-subdomain/traffic-counting-subdomain";

export class TrafficVolumeAreaLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "traffic-volume-area-label",
                type: "symbol",
                maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = await this.context.invipo.getAreas("type=TrafficVolume");

        // Load items
        //let items = await this.context.invipo.getAreaItems(`class=TrafficLightController`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            // Load area items
            let items = await this.context.invipo.getAreaItems(area.id);

            // Maximum volume and number of segments
            let peak = 0;
            let segments = 0;

            for (let item of items) {
                // No data?
                if (!item.data?.traffic) {
                    continue
                }

                for (let data of item.data.traffic) {
                    // Hour value from data
                    let hour = data.count * (60 / this.context.config.aggregations.traffic.interval);

                    // Current segment definition
                    let segment = (<any[]>item.meta?.segments)?.find(x => x.name == data.segment);

                    // Calcualte volume as percentage of capacity
                    let volume = hour / (segment?.capacity || DEFAULT_LANE_CAPACITY) * 100;

                    // New maximum?
                    if (volume > peak) {
                        peak = volume;
                    }
                }

                segments += item.meta?.segments?.length || 0;
            }

            json.features.push({
                type: "Feature",
                properties: {
                    detail: this.options.detail,
                    cardId: area.id,
                    areaId: area.id,
                    areaName: area.name,
                    color: peak > 75 ? "#ffffff" : "#161616",
                    label: `${Helpers.toNumber(Helpers.clamp(peak, 0, 100))} %`
                },
                geometry: {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(area.geometry).getCenter().toArray()
                }
            })
        }

        return json;
    }
}
