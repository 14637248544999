import "./environmental-sensor-overview.scss";
import * as template from "./environmental-sensor-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { EnvironmentalSensorOverviewOptions } from "./types";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class EnvironmentalSensorOverview extends DetailPanel<EnvironmentalSensorOverviewOptions> {

    // Properties
    public history: { [data: string]: DetailHistoryValue[] };

    constructor(context: InvipoContext, options?: EnvironmentalSensorOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history data
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let history = await this.context.invipo.getDataset("items-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        this.history = {};

        // Build all char data from all envi blocks and properties into one history object

        for (let m of ["air", "pollution"]) {
            for (let d of (Object.keys(METRICS.envi[m]))) {
                // Entry record
                this.history[d] = [];
                
                // Return to start line
                let timestamp = new Date(from);

                // Get history timestamp yesterday's midnight to today's midnight
                for (let q = 0; q < 96; q++) {
                    // Find hour in data
                    let data = history.data.find(x => x.timestamp == timestamp.toISOString());

                    // Add history line
                    if (data) {
                        // Get current metrics
                        let metrics = METRICS.envi[m][d];

                        // Get value under right data block and proeprty
                        let value = data.item.data.envi && data.item.data.envi[m] && data.item.data?.envi[m][d];

                        // Push new data recrod
                        this.history[d].push({
                            timestamp: timestamp.toISOString(),
                            type: m,
                            data: d,
                            value: value,
                            percent: Helpers.range(0, 100, metrics.range[0], metrics.range[1], value),
                            color: metrics.colors[Math.round(Helpers.range(0,metrics.colors.length - 1, metrics.range[0], metrics.range[1], value))]
                        });
                    }
                    // No data for hour, we are in the future
                    else {
                        this.history[d].push({
                            timestamp: timestamp.toISOString()
                        });
                    }

                    // Move to next hour
                    timestamp.setTime(timestamp.getTime() + 1800000);
                }
            }
        }

    }

}
