import "./battery-status-browser.scss";
import * as template from "./battery-status-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";
import { BatteryStatusBrowserOptions } from "./types";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";

export class BatteryStatusBrowser extends MuklitComponent<InvipoContext, BatteryStatusBrowserOptions> {

    // Components
    public form: Form;
    public table: QueryTable;

    constructor(context: InvipoContext, options?: BatteryStatusBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
    }

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "All",
                            itemClass: "Battery",
                            items: [],
                            multiselect: true
                        })
                    ]
                }
            ]
        });

        // Log data
        this.form.onSubmit = async (data: any) => {
            // Send new query to table
            await this.table.search(data);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/batteries-data`,
            http: this.context.invipo.http,
            bar: {
                title: "components.BatteryStatusBrowser.title",
                reloadable: true,
                closable: this.options.closable ?? true,
                items: [
                    {
                        name: "Export",
                        label: "components.TitleBar.export"
                    }
                ]
            },
            pagination: {
                page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        width: 220,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "voltage",
                        type: "Number",
                        property: "voltage",
                        label: "tables.columns.voltage",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} V</div>`
                        },
                        width: 120,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "temperature",
                        type: "Number",
                        property: "temperature",
                        label: "tables.columns.temperature",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &deg;C</div>`
                        },
                        width: 120,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "last",
                        type: "String",
                        property: "last",
                        label: null,
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onMenuSelect = (item: MenuItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Clear input
        this.table.onTagRemove = (tag: TitleBarTag) => {
           this.form.setValue(tag.name, null);
        }

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "BatteryCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
