import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";

export class HotspotConnectionsLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "WifiAccessPointCard",
            detail: "CityDetail",
            layer: {
                id: "hotspot-connections-label",
                type: "symbol",
                minzoom: ZOOM_LABEL,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-line-height": 1,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 10
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems(`class=WifiAccessPoint`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            json.features.push({
                type: "Feature",
                properties: {
                    data: "connections",
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    label: `${item.name}\n${item.data?.network?.connections || 0}`
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
