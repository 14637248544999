import './fleet-system-tile.scss';
import * as template from "./fleet-system-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetSystemTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis } from "../../common/tile/types";
import { Helpers } from "../../../../hiyo/helpers";

export class FleetSystemTile extends Tile<FleetSystemTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: FleetSystemTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        const dashboard = await this.context.gpsCockpit.getDashboard();
        console.log("Dashboard", dashboard);

        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.FleetSystemTile.deviceCount",
                    value: Helpers.toNumber(dashboard.deviceCount)
                },
                {
                    label: "components.FleetSystemTile.driverCount",
                    value: Helpers.toNumber(dashboard.driverCount)
                },
                {
                    label: "components.FleetSystemTile.geofenceCount",
                    value: Helpers.toNumber(dashboard.geofenceCount)
                },
                {
                    label: "components.FleetSystemTile.userCount",
                    value: Helpers.toNumber(dashboard.userCount)
                }
            ]
        }
    }

}
