import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";

export class WasteCollectionFrequencyHeatmapLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "waste-collection-frequency-heatmap",
                type: "heatmap",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ['linear'],
                        ["get", "count"],
                        0, 0,
                        7, 1
                    ],
                    "heatmap-intensity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 0.1,
                        9, 1
                    ],
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0.0, "rgba(204, 204, 204, 0)",
                        0.2, "#7cd4ff",
                        0.4, "#00acff",
                        0.6, "#008cd0",
                        0.8, "#ff9046",
                        1.0, "#c94f16",
                    ],
                    "heatmap-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        9, 10,
                        16, 60,
                        22, 300
                    ],
                    "heatmap-opacity": 0,
                    "heatmap-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "heatmap-opacity": 0.75
            }
        });
    }

    public async load(): Promise<any> {
        // Load data
        let from = new Date(new Date(Date.now() - 7 * 86400000).setHours(0, 0, 0, 0));
        let data = await this.context.invipo.getQuery("waste-collection-by-item", `item.class=GarbageBin&from=${from}`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let d of data) {
            json.features.push({
                type: "Feature",
                properties: {
                    type: "Location",
                    itemId: d.item.id,
                    itemName: d.item.name,
                    itemClass: d.item.class,
                    count: d.count
                },
                geometry: d.item.geometry["location"]
            })
        }

        return json;
    }

}
