import { Context } from "../../hiyo/context";
import { Log } from "../../hiyo/log";
import { InvipoClient } from "../clients/invipo-client/invipo-client";
import { WsClient } from "../clients/ws-client/ws-client";
import { HiyoEventSource } from "../../hiyo/event-broker";
import { InvipoEvent } from "../clients/invipo-client/types";
import { InvipoConfig, InvipoContextOptions } from "./types";
import { GpsCockpitClient } from "invipo/clients/gpscockpit-client/gpscockpit-client";

export class InvipoContext extends Context {

    // Properties
    public options: InvipoContextOptions;
    public config: InvipoConfig;
    public invipo: InvipoClient;
    public ws: WsClient;
    public gpsCockpit: GpsCockpitClient;

    constructor(options: InvipoContextOptions) {
        Log.i(`Creating InvipoContext instance`);

        // Validation
        if (!options.host) {
            throw new Error(`Missing options.host in InvipoContextOptions`);
        }
        if (!options.langs) {
            throw new Error(`Missing options.langs in InvipoContextOptions`);
        }
        if (!options.project) {
            throw new Error(`Missing options.project in InvipoContextOptions`);
        }
        if (!options.name) {
            throw new Error(`Missing options.name in InvipoContextOptions`);
        }
        if (!options.classes) {
            throw new Error(`Missing options.classes in InvipoContextOptions`);
        }
        if (!options.permissions) {
            throw new Error(`Missing options.permissions in InvipoContextOptions`);
        }
        if (!options.notifications) {
            throw new Error(`Missing options.notifications in InvipoContextOptions`);
        }
        if (!options.home) {
            throw new Error(`Missing options.home in InvipoContextOptions`);
        }
        if (!options.city) {
            throw new Error(`Missing options.city in InvipoContextOptions`);
        }
        if (!options.views) {
            throw new Error(`Missing options.views in InvipoContextOptions`);
        }
        for (let domain of options.city) {
            if (!domain.domain) {
                throw new Error(`Missing options.city.domain in InvipoContextOptions`);
            }
            if (!domain.subdomains) {
                throw new Error(`Missing options.city.subdomains[] in InvipoContextOptions`);
            }
            if (!domain.data) {
                throw new Error(`Missing options.city.data[] in InvipoContextOptions`);
            }
            if (!domain.insights) {
                throw new Error(`Missing options.city.insights[] in InvipoContextOptions`);
            }
        }

        // Base init
        super(options);

        this.options = options;

        // Invipo client instance
        this.invipo = new InvipoClient({
            host: this.options.host,
            accessToken: this.options.accessToken
        });

        // Websocket URL uses same host
        let wsUrl = `${new URL(this.options.host).protocol.replace("http", "ws")}//${new URL(this.options.host).host}/ws`;

        // WebSocket client
        this.ws = new WsClient({
            wsUrl: wsUrl
        });

        // Ws onEvent handler
        this.ws.onEvent = (event: InvipoEvent) => {

            // InvipoEvent -> HiyoEvent
            this.broker.fire({
                type: event.type,
                source: HiyoEventSource.Websocket,
                timestamp: event.timestamp,
                payload: event
            });
        };

        // GpsCockpit client
        this.gpsCockpit = new GpsCockpitClient(this.invipo);
    }
}

