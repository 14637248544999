import * as template from "./monitoring-panel.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { MonitoringPanelOptions } from "./types";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { DatasetResult } from "../../../../muklit/components/query-table/types";

export class MonitoringPanel extends DetailPanel<MonitoringPanelOptions> {

    // Properties
    public history: DetailHistoryValue[];
    public downtime: DatasetResult;
    public log: DatasetResult;

    constructor(context: InvipoContext, options?: MonitoringPanelOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history data
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let history = await this.context.invipo.getDataset("items-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let q = 0; q < 144; q++) {
            // Find hour in data
            let data = history.data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (data) {
                // Occupancy color
                let color = "";
                let status = data.item.monitoringStatus?.status;

                // No data
                if (status == null) {
                    color = "#cccccc";
                }
                // Ok
                else if (status == "Ok") {
                    color = "#0fd170";
                }
                // Warn
                else if (status == "Warn") {
                    color = "#fcb836";
                }
                // Error
                else if (status == "Error") {
                    color = "#ff0038";
                }
                // Unreachable
                else if (status == "Unreachable") {
                    color = "#7d00ff";
                }
                // Disconnected
                else if (status == "Disconnected") {
                    color = "#cccccc";
                }

                // Push new data recrod
                this.history.push({
                    timestamp: from.toISOString(),
                    type: status,
                    value: 100,
                    percent: 100,
                    color: color
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString()
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 1200000);
        }

        // Set from 2 weeks ago
        from = new Date(new Date().setHours(-14 * 24, 0, 0, 0));

        // Load last history changes
        this.downtime = await this.context.invipo.getDataset("monitoring-status-history", `item.id=${this.item.id}&from=${from.toISOString()}&status=Disconnected,Unreachable&pageSize=5&sort=timestamp:desc`);

        // Load last log
        this.log = await this.context.invipo.getDataset("items-log", `item.id=${this.item.id}&pageSize=5&sort=timestamp:desc`);
    }

}
