import "./ulm-dashboard.scss";
import * as template from "./ulm-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Component } from "hiyo/component";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { UlmDashboardOptions } from "./types";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";
import { ItemsInspectorTile } from "../items-inspector-tile/items-inspector-tile";
import { CategoryVolumeTile } from "../bicycle-count-tile/category-volume-tile";

export class UlmDashboard extends Component<InvipoContext, UlmDashboardOptions> {

    // Components
    public map: BasicMap;
    public storage: DataStorageTile;
    public inspector: ItemsInspectorTile;
    public volumes: TrafficVolumeTile[];
    public bicycles: CategoryVolumeTile;
    public trucks: CategoryVolumeTile;

    constructor(context: InvipoContext, options?: UlmDashboardOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createTiles();
    }

    public onAttach() {
        // Attach inspector tile
        this.inspector.attach(this.query("div.column-system"));

        // Attach storage tile
        this.storage.attach(this.query("div.column-system"));

        // Attach volume tiles
        for (let volume of this.volumes) {
            volume.attach(this.query("div.column-traffic div.tiles"));
        }

        // Attach bicycle count tile
        this.bicycles.attach(this.query("div.column-mobility"));

        // Attach trucks count tile
        this.trucks.attach(this.query("div.column-mobility"));
    }

    public onDetach() {
        // Detach storage tile
        this.storage.detach();

        // Detach inspector tile
        this.inspector.detach();

        // Detach volume tiles
        for (let volume of this.volumes) {
            volume.detach();
        }

        // Detach bicycles tile
        this.bicycles.detach();

        // Detach bicycles tile
        this.trucks.detach();
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 21
        });

        // Register component
        this.registerComponent(this.map, "map");
    }

    private createTiles(): void {
        // Create data storage tile
        this.storage = new DataStorageTile(this.context, {
            style: "Light"
        });

        // Create items inspector tile
        this.inspector = new ItemsInspectorTile(this.context, {
            style: "Light"
        });

        // Volume tiles are created in load() method
        this.volumes = [];

        // Create bicycle count tile
        this.bicycles = new CategoryVolumeTile(this.context, {
            style: "Light",
            categoryId: 2
        });

        // Create trucks count tile
        this.trucks = new CategoryVolumeTile(this.context, {
            style: "Light",
            categoryId: 7
        });
    }

    public async load(): Promise<void> {
        // Tiles laraedy created?
        if (this.volumes?.length) {
            return;
        }

        // Load areas
        let areas = await this.context.invipo.getAreas("type=TrafficVolume");

        // Tiles not created?
        for (let area of areas) {
            // Create tile
            let volume = new TrafficVolumeTile(this.context, {
                style: "Light",
                areaId: area.id
            })

            // Attach tile
            volume.attach(this.query("div.column-traffic div.tiles"));

            // Add volume to volumes for detach
            this.volumes.push(volume);
        }
    }

}
