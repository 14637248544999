import "./date-input.scss";
import * as template from "./date-input.hbs";
import { Context } from "hiyo/context";
import { DateInputOptions } from "./types";
import { Input } from "../input/input";
import { Helpers } from "../../../hiyo/helpers";
import { InputValue } from "../input/types";
import { Calendar } from "../calendar/calendar";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

const CLASS_ACTIVE = "muklit-date-input-active";

export class DateInput extends Input<Context, DateInputOptions> {
    // Components
    public calendar: Calendar;

    constructor(context: Context, options: DateInputOptions) {
        // Localization
        Handlebars.registerHelper("asInputDate", (t: string) => {
            if (t) {
                return new Date(new Date(t).getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 10)
            } else {
                return '';
            }
        });

        Handlebars.registerHelper("asInputDateTime", (t: string) => {
            if (t) {
                return new Date(new Date(t).getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 16)
            } else {
                return '';
            }
        });

        super(context, template, options);
    }

    public onCreate(): void {
        // Value rearrangement
        if (this.options.value && typeof this.options.value == "string") {
            let value: any = {};
            value[new Date(this.options.value).toISOString()] = Helpers.toDateTimeString(this.options.value);

            this.options.value = value;
        }

        // Create components
        this.calendar = new Calendar(this.context, {
            style: this.options.style,
            type: 'Day',
            from: this.options.value ? Object.keys(this.options.value)[0] : undefined,
            start: "BottomLeft",
            anchor: "TopLeft",
            offset: [0, 1],
        });

        this.calendar.onDateSelect = (date) => {
            // Hide calendar
            this.calendar.hide();

            // Update inputs
            let value: any = {};
            value[new Date(date).toISOString()] = Helpers.toDateTimeString(date);

            this.setValue(value);

            // OnSubmit handler
            this.onSubmit();
        }
    }

    public onAttach (): void {
        if (this.calendar.isAttached()) {
            this.updateCalendar();
        }
    }

    public onUpdate (): void {
        if (this.calendar.isAttached()) {
            this.element.classList.add(CLASS_ACTIVE);
        }
    }

    private updateCalendar () {
        // Light or Dark
        this.calendar.options.style = this.options.style;

        // Update current values
        if (this.options.value) {
            this.calendar.setValue(Object.keys(this.options.value)[0]);
        }
        else {
            this.calendar.setValue(null);
        }
    }

    public overlayClick () {
        // Remove active class from element
        this.element.classList.remove(CLASS_ACTIVE);

        // Close calendar
        this.calendar.hide();
    }

    public click(): void {
        if (this.options.disabled) return;

        if (!this.calendar.isAttached()) {
            // Set element as active
            this.element.classList.add(CLASS_ACTIVE);

            // Show calendar
            this.calendar.show(this.element.querySelector("div.input"));

            // Set data to calendar
            this.updateCalendar();
        }
    }

    public focus(selectAll?: boolean): void {
        this.element.classList.add(CLASS_ACTIVE);

        // Focus also an input element
        this.query("input").focus();

        // Select all text inside input?
        if (selectAll && this.options.value) {
            this.query<HTMLInputElement>("input").setSelectionRange(0, this.options.value.length);
        }
    }

    public key(key: number) {
        // Read value
        let value = this.element.querySelector<HTMLInputElement>("input").value;

        // Enter
        if (key == 13) {
            this.onSubmit();

            // Remove active class and close calendar
            this.element.querySelector('input').blur();
            this.element.classList.remove(CLASS_ACTIVE);
            this.calendar.hide();
        }
        // ESC
        else if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear value
            this.setValue(null);

            // Remove active class and close calendar
            this.element.querySelector('input').blur();
            this.element.classList.remove(CLASS_ACTIVE);
            this.calendar.hide();

            // OnSubmit handler
            this.onSubmit();
        }
        // Standard key
        else {
            // Set value
            this.options.value = {};

            // Sets only not null value
            if (value) {
                this.options.value[new Date(value).toISOString()] = Helpers.toDateTimeString(value);
            }
            this.updateCalendar();
        }
    }

    public setValue(value: string | InputValue): void {
        // Set value options
        if (value == null) {
            this.options.value = null;
        }
        else if (typeof value == "object") {
            this.options.value = value;
        }
        else {
            this.options.value = {};
            this.options.value[new Date(value).toISOString()] = Helpers.toDateTimeString(value);
        }

        // Reattach select to render it properly
        this.update();
    }

}
