import "./text-area.scss";
import * as template from "./text-area.hbs";
import { Context } from "hiyo/context";
import { TextAreaOptions } from "./types";
import { Input } from "../input/input";

const CLASS_ACTIVE = "muklit-text-area-active";

export class TextArea extends Input<Context, TextAreaOptions> {

    // Event handling methods
    public onKey(key: number): void {};

    constructor(context: Context, options: TextAreaOptions) {
        super(context, template, options);
    }

    public focus(selectAll?: boolean): void {
        this.element.classList.add(CLASS_ACTIVE);

        // Focus also an input element
        this.query("textarea").focus();

        // Select all text inside input?
        if (selectAll && this.options.value) {
            this.query<HTMLInputElement>("textarea").setSelectionRange(0, this.options.value.length);
        }
    }

    public blur(): void {
        this.element.classList.remove(CLASS_ACTIVE);
    }

    public key(key: number) {
        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Read value
        this.options.value = this.element.querySelector<HTMLInputElement>("textarea").value;

        // Enter - textarea usually uses Ctrl+Enter to confirm
        if (key == 13 && (<KeyboardEvent>event).ctrlKey) {
            this.onSubmit();
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear value
            this.setValue(null);
            this.onSubmit();
            return;
        }

        // OnKey handler
        this.onKey(key);
    }

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        this.update();
    }

}
