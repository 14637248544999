import "./item-geometry-form.scss";
import * as template from "./item-geometry-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { ItemGeometryFormOptions } from "./types";
import { Feature, Point } from "geojson";
import { DrawMap } from "muklit/components/draw-map/draw-map";
import { HradecKraloveOrthophotoLayer } from "../../../layers/custom/hradec-kralove-orthophoto-layer";
import { FeatureForm } from "../feature-form/feature-form";
import { MapControl } from "../../common/map-control/map-control";
import { Button } from "muklit/components/button/button";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { DrawerGroup, DrawerItem } from "../../common/drawer-menu/types";
import { DrawerMenu } from "../../common/drawer-menu/drawer-menu";
import { ItemGeometryDetail } from "../item-geometry-detail/item-geometry-detail";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Helpers } from "../../../../hiyo/helpers";

export const FEATURE_KEY: {[ type: string]: string } = {
    "LineString": "segment",
    "Point": "location",
    "Polygon": "area"
}
export class ItemGeometryForm extends Detail<InvipoContext, ItemGeometryFormOptions> {

    // Components
    public map: DrawMap;
    public menu: DrawerMenu;
    public control: MapControl;
    public button: Button;
    public form: FeatureForm;
    public detail: ItemGeometryDetail;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: ItemGeometryFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createMenu();
        this.createDetail();
        this.createControl();
        this.createButton();
    }

    public createMap () {
        // Create component
        this.map = new DrawMap(this.context, {
            style: "Light",
            center: this.options.item ? (<Point>this.options.item.geometry.location).coordinates : this.context.options.home.center,
            zoom: 18,
            maxZoom: 22,
            pointControl: true,
            lineControl: true,
            areaControl: true
        });

        // Check custom layers on style loaded
        this.map.onMapLoad = () => {
            // HradecKraloveOrthophoto style?
            if (this.map.options.style == "HradecKraloveOrthophoto") {
                // Project custom orthophoto raster layer that must be added separately outside the layer management as a first layer
                this.map.mapboxMap.addLayer(new HradecKraloveOrthophotoLayer(this.context).options.layer, this.map.mapboxMap.getStyle().layers[0].id);
            }
            // GoogleOrthophoto style?
            if (this.map.options.style == "GoogleOrthophoto") {
                // Google orthophoto imaginery
                this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer, this.map.mapboxMap.getStyle().layers[0].id);
            }
        }

        // Add feature and open form
        this.map.onFeatureCreate = async (feature: Feature) => {
            // Set key to properties to store Invipo geo type
            feature.properties.key = FEATURE_KEY[feature.geometry.type];

            // Update feature via add()
            this.map.addFeature(feature);

            // Update features in detail
            this.detail.setFeatures(this.map.getFeatures());
        }

        // Open feature form
        this.map.onFeatureUpdate = async (feature: Feature) => {
            // Open feature form
            this.openFeature(feature);

            // Update features in detail
            this.detail.setFeatures(this.map.getFeatures());
        }

        // Dismis feature form
        this.map.onFeatureDelete = async (feature: Feature) => {
            // Close feature form
            this.openFeature(null);

            // Update features in detail
            this.detail.setFeatures(this.map.getFeatures());
        }

        // Open feature form
        this.map.onFeatureSelect = async (feature: Feature) => {
            // Open feature form
            this.openFeature(feature);

            // Update features in detail
            this.detail.setFeatures(this.map.getFeatures());
        }

        // Register component
        this.registerComponent(this.map, "map");
    }

    public createMenu(): void {
        // New group based on domain name
        let group: DrawerGroup = {
            name: "Data",
            title: "details.blocks.data",
            items: []
        }

        // Item alias
        let item = this.options.item;

        // Has traffic data
        if (item.data?.traffic?.length) {
            for (let data of item.data.traffic) {
                group.items.push({
                    name: data.segment,
                    label: data.segment
                })
            }
        }

        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [group]
        });

        // Handle select
        this.menu.onSelect = (item: DrawerItem) => {
            // TODO
        }

        // Register component
        this.registerComponent(this.menu, "menu");
    }

    public createDetail(): void {
        // Create component
        this.detail = new ItemGeometryDetail(this.context, {
            style: "Light",
            title: this.options.item.name,
            subtitle: `classes.${this.options.item.class}`,
            item: this.options.item
        });

        // Register component
        this.registerComponent(this.detail, "detail");
    }

    private createControl(): void {
        // Create component
        this.control = new MapControl(this.context, {
            style: "Light",
            styleItems: [
                {
                    name: "Light",
                    label: "enums.MapStyle.Light",
                    disabled: true // Light style is default, see createMap()
                }
            ]
        });

        // Custom styles in config?
        if (this.context.options.home.styles) {
            for (let style of this.context.options.home.styles) {
                this.control.options.styleItems.push({
                    name: style,
                    label: `enums.MapStyle.${style}`
                });
            }
        }

        // Zoom in map
        this.control.onZoomInSelect = () => {
            this.map.zoomIn();
        }

        // Zoom out map
        this.control.onZoomOutSelect = () => {
            this.map.zoomOut();
        }

        // Zoom to all
        this.control.onZoomAllSelect = () => {
            //this.map.fitAll();
        }

        // Zoom to default position
        this.control.onZoomHomeSelect = () => {
            this.map.flyTo( {
                center: this.context.options.home.center,
                zoom: this.context.options.home.zoom,
                duration: 3000
            });
        }

        // Change map style
        this.control.onStyleSelect = (name: string) => {
            this.map.setStyle(name);
        }

        // Register component
        this.registerComponent(this.control, "control");
    }

    private createButton(): void {
        // Create component
        this.button = new Button(this.context, {
            style: "Light",
            kind: "Primary",
            size: "Medium",
            type: "LabelOnly",
            label: "labels.save",
            align: "Center",
            width: "80px"
        })

        // Save form
        this.button.onClick = async () => {
            await this.submit();
        }

        // Register component
        this.registerComponent(this.button, "button");
    }

    public openFeature(feature: Feature): void {
        console.info(feature);

        // Detach existing and construct new
        if (this.form?.isAttached()) {
            this.form.detach();
        }

        // Just unselecting if no feature was selected
        if (!feature) {
            return;
        }

        // New image detail
        this.form = new FeatureForm(this.context, {
            style: "Light",
            title: feature.properties.key ? `components.FeatureForm.key.${feature.properties.key}` : "components.FeatureForm.unsupported",
            subtitle: feature.geometry.type == "LineString" ? `${Helpers.toNumber(InvipoHelpers.toLength(feature), 0)} m` : null,
            feature: feature
        });

        // Update feature when saved
        this.form.onSubmit = (feature: Feature) => {
            // Adding the same feature will update it
            this.map.addFeature(feature);

            // Update features in detail
            this.detail.setFeatures(this.map.getFeatures());
        }

        // Show
        this.form.attach(this.query("div.feature"));
    }

    public async submit(): Promise<void> {
        // Shortcut from options
        let item = this.options.item;
        let features = this.map.getFeatures();

        // Data selection from features
        let location = features.find(x => x.properties.key == "location");
        let area = features.find(x => x.properties.key == "area");
        let segments = features.filter(x => x.properties.key == "segment");

        // Show loader
        this.showLoader();

        // Update flags
        let updateMeta = false;
        let updateGeometry = false;

        // Merge features with item data and if changed do partial updates
        try {
            // Has location?
            if (location) {
                // Set item new location
                item.geometry.location = location.geometry;

                // Safe way to add direction to existing or empty meta object
                item.meta = {
                    ...item.meta,
                    direction: location.properties.direction
                }

                // Update flags
                updateGeometry = true;
                updateMeta = true;
            }

            // Has area?
            if (area) {
                // Set item new area
                item.geometry.area = area.geometry;

                // Update flags
                updateGeometry = true;
            }

            // Has segments?
            if (segments?.length > 0) {
                // Safe way to add direction to existing or empty meta object
                item.meta = {
                    ...item.meta,
                    segments: []
                }

                // add segments to item
                for (let feature of segments) {
                    // Delete key propery not to merge
                    delete feature.properties.key;

                    // Ad new item segment
                    item.meta.segments.push({
                        ...feature.properties,
                        segment: feature.geometry
                    });
                }

                // Update flag
                updateMeta = true;
            }

            // Should be geometry updated?
            if (updateGeometry) {
                await this.context.invipo.updateItemGeometry(item.id, {
                    geometry: item.geometry
                });
            }

            // Should be meta updated?
            if (updateMeta) {
                // Update item metadata
                await this.context.invipo.updateItemMeta(item.id, {
                    meta: item.meta
                });
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }

    public async load(): Promise<void> {
        // Shortcut from options
        let item = this.options.item;

        // Map feature to be displayd
        let features: Feature[] = [];

        // Standard item geometry location
        if (item.geometry.location) {
            features.push({
                type: "Feature",
                properties: {
                    key: "location",
                    direction: item.meta?.direction
                },
                geometry: item.geometry.location
            });
        }

        // Standard item geometry area
        if (item.geometry.area) {
            features.push({
                type: "Feature",
                properties: {
                    key: "area",
                    direction: item.meta?.direction
                },
                geometry: item.geometry.area
            });
        }

        // Meta segments for traffic counter?
        if (item.meta?.segments) {
            for (let segment of item.meta.segments) {
                features.push({
                    type: "Feature",
                    properties: {
                        key: "segment",
                        name: segment.name,
                        capacity: segment.capacity,
                        offset: segment.offset,
                        type: segment.type,
                        bidirectional: segment.bidirectional
                    },
                    geometry: segment.segment
                });
            }
        }

        // Set features to map
        this.map.setFeatures(features);

        // Set features to detail
        this.detail.setFeatures(features);
    }
}
