import { Select } from "muklit/components/select/select";
import { InvipoContext } from "../../../context/invipo-context";
import { AreaSelectOptions } from "./types";

export class AreaSelect extends Select<InvipoContext, AreaSelectOptions> {

    public async load(): Promise<void> {
        // Load items
        let items = await this.context.invipo.getAreas(`type=${this.options.areaType}&sort=name:asc`);

        // Reset menu options
        this.options.items = []

        for (let item of items) {
            // Add item
            this.options.items.push({
                name: item.id,
                label: item.name
            });
        }

        // Value rearrangement
        if (this.options.value && typeof this.options.value == "string") {
            let item = this.options.items.find(x => x.name == this.options.value);

            // Item found?
            if (item) {
                this.options.value = {};
                this.options.value[item.name] = item.label;
            }
        }

        // Gone while loading?
        if (!this.isAttached()) {
            return;
        }

        // Reattach select to render it properly
        this.update();
    }

}
