import "./envi-browser.scss";
import * as template from "./envi-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { EnviBrowserOptions } from "./types";
import { EnviDetail } from "../envi-detail/envi-detail";
import { EnviDetailOptions } from "../envi-detail/types";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";

export abstract class EnviBrowser<T extends EnviBrowserOptions> extends MuklitComponent<InvipoContext, T> {

    // Components
    public form: Form;
    public table: FilterTable;
    public detail: EnviDetail;

    constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach(this.query("div.detail"));
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public abstract createTable(): void;

    public async openDetail(data: any): Promise<void> {
        // Detail options
        let options: EnviDetailOptions = {
            style: "Light",
            envi: data,
            title: null,
            subtitle: data.item.name,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new EnviDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.envi.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach(this.query("div.detail"));
    }
}
