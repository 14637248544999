import { ContentView } from "../content-view";

export class Devices extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("ItemManager");
    }

}
