import "./traffic-messages-subdomain.scss";
import * as template from "./traffic-messages-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficMessagesSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { TrafficMessageSymbolLayer } from "../../../layers/traffic/traffic-message-symbol-layer";
import { TrafficMessageCircleLayer } from "../../../layers/traffic/traffic-message-circle-layer";
import { HiyoEvent } from "../../../../hiyo/event-broker";
import { TrafficMessageList } from "../traffic-message-list/traffic-message-list";
import { ItemList } from "../../city/item-list/item-list";
import { DatasetResult } from "../../../../muklit/components/query-table/types";
import { CitsVehicleList } from "../cits-vehicle-list/cits-vehicle-list";
import { CitsVehicleCircleLayer } from "../../../layers/traffic/cits-vehicle-circle-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class TrafficMessagesSubdomain extends CitySubdomain<TrafficMessagesSubdomainOptions> {

    // Properties
    public messages: any[];
    public vehicles: DatasetResult;

    constructor(context: InvipoContext, options?: TrafficMessagesSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Messages",
                    itemClass: "V2XUnit",
                    layers: [
                        new TrafficMessageCircleLayer(context),
                        new TrafficMessageSymbolLayer(context)
                    ]
                },
                {
                    name: "Vehicles",
                    itemClass: "V2XUnit",
                    layers: [
                        new CitsVehicleCircleLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public onAttach(): void {
        this.context.broker.subscribe(this, ["CommandRequested", "TrafficMessageReceived", "TrafficMessageRemoved", "CitsVehicleUpdated", "CitsVehicleTerminated"]);
        // CitsVehicle events caused multiple relaods
        //this.context.broker.subscribe(this, ["CommandRequested", "TrafficMessageReceived", "TrafficMessageRemoved"]);
    }

    public onDetach(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent) {
        // Different command?
        if (event.type == "CommandRequested" && event.payload.extras.type != "TriggerTrafficMessage" && event.payload.extras.type != "UpdateTrafficMessage" && event.payload.extras.type != "CancelTrafficMessage") {
            return;
        }

        // Reload
        await this.load();
    }

    public selectMessages(messages?: any[], tags?: string[]): void {
        // Crate item list
        let list = new TrafficMessageList(this.context, {
            style: "Light",
            title: "components.TrafficMessageList.title",
            subtitle: "components.TrafficMessageList.subtitle",
            action: "components.TrafficMessageList.newMessage",
            tags: tags || [],
            layers: []
        });

        // Messages already loaded, will use them
        list.messages = messages || this.messages;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            layers: [
                new ItemCircleLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectVehicles(vehicles?: any[], tags?: string[]): void {
        // Crate item list
        let list = new CitsVehicleList(this.context, {
            style: "Light",
            title: "components.CitsVehicleList.title",
            subtitle: "components.CitsVehicleList.subtitle",
            tags: tags || [],
            layers: []
        });

        // Messages already loaded, will use them
        list.vehicles = vehicles || this.vehicles.data;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string) {
        // Messags metrics?
        if (this.metrics.name == "Messages") {
            this.selectMessages(this.messages.filter(x => x.type == symbol), [`enums.TrafficMessageType.${symbol}`]);
        }

        // Vehicles metrics?
        if (this.metrics.name == "Vehicles") {
            this.selectVehicles(this.vehicles.data.filter(x => x.type == symbol), [`enums.CitsVehicleType.${symbol}`]);
        }
    }

    public async extraLoad(): Promise<void> {
        // Clear data
        this.messages = null;
        this.vehicles = null;

        // Messages metrics?
        if (this.metrics.name == "Messages") {
            // Load traffic messages data
            this.messages = await this.context.invipo.getQuery("traffic-messages-current");

            // Create messages legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let i in METRICS.traffic.messages.messages.range) {
                let symbol = METRICS.traffic.messages.messages.range[i]
                let count = this.messages.filter(x => x.type == symbol).length;

                this.legend.symbols.push({
                    symbol: symbol,
                    count: count,
                    label: `enums.TrafficMessageType.${symbol}`,
                    percent: count / (this.messages.length || 1) * 100,
                    color: METRICS.traffic.messages.messages.colors[i],
                    selectable: true
                });
            }
        }

        // Vehicles metrics?
        if (this.metrics.name == "Vehicles") {
            // load CITS vehicle data
            this.vehicles = await this.context.invipo.getDataset("cits-vehicles");

            // Create events legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let i in METRICS.traffic.messages.vehicles.range) {
                let symbol = METRICS.traffic.messages.vehicles.range[i]
                let count = this.vehicles.data.filter(x => x.type == symbol).length;

                this.legend.symbols.push({
                    symbol: symbol,
                    count: count,
                    label: `enums.CitsVehicleType.${symbol}`,
                    percent: count / (this.vehicles.data.length || 1) * 100,
                    color: METRICS.traffic.messages.vehicles.colors[i],
                    selectable: true
                });
            }
        }
    }

}
