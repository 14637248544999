import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";

export class WasteCollectionFrequencyLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "WasteCollectionCard",
            detail: "CityDetail",
            layer: {
                id: "waste-collection-frequency-label",
                type: "symbol",
                minzoom: ZOOM_LABEL,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1,
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-line-height": 1,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 10
                }
            },
            transitions: {
                "text-opacity": 1
            }
        });
    }

    public async load(): Promise<any> {
        // Load data
        let from = new Date(new Date(Date.now() - 7 * 86400000).setHours(0, 0, 0, 0));
        let data = await this.context.invipo.getQuery("waste-collection-by-item", `item.class=GarbageBin&from=${from}`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let d of data) {
            json.features.push({
                type: "Feature",
                properties: {
                    subdomain: "WasteCollection",
                    data: "count",
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: d.item.id,
                    itemId: d.item.id,
                    itemName: d.item.name,
                    itemClass: d.item.class,
                    label: `${d.item.name}\n${d.count}×`
                },
                geometry: d.item.geometry["location"]
            })
        }

        return json;
    }
}
