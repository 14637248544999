import "./checkbox.scss";
import * as template from "./checkbox.hbs";
import { Context } from "hiyo/context";
import { CheckboxOptions } from "./types";
import { Input } from "../input/input";

export class Checkbox extends Input<Context, CheckboxOptions> {

    // Event handling methods
    public onCheck(checked: boolean): void {};

    constructor(context: Context, options: CheckboxOptions) {
        super(context, template, options);

        // Default is false if not defined
        this.options.value = this.options.value || false;
    }

    public check(checked?: boolean): void {
        // Set or toggle checked status
        this.options.value = checked || !this.options.value;

        // Toggle class
        this.element.classList.toggle("muklit-checkbox-checked", this.options.value);

        // Handlers
        this.onCheck(this.options.value);
    }

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        this.update();
    }

}
