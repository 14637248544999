import "./fleet-asset-trips.scss";
import * as template from "./fleet-asset-trips.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetAssetTripsOptions } from "./types";
import { Form } from "../../../../muklit/components/form/form";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { Button } from "../../../../muklit/components/button/button";
import { MuklitComponent } from "../../../../muklit/components/muklit-component/muklit-component";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Dom } from "../../../../hiyo/dom";
import { Trip } from "invipo/clients/gpscockpit-client/types";
import { AssetSelect } from "../../common/asset-select/asset-select";

export class FleetAssetTrips extends MuklitComponent<InvipoContext, FleetAssetTripsOptions> {

    // Properties
    public trips: Trip[];
    public test: string[];

    // Components
    public form: Form;

    public constructor(context: InvipoContext, options?: FleetAssetTripsOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            //value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                            value: new Date(Date.now() - 600000).toISOString(),
                            width: 320,
                            bright: true,
                            type: "DateTime"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            value: new Date().toISOString(),
                            width: 320,
                            bright: true,
                            type: "DateTime",
                            // disabled: true
                        }),
                        new AssetSelect(this.context, {
                            style: "Light",
                            name: "assetId",
                            label: "forms.fields.asset",
                            value: this.options.assetId?.toString(),
                            placeholderText: "forms.placeholders.selectOne",
                            items: [],
                            width: 672,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Reload data on form submit
        this.form.onSubmit = async () => {
            // Reload
            await this.load();
        }
    }

    public async save(selector: string): Promise<void> {
        // Convert element to canvas
        let canvas = await InvipoHelpers.toCanvas(this.query(selector));

        // Download
        Dom.openLink(canvas.toDataURL("image/png"), `export-${Date.now()}.png`)
    }

    public export(selector: string): void {
        // Create link to download CVS on background
        let blob = new Blob([InvipoHelpers.toCsv(this.query(selector))], { type: "text/csv;charset=utf-8;" });
        let url = URL.createObjectURL(blob);

        // Download
        Dom.openLink(url, `export-${Date.now()}.csv`)
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Get simplified form data
        let form = this.form.getData(true);

        const from = Math.round(new Date(form.from).getTime() / 1000);
        const to = Math.round(new Date(form.to).getTime() / 1000);

        this.trips = await this.context.gpsCockpit.getTrips(from, to, this.options.assetId);

        console.log("FleetAssetTrips.load", this.trips);

        // Hide loader
        this.hideLoader();

        // Update
        this.invalidate(true);
    }
}
