import './monitoring-search.scss';
import * as template from "./monitoring-search.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { MonitoringSearchClass, MonitoringSearchOptions } from "./types";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";
import { Dom } from "../../../../hiyo/dom";
import { Helpers } from "../../../../hiyo/helpers";

const CLASS_OPEN = "invipo-monitoring-search-open";
const CLASS_EMPTY = "invipo-monitoring-search-empty";

export class MonitoringSearch extends MuklitComponent<InvipoContext, MonitoringSearchOptions> {

    // Properties
    public items: InvipoItem[];
    public areas: InvipoArea[];
    public classes: MonitoringSearchClass[];
    public index: number;
    public timer: any;

    // Private handlers
    private readonly closeListener: () => void;

    // Event handling methods
    public onAreaSelect(area: InvipoArea): void {}
    public onItemSelect(item: InvipoItem): void {}
    public onClassSelect(name: string): void {}
    public onSearch(term: string): void {}

    constructor(context: InvipoContext, options?: MonitoringSearchOptions) {
        super(context, template, options);

        // Handler single instance
        this.closeListener = () => {
            this.close();
        }
    }

    public open(): void {
        // Already opened?
        if (this.element.classList.contains(CLASS_OPEN)) {
            return;
        }

        // Reset index
        this.index = 0;

        // Update class
        this.element.classList.add(CLASS_OPEN);

        // We add document listener after some short delay because of event bubbling
        setTimeout(() => {
            document.addEventListener("click", this.closeListener);
        }, 150)
    }

    public keyUp(key: number) {
        // Read value
        let value = this.element.querySelector<HTMLInputElement>("input").value;

        // Arrow keys are handled on down event
        if (key == 38 || key == 40) {
            return;
        }

        // Enter
        if (key == 13) {
            // Something selected?
            if (this.index) {
                this.query(`div.content div.item[tabindex="${this.index}"]`).click();
            }
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Close
            this.clear();
            return;
        }

        // Empty class?
        this.element.classList.toggle(CLASS_EMPTY, !value?.length);

        // Search for match
        this.search(value);
    }

    public keyDown(key: number) {
        // Arrow up
        if (key == 38) {
            // Stop propagation to prevent caret moving
            event.preventDefault();

            // Select next item
            this.move(-1);
            return;
        }

        // Arrow down
        if (key == 40) {
            // Stop propagation to prevent caret moving
            event.preventDefault();

            // Select next item
            this.move(1);
            return;
        }
    }

    public close(): void {
        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Deselect selected item
        this.query(`div.content div[tabindex="${this.index}"]`)?.classList.remove("item-selected");

        // Blur input
        this.element.querySelector<HTMLInputElement>("input").blur();

        // Update class
        this.element.classList.remove(CLASS_OPEN);

        // Remove listener
        document.removeEventListener("click", this.closeListener);

        // OnClose handler
        this.onClose();
    }

    public clear(close?: boolean): void {
        // Clear input
        this.query<HTMLInputElement>("div.input input").value = "";

        // Add empty class
        this.element.classList.add(CLASS_EMPTY);

        // Reset searched results
        this.search(null);

        // And deactivate
        if (close) {
            this.close();
        }
    }

    public move(step: number): void {
        // Not possible to move?
        if (!this.query(`div.content div[tabindex="${this.index + step}"]`)) {
            return;
        }

        // Get next element
        let previous = this.query(`div.content div[tabindex="${this.index}"]`);

        // Next index
        this.index += step;

        let next = this.query(`div.content div[tabindex="${this.index}"]`);

        // Update classes
        previous?.classList.remove("item-selected");
        next.classList.add("item-selected");
    }

    public search(term: string): void {
        // Minimum term length of 3 characters is required to start search
        // But search with null is possible to clear the results!

        // Reset cursor index
        this.index = 0;

        // HTML result
        let html = "";

        // Search for non-nullable term
        // This is the main search routine, extend for new search rules
        let areas = term && term.length >= 3 && this.areas.filter(x => Helpers.contains(x.name, term));
        let items = term && term.length >= 3 && this.items.filter(x => Helpers.contains(x.name, term) || Helpers.contains(x.class, term) || Helpers.contains(x.model, term));
        let classes = term && term.length >= 3 && this.classes.filter(x => Helpers.contains(x.name, term) || Helpers.contains(x.label, term));

        // tabindex
        let index = 1;

        // Build areas
        if (areas?.length) {
            html += `<div class="group">`;
            html += `    <div class="title">${this.context.locale.getMessage("components.MonitoringSearch.areas")}</div>`;

            for (let i = 0; i < areas.length && i < 5; i++) {
                html += `<div class="item" tabindex="${index++}" onclick="component.selectArea('${areas[i].id}')">`;
                html += `    <div class="icon icon-area"></div>`;
                html += `    <div class="name">`;
                html += `        <div class="label">${areas[i].name}</div>`;
                html += `        <div class="description">${this.context.locale.getMessage(`enums.AreaType.${areas[i].type}`)}</div>`;
                html += `    </div>`;
                html += `</div>`;
            }

            html += `</div>`;
        }

        // Build classes
        if (classes?.length) {
            html += `<div class="group">`;
            html += `    <div class="title">${this.context.locale.getMessage("components.MonitoringSearch.classes")}</div>`;

            for (let i = 0; i < classes.length && i < 5; i++) {
                html += `<div class="item" tabindex="${index++}" onclick="component.selectClass('${classes[i].name}')">`;
                html += `    <div class="icon icon-class"></div>`;
                html += `    <div class="name">`;
                html += `        <div class="label">${classes[i].label}</div>`;
                html += `        <div class="description">${this.context.locale.getMessage("components.MonitoringSearch.classes")}</div>`;
                html += `    </div>`;
                html += `</div>`;
            }

            html += `</div>`;
        }

        // Build items
        if (items?.length) {
            html += `<div class="group">`;
            html += `<div class="title">${this.context.locale.getMessage("components.MonitoringSearch.items")}</div>`;

            for (let i = 0; i < items.length && i < 5; i++) {
                html += `<div class="item" tabindex="${index++}" onclick="component.selectItem('${items[i].id}')">`;
                html += `    <div class="status status-${Helpers.toKebabCase(items[i].monitoringStatus.status)}"></div>`;
                html += `    <div class="name">`;
                html += `        <div class="label">${items[i].name}</div>`;
                html += `        <div class="description">${this.context.locale.getMessage(`classes.${items[i].class}`)}</div>`;
                html += `    </div>`;
                html += `</div>`;
            }

            html += `</div>`;
        }

        // Assign new results
        this.query("div.content").innerHTML = html;

        // Propagate component to context as we injected new HTML
        Dom.propagateToContext(this.element, this);

        // OnSearch handler
        this.onSearch(term);
    }

    public selectArea(id: string): void {
        let area = this.areas.find(x => x.id == id);

        // Not found?
        if (!area) {
            return;
        }

        // Close
        this.close();

        // OnItemSelect handler
        this.onAreaSelect(area);
    }

    public selectItem(id: string): void {
        let item = this.items.find(x => x.id == id);

        // Not found?
        if (!item) {
            return;
        }

        // Close
        this.close();

        // OnItemSelect handler
        this.onItemSelect(item);
    }

    public selectClass(name: string): void {
        // Close
        this.close();

        // OnClassSelect handler
        this.onClassSelect(name);
    }

    public async load(): Promise<void> {
        // Clear to prevent multiple timers
        clearTimeout(this.timer);

        // Show loader
        this.showLoader();

        // Load all data for filtering
        this.areas = await this.context.invipo.getAreas();
        this.items = await this.context.invipo.getItems(`class=${this.context.options.classes.join(",")}`);

        // Build class list and translations
        this.classes = []

        for (let c of this.context.options.classes) {
            this.classes.push({
                name: c,
                label: this.context.locale.getMessage(`components.MonitoringHud.technology.${c}`)
            });
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Autorefresh
        this.timer = setTimeout(async () => {
            if (this.isAttached()) {
                await this.load();
            }
        }, 60 * 1000);
    }
}
