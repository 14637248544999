import './form.scss';
import * as template from "./form.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { FormOptions, ValidationError } from "./types";
import { InputValue } from "../input/types";
import { Input } from "../input/input";
import { Helpers } from "../../../hiyo/helpers";

export class Form extends MuklitComponent<Context, FormOptions> {

    // Event handling methods
    public onSubmit(data: any): void {};

    public constructor(context: Context, options: FormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {
                // Handle each field to do a form submit
                field.onSubmit = () => {
                    this.submit();
                }

                // Register component to be automatically attached
                this.registerComponent(field, field.options.name);
            }
        }
    }

    public getField<T extends Input>(name: string): T {
        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {
                // Field found?
                if (field.options.name == name) {
                    return <T>field;
                }
            }
        }

        return null;
    }

    public setValue(name: string, value: string | InputValue): void {
        let field = this.getField(name);

        // Field found?
        if (field) {
            field.setValue(value);
        }
    }

    public getData(flat?: boolean): any {
        let data: any = {};

        // Collect values from all form fields
        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {
                // Skipp hidden fields
                if (field.options.hidden) {
                    continue;
                }

                // Null value?
                if (field.options.value == null) {
                    // Undefined removes property from database
                    data[field.options.name] = null;
                }
                // Empty string?
                else if (typeof field.options.value == "string" && field.options.value.length == 0) {
                    // Undefined removes property from database
                    data[field.options.name] = null;
                }
                // Empty object?
                else if (typeof field.options.value == "object" && Object.keys(field.options.value).length == 0) {
                    // Undefined removes property from database
                    data[field.options.name] = null;
                }
                else {
                    data[field.options.name] = (flat && typeof field.options.value == "object") ? Helpers.toCommaKeys(field.options.value) : field.options.value;
                }
            }
        }

        return data;
    }

    public setData(data: any): any {
        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {
                // No data to empty field
                if (data == null) {
                    field.setValue(null);
                }
                // Set data if exists
                else if (data[field.options.name]) {
                    field.setValue(data[field.options.name]);
                }
            }
        }
    }

    public setValidationErrors(errors: ValidationError[]): void {
        for (let error of errors) {
            this.getField(error.field)?.setInvalid(true, error.code ? `forms.validation.${error.code}` : error.message);
        }
    }

    public validate(): boolean {
        let result = true;

        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {

                // Validation result must be a string representing message
                let message = field.validate();

                // Invalid field?
                if (message) {
                    // Set invalid status and message
                    field.options.invalid = true;
                    field.options.messageText = message;

                    // Update field in both cases
                    field.update();

                    result = false;
                }
                // Invalid field but correct now
                else if (field.options.invalid) {
                    // Reset invalid status and message
                    field.options.invalid = false;
                    field.options.messageText = null;

                    // Update field in both cases
                    field.update();
                }
            }
        }

        // Notify by scroll
        this.notify();

        return result;
    }

    public notify(): void {
        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {
                // First invalid field?
                if (field.options.invalid) {
                    // Scroll to fieldset and quit
                    this.query(`div.fieldset-${Helpers.toKebabCase(fieldset.name)}`).scrollIntoView({ behavior: "smooth" });
                    return;
                }
            }
        }
    }

    public clear(): void {
        for (let fieldset of this.options.fieldsets) {
            for (let field of fieldset.fields) {
                field.setValue(null);
            }
        }
    }

    public submit(): void {
        // Form validation?
        if (this.options.validate && !this.validate()) {
            return;
        }

        // OnSubmit handler
        this.onSubmit(this.getData());
    }
}
