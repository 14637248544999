import { MonitoringMap } from "./components/monitoring/monitoring-map/monitoring-map";
import { ConnectorBrowser } from "./components/monitoring/connector-browser/connector-browser";
import { LogBrowser } from "./components/monitoring/log-browser/log-browser";
import { ItemBrowser } from "./components/monitoring/item-browser/item-browser";
import { TrafficQueueBrowser } from "./components/traffic/traffic-queue-browser/traffic-queue-browser";
import { BasicVehicleBrowser } from "./components/traffic/basic-vehicle-browser/basic-vehicle-browser";
import { VehicleVolumeStats } from "./components/traffic/vehicle-volume-stats/vehicle-volume-stats";
import { EnviAirBrowser } from "./components/envi/envi-air-browser/envi-air-browser";
import { HomeScreen } from "./components/home/home-screen/home-screen";
import { BicycleVolumeInsight } from "./components/traffic/bicycle-volume-insight/bicycle-volume-insight";
import { UserProfile } from "./components/user/user-profile/user-profile";
import { UserActivity } from "./components/user/user-activity/user-activity";
import { UserExports } from "./components/user/user-exports/user-exports";
import { ParkingOccupancyBrowser } from "./components/parking/parking-occupancy-browser/parking-occupancy-browser";
import { PublicVehicleBrowser } from "./components/transit/public-vehicle-browser/public-vehicle-browser";
import { BasicTrafficBrowser } from "./components/traffic/basic-traffic-browser/basic-traffic-browser";
import { DockerBrowser } from "./components/monitoring/docker-browser/docker-browser";
import { TrafficVolumeStats } from "./components/traffic/traffic-volume-stats/traffic-volume-stats";
import { RoadEnviBrowser } from "./components/envi/road-envi-browser/road-envi-browser";
import { UserManager } from "./components/settings/user-manager/user-manager";
import { EventBrowser } from "./components/monitoring/event-browser/event-browser";
import { ItemManager } from "./components/devices/item-manager/item-manager";
import { AreaManager } from "./components/settings/area-manager/area-manager";
import { AirTemperatureStats } from "./components/envi/air-temperature-stats/air-temperature-stats";
import { RoadTemperatureStats } from "./components/roads/road-temperature-stats/road-temperature-stats";
import { MonitoringDetail } from "./components/monitoring/monitoring-detail/monitoring-detail";
import { PublicTransportPreferenceBrowser } from "./components/transit/public-transport-preference-browser/public-transport-preference-browser";
import { TrafficLightMarker } from "./components/schemas/traffic-light-marker/traffic-light-marker";
import { TrafficDetectorMarker } from "./components/schemas/traffic-detector-marker/traffic-detector-marker";
import { CisWall } from "./components/wall/cis-wall/cis-wall";
import { TrafficVolumeInsight } from "./components/traffic/traffic-volume-insight/traffic-volume-insight";
import { EnergyConsumptionBrowser } from "./components/energy/energy-consumption-browser/energy-consumption-browser";
import { EnergyBrowser } from "./components/energy/energy-browser/energy-browser";
import { WasteCollectionBrowser } from "./components/waste/waste-collection-browser/waste-collection-browser";
import { EnergyMeteringStats } from "./components/energy/energy-metering-stats/energy-metering-stats";
import { WasteCollectionStats } from "./components/waste/waste-collection-stats/waste-collection-stats";
import { ItemTooltip } from "./components/devices/item-tooltip/item-tooltip";
import { ItemGeneral } from "./components/devices/item-general/item-general";
import { TrafficLightControllerOverview } from "./components/traffic/traffic-light-controller-overview/traffic-light-controller-overview";
import { ParkingLotCard } from "./components/parking/parking-lot-card/parking-lot-card";
import { TrafficControlCard } from "./components/traffic/traffic-control-card/traffic-control-card";
import { ParkingSignCard } from "./components/parking/parking-sign-card/parking-sign-card";
import { ParkingAreaCard } from "./components/parking/parking-area-card/parking-area-card";
import { ParkingSensorCard } from "./components/parking/parking-sensor-card/parking-sensor-card";
import { StreetLightControllerCard } from "./components/energy/street-light-controller-card/street-light-controller-card";
import { PublicTransportPreferenceCard } from "./components/transit/public-transport-preference-card/public-transport-preference-card";
import { PassportPanel } from "./components/inventory/passport-panel/passport-panel";
import { MonitoringPanel } from "./components/monitoring/monitoring-panel/monitoring-panel";
import { EnvironmentalSensorCard } from "./components/envi/environmental-sensor-card/environmental-sensor-card";
import { EnviPollutionBrowser } from "./components/envi/envi-pollution-browser/envi-pollution-browser";
import { CityDetail } from "./components/city/city-detail/city-detail";
import { EnvironmentalSensorOverview } from "./components/envi/environmental-sensor-overview/environmental-sensor-overview";
import { ParkingLotOverview } from "./components/parking/parking-lot-overview/parking-lot-overview";
import { WasteCollectionCard } from "./components/waste/waste-collection-card/waste-collection-card";
import { GarbageBinOverview } from "./components/waste/garbage-bin-overview/garbage-bin-overview";
import { GarbageBrowser } from "./components/waste/garbage-browser/garbage-browser";
import { TrafficLightControllerDiagram } from "./components/traffic/traffic-light-controller-diagram/traffic-light-controller-diagram";
import { BatteryStatusBrowser } from "./components/energy/battery-status-browser/battery-status-browser";
import { BatteryOverview } from "./components/energy/battery-overview/battery-overview";
import { BatteryCard } from "./components/energy/battery-card/batttery-card";
import { BatteryStatusStats } from "./components/safety/battery-status-stats/battery-status-stats";
import { NotificationManager } from "./components/settings/notification-manager/notification-manager";
import { TrafficCounterOverview } from "./components/traffic/traffic-counter-overview/traffic-counter-overview";
import { EnergyMeterCard } from "./components/energy/energy-meter-card/energy-meter-card";
import { EnergyMeterOverview } from "./components/energy/energy-meter-overview/energy-meter-overview";
import { CityMap } from "./components/city/city-map/city-map";
import { TrafficCountingSubdomain } from "./components/traffic/traffic-counting-subdomain/traffic-counting-subdomain";
import { WasteCollectionSubdomain } from "./components/waste/waste-collection-subdomain/waste-collection-subdomain";
import { TrafficControlSubdomain } from "./components/traffic/traffic-control-subdomain/traffic-control-subdomain";
import { TrafficEventsSubdomain } from "./components/traffic/traffic-events-subdomain/traffic-events-subdomain";
import { TrafficEventBrowser } from "./components/traffic/traffic-event-browser/traffic-event-browser";
import { TrafficEventCard } from "./components/traffic/traffic-event-card/traffic-event-card";
import { BatteryStatusSubdomain } from "./components/energy/battery-status-subdomain/battery-status-subdomain";
import { TrafficEventOverview } from "./components/traffic/traffic-event-overview/traffic-event-overview";
import { TrafficLightControllerPanel } from "./components/traffic/traffic-light-controller-panel/traffic-light-controller-panel";
import { TrafficSignalsSubdomain } from "./components/traffic/traffic-signals-subdomain/traffic-signals-subdomain";
import { WarningLightCard } from "./components/traffic/warning-light-card/warning-light-card";
import { PublicTransportSubdomain } from "./components/transit/public-transport-subdomain/public-transport-subdomain";
import { PublicVehicleCard } from "./components/transit/public-vehicle-card/public-vehicle-card";
import { ParkingSubdomain } from "./components/parking/parking-subdomain/parking-subdomain";
import { PublicTransportPreferenceSubdomain } from "./components/traffic/public-transport-preference-subdomain/public-transport-preference-subdomain";
import { PublicTransportPreferenceOverview } from "./components/traffic/public-transport-preference-overview/public-transport-preference-overview";
import { StreetLightSubdomain } from "./components/energy/street-light-subdomain/street-light-subdomain";
import { WarningLightOverview } from "./components/traffic/warning-light-overview/warning-light-overview";
import { StreetLightControllerOverview } from "./components/energy/street-light-controller-overview/street-light-controller-overview";
import { EnviAirSubdomain } from "./components/envi/envi-air-subdomain/envi-air-subdomain";
import { CitsManager } from "./components/management/cits-manager/cits-manager";
import { TrafficEventManager } from "./components/management/traffic-event-manager/traffic-event-manager";
import { TrafficMessagesSubdomain } from "./components/traffic/traffic-messages-subdomain/traffic-messages-subdomain";
import { TrafficMessageCard } from "./components/traffic/traffic-message-card/traffic-message-card";
import { TrafficMessageOverview } from "./components/traffic/traffic-message-overview/traffic-message-overview";
import { TrafficMessageBrowser } from "./components/traffic/traffic-message-browser/traffic-message-browser";
import { EnergyMeteringSubdomain } from "./components/energy/energy-metering-subdomain/energy-metering-subdomain";
import { V2XUnitCard } from "./components/traffic/v2x-unit-card/v2x-unit-card";
import { V2XUnitOverview } from "./components/traffic/v2x-unit-overview/v2x-unit-overview";
import { AccessSubdomain } from "./components/safety/access-subdomain/access-subdomain";
import { AccessCard } from "./components/safety/access-card/access-card";
import { TrafficCameraCard } from "./components/traffic/traffic-camera-card/traffic-camera-card";
import { PublicTransportStopCard } from "./components/transit/public-transport-stop-card/public-transport-stop-card";
import { WallCameras } from "./components/wall/wall-cameras/wall-cameras";
import { MessageSignsSubdomain } from "./components/traffic/message-signs-subdomain/message-signs-subdomain";
import { MessageSignCard } from "./components/traffic/message-sign-card/message-sign-card";
import { MessageSignOverview } from "./components/traffic/message-sign-overview/message-sign-overview";
import { MessageSignsInsight } from "./components/traffic/message-signs-insight/message-signs-insight";
import { CamerasSubdomain } from "./components/safety/cameras-subdomain/cameras-subdomain";
import { CameraCard } from "./components/safety/camera-card/camera-card";
import { CameraBrowser } from "./components/safety/camera-browser/camera-browser";
import { TrafficLoadsBrowser } from "./components/traffic/traffic-loads-browser/traffic-loads-browser";
import { GarbageSubdomain } from "./components/waste/garbage-subdomain/garbage-subdomain";
import { EnviPollutionSubdomain } from "./components/envi/envi-pollution-subdomain/envi-pollution-subdomain";
import { HotspotsSubdomain } from "./components/services/hotspots-subdomain/hotspots-subdomain";
import { NetworkBrowser } from "./components/services/network-browser/network-browser";
import { WifiAccessPointCard } from "./components/services/wifi-access-point-card/wifi-access-point-card";
import { WifiAccessPointOverview } from "./components/services/wifi-access-point-overview/wifi-access-point-overview";
import { NetworkConnectionsStats } from "./components/services/network-connections-stats/network-connections-stats";
import { GarbageCard } from "./components/waste/garbage-card/garbage-card";
import { GarbageOverview } from "./components/waste/garbage-overview/garbage-overview";
import { ItemCard } from "./components/city/item-card/item-card";
import { TrafficVolumeReport } from "./components/traffic/traffic-volume-report/traffic-volume-report";
import { CameraOverview } from "./components/safety/camera-overview/camera-overview";
import { TicketManager } from "./components/desk/ticket-manager/ticket-manager";
import { TrafficEventHeatmap } from "./components/traffic/traffic-event-heatmap/traffic-event-heatmap";
import { TrafficIncidentBrowser } from "./components/traffic/traffic-incident-browser/traffic-incident-browser";
import { TrafficVolumeCard } from "./components/traffic/traffic-volume-card/traffic-volume-card";
import { ItemEditor } from "./components/items/item-editor/item-editor";
import { TrafficIncidentsSubdomain } from "./components/traffic/traffic-incidents-subdomain/traffic-incidents-subdomain";
import { TrafficIncidentCard } from "./components/traffic/traffic-incident-card/traffic-incident-card";
import { TrafficPentlogramReport } from "./components/traffic/traffic-pentlogram-report/traffic-pentlogram-report";
import { TrafficSpeedReport } from "./components/traffic/traffic-speed-report/traffic-speed-report";
import { CitsVehicleCard } from "./components/traffic/cits-vehicle-card/cits-vehicle-card";
import { CitsVehicleBrowser } from "./components/traffic/cits-vehicle-browser/cits-vehicle-browser";
import { TrafficVolumeDiagram } from "./components/traffic/traffic-volume-diagram/traffic-volume-diagram";
import { MonitoringCard } from "./components/monitoring/monitoring-card/monitoring-card";
import { TechnologySubdomain } from "./components/infrastructure/technology-subdomain/technology-subdomain";
import { SmartBuildingsSubdomain } from "./components/energy/smart-buildings-subdomain/smart-buildings-subdomain";
import { TrafficDailyVolumeReport } from "./components/traffic/traffic-daily-volume-report/traffic-daily-volume-report";
import { RawDataBrowser } from "./components/monitoring/raw-data-browser/raw-data-browser";
import { RegisteredDataBrowser } from "./components/monitoring/registered-data-browser/registered-data-browser";
import { DetectionBrowser } from "./components/safety/detection-browser/detection-browser";
import { AccessBrowser } from "./components/safety/access-browser/access-browser";
import { UlmDashboard } from "./components/dashboard/ulm-dashboard/ulm-dashboard";
import { WorkflowViolationBrowser } from "./components/enforcement/workflow-violation-browser/workflow-violation-browser";
import { ViolationsSubdomain } from "./components/enforcement/violations-subdomain/violations-subdomain";
import { ViolationTypeCard } from "./components/enforcement/violation-type-card/violation-type-card";
import { KrnapDashboard } from "./components/dashboard/krnap-dashboard/krnap-dashboard";
import { BasicViolationBrowser } from "./components/enforcement/basic-violation-browser/basic-violation-browser";
import { FleetSubdomain } from "./components/transit/fleet-subdomain/fleet-subdomain";
import { FleetAssetCard } from "./components/transit/fleet-asset-card/fleet-asset-card";
import { FleetDetail } from "./components/transit/fleet-detail/fleet-detail";
import { FleetAssetOverview } from "./components/transit/fleet-asset-overview/fleet-asset-overview";
import { FleetAssetTrips } from "./components/transit/fleet-asset-trips/fleet-asset-trips";
import { FleetAssetBrowser } from "./components/transit/fleet-asset-browser/fleet-asset-browser";
import { FleetTripBrowser } from "./components/transit/fleet-trip-browser/fleet-trip-browser";
import { FleetDashboard } from "./components/dashboard/fleet-dashboard/fleet-dashboard";
import { FleetDriverBrowser } from "./components/transit/fleet-driver-browser/fleet-driver-browser";

export const COMPONENT_STORE: any = {
    HomeScreen,
    MonitoringMap,
    ConnectorBrowser,
    DockerBrowser,
    LogBrowser,
    BasicVehicleBrowser,
    BasicTrafficBrowser,
    TrafficQueueBrowser,
    ItemBrowser,
    VehicleVolumeStats,
    TrafficVolumeStats,
    EnviAirBrowser,
    EnviPollutionBrowser,
    RoadEnviBrowser,
    BicycleVolumeInsight,
    UserProfile,
    UserActivity,
    UserExports,
    ParkingOccupancyBrowser,
    PublicVehicleBrowser,
    UserManager,
    EventBrowser,
    ItemManager,
    AreaManager,
    AirTemperatureStats,
    RoadTemperatureStats,
    MonitoringDetail,
    PublicTransportPreferenceBrowser,
    TrafficLightMarker,
    TrafficDetectorMarker,
    CisWall,
    TrafficVolumeInsight,
    EnergyConsumptionBrowser,
    EnergyBrowser,
    WasteCollectionBrowser,
    EnergyMeteringStats,
    WasteCollectionStats,
    ItemTooltip,
    ItemGeneral,
    TrafficLightControllerOverview,
    ParkingLotCard,
    TrafficVolumeCard,
    TrafficControlCard,
    ParkingSignCard,
    ParkingAreaCard,
    ParkingSensorCard,
    StreetLightControllerCard,
    PublicTransportPreferenceCard,
    PassportPanel,
    MonitoringPanel,
    EnvironmentalSensorCard,
    CityDetail,
    EnvironmentalSensorOverview,
    ParkingLotOverview,
    WasteCollectionCard,
    GarbageBinOverview,
    GarbageBrowser,
    TrafficLightControllerDiagram,
    BatteryStatusBrowser,
    BatteryOverview,
    BatteryCard,
    RawDataBrowser,
    RegisteredDataBrowser,
    DetectionBrowser,
    BatteryStatusStats,
    NotificationManager,
    TrafficCounterOverview,
    EnergyMeterCard,
    EnergyMeterOverview,
    CityMap,
    TrafficCountingSubdomain,
    WasteCollectionSubdomain,
    TrafficControlSubdomain,
    TrafficEventsSubdomain,
    TrafficEventBrowser,
    TrafficEventCard,
    BatteryStatusSubdomain,
    TrafficEventOverview,
    TrafficLightControllerPanel,
    TrafficSignalsSubdomain,
    WarningLightCard,
    PublicTransportSubdomain,
    PublicVehicleCard,
    ParkingSubdomain,
    PublicTransportPreferenceSubdomain,
    PublicTransportPreferenceOverview,
    StreetLightSubdomain,
    WarningLightOverview,
    StreetLightControllerOverview,
    EnviAirSubdomain,
    CitsManager,
    TrafficEventManager,
    TrafficMessagesSubdomain,
    TrafficMessageCard,
    TrafficMessageOverview,
    TrafficMessageBrowser,
    EnergyMeteringSubdomain,
    V2XUnitCard,
    V2XUnitOverview,
    TechnologySubdomain,
    AccessSubdomain,
    AccessCard,
    TrafficCameraCard,
    PublicTransportStopCard,
    WallCameras,
    MessageSignsSubdomain,
    MessageSignCard,
    MessageSignOverview,
    MessageSignsInsight,
    CamerasSubdomain,
    CameraCard,
    CameraBrowser,
    TrafficLoadsBrowser,
    GarbageSubdomain,
    EnviPollutionSubdomain,
    HotspotsSubdomain,
    NetworkBrowser,
    WifiAccessPointCard,
    WifiAccessPointOverview,
    NetworkConnectionsStats,
    GarbageCard,
    GarbageOverview,
    ItemCard,
    TrafficVolumeReport,
    CameraOverview,
    TicketManager,
    TrafficEventHeatmap,
    TrafficIncidentBrowser,
    ItemEditor,
    TrafficIncidentsSubdomain,
    TrafficIncidentCard,
    TrafficPentlogramReport,
    TrafficSpeedReport,
    BasicViolationBrowser,
    WorkflowViolationBrowser,
    CitsVehicleCard,
    CitsVehicleBrowser,
    TrafficVolumeDiagram,
    ViolationsSubdomain,
    ViolationTypeCard,
    MonitoringCard,
    SmartBuildingsSubdomain,
    TrafficDailyVolumeReport,
    AccessBrowser,
    UlmDashboard,
    KrnapDashboard,
    FleetSubdomain,
    FleetAssetCard,
    FleetDetail,
    FleetAssetOverview,
    FleetAssetTrips,
    FleetAssetBrowser,
    FleetTripBrowser,
    FleetDriverBrowser,
    FleetDashboard
}
