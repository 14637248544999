import "./camera-detail.scss";
import * as template from "./camera-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CameraDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { Tabs } from "../../../../muklit/components/tabs/tabs";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class CameraDetail extends Detail<InvipoContext, CameraDetailOptions> {

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: CameraDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "General",
                    label: "details.tabs.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "Snapshots",
                    label: "details.tabs.images",
                    content: "div.content-snapshots"
                }
            ]
        });
    }

    public selectImage(i: number): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: `${this.context.options.host}/download${this.options.camera.snapshots[i].url}`,
            urls: (<any[]>this.options.camera.snapshots).map(x => `${this.context.options.host}/download${x.url}`),
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }
}
