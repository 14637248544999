import "./traffic-light-controller-diagram.scss";
import * as template from "./traffic-light-controller-diagram.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { TrafficLightControllerDiagramOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { HiyoEvent } from "hiyo/event-broker";
import { Dom } from "hiyo/dom";
import { Helpers } from "hiyo/helpers";
import { OrtoMarker } from "../../schemas/orto-marker/orto-marker";
import { COMPONENT_STORE } from "../../../store";
import { Log } from "../../../../hiyo/log";
import { HradecKraloveOrthophotoLayer } from "../../../layers/custom/hradec-kralove-orthophoto-layer";

const MAX_DIAGRAM_FRAMES = 120;
const EVENTS_BUFFER_TIME = 3;

export class TrafficLightControllerDiagram extends MuklitComponent<InvipoContext, TrafficLightControllerDiagramOptions> {

    // Properties
    public item: InvipoItem;
    public frame: number;
    public events: HiyoEvent[];
    public timeout: any;

    // Components
    public map: BasicMap;

    constructor(context: InvipoContext, options?: TrafficLightControllerDiagramOptions) {
        super(context, template, options);

        // Reset properties
        this.frame = 0;
        this.events = [];
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        // Listen to new traffci second
        this.context.broker.subscribe(this, ["TrafficControlDataReceived"]);
    }

    public onDetach(): void {
        // Unsubscribe all listeners
        this.context.broker.unsubscribe(this);
    }

    public onHandle(event: HiyoEvent) {
        // Traffic second frame
        if (event.type == "TrafficControlDataReceived" && event.payload.item.id == this.options.itemId) {
            // Push events to queue because of better load balancing and sort them by timestamp
            this.events.push(event);
            this.events.sort((a: HiyoEvent, b: HiyoEvent) => { return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime() });
        }
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            //style: "HradecKraloveOrthophoto",
            style: <any>this.context.options.home.styles.find(x => x == "HradecKraloveOrthophoto") || "Satellite",
            center: this.context.options.home.center,
            zoom: 18,
            minZoom: 18,
            maxZoom: 21
        });

        // Check custom layers on style loaded
        this.map.onMapLoad = () => {
            // HradecKraloveOrthophoto style?
            if (this.map.options.style == "HradecKraloveOrthophoto") {
                // Project custom orthophoto raster layer that must be added separately outside the layer management
                this.map.mapboxMap.addLayer(new HradecKraloveOrthophotoLayer(this.context).options.layer);
            }
        }
    }

    public frameEvents(): void {
        // Component is gone
        if (!this.isAttached()) {
            return;
        }

        // Item not yet loaded
        if (!this.item) {
            return;
        }

        // Take first event from buffer if there is at least one more
        if (this.events.length > 0) {
            // How much we will take from buffer?
            let count = 1;
            if (this.events.length > EVENTS_BUFFER_TIME) {
                count += this.events.length - EVENTS_BUFFER_TIME;
            }

            for (let i = 0; i < count; i++) {
                // Take first
                let event = this.events.shift();

                // Add frame to bar diagram
                this.addFrame(event.timestamp, event.payload.extras);

                // Broadcast event to detail map markers
                for (let marker of Object.values(this.map.markers)) {
                    marker.onHandle(event);
                }
            }
        }

        // Set timeout
        setTimeout(() => this.frameEvents(), 1000);
    }

    public addFrame(timestamp: string, data: any): void {
        // Component gone?
        if (!this.isAttached()) {
            return;
        }

        // Element to add frames
        let frames = this.query("div.diagram div.content div.frames");

        // Diagram not ready?
        if (!frames) {
            return;
        }

        // New column HTML
        let html = "";

        // Process traffic second data
        html += `<div class="frame">`;

        // Timestamp
        //html += `<div class="second">${new Date(timestamp).getSeconds() == 0 ? Helpers.toTimeString(new Date(timestamp)) : ""}</div>`;

        // tx
        html +=     `<div class="second second-${data.tx}">${this.frame == 0 || data.tx == 0 || data.tx % 5 == 0 ? data.tx : ""}</div>`;

        // Signal groups
        for (let group of data.groups) {
            html += `<div class="bar bar-state-${Helpers.toKebabCase(group.state.toString())}" data-tooltip="${group.name} (#${group.no})<br />${group.state}<br />${Helpers.toDateTimeString(timestamp)} (tx ${data.tx})" data-tooltip-delay="100"></div>`;
        }

        // Vehicle detectors
        for (let detector of data.detectors) {
            let occupied = (<any[]>detector.occupancies).some(x => x.occupied == true);
            let call = (<any[]>detector.occupancies).some(x => x.call == true);
            html += `<div class="bar bar-detector ${occupied ? "bar-detector-occupied" : ""} ${call ? "bar-detector-call" : ""}" data-tooltip="${detector.name} (#${detector.no})<br />${Helpers.toDateTimeString(timestamp)} (tx ${data.tx})" data-tooltip-delay="100"></div>`;
        }

        // Preference requests
        let tooltip = "";
        for (let preference of data.preferences) {
            tooltip += `${preference.point}<br />`;
        }
        tooltip += `${Helpers.toDateTimeString(timestamp)} (tx ${data.tx})`;

        html +=     `<div class="bar bar-preference-${data.preferences?.length ? "requested" : ""}" ${tooltip ? `data-tooltip="${tooltip}" data-tooltip-delay="100"` : ""}></div>`;

        // End of row
        html += "</div>";

        let autoscroll = (frames.scrollLeft >= frames.scrollWidth - frames.clientWidth);

        // Adds html to bars
        Dom.createElement(frames, html);

        // Check max frames count
        if (frames.children.length >= MAX_DIAGRAM_FRAMES) {
            // Clipping
            frames.removeChild(frames.firstChild);
        }

        // If autoscroll we scroll to very right
        if (autoscroll) {
            frames.scrollLeft = frames.scrollWidth - frames.clientWidth;
        }

        // Set started flag
        this.element.classList.toggle("invipo-traffic-light-controller-diagram-started", true);

        // Update detail HUD
        this.query("div.diagram div.property-tx div.value").innerText = data.tx;
        this.query("div.diagram div.property-state div.value").innerText = data.state;
        this.query("div.diagram div.property-plan div.value").innerText = data.plan;

        // Frame counter
        this.frame += 1;
    }

    public addGroups(): void {
        // Component gone?
        if (!this.isAttached()) {
            return;
        }

        // Element to add frames
        let groups = this.query("div.diagram div.content div.groups");
        let frames = this.query("div.diagram div.content div.frames");

        // Diagram not ready?
        if (!groups || !frames) {
            return;
        }

        // New column HTML
        let html = "";

        // Groups
        for (let group of this.item.meta.groups) {
            html += `<div class="name group-${group.no}">${group.name}</div>`;
        }

        // Detectors
        for (let detector of this.item.meta.detectors) {
            html += `<div class="name group-${detector.no}">${detector.name}</div>`;
        }

        // Assign html
        groups.innerHTML = html;

        // Synchronize scrolling with frames
        frames.onscroll = () => {
            groups.scrollTo(0, frames.scrollTop);
            //console.info(groups.scrollTop);
        }

        // Synchronize scrolling with groups
        groups.onscroll = () => {
            //console.info("ttttt");
        }
    }

    public enableSchema(): void {
        // Orto map definition json in item
        let orto = this.item?.schema?.orto;

        // Center and zoom map
        this.map.setCenter(orto.map.center);
        this.map.setZoom(orto.map.zoom);
        this.map.setMinZoom(orto.map.minZoom);
        this.map.setMaxZoom(orto.map.maxZoom);

        // Add markers
        for (let m of orto.markers) {
            // Create topo marker from schema
            let marker: OrtoMarker = new COMPONENT_STORE[m.name](this.context, {
                ...m.options,
                draggable: true,
                itemId: this.item.id
            });

            // Add to map
            this.map.addMarker(marker);
        }
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load item data
        this.item = await this.context.invipo.getItem(this.options.itemId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Assign group names
        this.addGroups();

        // Orto schema available?
        if (this.item.schema?.orto) {
            this.enableSchema();
        }
        // No orto schema, will hide map
        else {
            this.query("div.map").style.display = "none";
            Log.w(`Missing item.schema.orto definition for ${this.item.name}`)
        }

        // Delay to read buffer
        if (!this.timeout) {
            this.timeout = setTimeout(() => {
                // Hide loader
                this.hideLoader();

                // Start to proceed controller state events
                this.frameEvents()
            }, EVENTS_BUFFER_TIME * 1000);
        }

    }
}
