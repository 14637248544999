import { InvipoContext } from "invipo/context/invipo-context";
import { ContentDetailOptions } from "./types";
import { Component } from "../../../../hiyo/component";
import { Detail } from "../../../../muklit/components/detail/detail";
import { Log } from "../../../../hiyo/log";
import { COMPONENT_STORE } from "../../../store";

export abstract class ContentDetail<T extends ContentDetailOptions = ContentDetailOptions> extends Detail<InvipoContext, T> {

    // Properties
    public content: Component;
    public cache: { [id: string]: Component }; // Cache of already created components

    protected constructor(context: InvipoContext, template: any, options?: T) {
        super(context, template, options);
    }

    public onAttach(): void {
        // Attach content if not visible
        if (this.content && !this.content.isAttached()) {
            // Assign self as parent
            this.content.parent = this;

            // Attach to content placeholder
            this.content.attach(this.query("div.content"));

            // Close self with component close
            this.content.onClose = () => {
                this.close();
            }
        }
    }

    public onDetach(): void {
        // Detach content if visible
        if (this.content && this.content.isAttached()) {
            this.content.detach();
        }
    }

    public setContent(component: string, noCache?: boolean, skipLoad?: boolean, options?: any): void {
        Log.i(`Setting content of ${this.name} component to ${component}`);

        // Detach already existing content
        if (this.content?.isAttached()) {
            this.content.detach();
        }

        // Ensure cache was initialized
        this.cache = this.cache || {};

        // If restored from cache, we need to skip loading
        let cached = false;

        // Already created and cached?
        if (!noCache && this.cache[component]) {
            this.content = this.cache[component];
            cached = true;
        }
        // Not cached, but registered in store?
        else if (COMPONENT_STORE[component]) {
            this.content = new COMPONENT_STORE[component](this.context, {
                ...this.options,
                ...options
            });
            this.cache[component] = this.content;
        }
        // Not cached and not found in store
        else {
            Log.w(`Trying to set content of ${this.id} component to non-stored component ${component}`)
            this.content = null;
            return;
        }

        // Attach content if not empty and view is active
        if (this.content && this.isAttached()) {
            // Assign self as parent
            this.content.parent = this;

            // Attach to content placeholder
            this.content.attach(this.query("div.content"), false, cached && skipLoad);

            // Close self with component close
            this.content.onClose = () => {
                this.close();
            }
        }
    }

}
