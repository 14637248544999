import "./waste-collection-subdomain.scss";
import * as template from "./waste-collection-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { WasteCollectionDayCircleLayer } from "../../../layers/waste/waste-collection-day-circle-layer";
import { WasteCollectionAreaBoundaryLayer } from "../../../layers/waste/waste-collection-area-boundary-layer";
import { WasteCollectionFrequencyHeatmapLayer } from "../../../layers/waste/waste-collection-frequency-heatmap-layer";
import { WasteCollectionWeightHeatmapLayer } from "../../../layers/waste/waste-collection-weight-heatmap-layer";
import { WasteCollectionWeightLabelLayer } from "../../../layers/waste/waste-collection-weight-label-layer";
import { WasteCollectionFrequencyLabelLayer } from "../../../layers/waste/waste-collection-frequency-label-layer";
import { WasteCollectionDayLabelLayer } from "../../../layers/waste/waste-collection-day-label-layer";
import { WasteCollectionSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { MapLayer } from "../../../../muklit/components/basic-map/map-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";

export class WasteCollectionSubdomain extends CitySubdomain<WasteCollectionSubdomainOptions> {

    constructor(context: InvipoContext, options?: WasteCollectionSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Count",
                    itemClass: "GarbageBin",
                    layers: [
                        new WasteCollectionDayCircleLayer(context),
                        new WasteCollectionDayLabelLayer(context),
                        new WasteCollectionAreaBoundaryLayer(context)
                    ]
                },
                {
                    name: "Frequency",
                    itemClass: "GarbageBin",
                    layers: [
                        new WasteCollectionFrequencyHeatmapLayer(context),
                        new WasteCollectionFrequencyLabelLayer(context)
                    ]
                },
                {
                    name: "Weight",
                    itemClass: "GarbageBin",
                    layers: [
                        new WasteCollectionWeightHeatmapLayer(context),
                        new WasteCollectionAreaBoundaryLayer(context),
                        new WasteCollectionWeightLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.bins",
            subtitle: "components.WasteCollectionSubdomain.bins",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Metrics name
        let name = Helpers.toKebabCase(this.metrics.name);

        this.legend = {
            description: `components.WasteCollectionSubdomain.${name}Description`,
            range: {
                range: METRICS.waste.collection[name].range,
                colors: METRICS.waste.collection[name].colors,
                count: 0,
                value: 0
            },
            symbols: []
        }
    }
}
