import { ContentView } from "../content-view";

export class Dashboard extends ContentView {

    public onCreate(): void {
        // Set component from invipo.json (dashboard)
        this.setContent(this.context.options.dashboard);
    }

}
