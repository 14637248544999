import "./traffic-event-heatmap.scss";
import * as template from "./traffic-event-heatmap.hbs";

import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { ClientExportForm } from "../../common/client-export-form/client-export-form";
import { TrafficEventHeatmapOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Select } from "../../../../muklit/components/select/select";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { TrafficEventHeatmapLayer } from "./traffic-event-heatmap-layer";
import { TrafficEventHeatmapCircleLayer } from "./traffic-event-heatmap-circle-layer";
import { SYMBOL_MAP, TrafficEventHeatmapSymbolLayer } from "./traffic-event-heatmap-symbol-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "invipo/components/city/city-subdomain/types";
import { InvipoHelpers } from "invipo/invipo-helpers";
import { Dom } from "hiyo/dom";

export class TrafficEventHeatmap extends MuklitComponent<InvipoContext, TrafficEventHeatmapOptions> {

    // Properties
    public data: any[];

    // Components
    public form: Form;
    public map: BasicMap;
    public heatmapLayer: TrafficEventHeatmapLayer;
    public circleLayer: TrafficEventHeatmapCircleLayer;
    public sympolLayer: TrafficEventHeatmapSymbolLayer;

    public constructor(context: InvipoContext, options?: TrafficEventHeatmapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Default options: series
        this.options = {
            ...this.options,
        }

        // Create components
        this.createForm();
        this.createMap();

        this.registerComponent(this.form, 'form')
        this.registerComponent(this.map, 'map')
    }

    public onDetach(): void {
        // Detach forms
        if (this.form?.isAttached()) {
            this.form.detach();
        }
    }

    public createForm(): void {
        const from = new Date();
        from.setDate(1);
        from.setHours(0,0,0,0);
        const to = new Date(from);
        to.setMonth(from.getMonth()+1)
        console.log(from, to);

        // New form component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "timestamp",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: from.toISOString(),
                            bright: true,
                            type: "Date"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            value: to.toISOString(),
                            bright: true,
                            type: "Date"
                        }),
                        new Select(this.context, {
                            style: "Light",
                            bright: true,
                            name: "type",
                            label: "forms.fields.type",
                            placeholderText: "forms.placeholders.all",
                            items: [
                                {
                                    name: "Accident",
                                    label: "enums.TrafficEventType.Accident"
                                },
                                {
                                    name: "Closure",
                                    label: "enums.TrafficEventType.Closure"
                                },
                                {
                                    name: "Roadworks",
                                    label: "enums.TrafficEventType.Roadworks"
                                },
                                {
                                    name: "Danger",
                                    label: "enums.TrafficEventType.Danger"
                                }
                            ],
                            multiselect: true
                        }),
                    ]
                }
            ]
        });

        // Reload insight on change
        this.form.onSubmit = async (data: any) => {
            // series.values.from = data["from"] ? Object.keys(data["from"])[0] : null;
            // series.values.to = data["to"] ? Object.keys(data["to"])[0] : null;
            // series.values.itemName = data["item.id"] ? Object.values(data["item.id"])[0] : null;
            // series.values.itemId = data["item.id"];

            // Reload completely
            await this.load();
        }
    }

    public createMap(): void {
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 5,
            maxZoom: 21
        });

        // Create map layers
        this.heatmapLayer = new TrafficEventHeatmapLayer(this.context);
        this.circleLayer = new TrafficEventHeatmapCircleLayer(this.context);
        this.sympolLayer = new TrafficEventHeatmapSymbolLayer(this.context);

        // Add map layers
        this.map.addLayer(this.heatmapLayer);
        this.map.addLayer(this.circleLayer);
        this.map.addLayer(this.sympolLayer);
    }

    public openExport(): void {
        // // Export form to choose export type
        let form = new ClientExportForm(this.context, {
            style: "Light",
            title: "components.ClientExportForm.title",
            exportPdf: true,
            overlay: true,
            closable: this.options.closable
        });

        // Handle export
        form.onPdfExport = (orientation: string, title?: string) => {
            this.exportPdf(orientation, title);
        }

        // Show form
        form.attach();
    }

    private async exportPdf(orientation: string, title?: string) {
        title = title ?? this.context.locale.getMessage(`components.${this.name}.title`);
        // Convert element to canvas
        let canvas = await InvipoHelpers.toCanvas(this.query(''));

        // Download
        Dom.openLink(canvas.toDataURL("image/png"), `export-${Date.now()}.png`)
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Get traffic event filter data
        const filter = this.form.getData();
        const from = filter.from ? Object.keys(filter.from)[0] : new Date().toISOString();
        const to = filter.to ? Object.keys(filter.to)[0] : new Date().toISOString();
        const type = filter.type ? Object.keys(filter.type).join(',') : '';

        // Update data in map layers and wait until ther're all loaded
        await Promise.allSettled([
            this.heatmapLayer.setEventsFilter(from, to, type),
            this.circleLayer.setEventsFilter(from, to, type),
            this.sympolLayer.setEventsFilter(from, to, type)
        ]);

        // Hide loader
        this.hideLoader();
    }
}
