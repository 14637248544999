import "./parking-subdomain.scss";
import * as template from "./parking-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ParkingSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ParkingSensorCircleLayer } from "../../../layers/parking/parking-sensor-circle-layer";
import { ParkingSensorLabelLayer } from "../../../layers/parking/parking-sensor-label-layer";
import { ParkingAreaPatternLayer } from "../../../layers/parking/parking-area-pattern-layer";
import { ParkingLotBoundaryLayer } from "../../../layers/parking/parking-lot-boundary-layer";
import { ParkingLotCircleLayer } from "../../../layers/parking/parking-lot-circle-layer";
import { ParkingSignCircleLayer } from "../../../layers/parking/parking-sign-circle-layer";
import { ParkingSignLabelLayer } from "../../../layers/parking/parking-sign-label-layer";
import { ParkingLotLabelLayer } from "../../../layers/parking/parking-lot-label-layer";
import { ParkingAreaLabelLayer } from "../../../layers/parking/parking-area-label-layer";
import { ParkingAreaSymbolLayer } from "../../../layers/parking/parking-area-symbol-layer";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemList } from "../../city/item-list/item-list";
import { ParkingLotFillLayer } from "../../../layers/parking/parking-lot-fill-layer";
import { AreaList } from "../../city/area-list/area-list";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class ParkingSubdomain extends CitySubdomain<ParkingSubdomainOptions> {

    // Properties
    public lots: InvipoItem[];
    public sensors: InvipoItem[];

    constructor(context: InvipoContext, options?: ParkingSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Occupancy",
                    itemClass: "ParkingLot,ParkingSensor",
                    areaType: "Parking",
                    layers: [
                        new ParkingSensorCircleLayer(context),
                        new ParkingSensorLabelLayer(context),
                        new ParkingAreaPatternLayer(context),
                        //new ParkingAreaBoundaryLayer(context),
                        new ParkingLotFillLayer(context),
                        new ParkingLotBoundaryLayer(context),
                        new ParkingLotCircleLayer(context),
                        new ParkingLotLabelLayer(context),
                        new ParkingAreaLabelLayer(context),
                        new ParkingAreaSymbolLayer(context)
                    ]
                },
                {
                    name: "Navigation",
                    itemClass: "ParkingSign",
                    layers: [
                        new ParkingSignCircleLayer(context),
                        new ParkingSignLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.AreaList.subtitle",
            layers: []
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectLots(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.lots",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items),
                new ItemLabelLayer(this.context, items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items.filter(x => x.class == "ParkingLot");

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSensors(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.sensors",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items.filter(x => x.class == "ParkingSensor");

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSigns(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.signs",
            subtitle: "components.ItemList.subtitle",
            layers: [
                //new ItemCircleLayer(this.context, "ParkingSign")
            ]
        });

        // Items already loaded, will use them
        list.items = this.items.filter(x => x.class == "ParkingSign");

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string): void {
        // Occupancy metrics?
        if (this.metrics.name == "Occupancy") {
            // Get occupancy interval
            let interval = METRICS.parking.parking.occupancy.interval[METRICS.parking.parking.occupancy.range.indexOf(symbol)];

            // No data?
            if (symbol == "Unknown") {
                this.selectLots(this.lots.filter(x => !x.data?.occupancy?.overall?.occupancy == null), [`enums.ParkingOccupancy.${symbol}`]);
            }
            // Data matches interval?
            else {
                this.selectLots(this.lots.filter(x => x.data?.occupancy?.overall?.occupancy != null && Math.max(x.data.occupancy.overall.occupancy, 0) >= interval[0] && Math.min(x.data.occupancy.overall.occupancy, 100) < interval[1]), [`enums.ParkingOccupancy.${symbol}`]);
            }
        }

        // Navigation metrics?
        if (this.metrics.name == "Navigation") {
            this.selectSigns();
        }
    }

    public async extraLoad(): Promise<void> {
        // Filter out by class
        this.lots = this.items.filter(x => x.class == "ParkingLot");
        this.sensors = this.items.filter(x => x.class == "ParkingSensor");

        // Create occupancy legend
        this.legend = {
            range: null,
            symbols: []
        };

        // Occupncy metrics?
        if (this.metrics.name == "Occupancy") {
            // Filtr to parking lots only

            for (let i in METRICS.parking.parking.occupancy.range) {
                let symbol = METRICS.parking.parking.occupancy.range[i];
                let interval = METRICS.parking.parking.occupancy.interval[i];
                let count = 0;

                if (symbol == "Unknown") {
                    count = this.lots.filter(x => x.data?.occupancy?.overall?.occupancy == null).length;
                }
                else {
                    count = this.lots.filter(x => x.data?.occupancy?.overall?.occupancy != null && Math.max(x.data.occupancy.overall.occupancy, 0) >= interval[0] && Math.min(x.data.occupancy.overall.occupancy, 100) < interval[1]).length;
                }

                count = this.lots.filter(
                    symbol == "Unknown" ?
                        x => x.data?.occupancy?.overall?.occupancy == null :
                        x => x.data?.occupancy?.overall?.occupancy != null && Math.max(x.data.occupancy.overall.occupancy, 0) >= interval[0] && Math.min(x.data.occupancy.overall.occupancy, 100) < interval[1]).length;

                // Add row to legend
                this.legend.symbols.push({
                    symbol: symbol,
                    count: count,
                    label: `enums.ParkingOccupancy.${symbol}`,
                    percent: count / (this.lots.length || 1) * 100,
                    color: METRICS.parking.parking.occupancy.colors[i],
                    selectable: true
                });
            }
        }

        // Navigation metrics?
        if (this.metrics.name == "Navigation") {
            this.legend.symbols.push({
                symbol: "ParkingSign",
                count: this.items.length,
                label: "classes.ParkingSign",
                percent: this.items.length / (this.items.length || 1) * 100,
                color: "#8b88ff"
            });
        }
    }

}
