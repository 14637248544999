import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";

export class ParkingLotFillLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: true,
            card: "ParkingLotCard",
            detail: "CityDetail",
            before: "road-label",
            layer: {
                id: "parking-lot-fill",
                type: "fill",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "fill-color": ["get", "color"],
                    "fill-opacity": 0,
                    "fill-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "fill-opacity": OCCUPANCY_CIRCLE_AREA
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems("class=ParkingLot");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Area not defined?
            if (!item.geometry.area) {
                continue;
            }

            // Gemoetry and occupancy color
            let occupancy = item.data?.occupancy?.overall?.occupancy;
            let symbol = "Unknown";
            let color = "#aaaaaa";

            // Has data?
            if (occupancy != undefined) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }

                // Get color from scale
                color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];
            }

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: `${item.name}\n${occupancy} %`,
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color,
                },
                geometry: item.geometry["area"],
            })
        }

        return json;
    }

}
