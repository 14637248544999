import "./traffic-message-overview.scss";
import * as template from "./traffic-message-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { LineString, Point } from "geojson";
import { TrafficMessageCircleLayer } from "../../../layers/traffic/traffic-message-circle-layer";
import { TrafficMessageSymbolLayer } from "../../../layers/traffic/traffic-message-symbol-layer";
import { TrafficMessageOverviewOptions } from "./types";

export class TrafficMessageOverview extends DetailPanel<TrafficMessageOverviewOptions> {

    // Properties
    public message: any;

    constructor(context: InvipoContext, options?: TrafficMessageOverviewOptions) {
        super(context, template, options);
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: 16,
            minZoom: 5,
            maxZoom: 20
        });

        // Center map after load
        this.map.onMapLoad = () => {
            // Center map to first point in line
            if (this.message.position.type == "LineString") {
                this.map.setCenter((<LineString>this.message.position).coordinates[0]);
            }

            // Center map to coordinates
            if (this.message.position.type == "Point") {
                this.map.setCenter((<Point>this.message.position).coordinates);
            }
        }

        // Register map layers
        this.map.addLayer(new TrafficMessageCircleLayer(this.context, this.message));
        this.map.addLayer(new TrafficMessageSymbolLayer(this.context, this.message));
    }

    public async extraLoad(): Promise<void> {
        // Load event data
        this.message = await this.context.invipo.getManagement(`/traffic/messages/${this.options.messageId}`)
    }

}
