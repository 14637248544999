import "./traffic-message-browser.scss";
import * as template from "./traffic-message-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { DateInput } from "muklit/components/date-input/date-input";
import { Select } from "../../../../muklit/components/select/select";
import { TrafficMessageBrowserOptions } from "./types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { MessageDetailOptions } from "../../management/message-detail/types";
import { MessageDetail } from "../../management/message-detail/message-detail";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";
import { FilterItem } from "../../../../muklit/components/filter/types";

export class TrafficMessageBrowser extends MuklitComponent<InvipoContext, TrafficMessageBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: MessageDetail;

    constructor(context: InvipoContext, options?: TrafficMessageBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach(this.query("div.detail"));
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-messages-history`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TrafficMessageBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from",
                                bright: true
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to",
                                bright: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficMessageType"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                }),
                                bright: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "subtype",
                                label: "forms.fields.subtype",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficMessageSubtype"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                }),
                                bright: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "locked",
                                label: "forms.fields.source",
                                placeholderText: "forms.placeholders.all",
                                items: [
                                    {
                                        name: "false",
                                        label: "enums.TrafficMessageSource.Internal"
                                    },
                                    {
                                        name: "true",
                                        label: "enums.TrafficMessageSource.External"
                                    }
                                ],
                                bright: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "duration.from",
                        type: "DateTime",
                        property: "duration.from",
                        label: "tables.columns.from",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "duration.to",
                        type: "DateTime",
                        property: "duration.to",
                        label: "tables.columns.to",
                        width: 160,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "subtype",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficMessageSubtype.${data.subtype}`);
                        },
                        label: "tables.columns.subtype",
                        width: 250,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficMessageType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        minWidth: 250,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Clear input
        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected openDetail(data: any): void {
        // Detail options
        let options: MessageDetailOptions = {
            style: "Light",
            message: data,
            title: `enums.TrafficMessageSubtype.${data.subtype}`,
            subtitle: `enums.TrafficMessageType.${data.type}`,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MessageDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.message.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach(this.query("div.detail"));
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "TrafficMessagesCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
