import "./fleet-asset-overview.scss";
import * as template from "./fleet-asset-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { FleetAssetOverviewOptions } from "./types";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { Asset, DeviceState, DeviceStateEnum } from "invipo/clients/gpscockpit-client/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { FleetStatusCircleLayer } from "invipo/layers/transit/fleet-status-circle-layer";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export class FleetAssetOverview extends DetailPanel<FleetAssetOverviewOptions> {
    // Properties
    public asset: Asset;

    constructor(context: InvipoContext, options?: FleetAssetOverviewOptions) {
        super(context, template, options);

        Handlebars.registerHelper("asDeviceState", (t: number) => {
            var state = DeviceStateEnum[t];

            return this.context.locale.getMessage(`enums.FleetAssetStatus.${state}`);
        });
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 0,
            maxZoom: 20
        });
    }

    public onLoad(): void {
        this.map.flyTo({
            center: [this.asset.state.currentPosition.longitude, this.asset.state.currentPosition.latitude],
            zoom: 15
        });
    }

    public async extraLoad(): Promise<void> {
        const asset = JSON.parse(this.options.asset);
        this.asset = await this.context.gpsCockpit.getAssetWithState(asset.id);

        if (this.asset.state?.odometer?.gpsOdometer) {
            this.asset.state.odometer.gpsOdometer /= 1000;
        }
        if (this.asset.state?.odometer?.canBusOdometer) {
            this.asset.state.odometer.canBusOdometer /= 1000;
        }

        // Register map layers
        this.map.addLayer(new FleetStatusCircleLayer(this.context, this.asset));
    }

}
