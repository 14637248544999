import { MenuItem } from "muklit/components/overflow-menu/types";
import { Feature, Geometry, LineString, Point, Polygon } from "geojson";
import { LngLatBounds } from "mapbox-gl";
import jsPDF from "jspdf";
import Chart = require("chart.js");
import { Log } from "../hiyo/log";
import { InvipoItem } from "./clients/invipo-client/types";
import { InputValue } from "../muklit/components/input/types";
import length from "@turf/length";
import area from "@turf/area";

// known typescript import problem: https://github.com/niklasvh/html2canvas/issues/1440
const html2canvas = require("html2canvas");

export class InvipoHelpers {

    public static toInputValue(values: string[] | any[], name?: string, value?: string): InputValue {
        let result: InputValue = {};

        for (let v of values) {
            if (typeof v == "string") {
                result[v] = v;
            }
            else {
                result[v[name]] = v[value];
            }
        }

        return result;
    }

    public static toMenuItems(object: any, prefix?: string, compare?: (a: MenuItem, b: MenuItem) => number): MenuItem[] {
        let items: MenuItem[] = [];

        if (object != null) {
            for (let o of Object.keys(object)) {
                items.push({
                    name: o,
                    label: (prefix != null) ? `${prefix}${object[o]}` : object[o]
                })
            }
        }

        if (compare) {
            items.sort(compare);
        }

        return items;
    }

    public static toClassItems(classes: any[], compare?: (a: MenuItem, b: MenuItem) => number): MenuItem[] {
        let items : MenuItem[] = [];

        if (classes != null) {
            for (let c of classes) {
                items.push({
                    name: c,
                    label: `classes.${c}`
                })
            }
        }

        // Sort
        if (compare) {
            items.sort(compare);
        }

        return items;
    }

    public static toCategoryItems(categories: any[]): MenuItem[] {
        let items : MenuItem[] = [];

        if (categories != null) {
            for (let c of categories) {
                items.push({
                    name: c.id,
                    label: c.name
                })
            }
        }

        return items;
    }

    public static toChartColor(index: number): string {
        switch (index) {
            case 1: return "rgba(0, 180, 245, 1)";
            case 2: return "rgb(162, 0, 255, 1)";
            case 3: return "rgba(255, 91, 45, 1)";
            case 4: return "rgba(162, 206, 0, 1)";
            case 5: return "rgba(0, 205, 181, 1)";
            case 6: return "rgba(214, 197, 0, 1)";
            case 7: return "rgba(0, 234, 255, 1)";
            case 8: return "rgba(218, 37, 255, 1)";
            case 9: return "rgba(255, 157, 2, 1)";
            case 10: return "rgba(209, 228, 0, 1)";
            case 11: return "rgba(0, 255, 228, 1)";
            case 12: return "rgba(240, 221, 0, 1)";
            case 13: return "rgba(0, 92, 240, 1)";
            case 14: return "rgba(112, 1, 209, 1)";
            case 15: return "rgba(255, 1, 88, 1)";
            case 16: return "rgba(113, 178, 0, 1)";
            case 17: return "rgba(0, 176, 152, 1)";
            case 18: return "rgba(176, 162, 0, 1)";
            default: return "#dddddd"
        }
    }

    public static toBounds(geometry: Geometry): LngLatBounds {
        let bounds = new LngLatBounds();

        // Polygon?
        if (geometry.type == "Polygon") {
            // Find area center
            for (let coordinates of (<Polygon>geometry).coordinates) {
                for (let position of coordinates) {
                    bounds.extend([position[0], position[1]]);
                }
            }
        }
        // LineString
        else if (geometry.type == "LineString") {
            // Find area center
            for (let coordinates of (<LineString>geometry).coordinates) {
                bounds.extend([coordinates[0], coordinates[1]]);
            }
        }
        else {
            Log.w(`Could not get bounds of geometry ${geometry.type}`);
        }

        return bounds;
    }

    public static isOutdated(data: any, age: number = 3600): boolean {
        let timestamp = data?.timestamp ?? data?.interval?.to;

        // No timestamp found
        if (timestamp == null) {
            return true;
        }

        // Fit to time window?
        return ((new Date().getTime() - new Date(timestamp).getTime()) / 1000) > age;
    }

    public static toPdf(chart: Chart, orientation: string, title?: string): void {
        if (chart == null) {
            Log.w(`Could not export null chart to PDF`);
            return;
        }

        // PDF generation framework
        const doc = new jsPDF({
            orientation: (orientation == "Portrait") ? "portrait" : "landscape",
            unit: "mm",
            format: [210, 297]
        });

        let parent = chart.canvas.parentElement;

        // Draw to newly resized canvas to fit the A4 format
        parent.style.width = `${(orientation == "Portrait") ? 604 : 855}px`; // 72 DPI
        parent.style.height = `${(orientation == "Portrait") ? 855 : 604}px`; // 72 DPI
        parent.style.display = "block";
        parent.style.flex = "none";
        chart.resize();

        // Generate PDF document
        doc.addImage(chart.canvas, "PNG", 15, 35, (orientation == "Portrait") ? 180 : 267, (orientation == "Portrait") ? 247 : 160, "chart", "NONE");
        doc.setFont("Helvetica");
        doc.setFontSize(12);
        doc.text(title,10, 15);
        doc.save(`chart-${new Date().getTime()}.pdf`);

        // Set canvas size back to original size
        parent.style.width = "auto";
        parent.style.height = "auto";
        parent.style.display = "flex";
        parent.style.flex = "1 1 auto";
        chart.resize();
    }

    public static async toCanvas(element: HTMLElement): Promise<HTMLCanvasElement> {
        return await html2canvas(element);
    }

    public static toCsv(table: HTMLTableElement): string {
        // Start with UTF-8 BOM sequence
        let csv = "\ufeff";

        // Columns first
        table.querySelectorAll("thead tr:first-child th").forEach((th: HTMLElement) => {
            csv += `${th.innerText};`;
        });

        // Remove last comma and add new line
        csv = csv.slice(0, -1)
        csv += "\n";

        // All rows
        table.querySelectorAll("tbody tr").forEach((tr: HTMLElement) => {
            // All cells
            tr.querySelectorAll("td").forEach((td: HTMLElement) => {
                csv += `${td.innerText};`;
            });

            // Remove last comma and add new line
            csv = csv.slice(0, -1)
            csv += "\n";
        });

        return csv;
    }

    public static toItemCluster(items: InvipoItem[]): ItemCluster {
        let cluster: ItemCluster = {};

        for (let item of items) {
            let location = <Point>item.geometry?.location;

            // No location?
            if (!location) {
                continue;
            }

            let position = `${location.coordinates[0]},${location.coordinates[1]}`;

            // Position not registered yet?
            if (!cluster[position]) {
                cluster[position] = [item];
                continue;
            }

            // Another item with same position
            cluster[position].push(item);
        }

        return cluster;
    }

    public static toLength(feature: Feature): number {
        return length(feature, { units: "meters" });
    }


    public static toArea(feature: Feature): number {
        return area(feature);
    }
}

export type ItemCluster = { [position: string] : InvipoItem[]};
