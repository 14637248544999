import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { InvipoContext } from "invipo/context/invipo-context";
import { TrafficeEventHeatmapFilter } from "./types";

export class TrafficEventHeatmapLayer extends MapLayer<InvipoContext> {
    private filter: TrafficeEventHeatmapFilter;

    constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            layer: {
                'id': 'traffic-event-heat',
                'type': 'heatmap',
                'source': {
                    'type': "geojson",
                    'data': null
                },
                'paint': {
                    "heatmap-intensity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        22, 3
                    ],
                    'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0.0, "rgba(204, 204, 204, 0)",
                        0.2, "#7cd4ff",
                        0.4, "#00acff",
                        0.6, "#008cd0",
                        0.8, "#ff9046",
                        1.0, "#c94f16",
                    ],
                    "heatmap-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        9, 10,
                        16, 60,
                        22, 300
                    ],
                    'heatmap-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10, 0.75,
                        12, 0.75,
                        13.9, 0.75,
                        14, 0
                    ]
                }
            }
        })
    }

    public async setEventsFilter (from: string, to: string, type: string) {
        // Update filter
        this.filter = { from, to, type };
        // Load data
        this.data = await this.load();
        // Redraw map layer
        this.update();
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Do not load all events from database!
        if (!this.filter) return json;

        const events = await this.context.invipo.getDataset("traffic-events", `from=${this.filter.from}&to=${this.filter.to}&type=${this.filter.type}`);

        // Push features from items
        for (let event of events.data) {
            // Get symbol color from definition

            json.features.push({
                type: "Feature",
                properties: {
                    cardId: event.id,
                    eventId: event.id,
                    eventType: event.type
                },
                geometry: event.position
            });
        }

        // return json;
        return json;
    }
}
