import "./traffic-queue-browser.scss";
import * as template from "./traffic-queue-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { TrafficQueueBrowserOptions } from "./types";
import { DateInput } from "muklit/components/date-input/date-input";
import { TextInput } from "muklit/components/text-input/text-input";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";

export class TrafficQueueBrowser extends MuklitComponent<InvipoContext, TrafficQueueBrowserOptions> {

    // Components
    public form: Form;
    public table: QueryTable;

    constructor(context: InvipoContext, options?: TrafficQueueBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
    }

    private createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "timestamp",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to"
                        })
                    ]
                },
                {
                    name: "Length",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "length.min",
                            label: "forms.fields.lengthMin",
                            placeholderText: "forms.placeholders.lengthMin"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "length.max",
                            label: "forms.fields.lengthMax",
                            placeholderText: "forms.placeholders.lengthMax"
                        })
                    ]
                }
            ]
        });

        // Log data
        this.form.onSubmit = async (data: any) => {
            // Send new query to table
            await this.table.search(data);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-queue`,
            http: this.context.invipo.http,
            bar: {
                title: "components.TrafficQueueBrowser.title",
                reloadable: true,
                items: [
                    {
                        name: "Export",
                        label: "components.TitleBar.export"
                    }
                ]
            },
            pagination: {
              page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "Timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "length",
                        type: "Number",
                        property: "length",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-right">${value || 0} m</div>`
                        },
                        label: "Queue",
                        width: 120,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "last",
                        type: "String",
                        property: "last",
                        label: null,
                        align: "Center"
                    }
                ]
            }
        });

        // Clear input
        this.table.onTagRemove = (tag: TitleBarTag) => {
           this.form.setValue(tag.name, null);
        }

        // Handle menu selection
        this.table.onMenuSelect = (item: MenuItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            //await this.openDetail(data);
        }
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "TrafficQueueCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getUrl(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
