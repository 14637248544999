import './smart-buildings-subdomain.scss';
import * as template from "./smart-buildings-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ItemList } from "../../city/item-list/item-list";
import { AreaList } from "../../city/area-list/area-list";
import { EnergyConsumptionAreaFillLayer } from "../../../layers/energy/energy-consumption-area-fill-layer";
import { EnergyConsumptionAreaLabelLayer } from "../../../layers/energy/energy-consumption-area-label-layer";
import { EnergyConsumptionAreaCircleLayer } from "../../../layers/energy/energy-consumption-area-circle-layer";
import { EnergyConsumptionAreaBoundaryLayer } from "../../../layers/energy/energy-consumption-area-boundary-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { SmartBuildingsSubdomainOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class SmartBuildingsSubdomain extends CitySubdomain<SmartBuildingsSubdomainOptions> {

    constructor(context: InvipoContext, options?: SmartBuildingsSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Consumption",
                    itemClass: "EnergyMeter",
                    areaType: "SmartBuilding",
                    layers: [
                        new EnergyConsumptionAreaFillLayer(context),
                        new EnergyConsumptionAreaBoundaryLayer(context),
                        new EnergyConsumptionAreaCircleLayer(context),
                        new EnergyConsumptionAreaLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.SmartBuildingsSubdomain.buildings",
            layers: [
                //new AreaBoundaryLayer(this.context, this.areas)
            ]
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.SmartBuildingsSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Create volume legend
        this.legend = {
            range: {
                range: METRICS.energy.metering.consumption.range,
                colors: METRICS.energy.metering.consumption.colors,
                count: 0,
                value: 0,
                unit: "kW/h"
            },
            symbols: []
        }

        // Calculate volume average
        for (let item of this.items) {
            // No data?
            if (!item.data?.consumption) {
                continue;
            }

            this.legend.range.value += item.data.consumption.consumption;
            this.legend.range.count += 1;
        }

        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1], this.legend.range.value);
    }
}
