import { FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";

export class PublicTransportPreferenceHeatmapLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            layer: {
                id: "public-transport-preference-heatmap-layer",
                type: "heatmap",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ['linear'],
                        ["get", "count"],
                        0, 0,
                        1000, 1
                    ],
                    "heatmap-intensity": 3.5,
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0.0, "rgba(255, 255, 255, 0)",
                        0.2, "#7cd4ff",
                        0.4, "#00acff",
                        0.6, "#008cd0",
                        0.8, "#ff9046",
                        1.0, "#c94f16",
                    ],
                    "heatmap-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        9, 10,
                        16, 60,
                        22, 300
                    ],
                    "heatmap-opacity": 0,
                    "heatmap-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "heatmap-opacity": 0.75
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // Load history
        let history = await this.context.invipo.getDataset("public-vehicles-preferences", `from=${new Date(new Date().getTime() - 48 * 3600000 ).toISOString()}&sort=timestamp:desc`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let vehicle of history.data) {
            json.features.push({
                type: "Feature",
                properties: {
                    type: "Location",
                    count: 1
                },
                geometry: vehicle.position
            })
        }

        return json;
    }
}
