import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";

const MIN_ZOOM = 16;

export class PublicTransportPreferencePointFillLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            card: "PublicTransportPreferenceCard",
            detail: "CityDetail",
            layer: {
                id: "public-transport-preference-point-fill",
                type: "fill",
                minzoom: MIN_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "fill-color": "#fac800",
                    "fill-outline-color": "#fac800",
                    "fill-opacity": 0.5,
                }
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        let areas = await this.context.invipo.getAreas("type=PreferenceDetectionPoint");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            json.features.push({
                type: "Feature",
                properties: {
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: area.id,
                    areaId: area.id,
                    areaType: area.type,
                    areaName: area.name
                },
                geometry: area.geometry
            });
        }

        return json;
    }
}
