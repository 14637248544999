import "./parking-occupancy-browser.scss";
import * as template from "./parking-occupancy-browser.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { Select } from "muklit/components/select/select";
import { ParkingOccupancyBrowserOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { MuklitComponent } from "../../../../muklit/components/muklit-component/muklit-component";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";
import { FilterItem } from "../../../../muklit/components/filter/types";

export class ParkingOccupancyBrowser extends MuklitComponent<InvipoContext, ParkingOccupancyBrowserOptions> {

    // Components
    public table: FilterTable;

    constructor(context: InvipoContext, options?: ParkingOccupancyBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/parkings-occupancies`,
            http: this.context.invipo.http,
            filter: {
                title: "components.ParkingOccupancyBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from"
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "item.class",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: [
                                    {
                                        name: "ParkingLot",
                                        label: "classes.ParkingLot"
                                    },
                                    {
                                        name: "ParkingSensor",
                                        label: "classes.ParkingSensor"
                                    }
                                ]
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                //value: value,
                                placeholderText: "All",
                                itemClass: "ParkingLot,ParkingSensor",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "Timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "overall",
                        type: "Number",
                        property: "overall.occupancy",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-center">${Helpers.toNumber(data.overall.occupancy)} %</div>`
                        },
                        label: "tables.columns.occupancy",
                        width: 120,
                        align: "Center",
                        sortable: true
                    },
                    {
                        name: "free",
                        type: "Number",
                        property: "overall.free",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-center">${Helpers.toNumber(data.overall.free)}</div>`
                        },
                        label: "tables.columns.free",
                        width: 120,
                        align: "Center",
                        sortable: true
                    },
                    {
                        name: "class",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`classes.${data.item.class}`);
                        },
                        label: "tables.columns.type",
                        ellipsis: true,
                        sortable: true,
                        width: 200
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Close self
        this.table.onClose = () => {
            this.detach();

            // OnClose handler
            this.onClose();
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "ParkingsOccupanciesCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

}
