import './fleet-moving-count-tile.scss';
import * as template from "./fleet-moving-count-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetMovingCountTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis, TileLegend } from "../../common/tile/types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class FleetMovingCountTile extends Tile<FleetMovingCountTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: FleetMovingCountTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        let data = await this.context.gpsCockpit.getDashboardLocationCount();
        data = data.sort((a, b) => a.summaryDate > b.summaryDate ? 1 : -1);

        const maxDay = data.reduce((a, b) => a.deviceMovingCount > b.deviceMovingCount ? a : b);
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.FleetMovingCountTile.peakCount",
                    value: Helpers.toNumber(maxDay.deviceMovingCount)
                },
                {
                    label: "components.FleetMovingCountTile.peakDay",
                    value: Helpers.toDateString(maxDay.summaryDate)
                }
            ]
        }

        // Calculate history data (last 2 days)
        this.chart = {
            size: "30",
            data: []
        };

        for (let i = 0; i < data.length; i++) {
            const day = data[i];

            this.chart.data.push({
                timestamp: i == 0 || i == 30 ? day.summaryDate : "",
                value: day.distanceInMeters,
                percent: day.deviceMovingCount / maxDay.deviceMovingCount * 100,
                color: METRICS.traffic.counting.volume.colors[0],
                tooltip: `${Helpers.toDateString(day.summaryDate)}: ${Helpers.toNumber(day.deviceMovingCount)}`
            });
        }

    }

}
