import { ContentView } from "../content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";

export class Management extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu);

        // Set first item as default content
        if (this.menu.options.groups[0].items.length > 0) {
            this.setContent(this.menu.options.groups[0].items[0].name);
        }
    }

    private createMenu(): void {
        // Items based on subdomains enabled
        let items: DrawerItem[] = [];

        // Traffic events enabled?
        if (this.context.options.city.find(x => x.domain == "Traffic")?.subdomains.includes("TrafficEventsSubdomain")) {
            items.push({
                name: "TrafficEventManager",
                label: "components.TrafficEventManager.title",
            })
        }

        // Traffic messages enabled?
        if (this.context.options.city.find(x => x.domain == "Traffic")?.subdomains.includes("TrafficMessagesSubdomain")) {
            items.push({
                name: "CitsManager",
                label: "components.CitsManager.title",
            })
        }

        // Select first item
        if (items.length > 0) {
            items[0].selected = true;
        }

        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                {
                    name: "Managers",
                    items: items
                }
            ]
        })

        this.menu.onSelect = (item: DrawerItem) => {
            // Already selected?
            if (this.content?.name == item.name) {
                return;
            }

            // Change content component
            this.setContent(item.name);
        }
    }

}
