import { ContentView } from "../content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerGroup, DrawerItem } from "../components/common/drawer-menu/types";

export class Insights extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu, "menu");
    }

    private createMenu(): void {
        // Menu groups
        let groups: DrawerGroup[] = [];

        // Build groups and items
        for (let domain of this.context.options.city) {
            // New group based on domain name
            let group: DrawerGroup = {
                name: domain.domain,
                title: `domains.${domain.domain}`,
                items: []
            }

            // Add data
            for (let insight of domain.insights) {
                group.items.push({
                    name: insight,
                    label: `components.${insight}.title`
                })
            }

            // Add only non-empty group
            if (group.items.length > 0) {
                groups.push(group);
            }
        }

        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: groups
        });

        // Handle select
        this.menu.onSelect = (item: DrawerItem) => {
            // OnItemSelect handler
            this.setContent(item.name);
        }

        // At least one group?
        if (this.menu.options.groups.length) {
            // Get first item
            let first = this.menu.options.groups[0].items[0];

            // And mae it selected
            first.selected = true;
            this.setContent(first.name);
        }
    }
}
