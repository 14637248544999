import "./traffic-speed-report.scss";
import * as template from "./traffic-speed-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { TrafficSpeedReportOptions } from "./types";
import { Form } from "../../../../muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { PanelChart, PanelKpis } from "../../common/panel/types";
import { Helpers } from "../../../../hiyo/helpers";

export class TrafficSpeedReport extends Panel<TrafficSpeedReportOptions> {

    // Properties
    public hours: PanelChart;
    public speeds: PanelChart;
    public kpis: PanelKpis;

    // Components
    public form: Form;

    public constructor(context: InvipoContext, options?: TrafficSpeedReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            itemClass: "TrafficCounter,WimStation",
                            items: [],
                            width: 320,
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "timestamp",
                            label: "forms.fields.timestamp",
                            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                            width: 320,
                            bright: true,
                            type: "Date"
                        })
                    ]
                }
            ]
        });

        // Reload data on form submit
        this.form.onSubmit = async () => {
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Query string
        let query = "";

        if (form.itemId) {
            query += `&item.id=${form.itemId}`;
        }

        // Interval
        let from = new Date(new Date(form.timestamp).setHours(0, 0, 0, 0));
        let to = new Date(new Date(form.timestamp).setHours(24, 0, 0, 0));

        // Speed data per hour
        let data = await this.context.invipo.getQuery("traffic-by-hour", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build hours chart
        this.hours = {
            type: "Bar",
            size: "Tall",
            name: "Speed",
            label: "components.TrafficSpeedReport.speedHours",
            series: []
        }

        // Add hourly data to chart series
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = data.find(x => new Date(x.timestamp).getHours() == h);

            // Has data?
            if (d) {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: d.speed,
                            valueX: h.toString().padStart(2, "0"),
                            percent: Helpers.range(0, 100, 0, 130, d.speed),
                            label: `${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.vehicles")}`,
                            color: "#00b4f5",
                        }
                    ]
                );
            }
            // No data
            else {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }

        // Traffic data per day
        let traffic = await this.context.invipo.getDataset("traffic-data", `${query}&from=${from.toISOString()}&to=${to.toISOString()}&`);

        // Build hour chart
        this.speeds = {
            type: "Bar",
            size: "Tall",
            name: "Percentiles",
            label: "components.TrafficSpeedReport.speedPercentiles",
            series: []
        }

        // Speed intervals
        let limits = [[0, 30], [30, 40], [40, 50], [50, 55], [55, 60]];

        // Total vehicle count
        let total = traffic.data.map(x => x.count).reduce((r, n) => { return r + n }, 0);

        // Add hourly data to chart series
        for (let i = 0; i < limits.length; i++) {
            // Find hour in data
            let values = traffic.data.filter(x => x.speed > limits[i][0] && x.speed <= limits[i][1]);

            // Calculate counts
            let count = values.map(x => x.count).reduce((r, n) => { return r + n }, 0);

            this.speeds.series.push(
                [
                    {
                        timestamp: new Date().toISOString(),
                        valueY: `${Helpers.toNumber(count / total * 100, 1, 1)} %`,
                        valueX: `${Helpers.toNumber(limits[i][0])} &ndash; ${Helpers.toNumber(limits[i][1])} ${this.context.locale.getMessage("units.kmph")}`,
                        percent: Math.min(Math.round(count / total * 100), 90), // Max 90 percent to have space for label
                        color: "#00b4f5",
                    }
                ]
            );
        }

        // Calculate speed KPIs
        let min = Math.min(...traffic.data.map(x => x.speed));
        let max = Math.max(...traffic.data.map(x => x.speed));
        let avg = traffic.data.map(x => x.speed).reduce((r, n) => { return r + n }, 0) / traffic.data.map(x => x.count).reduce((r, n) => { return r + n }, 0);

        // Build speed KPIs
        this.kpis = {
            size: "Third",
            data: [
                {
                    label: "components.TrafficSpeedReport.minimumSpeed",
                    value: Number.isInteger(min) ? `${Helpers.toNumber(min)} ${this.context.locale.getMessage(`units.kmph`)}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isInteger(min) ? `${Helpers.toShortTimeString(traffic.data.find(x => x.speed == min)?.interval?.from)} &ndash; ${Helpers.toShortTimeString(traffic.data.find(x => x.speed == min)?.interval?.to)}` : ""
                },
                {
                    label: "components.TrafficSpeedReport.averageSpeed",
                    value: Number.isFinite(avg) ? `${Helpers.toNumber(avg)} ${this.context.locale.getMessage(`units.kmph`)}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isFinite(avg) ? `${Helpers.toShortDateTimeString(from)} &ndash; ${Helpers.toShortDateTimeString(to)}` : ""
                },
                {
                    label: "components.TrafficSpeedReport.maximumSpeed",
                    value: Number.isInteger(max) ? `${Helpers.toNumber(max)} ${this.context.locale.getMessage(`units.kmph`)}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isInteger(max) ? `${Helpers.toShortTimeString(traffic.data.find(x => x.speed == max)?.interval?.from)} &ndash; ${Helpers.toShortTimeString(traffic.data.find(x => x.speed == max)?.interval?.to)}` : ""
                }
            ]
        }
    }
}
