import "./camera-browser.scss";
import * as template from "./camera-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "../../../../hiyo/helpers";
import { CameraBrowserOptions } from "./types";
import { CameraDetailOptions } from "../camera-detail/types";
import { CameraDetail } from "../camera-detail/camera-detail";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";

export class CameraBrowser extends MuklitComponent<InvipoContext, CameraBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: CameraDetail;

    constructor(context: InvipoContext, options?: CameraBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/cameras-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.CameraBrowser.title",
                items: [
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from"
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                itemClass: "Camera",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${Helpers.toDateString(data.interval.from)} ${Helpers.toShortTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}</div>`
                        },
                        label: "tables.columns.interval",
                        width: 240,
                        selected: true,
                        sortable: true,
                        extraSort: "item.name:desc",
                        descendent: true
                    },
                    {
                        name: "snapshots",
                        type: "Number",
                        property: "snapshots",
                        label: "tables.columns.snapshots",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(data.snapshots.length)}</div>`
                        },
                        width: 60,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected openDetail(data: any): void {
        data.categories?.sort((a: any, b: any) => {
            return b.count - a.count;
        });

        // Detail options
        let options: CameraDetailOptions = {
            style: "Light",
            camera: data,
            title: "components.CameraDetail.title",
            subtitle: data.item.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new CameraDetail(this.context, options);

        // Unselect table raw on detail close
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.camera.id);
        }

        // Show detail
        this.detail.attach(this.query("div.detail"));
    }
}
