import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoHelpers } from "../../invipo-helpers";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";

export class EnviPollutionSo2CircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "envi-pollution-so2-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-blur": 1,
                    "circle-radius": [
                        "interpolate",
                        ["exponential", 1.9],
                        ["zoom"],
                        1, 1,
                        20, 50000
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 0.2
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 0.15
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems(`class=EnvironmentalSensor`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No data or data too old?
            if (item.data?.envi?.pollution?.so2 == null || InvipoHelpers.isOutdated(item?.data?.envi)) {
                continue;
            }

            // Color calculation from range
            let range = Helpers.range(0, METRICS.envi.pollution.so2.colors.length - 1, METRICS.envi.pollution.so2.range[0], METRICS.envi.pollution.so2.range[1], item.data?.envi?.pollution?.so2);
            let color = METRICS.envi.pollution.so2.colors[Math.round(range)];

            json.features.push({
                type: "Feature",
                properties: {
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }

}
