import { ContentView } from "../content-view";

export class City extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("CityMap");
    }

}
