import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoContext } from "invipo/context/invipo-context";
import { TrafficeEventHeatmapFilter } from "./types";
import { METRICS } from "invipo/components/city/city-subdomain/types";
import { FeatureCollection } from "geojson";

export class TrafficEventHeatmapCircleLayer extends MapLayer<InvipoContext> {
    private filter: TrafficeEventHeatmapFilter;

    constructor(context: InvipoContext) {
        super(context, {
            // pointer: true,
            dynamic: true,
            layer: {
                id: "traffic-event-heatmap-cirle-layer",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                minzoom: 12,
                paint: {
                    "circle-radius": 14,
                    "circle-color": ["get", "color"],
                    "circle-opacity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 0,
                        13.9, 0,
                        14.0, 0.75,
                        22, 0.75
                    ],
                    "circle-stroke-width": 2,
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 0,
                        13.9, 0,
                        14.0, 0.75,
                        22, 0.75
                    ]
                }
            }
        })
    }

    public async setEventsFilter (from: string, to: string, type: string) {
        // Update filter
        this.filter = { from, to, type };
        // Load data
        this.data = await this.load();
        // Redraw map layer
        this.update();
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Do not load all events from database!
        if (!this.filter) return json;

        const events = await this.context.invipo.getDataset("traffic-events", `from=${this.filter.from}&to=${this.filter.to}&type=${this.filter.type}`);

        // Push features from items
        for (let event of events.data) {
            // Get symbol color from definition
            let color = METRICS.traffic.events.events.colors[METRICS.traffic.events.events.range.indexOf(event.type)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.context.locale.getMessage(`enums.TrafficEventType.${event.type}`),
                    cardId: event.id,
                    eventId: event.id,
                    eventType: event.type,
                    color: color
                },
                geometry: event.position
            });
        }

        // return json;
        return json;
    }
}
