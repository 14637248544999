import "./network-browser.scss";
import * as template from "./network-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { DateInput } from "muklit/components/date-input/date-input";
import { NetworkBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";

export class NetworkBrowser extends MuklitComponent<InvipoContext, NetworkBrowserOptions> {

    // Components
    public form: Form;
    public table: QueryTable;

    constructor(context: InvipoContext, options?: NetworkBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
    }

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.all",
                            itemClass: "WifiAccessPoint",
                            items: [],
                            multiselect: true
                        })
                    ]
                }
            ]
        });

        // Log data
        this.form.onSubmit = async (data: any) => {
            // Send new query to table
            await this.table.search(data);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/network-data`,
            http: this.context.invipo.http,
            bar: {
                title: "components.NetworkBrowser.title",
                reloadable: true,
                closable: this.options.closable
            },
            pagination: {
                page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                groups: {
                    property: "interval.from",
                    formatter: (value: any, data: any) => {
                        return `${Helpers.toDateString(value)} ${Helpers.toShortTimeString(value)}`;
                    }
                },
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${Helpers.toShortTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}</div>`
                        },
                        label: "tables.columns.interval",
                        width: 220,
                        selected: true,
                        sortable: true,
                        extraSort: "item.name",
                        descendent: true
                    },
                    {
                        name: "connections",
                        type: "String",
                        property: "connections",
                        label: "tables.columns.connections",
                        width: 80,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    /*{
                        name: "count",
                        type: "Number",
                        property: "count",
                        label: "tables.columns.count",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-right">${Helpers.toNumber(data.count)} ${this.context.locale.getMessage("units.vph")}</div>`
                        },
                        width: 220,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },*/
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Clear input
        this.table.onTagRemove = (tag: TitleBarTag) => {
           this.form.setValue(tag.name, null);
        }

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }
    }
}
