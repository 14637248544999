import "./message-form.scss";
import * as template from "./message-form.hbs";
import "./message-form-marker.scss";
import * as markerTemplate from "./message-form-marker.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { messageFormMenuGroups, MessageFormOptions } from "./types";
import { Select } from "muklit/components/select/select";
import * as pickerMenu from "../../common/picker-menu/picker-menu";
import { Helpers } from "../../../../hiyo/helpers";
import { PickerItem } from "../../common/picker-menu/types";
import { FormFieldset } from "../../../../muklit/components/form/types";
import { TrafficMessage, TrafficMessageSubType, TrafficMessageType, RwwExtras, IvsExtras, HlnExtras } from "../cits-manager/types";
import { DateInput } from "muklit/components/date-input/date-input";
import { HttpMethod } from "hiyo/http";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { LngLatBounds } from "mapbox-gl";
import { SignInfoInput } from "./sign-info-input/sign-info-input";
import { PickerMenu } from "../../common/picker-menu/picker-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";

export class MessageForm extends Detail<InvipoContext, MessageFormOptions> {

    // Components
    public menu: PickerMenu;
    public form: Form;

    // Message Time Components
    public from: DateInput;
    public to: DateInput;
    public transmission: DateInput;

    // Message Position Componets
    // public map: DesignMap;
    public positionType: Select;
    public fromLatitude: TextInput;
    public fromLongitude: TextInput;
    public toLatitude: TextInput;
    public toLongitude: TextInput;

    public basicMap: BasicMap;
    public startMarker: MapMarker;
    public endMarker: MapMarker;

    // Message Distance Inputs
    public detectionDistance: TextInput;
    public relevanceDistance: TextInput;

    // Message Direction Inputs
    public direction: Select;
    public lanes: TextInput;

    // IVS Message Inputs
    public enLang: TextInput;
    public enLane1: TextInput;
    public enLane2: TextInput;
    public deLang: TextInput;
    public deLane1: TextInput;
    public deLane2: TextInput;
    public elLang: TextInput;
    public elLane1: TextInput;
    public elLane2: TextInput;
    public csLang: TextInput;
    public csLane1: TextInput;
    public csLane2: TextInput;
    public signInfo: SignInfoInput;
    public vehicleTypeRestriction: Select;

    // RWW Message Inputs
    public rwwLanes: TextInput;
    public rwwInner: Select;
    public rwwOuter: Select;
    public rwwRule: Select;
    public rwwSpeed: TextInput;

    // Message Info Inputs
    public guid: TextInput;
    public timestamp: DateInput;

    // HLN-WCW and HLN-TSR Message inputs
    public weatherType: Select;
    public weatherExtreme: Select;
    public weatherVisibility: Select;
    public weatherPrecipitation: Select;

    // Message Cause Component
    public cause: Select;
    public hlnAprType: Select;
    public hlnAprAnimalCause: Select;
    public hlnAprPersonCause: Select;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: MessageFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.menu, "menu");
        this.registerComponent(this.form, "form");
    }

    public onAttach() {
        // Show proper content
        this.toggle(this.options.message?.type ? "form" : "menu");
    }

    private createMenu(): void {
        // Create component
        this.menu = new pickerMenu.PickerMenu(this.context, {
            style: "Light",
            title: "components.MessageForm.createMessage",
            closable: true,
            overlay: true,
            groups: messageFormMenuGroups
        });

        this.menu.onSelect = (item: PickerItem) => {
            // Set selected type and subtype
            if (!this.options.message) this.options.message = <TrafficMessage>{}
            this.options.message.type = <TrafficMessageType>item.name.split('-')[0]
            this.options.message.subtype = <TrafficMessageSubType>item.name;

            // Detachg and recreate the form due to custom validation fields
            this.form.detach();
            this.createForm();

            // Rerender form because field options have changed
            this.form.attach(this.query("div.content-form div.form"));

            // Finally switch to form content
            this.toggle("form");
        }
    }

    private createForm(): void {
        // Create all inputs necessary for the form
        this.createTimeInputs();
        this.createPositionInputs();
        this.createDistanceInputs();
        this.createDirectionInputs();
        this.createIvsInputs();
        this.createRwwInputs();
        this.createWeatherInputs();
        this.createCauseInputs();
        this.createHlnAprCause();
        this.createInfoInputs();

        // Create form fieldsets
        let fieldsets: FormFieldset[] = [
            {
                name: "TimeInfo",
                label: `${this.context.locale.getMessage(`enums.TrafficMessageType.${this.options.message?.type}`)} - ${this.context.locale.getMessage(`enums.TrafficMessageSubtype.${this.options.message?.subtype}`)}`,
                fields: [this.transmission, this.from, this.to]
            },
            {
                name: "Location",
                label: "components.MessageForm.location",
                fields: [this.positionType, this.fromLatitude, this.fromLongitude, this.toLatitude, this.toLongitude]
            },
            {
                name: "Relevance",
                label: "components.MessageForm.relevanceDistances",
                fields: [this.relevanceDistance, this.detectionDistance]
            },
            {
                name: "Direction",
                label: "components.MessageForm.directionInfo",
                fields: [this.direction]
            },
            {
                name: "Lanes",
                label: "components.MessageForm.affectedLanesInfo",
                fields: [this.lanes]
            },
            // Optional fieldsets - dependent on TrafficMessage type
            this.options.message?.subtype === "HLN-WCW" || this.options.message?.subtype === "HLN-TSR" ? {
                name: "Weather",
                label: "components.MessageForm.weather",
                fields: [this.weatherType, this.weatherExtreme, this.weatherVisibility, this.weatherPrecipitation]
            } : undefined,
            this.options.message?.type === "IVS" ? {
                name: "IVS-vehicleTypeRestriction",
                label: "components.MessageForm.vehicleTypeRestriction",
                fields: [this.vehicleTypeRestriction]
            } : undefined,
            this.options.message?.type === "IVS" ? {
                name: "IVS",
                label: "enums.TrafficMessageType.IVS",
                fields: [
                    this.enLang, this.enLane1, this.enLane2,
                    this.deLang, this.deLane1, this.deLane2,
                    this.elLang, this.elLane1, this.elLane2,
                    this.csLang, this.csLane1, this.csLane2
                ]
            } : undefined,
            this.options.message?.subtype === "IVS-TS" ? {
                ...this.options,
                name: "IVS-TS",
                label: "enums.TrafficMessageSubtype.IVS-TS",
                fields: [this.signInfo]
            } : undefined,
            this.options.message?.type === "RWW" ? {
                name: "RWW",
                label: "enums.TrafficMessageType.RWW",
                fields: [
                    this.rwwLanes, this.rwwInner, this.rwwOuter,
                    this.rwwSpeed, this.rwwRule
                ]
            } : undefined,
            this.cause.options.items.length > 1 ? {
                name: "Cause",
                label: "components.MessageForm.cause",
                fields: [this.cause]
            } : undefined,
            this.options.message?.subtype === "HLN-APR" ? {
                name: "Cause",
                label: "components.MessageForm.cause",
                fields: [this.hlnAprType, this.hlnAprAnimalCause, this.hlnAprPersonCause]
            } : undefined,
            this.options.message?.id ? {
                name: "Info",
                label: "components.MessageForm.info",
                fields: [this.guid, this.timestamp]
            }: undefined,
        ].filter(x => Boolean(x));

        // Create form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: fieldsets
        });

        this.form.onAttach = () => {
            const type = (this.options.message?.extras as HlnExtras)?.ucSpecificHlnWcw?.ucHlnWcwType;

            // Weather setting inputs are hidden until Weather type changes
            if (type !== "ExtremeWeatherCondition") this.weatherExtreme?.element?.parentElement?.classList.add('hidden');
            if (type !== "Visibility") this.weatherVisibility?.element?.parentElement?.classList.add('hidden');
            if (type !== "Precipitation") this.weatherPrecipitation?.element?.parentElement?.classList.add('hidden');

            this.hlnAprPersonCause.element?.parentElement?.classList.add('hidden');

            // Map is attech into "Location" fieldset
            // therefore it has to be attached created and attached after from
            this.createMap();
        };

        // Project position changes to map
        const updateMapFeature = () => {
            const bounds = new LngLatBounds();

            const isPoint = !this.positionType.options.value["LineString"]

            // Update starting marker
            const fromLat = parseFloat(this.fromLatitude.options.value);
            const fromLon = parseFloat(this.fromLongitude.options.value);
            if (!isNaN(fromLat) && !isNaN(fromLon)) {
                this.basicMap.addMarker(this.startMarker);
                this.basicMap.removeMarker(this.endMarker.id);

                this.startMarker.setPosition([fromLon, fromLat]);
                bounds.extend([fromLon, fromLat]);

            }

            // Update ending marker
            const toLat = parseFloat(this.toLatitude.options.value);
            const toLon = parseFloat(this.toLongitude.options.value);
            if (!isPoint && !isNaN(toLat) && !isNaN(toLon)) {
                this.basicMap.addMarker(this.endMarker);
                this.endMarker.setPosition([toLon, toLat]);

                bounds.extend([toLon, toLat]);
            }

            // Fit markers on map
            if (bounds.isEmpty()) {
                this.basicMap.removeMarker(this.startMarker.id);
                this.basicMap.removeMarker(this.endMarker.id);
            } else {
                this.basicMap.fitBounds(bounds, 0);
                if (isPoint) this.basicMap.setZoom(18);
            }
        }

        // Position changed - update map
        this.positionType.onSubmit = () => {
            const value = <any>(this.positionType.options.value);

            this.toLatitude.setDisabled(!value["LineString"]);
            this.toLongitude.setDisabled(!value["LineString"]);

            updateMapFeature();
        }
        this.fromLatitude.onKey = () => updateMapFeature();
        this.fromLongitude.onKey = () => updateMapFeature();
        this.toLatitude.onKey = () => updateMapFeature();
        this.toLongitude.onKey = () => updateMapFeature();

        // Weather type changed - display relevant inputs
        this.weatherType.onSubmit = () => {
            this.weatherExtreme.element?.parentElement?.classList.add('hidden');
            this.weatherVisibility.element?.parentElement?.classList.add('hidden');
            this.weatherPrecipitation.element?.parentElement?.classList.add('hidden');

            if (this.weatherType.options.value) {
                if (this.weatherType.options.value["ExtremeWeatherCondition"]) {
                    this.weatherExtreme.element?.parentElement?.classList.remove('hidden');
                }
                if (this.weatherType.options.value["Visibility"]) {
                    this.weatherVisibility.element?.parentElement?.classList.remove('hidden');
                }
                if (this.weatherType.options.value["Precipitation"]) {
                    this.weatherPrecipitation.element?.parentElement?.classList.remove('hidden');
                }
            }
        }

        this.hlnAprType.onSubmit = () => {
            this.hlnAprAnimalCause.element?.parentElement?.classList.add('hidden');
            this.hlnAprPersonCause.element?.parentElement?.classList.add('hidden');

            const value = Object.keys(this.hlnAprType.options.value ?? {})[0];
            if (value === "AnimalOnTheRoad") {
                this.hlnAprAnimalCause.element?.parentElement?.classList.remove('hidden');
            } else if (value === "PersonOnTheRoad") {
                this.hlnAprPersonCause.element?.parentElement?.classList.remove('hidden');
            }
            console.log(value);
        };
        // this.hlnAprAnimalCause.element?.parentElement?.classList.remove('hidden');
        // this.hlnAprPersonCause.element?.parentElement?.classList.add('hidden');
    }

    private createCauseInputs(): void {
        const items: MenuItem[] = [{
            name: "Unavailable",
            label: "enums.TrafficMessageCause.Unavailable"
        }];

        switch (this.options.message?.subtype) {
            // case "RWW-LC":
            //     items.push({
            //         name: "SlowMovingRoadMaintenance",
            //         label: "enums.TrafficMessageCause.slowMovingRoadMaintenance"
            //     });
            //     break
            // case "RWW-RM":
            //     items.push({
            //         name: "ShortTermStationaryRoadworks",
            //         label: "enums.TrafficMessageCause.shortTermStationaryRoadworks"
            //     });
            //     break
            case "HLN-OR":
                items.push({
                    name: "ShedLoad",
                    label: "enums.TrafficMessageCause.ShedLoad"
                }, {
                    name: "PartsOfVehicles",
                    label: "enums.TrafficMessageCause.PartsOfVehicles"
                }, {
                    name: "PartsOfTyres",
                    label: "enums.TrafficMessageCause.PartsOfTyres"
                }, {
                    name: "BigObjects",
                    label: "enums.TrafficMessageCause.BigObjects"
                }, {
                    name: "FallenTrees",
                    label: "enums.TrafficMessageCause.FallenTrees"
                });
                break;
            // case "HLN-APR":
                // items.push({
                //     name: "WildAnimals",
                //     label: "enums.TrafficMessageCause.WildAnimals"
                // }, {
                //     name: "HerdOfAnimals",
                //     label: "enums.TrafficMessageCause.HerdOfAnimals"
                // }, {
                //     name: "SmallAnimals",
                //     label: "enums.TrafficMessageCause.SmallAnimals"
                // }, {
                //     name: "LargeAnimals",
                //     label: "enums.TrafficMessageCause.LargeAnimals"
                // }, {
                //     name: "ChildrenOnRoadway",
                //     label: "enums.TrafficMessageCause.ChildrenOnRoadway"
                // }, {
                //     name: "CyclistOnRoadway",
                //     label: "enums.TrafficMessageCause.CyclistOnRoadway"
                // }, {
                //     name: "MotorcyclistOnRoadway",
                //     label: "enums.TrafficMessageCause.MotorcyclistOnRoadway"
                // });
                // break;
            case "HLN-AZ":
                items.push({
                    name: "MultiVehicleAccident",
                    label: "enums.TrafficMessageCause.MultiVehicleAccident"
                }, {
                    name: "HeavyAccident",
                    label: "enums.TrafficMessageCause.HeavyAccident"
                }, {
                    name: "AccidentInvolvingLorry",
                    label: "enums.TrafficMessageCause.AccidentInvolvingLorry"
                }, {
                    name: "AccidentInvolvingHazardousMaterials",
                    label: "enums.TrafficMessageCause.AccidentInvolvingHazardousMaterials"
                }, {
                    name: "AccidentInvolvingBus",
                    label: "enums.TrafficMessageCause.AccidentInvolvingBus"
                }, {
                    name: "UnsecuredAccident",
                    label: "enums.TrafficMessageCause.UnsecuredAccident"
                });
                break;
            case "HLN-TJA":
                items.push({
                    name: "UnknownExtensionOfTrafficJam",
                    label: "enums.TrafficMessageCause.UnknownExtensionOfTrafficJam"
                }, {
                    name: "KnownSizeOfTrafficJam",
                    label: "enums.TrafficMessageCause.KnownSizeOfTrafficJam"
                });
                break;
            case "HLN-SV":
                items.push({
                    name: "VehicleBreakdown",
                    label: "enums.TrafficMessageCause.VehicleBreakdown"
                });
                break;
            case "HLN-TSR":
                items.push({
                    name: "HeavyFrostOnRoad",
                    label: "enums.TrafficMessageCause.HeavyFrostOnRoad"
                }, {
                    name: "FuelOnRoad",
                    label: "enums.TrafficMessageCause.FuelOnRoad"
                }, {
                    name: "MudOnRoad",
                    label: "enums.TrafficMessageCause.MudOnRoad"
                }, {
                    name: "SnowOnRoad",
                    label: "enums.TrafficMessageCause.SnowOnRoad"
                }, {
                    name: "IceOnRoad",
                    label: "enums.TrafficMessageCause.IceOnRoad"
                }, {
                    name: "BlackIceOnRoad",
                    label: "enums.TrafficMessageCause.BlackIceOnRoad"
                }, {
                    name: "OilOnRoad",
                    label: "enums.TrafficMessageCause.OilOnRoad"
                }, {
                    name: "LooseChippings",
                    label: "enums.TrafficMessageCause.LooseChippings"
                }, {
                    name: "InstantBlackIce",
                    label: "enums.TrafficMessageCause.InstantBlackIce"
                });
                break;
            case "HLN-RLX":
                items.push({
                    name: "DoNotCross",
                    label: "enums.TrafficMessageCause.DoNotCross"
                }, {
                    name: "Closed",
                    label: "enums.TrafficMessageCause.Closed"
                }, {
                    name: "Unguarded",
                    label: "enums.TrafficMessageCause.Unguarded"
                }, {
                    name: "Nominal",
                    label: "enums.TrafficMessageCause.Nominal"
                });
                break;
        }



        const value = this.options.message?.extras?.ucSpecificHlnTsr?.ucHlnTsrSubcause
            || this.options.message?.extras?.ucSpecificHlnOr?.ucHlnOrSubcause
            || this.options.message?.extras?.ucSpecificHlnAz?.ucHlnAzSubcause
            || this.options.message?.extras?.ucSpecificHlnTja?.ucHlnTjaSubcause
            || this.options.message?.extras?.ucSpecificHlnSv?.ucHlnSvSubcause
            || this.options.message?.extras?.ucSpecificHlnRlx?.ucHlnRlxSubcause

        this.cause = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "cause",
            label: "components.MessageForm.cause",
            multiselect: false,
            width: 386,
            value: value ?? "unavailable",
            items: items
        });

    }

    private createHlnAprCause () {
        this.hlnAprType = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "hlnAprType",
            label: "components.MessageForm.type",
            multiselect: false,
            required: true,
            value: this.options.message?.extras?.ucSpecificHlnApr?.ucHlnAprType ?? 'AnimalOnTheRoad',
            items: [{
                name: "AnimalOnTheRoad",
                label: "enums.TrafficMessageCause.AnimalOnTheRoad"
            },
            {
                name: "PersonOnTheRoad",
                label: "enums.TrafficMessageCause.PersonOnTheRoad"
            }]
        });

        this.hlnAprAnimalCause = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "hlnAprAnimalCause",
            label: "components.MessageForm.cause",
            multiselect: false,
            width: 386,
            value: this.options.message?.extras?.ucSpecificHlnApr?.ucHlnAprAnimalSubcause ?? 'Unavailable',
            items: [
                {
                    name: "Unavailable",
                    label: "enums.TrafficMessageCause.Unavailable"
                },
                {
                    name: "WildAnimals",
                    label: "enums.TrafficMessageCause.WildAnimals"
                },
                {
                    name: "HerdOfAnimals",
                    label: "enums.TrafficMessageCause.HerdOfAnimals"
                },
                {
                    name: "SmallAnimals",
                    label: "enums.TrafficMessageCause.SmallAnimals"
                },
                {
                    name: "LargeAnimals",
                    label: "enums.TrafficMessageCause.LargeAnimals"
                }
            ]
        });

        this.hlnAprPersonCause = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "hlnAprPersonCause",
            label: "components.MessageForm.cause",
            multiselect: false,
            width: 386,
            value: this.options.message?.extras?.ucSpecificHlnApr?.ucHlnAprPersonSubcause ?? 'Unavailable',
            items: [
                {
                    name: "Unavailable",
                    label: "enums.TrafficMessageCause.Unavailable"
                },
                {
                    name: "ChildrenOnRoadway",
                    label: "enums.TrafficMessageCause.ChildrenOnRoadway"
                },
                {
                    name: "CyclistOnRoadway",
                    label: "enums.TrafficMessageCause.CyclistOnRoadway"
                },
                {
                    name: "MotorcyclistOnRoadway",
                    label: "enums.TrafficMessageCause.MotorcyclistOnRoadway"
                }
            ]
        });
    }

    private createTimeInputs(): void {
        // Default time values
        const from = new Date();
        from.setMinutes(from.getMinutes() + 5);
        from.setSeconds(0, 0);

        const to = new Date();
        to.setMinutes(to.getMinutes() + 65);
        to.setSeconds(0, 0);

        const transmission = new Date();
        from.setMinutes(from.getMinutes() + 5);
        transmission.setSeconds(0, 0);

        this.from = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "from",
            label: "forms.fields.from",
            required: true,
            value: this.options?.message?.duration?.from ?? from.toISOString()
        });

        this.to = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "to",
            label: "forms.fields.to",
            required: true,
            value: this.options?.message?.duration?.to ?? to.toISOString()
        });

        this.transmission = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "transmission",
            label: "components.MessageDetail.transmission",
            required: false,
            value: this.options?.message?.transmission ?? transmission.toISOString()
        });
    }

    private createPositionInputs(): void {
        const lineOnly = this.options.message?.type === "IVS" || this.options.message?.subtype === "RWW-LC";

        this.positionType = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "positionType",
            label: "components.MessageForm.positionType",
            multiselect: false,
            width: 186,
            value: lineOnly ? "LineString" : this.options.message?.position?.type ?? 'Point',
            items: lineOnly ? [
                { name: "LineString", label: "components.MessageForm.line" }
            ] : [
                { name: "Point", label: "components.MessageForm.point" },
                { name: "LineString", label: "components.MessageForm.line" }
            ]
        });

        // Parse GeoJSON Geometry to individual from-to values
        let fromPosition = this.options.message?.position?.coordinates ?? [];
        let toPosition: number[] = [];
        if (this.options.message?.position?.type === "LineString") {
            fromPosition = this.options.message.position.coordinates[0]
            toPosition = this.options.message.position.coordinates[1]
        }

        this.fromLatitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 186,
            name: "fromLatitude",
            label: "components.MessageForm.fromLatitude",
            value: fromPosition[1],
            required: true,
        });
        this.fromLongitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 186,
            name: "fromLongitude",
            label: "components.MessageForm.fromLongitude",
            value: fromPosition[0],
            required: true,
        });
        this.toLatitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 186,
            name: "toLatitude",
            label: "components.MessageForm.toLatitude",
            value: toPosition[1],
            disabled: !lineOnly && this.options.message?.position?.type !== 'LineString'
        });
        this.toLongitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 186,
            name: "toLongitude",
            label: "components.MessageForm.toLongitude",
            value: toPosition[0],
            disabled: !lineOnly && this.options.message?.position?.type !== 'LineString'
        });
    }

    private createDistanceInputs(): void {
        this.detectionDistance = new TextInput(this.context, {
            style: "Light",
            format: "Distance",
            bright: true,
            name: "detectionDistance",
            label: "components.MessageDetail.detectionDistance",
            value: this.options.message?.relevance?.detectionDistance ?? 500
        });

        this.relevanceDistance = new TextInput(this.context, {
            style: "Light",
            format: "Distance",
            bright: true,
            name: "relevanceDistance",
            label: "components.MessageDetail.geonetDistance",
            value: this.options.message?.relevance?.relevanceDistance ?? 4000
        });
    }

    private createDirectionInputs(): void {
        this.direction = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "direction",
            label: "components.MessageForm.direction",
            multiselect: false,
            required: true,
            width: 186,
            value: this.options.message?.direction ?? "Single",
            items: [
                { name: "Single", label: "enums.TrafficMessageDirection.Single" },
                { name: "Both", label: "enums.TrafficMessageDirection.Both" }
            ]
        });

        this.lanes = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "lanes",
            label: "components.MessageForm.lanes",
            value: this.options.message?.lanes?.join(', ')
        });
    }

    private createIvsInputs(): void {
        this.enLang = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "enLang",
            label: "components.MessageForm.language",
            value: "English",
            disabled: true
        });

        this.enLane1 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "enLane1",
            label: "components.MessageForm.line1",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'en')?.lines[0],
        });

        this.enLane2 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "enLane2",
            label: "components.MessageForm.line2",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'en')?.lines[1],
        });

        this.deLang = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "deLang",
            label: "components.MessageForm.language",
            value: "German",
            disabled: true
        });

        this.deLane1 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "deLane1",
            label: "components.MessageForm.line1",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'de')?.lines[0],
        });

        this.deLane2 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "deLane2",
            label: "components.MessageForm.line2",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'de')?.lines[1],
        });

        this.elLang = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "elLang",
            label: "components.MessageForm.language",
            value: "Greek",
            disabled: true
        });

        this.elLane1 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "elLane1",
            label: "components.MessageForm.line1",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'el')?.lines[0],
        });

        this.elLane2 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "elLane2",
            label: "components.MessageForm.line2",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'el')?.lines[1],
        });

        this.csLang = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "csLang",
            label: "components.MessageForm.language",
            value: "Czech",
            disabled: true
        });

        this.csLane1 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "csLane1",
            label: "components.MessageForm.line1",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'cs')?.lines[0],
        });

        this.csLane2 = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "csLane2",
            label: "components.MessageForm.line2",
            value: (this.options.message?.extras as IvsExtras)?.messages?.find(x => x.lang === 'cs')?.lines[1],
        });

        this.signInfo = new SignInfoInput(this.context, {
            style: "Light",
            bright: true,
            name: "signInfo",
            value: (this.options.message?.extras as IvsExtras)?.signInfo
        });

        // todo: default value
        this.vehicleTypeRestriction = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "vehicleTypeRestriction",
            label: "components.MessageForm.vehicleTypeRestriction",
            multiselect: true,
            required: false,
            width: 386,
            value: undefined,
            items: [
                { name: "unknown", label: "enums.TrafficMessageVehicleType.unknown" },
                { name: "pedestrian", label: "enums.TrafficMessageVehicleType.pedestrian" },
                { name: "cyclist", label: "enums.TrafficMessageVehicleType.cyclist" },
                { name: "moped", label: "enums.TrafficMessageVehicleType.moped" },
                { name: "motorcycle", label: "enums.TrafficMessageVehicleType.motorcycle" },
                { name: "passengerCar", label: "enums.TrafficMessageVehicleType.passengerCar" },
                { name: "bus", label: "enums.TrafficMessageVehicleType.bus" },
                { name: "lightTruck", label: "enums.TrafficMessageVehicleType.lightTruck" },
                { name: "heavyTruck", label: "enums.TrafficMessageVehicleType.heavyTruck" },
                { name: "trailer", label: "enums.TrafficMessageVehicleType.trailer" },
                { name: "truck", label: "enums.TrafficMessageVehicleType.truck" },
                { name: "specialVehicle", label: "enums.TrafficMessageVehicleType.specialVehicle" },
                { name: "roadSideUnit", label: "enums.TrafficMessageVehicleType.roadSideUnit" },
            ]
        });
    }

    private createRwwInputs(): void {
        this.rwwLanes = new TextInput(this.context, {
            style: "Light",
            bright: true,
            name: "rwwLanes",
            label: "components.MessageForm.lanes",
            value: (this.options.message?.extras as RwwExtras)?.closedLanes?.lanes?.join(', ')
        });

        this.rwwInner = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "rwwInner",
            label: "components.MessageForm.innerStatus",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as RwwExtras)?.closedLanes?.innerHardShoulderStatus ?? "",
            items: [
                { name: "AvailableForDriving", label: "enums.TrafficMessageShoulderStatus.AvailableForDriving" },
                { name: "AvailableForStopping", label: "enums.TrafficMessageShoulderStatus.AvailableForStopping" },
                { name: "Closed", label: "enums.TrafficMessageShoulderStatus.Closed" },
                { name: "Unknown", label: "enums.TrafficMessageShoulderStatus.Unknown" }
            ]
        });

        this.rwwOuter = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "rwwOuter",
            label: "components.MessageForm.outerStatus",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as RwwExtras)?.closedLanes?.outerHardShoulderStatus ?? "",
            items: [
                { name: "AvailableForDriving", label: "enums.TrafficMessageShoulderStatus.AvailableForDriving" },
                { name: "AvailableForStopping", label: "enums.TrafficMessageShoulderStatus.AvailableForStopping" },
                { name: "Closed", label: "enums.TrafficMessageShoulderStatus.Closed" },
                { name: "Unknown", label: "enums.TrafficMessageShoulderStatus.Unknown" }
            ]
        });

        this.rwwRule = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "rwwRule",
            label: "components.MessageForm.trafficRule",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as RwwExtras)?.trafficInfo?.trafficRule || "None",
            items: [
                { name: "None", label: "enums.TrafficMessageRule.None" },
                { name: "PassToLeft", label: "enums.TrafficMessageRule.PassToLeft" },
                { name: "PassToRight", label: "enums.TrafficMessageRule.PassToRight" },
                { name: "NoPassing", label: "enums.TrafficMessageRule.NoPassing" }
            ]
        });

        this.rwwSpeed = new TextInput(this.context, {
            style: "Light",
            format: "Speed",
            bright: true,
            name: "rwwSpeed",
            label: "components.MessageForm.speed",
            value: (this.options.message?.extras as RwwExtras)?.trafficInfo?.speedLimit
        });

    }

    private createInfoInputs(): void {
        this.guid = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 404,
            name: "guid",
            label: "components.MessageForm.guid",
            value: this.options.message?.guid,
            disabled: true,
        });
        this.timestamp = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "timestamp",
            label: "components.MessageForm.timestamp",
            disabled: true,
            value: this.options?.message?.timestamp
        });
    }

    private createWeatherInputs(): void {
        this.weatherType = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "weatherType",
            label: "components.MessageForm.weatherType",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as HlnExtras)?.ucSpecificHlnWcw?.ucHlnWcwType,
            items: [
                { name: "ExtremeWeatherCondition", label: "enums.TrafficMessageWeather.ExtremeWeatherCondition" },
                { name: "Visibility", label: "enums.TrafficMessageWeather.Visibility" },
                { name: "Precipitation", label: "enums.TrafficMessageWeather.Precipitation" },
            ]
        });

        this.weatherExtreme = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "weatherExtreme",
            label: "components.MessageForm.weatherSubtype",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as HlnExtras)?.ucSpecificHlnWcw?.ucHlnWcwSubtypeExtWeather,
            items: [
                { name: "Unavailable", label: "enums.TrafficMessageWeather.Unavailable" },
                { name: "StrongWinds", label: "enums.TrafficMessageWeather.StrongWinds" },
                { name: "DamagingHail", label: "enums.TrafficMessageWeather.DamagingHail" },
            ]
        });

        this.weatherVisibility = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "weatherVisibility",
            label: "components.MessageForm.weatherSubtype",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as HlnExtras)?.ucSpecificHlnWcw?.ucHlnWcwSubtypeVisibility,
            items: [
                { name: "Unavailable", label: "enums.TrafficMessageWeather.Unavailable" },
                { name: "Fog", label: "enums.TrafficMessageWeather.Fog" },
                { name: "Smoke", label: "enums.TrafficMessageWeather.Smoke" },
            ]
        });

        this.weatherPrecipitation = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "weatherPrecipitation",
            label: "components.MessageForm.weatherSubtype",
            multiselect: false,
            required: false,
            width: 186,
            value: (this.options.message?.extras as HlnExtras)?.ucSpecificHlnWcw?.ucHlnWcwSubtypePrecipitation,
            items: [
                { name: "Unavailable", label: "enums.TrafficMessageWeather.Unavailable" },
                { name: "HeavyRain", label: "enums.TrafficMessageWeather.HeavyRain" },
                { name: "HeavySnowfall", label: "enums.TrafficMessageWeather.HeavySnowfall" },
                { name: "SoftHail", label: "enums.TrafficMessageWeather.SoftHail" },
            ]
        });
    }

    public createMap () {
        this.basicMap = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: 10,
            // minZoom: 5,
            maxZoom: 20
        });
        this.basicMap.attach(this.query('.fieldset-location'));

        this.basicMap.onMapLoad = () => {
            this.basicMap.resize();
        };

        this.basicMap.mapboxMap.on('click', (e) => {
            const isPoint = !!this.positionType.options.value["Point"];
            const position = e.lngLat.toArray();

            if (!this.basicMap.hasMarker(this.startMarker.id)) {
                this.startMarker.options.position = position;
                this.basicMap.addMarker(this.startMarker);

                this.fromLatitude.setValue(position[1]);
                this.fromLongitude.setValue(position[0]);
            } else if (!isPoint && !this.basicMap.hasMarker(this.endMarker.id)) {
                this.endMarker.options.position = position;
                this.basicMap.addMarker(this.endMarker);

                this.toLatitude.setValue(position[1]);
                this.toLongitude.setValue(position[0]);
            }
        })

        // Create start and end Markers
        this.startMarker = new MapMarker(this.context, markerTemplate, {
            position: this.context.options.home.center,
            draggable: true,
            selected: false
        });

        this.startMarker.onMove = () => {
            this.fromLatitude.setValue(this.startMarker.options.position[1]);
            this.fromLongitude.setValue(this.startMarker.options.position[0]);
        }

        this.endMarker = new MapMarker(this.context, markerTemplate, {
            position: this.context.options.home.center,
            draggable: true,
            selected: true
        });

        this.endMarker.onMove = () => {
            this.toLatitude.setValue(this.endMarker.options.position[1]);
            this.toLongitude.setValue(this.endMarker.options.position[0]);
        }

        const bounds = new LngLatBounds();

        if (this.options.message?.position?.type === "Point") {
            this.startMarker.options.position = this.options.message.position.coordinates;
            this.basicMap.addMarker(this.startMarker);

            bounds.extend(this.startMarker.options.position as [number, number]);
        }
        if (this.options.message?.position?.type === 'LineString') {
            this.startMarker.options.position = this.options.message.position.coordinates[0];
            this.endMarker.options.position = this.options.message.position.coordinates[1];

            this.basicMap.addMarker(this.startMarker);
            this.basicMap.addMarker(this.endMarker);

            bounds.extend(this.startMarker.options.position as [number, number]);
            bounds.extend(this.endMarker.options.position as [number, number]);
        }

        if (!bounds.isEmpty()) {
            this.basicMap.fitBounds(bounds, 0);
            if (this.options.message?.position?.type === "Point") {
                this.basicMap.setZoom(18);
            }
        }
    }

    public toggle(content: string): void {
        // Hide all contents
        this.queryAll("div.content").forEach((element: HTMLElement) => {
            element.style.display = "none";
        });

        // Show only selected content
        this.query(`div.content-${Helpers.toKebabCase(content)}`).style.display = "block";
    }

    public async submit(): Promise<void> {

        // Basic form validation
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Resulting TrafficMessage data
        const message: TrafficMessage = {
            id: this.options.message.id,
            type: this.options.message.type,
            subtype: this.options.message.subtype,
            position: this.positionType.options.value["LineString"] ? {
                type: "LineString",
                coordinates: [this.startMarker.mapboxMarker.getLngLat().toArray(), this.endMarker.mapboxMarker.getLngLat().toArray()]
            } : {
                type: "Point",
                coordinates: this.startMarker.mapboxMarker.getLngLat().toArray()
            },
            duration: {
                from: data.from,
                to: data.to
            },
            transmission: data.transmission || data.from,
            relevance: {
                detectionDistance: data.detectionDistance,
                relevanceDistance: data.relevanceDistance
            },
            direction: data.direction,
            lanes: this.splitLanes(data.lanes),
            guid: this.options.message?.guid ?? undefined,
            timestamp: this.options.message?.timestamp ?? undefined
        };

        // Add data for IVS message
        if (this.options.message.subtype === "IVS-FT") {
            let ivsExtras: IvsExtras = {
                messages: []
            }
            if (data.enLane1 || data.enLane2) {
                ivsExtras.messages.push({
                    lang: "en",
                    lines: [data.enLane1 || "", data.enLane2 || ""]
                });
            }
            if (data.deLane1 || data.deLane2) {
                ivsExtras.messages.push({
                    lang: "de",
                    lines: [data.deLane1 || "", data.deLane2 || ""]
                });
            }
            if (data.elLane1 || data.elLane2) {
                ivsExtras.messages.push({
                    lang: "gr",
                    lines: [data.elLane1 || "", data.elLane2 || ""]
                });
            }
            if (data.csLane1 || data.csLane2) {
                ivsExtras.messages.push({
                    lang: "cs",
                    lines: [data.csLane1 || "", data.csLane2 || ""]
                });
            }

            if (ivsExtras.messages.length > 0) {
                message.extras = ivsExtras;
            }
        }

        // Add data for IVS message
        if (this.options.message.subtype === "IVS-TS") {
            let ivsExtras: IvsExtras = {
                signInfo: this.signInfo.options.value
            }

            if (ivsExtras.signInfo?.signs?.length > 0 && ivsExtras.signInfo?.type) {
                message.extras = ivsExtras;
            }
        }

        // ivs - vehicle type restriction
        if (this.options.message.type === "IVS") {
            const value = this.vehicleTypeRestriction.options.value;
            const keys = Object.keys(value ?? {});
            if (keys.length > 0) {
                message.extras = {
                    ...message.extras,
                    vehicleTypeRestriction: {}
                }
                keys.forEach((key: string) => {
                    message.extras.vehicleTypeRestriction[key] = true;
                });
            }
            console.log(message);
        }

        // Add data for RWW message
        if (this.options.message.type === "RWW") {
            let rwwExtras: RwwExtras = {};

            const innerHardShoulderStatus = data.rwwInner || undefined;
            const outerHardShoulderStatus = data.rwwOuter || undefined;
            const lanes = this.splitLanes(data.rwwLanes);
            if (innerHardShoulderStatus !== undefined || outerHardShoulderStatus !== undefined || lanes !== undefined) {
                rwwExtras.closedLanes = {};
                if (innerHardShoulderStatus !== undefined) rwwExtras.closedLanes.innerHardShoulderStatus = innerHardShoulderStatus;
                if (outerHardShoulderStatus !== undefined) rwwExtras.closedLanes.outerHardShoulderStatus = outerHardShoulderStatus;
                if (lanes?.length > 0) rwwExtras.closedLanes.lanes = lanes;
            }

            const speedLimit = parseInt(data.rwwSpeed);
            const trafficRule = data.rwwRule || undefined
            if (!isNaN(speedLimit) && speedLimit > 0 || trafficRule) {
                rwwExtras.trafficInfo = {};
                if (!isNaN(speedLimit) && speedLimit > 0) rwwExtras.trafficInfo.speedLimit = speedLimit;
                if (trafficRule) rwwExtras.trafficInfo.trafficRule = trafficRule;
            }

            message.extras = rwwExtras;
        }

        if (this.options.message?.subtype === "HLN-WCW") { // || this.options.message?.subtype === "HLN-TSR") {
            data.weatherType = Object.keys(this.weatherType.options.value ?? {})[0];

            const extras: HlnExtras = {};
            if (data.weatherType === "ExtremeWeatherCondition") {
                extras.ucSpecificHlnWcw = {
                    ucHlnWcwType: data.weatherType || undefined,
                    ucHlnWcwSubtypeExtWeather: data.weatherExtreme || undefined
                };
            }
            if (data.weatherType === "Visibility") {
                extras.ucSpecificHlnWcw = {
                    ucHlnWcwType: data.weatherType || undefined,
                    ucHlnWcwSubtypeVisibility: data.weatherVisibility || undefined
                };
            }
            if (data.weatherType === "Precipitation") {
                extras.ucSpecificHlnWcw = {
                    ucHlnWcwType: data.weatherType || undefined,
                    ucHlnWcwSubtypePrecipitation: data.weatherPrecipitation || undefined
                };
            }

            message.extras = extras;
        }

        // RWW-LC
        // RWW-RM

        if (this.options.message?.subtype === "HLN-TSR") {
            message.extras = message.extras ?? {};
            message.extras.ucSpecificHlnTsr = message.extras.ucSpecificHlnTsr ?? {};
            message.extras.ucSpecificHlnTsr.ucHlnTsrSubcause = Object.keys(this.cause.options.value ?? {})[0];
        }

        if (this.options.message?.subtype === "HLN-OR") {
            message.extras = message.extras ?? {
                ...message.extras,
                ucSpecificHlnOr: { ucHlnOrSubcause: Object.keys(this.cause.options.value ?? {})[0] }
            };
        }
        if (this.options.message?.subtype === "HLN-AZ") {
            message.extras = message.extras ?? {
                ...message.extras,
                ucSpecificHlnAz: { ucHlnAzSubcause: Object.keys(this.cause.options.value ?? {})[0] }
            };
        }
        if (this.options.message?.subtype === "HLN-TJA") {
            message.extras = message.extras ?? {
                ...message.extras,
                ucSpecificHlnTja: { ucHlnTjaSubcause: Object.keys(this.cause.options.value ?? {})[0] }
            };
        }
        if (this.options.message?.subtype === "HLN-SV") {
            message.extras = message.extras ?? {
                ...message.extras,
                ucSpecificHlnSv: { ucHlnSvSubcause: Object.keys(this.cause.options.value ?? {})[0] }
            };
        }
        if (this.options.message?.subtype === "HLN-RLX") {
            message.extras = message.extras ?? {
                ...message.extras,
                ucSpecificHlnRlx: { ucHlnRlxSubcause: Object.keys(this.cause.options.value ?? {})[0] }
            };
        }

        if (this.options.message?.subtype === "HLN-APR") {
            const hlnType = Object.keys(this.hlnAprType.options.value ?? {})[0];
            if (hlnType === "AnimalOnTheRoad") {
                message.extras = message.extras ?? {
                    ...message.extras,
                    ucSpecificHlnApr: {
                        ucHlnAprType: hlnType,
                        ucHlnAprAnimalSubcause: Object.keys(this.hlnAprAnimalCause.options.value ?? {})[0]
                    }
                };
            } else if (hlnType === "PersonOnTheRoad") {
                message.extras = message.extras ?? {
                    ...message.extras,
                    ucSpecificHlnApr: {
                        ucHlnAprType: hlnType,
                        ucHlnAprPersonSubcause: Object.keys(this.hlnAprPersonCause.options.value ?? {})[0]
                    }
                };
            }
        }

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            const http = this.context.invipo.http;
            const host = this.context.invipo.options.host;
            let method = HttpMethod.POST;
            let url = `${host}/api/management/traffic/messages`;

            if (this.options.message.id) {
                method = HttpMethod.PUT;
                url += `/${this.options.message.id}`;
            }

            await http.request(method, url, {
                data: message
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

    // split lanes (space, comma, semicolon)
    private splitLanes (lanes?: string): number[] {
        if (!lanes) return undefined;

        const strings = lanes
            .split(" ").join(",")
            .split(";").join(",")
            .split(",")
        const result = strings.map(x => parseInt(x)).filter(x => !isNaN(x));

        return result.length > 0 ? result : undefined;
    }
}
