import "./invipo-menu.scss";
import * as template from "./invipo-menu.hbs";
import { Component } from "hiyo/component";
import { InvipoMenuOptions } from "./types";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Language } from "../../../../hiyo/locale-manager";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { UserMenu } from "../../user/user-menu/user-menu";
import { Profile } from "../../../views/profile";
import { Helpers } from "../../../../hiyo/helpers";
import { InboxBrowser } from "../../city/inbox-browser/inbox-browser";
import { DatasetResult } from "../../../../muklit/components/query-table/types";
import { HiyoEvent } from "../../../../hiyo/event-broker";

const LANGUAGES = {
    "cs": "Čeština",
    "en": "English",
    "de": "Deutsch",
    "sk": "Slovenčina",
    "da": "Dansk",
    "el": "Ελληνικά",
    "vn": "Tiếng Việt"
}
const CLASS_ITEM_HOVERED = "item-hovered";
const TOOLTIP_TIMEOUT = 850;

export class InvipoMenu extends Component<InvipoContext, InvipoMenuOptions> {

    // Properties
    public notifications: DatasetResult;
    public timer: any;
    public tooltip: boolean;

    // Components
    public user: UserMenu;
    public language: OverflowMenu;

    // Event handling methods
    public onViewSelect(id: string): void {};
    public onUserLogout(): void {};
    public onLanguageSelect(langId: Language): void {};

    constructor(context: InvipoContext, options: InvipoMenuOptions) {
        super(context, template, options);
    }

    public onCreate() {
        // Create components
        this.createUser();
        this.createLanguage();
    }

    public onAttach(): void {
        // Subscribe for events
        this.context.broker.subscribe(this, ["InboxNotificationCreated", "InboxNotificationRead", "InboxNotificationsRead"]);
    }

    public onDetach(): void {
        // Unsubscribe from registered events
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent) {
        // Notification count changed, we need to reload
        await this.load();
    }

    private createUser(): void {
        // Create component
        this.user = new UserMenu(this.context);

        // Select handler
        this.user.onSelect = (name: string) => {
            // Logout?
            if (name == "UserLogout") {
                this.logoutUser();
            }
            // Profile content page?
            else {
                // Find profile view, we need to modify it according the selected item
                let profile = <Profile>this.context.application.views.find(x => x.id == "Profile");

                // Select menu item and unselect the others
                for (let group of profile.menu.options.groups) {
                    for (let item of group.items) {
                        // Select/Unselect item
                        item.selected = (item.name == name);
                    }
                }

                // Set view content first. If view is visible already, it will change immediately.
                // If view is not visible, it will be set after view is changed.
                profile.setContent(name);

                if (profile.isActive()) {
                    // Update menu if view is already visible
                    profile.menu.update();
                }
                else {
                    // Change to Profile view if view is not visible
                    this.context.application.changeView(profile.id);
                }
            }
        }
    }

    private createLanguage(): void {
        // Create component
        this.language = new OverflowMenu(this.context, {
            style: "Light",
            start: "BottomRight",
            anchor: "BottomLeft",
            items: []
        });

        // Push languages as items
        for (let langId of this.context.locale.options.langs) {
            this.language.options.items.push(
                {
                    name: langId,
                    label: LANGUAGES[langId] || langId,
                    disabled: langId == this.context.locale.options.langId
                }
            )
        }

        // Change language
        this.language.onSelect = (item: MenuItem): void => {
            // OnLanguageChange handler
            this.onLanguageSelect(<Language>item.name);
        }
    }

    public enterView(id: string): void {
        // Clear previously set timeout
        clearTimeout(this.timer);

        // Tooltip already active, we add hovered state
        if (this.tooltip) {
            this.query(`div.item-${Helpers.toKebabCase(id)}`).classList.add(CLASS_ITEM_HOVERED);
        }
        // Set timeout to display tooltip
        else {
            // Set timer to show tooltip
            this.timer = setTimeout(() => {
                this.tooltip = true;
                this.query(`div.item-${Helpers.toKebabCase(id)}`).classList.add(CLASS_ITEM_HOVERED);
            }, TOOLTIP_TIMEOUT);
        }
    }

    public leaveView(id: string): void {
        // Remove hovered state
        this.query(`div.item-${Helpers.toKebabCase(id)}`).classList.remove(CLASS_ITEM_HOVERED);

        // Clear previously set timeout
        clearTimeout(this.timer);

        // Set timeout to reset tooltip, otherwise it will be shown on next icon
        this.timer = setTimeout(() => {
            this.tooltip = false;
        }, TOOLTIP_TIMEOUT);
    }

    public selectView(id: string): void {
        // Already selected?
        if (this.context.application.currentView?.id == id) {
            return;
        }

        // OnViewChange handler
        this.onViewSelect(id);
    }

    public selectInbox(): void {
        // Create inbox
        let inbox = new InboxBrowser(this.context, {
            style: "Light",
            title: null,
            subtitle: null,
            overlay: true,
            closable: true
        });

        // Show
        inbox.attach();
    }

    public selectUser(): void {
        // Show menu
        this.user.show(this.query("div.item-user"));
    }

    public selectLanguage(): void {
        // Show overflow menu
        this.language.show(this.query("div.item-language"));
    }

    public logoutUser(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.InvipoMenu.logoutUser",
            text: "components.InvipoMenu.reallyLogoutUser",
            labelCancel: "labels.cancel",
            labelConfirm: "labels.logout"
        })

        // OnUserLogout handler
        dialog.onConfirm = () => {
            this.onUserLogout();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Load inbox notification
        this.notifications = await this.context.invipo.getDataset("inbox", "read=false&pageSize=1");

        // Redraw
        this.update();
    }

}
