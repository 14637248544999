import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { TripDetail } from "invipo/clients/gpscockpit-client/types";
import { LngLatBounds } from "mapbox-gl";

export class FleetTripPointFillLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext, private tripDetail: TripDetail[]) {
        super(context, {
            dynamic: true,
            layer: {
                id: `fleet-trip-circle-layer`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        const start = this.tripDetail[0];
        const end = this.tripDetail[this.tripDetail.length - 1];

        json.features.push({
            type: "Feature",
            properties: {
                color: '#ff4d4d'
            },
            geometry: {
                type: "Point",
                coordinates: [start.longitude, start.latitude]
            }
        });

        json.features.push({
            type: "Feature",
            properties: {
                color: '#00e66a'
            },
            geometry: {
                type: "Point",
                coordinates: [end.longitude, end.latitude]
            }
        });


        return json;
    }

    public getBounds(): LngLatBounds {
        return new LngLatBounds([
            [this.tripDetail[0].longitude, this.tripDetail[0].latitude],
            [this.tripDetail[this.tripDetail.length - 1].longitude, this.tripDetail[this.tripDetail.length - 1].latitude]
        ]);
    }
}
