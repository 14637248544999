import { ContentView } from "../content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";

export class Wim extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu);

        // Set default content
        this.setContent("WimVehicleBrowser");
    }

    private createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                /*{
                    name: "Overview",
                    label: "Overview",
                    items: [
                        {
                            name: "WimDashboard",
                            label: "Online dashboard",
                            icon: "Monitoring"
                        },
                        {
                            name: "PhotoMatrix",
                            label: "Photo matrix",
                            icon: "Journal"
                        }
                    ]
                },*/
                {
                    name: "Evidence",
                    title: "Evidence",
                    items: [
                        {
                            name: "VehicleBrowser",
                            label: "Vehicle browser",
                            selected: true
                        }
                    ]
                }
            ]
        })

        this.menu.onSelect = (item: DrawerItem) => {
            // Change content component
            this.setContent(item.name);
        }
    }
}
