import { InvipoContext } from "../../context/invipo-context";
import { FeatureCollection } from "geojson";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { ItemLayerOptions } from "../types";
import { InvipoItem } from "../../clients/invipo-client/types";
import { InvipoHelpers } from "../../invipo-helpers";

const STATUS_VALUE = {
    "Ok": 1,
    "Warn": 4,
    "Error": 5,
    "Unreachable": 2,
    "Disconnected": 3
}

const STATUS_COLOR = {
    "Ok": "#00de46",
    "Warn": "#ffb900",
    "Error": "#ff0038",
    "Unreachable": "#7d00ff",
    "Disconnected": "#ffffff"
}

export class ItemStatusCircleLayer extends MapLayer<InvipoContext, ItemLayerOptions> {

    // Properties
    public items: InvipoItem[];

    public constructor(context: InvipoContext, options?: ItemLayerOptions) {
        super(context, {
            ...options,
            dynamic: true,
            pointer: true,
            refreshInterval: 30,
            layer: {
                id: "item-status-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                minzoom: 0,
                maxzoom: 22,
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, [
                            "case",
                            ["==", ["get", "itemStatus"], "Ok"], ["*", ["get", "scale"], 2.5],
                            ["==", ["get", "itemStatus"], "Warn"], ["*", ["get", "scale"], 3],
                            ["==", ["get", "itemStatus"], "Error"], ["*", ["get", "scale"], 4],
                            ["==", ["get", "itemStatus"], "Unreachable"], ["*", ["get", "scale"], 2.5],
                            ["==", ["get", "itemStatus"], "Disconnected"], ["*", ["get", "scale"], 4],
                            2
                        ],
                        12, [
                            "case",
                            ["==", ["get", "itemStatus"], "Ok"], ["*", ["get", "scale"], 4],
                            ["==", ["get", "itemStatus"], "Warn"], ["*", ["get", "scale"], 5],
                            ["==", ["get", "itemStatus"], "Error"], ["*", ["get", "scale"], 6],
                            ["==", ["get", "itemStatus"], "Unreachable"], ["*", ["get", "scale"], 4],
                            ["==", ["get", "itemStatus"], "Disconnected"], ["*", ["get", "scale"], 6],
                            4
                        ],
                        18, [
                            "case",
                            ["==", ["get", "itemStatus"], "Ok"], ["*", ["get", "scale"], 8],
                            ["==", ["get", "itemStatus"], "Warn"], ["*", ["get", "scale"], 9],
                            ["==", ["get", "itemStatus"], "Error"], ["*", ["get", "scale"], 10],
                            ["==", ["get", "itemStatus"], "Unreachable"], ["*", ["get", "scale"], 8],
                            ["==", ["get", "itemStatus"], "Disconnected"], ["*", ["get", "scale"], 10],
                            8
                        ],
                        22, [
                            "case",
                            ["==", ["get", "itemStatus"], "Ok"], ["*", ["get", "scale"], 12],
                            ["==", ["get", "itemStatus"], "Warn"], ["*", ["get", "scale"], 13],
                            ["==", ["get", "itemStatus"], "Error"], ["*", ["get", "scale"], 14],
                            ["==", ["get", "itemStatus"], "Unreachable"], ["*", ["get", "scale"], 12],
                            ["==", ["get", "itemStatus"], "Disconnected"], ["*", ["get", "scale"], 14],
                            12
                        ]
                    ],
                    "circle-color": [
                        "case",
                        ["==", ["get", "cluster"], ["boolean", true]], "transparent",
                        ["get", "color"]
                    ],
                    "circle-opacity": 0,
                    "circle-opacity-transition": {
                        duration: 1200
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 2,
                        12, 3,
                        18, 4,
                        22, 6
                    ],
                    "circle-stroke-color": [
                        "case",
                        ["==", ["get", "cluster"], ["boolean", true]], ["get", "color"],
                        "#000000"
                    ],
                    "circle-stroke-opacity": 0,
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": [
                    "case",
                    ["==", ["get", "cluster"], ["boolean", true]], 1,
                    0
                ]
            }
        });
    }

    public setItemClass(name: string): void {
        // Set new class to options
        this.options.itemClass = name;

        // Recreate layer data
        if (this.options.itemClass) {
            this.data = this.getJson(this.items.filter(x => x.class == this.options.itemClass));
        }
        else {
            this.data = this.getJson(this.items);
        }

        // Update layer in map
        this.update();
    }

    private getJson(items: InvipoItem[]): FeatureCollection {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Build unique position list
        let cluster = InvipoHelpers.toItemCluster(items);

        // Push features from items
        for (let items of Object.values(cluster)) {
            // Sort inner status, worst comes first
            items.sort((a: InvipoItem, b: InvipoItem) => {
                return STATUS_VALUE[b.monitoringStatus?.status] - STATUS_VALUE[a.monitoringStatus.status];
            });

            // Result feature with properties
            json.features.push({
                type: "Feature",
                properties: {
                    detail: items.length == 1,
                    tooltip: items.length > 1 ? items.map(x => { return x.name }).join("\n") : `${items[0].name}\n${this.context.locale.getMessage(`classes.${items[0].class}`)}`,
                    scale: this.options.scale || 1,
                    cluster: items.length > 1,
                    color: STATUS_COLOR[items[0].monitoringStatus?.status],
                    itemId: items.map(x => { return x.id }).join(","),
                    itemName: items[0].name,
                    itemClass: items[0].class,
                    itemStatus: items[0].monitoringStatus?.status,
                },
                geometry: items[0].geometry.location
            })
        }

        return json;
    }

    public async load(): Promise<any> {
        // Query string
        let query = `class=${this.context.options.classes.join(",")}&`;

        if (this.options.itemModel) {
            query += `model=${this.options.itemModel}&`;
        }
        if (this.options.itemStatus) {
            query += `monitoringStatus.status=${this.options.itemStatus}&`;
        }

        // Empty result
        this.items = [];

        // History?
        if (this.options.timestamp) {
            // TODO: nahradit datasetem!
            //this.items = await this.context.invipo.getItemsHistory(this.options.timestamp, query);
        }
        // Items by area?
        else if (this.options.areaId) {
            this.items = await this.context.invipo.getAreaItems(this.options.areaId, query);
        }
        // Just items
        else {
            this.items = await this.context.invipo.getItems(query);
        }

        // To have faster response, we filter items based on class and monitoringStatus on client side
        if (this.options.itemClass) {
            return this.getJson(this.items.filter(x => x.class == this.options.itemClass));
        }
        else {
            return this.getJson(this.items);
        }
    }
}
