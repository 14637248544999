import "./public-transport-preference-card.scss";
import * as template from "./public-transport-preference-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { PublicTransportPreferenceCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { DatasetResult } from "../../../../muklit/components/query-table/types";
import { HiyoEvent } from "../../../../hiyo/event-broker";
import { CardHistoryValue } from "../../city/city-card/types";
import { METRICS } from "../../city/city-subdomain/types";

export class PublicTransportPreferenceCard extends CityCard<PublicTransportPreferenceCardOptions> {

    // Properties
    public history: CardHistoryValue[];
    public preferences: DatasetResult;

    constructor(context: InvipoContext, options: PublicTransportPreferenceCardOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        super.onAttach();

        // Listen to broker
        this.context.broker.subscribe(this, ["PublicVehiclePreferenceRequested"]);
    }

    public onDetach(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent): Promise<void> {
        // Reload seld
        await this.load();
    }

    public async extraLoad(): Promise<void> {
        // Load preferences
        this.preferences = await this.context.invipo.getDataset("public-vehicles-preferences", `point=${this.area.meta.number}&page=1&pageSize=10&sort=timestamp:desc`);

        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery("public-transport-preference-by-hour", `point=${this.area.meta.number}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.count,
                    percent: d.count / 50 * 100,
                    color: "#008efa"
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }
}
