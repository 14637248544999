import './cameras-subdomain.scss';
import * as template from "./cameras-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { AreaList } from "../../city/area-list/area-list";
import { ItemList } from "../../city/item-list/item-list";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { Helpers } from "../../../../hiyo/helpers";
import { CamerasSubdomainOptions } from "./types";
import { CameraSurveillanceCircleLayer } from "../../../layers/safety/camera-surveillance-circle-layer";
import { CameraSurveillanceLabelLayer } from "../../../layers/safety/camera-surveillance-label-layer";
import { CameraSurveillanceSymbolLayer } from "../../../layers/safety/camera-surveillance-symbol-layer";
import { CameraSurveillanceFillLayer } from "../../../layers/safety/camera-surveillance-fill-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class CamerasSubdomain extends CitySubdomain<CamerasSubdomainOptions> {

    constructor(context: InvipoContext, options?: CamerasSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Surveillance",
                    itemClass: "Camera",
                    areaType: "CameraSurveillance",
                    layers: [
                        new CameraSurveillanceFillLayer(context),
                        new CameraSurveillanceCircleLayer(context),
                        new CameraSurveillanceSymbolLayer(context),
                        new CameraSurveillanceLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.AreaList.subtitle",
            layers: []
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items || this.items),
                new ItemLabelLayer(this.context, items || this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSymbol(symbol: string) {
        // Get selected metrics
        let data = Helpers.toKebabCase(this.metrics.name);

        // Select devices
        this.selectDevices(this.items.filter(x => symbol == "Active" ? x.data?.snapshot : !x.data?.snapshot), [`components.CamerasSubdomain.surveillance.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Create state legend
        this.legend = {
            range: null,
            symbols: []
        };

        // Surveillance metrics
        if (this.metrics.name == "Surveillance") {
            this.legend.symbols = [
                {
                    symbol: "Active",
                    count: this.items.filter(x => x.data?.snapshot).length,
                    label: "components.CamerasSubdomain.surveillance.Active",
                    percent: this.items.filter(x => x.data?.snapshot).length / (this.items.length || 1) * 100,
                    color: METRICS.safety.cameras.surveillance.colors[0],
                    selectable: true
                },
                {
                    symbol: "None",
                    count: this.items.filter(x => !x.data?.snapshot).length,
                    label: "components.CamerasSubdomain.surveillance.None",
                    percent: this.items.filter(x => !x.data?.snapshot).length / (this.items.length || 1) * 100,
                    color: METRICS.safety.cameras.surveillance.colors[1],
                    selectable: true
                }
            ]
        }
    }
}
