import './fleet-subdomain.scss';
import * as template from "./fleet-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { FleetSubdomainOptions } from "./types";
import { FleetSymbolLayer } from 'invipo/layers/transit/fleet-symbol-layer';
import { FleetStatusCircleLayer } from 'invipo/layers/transit/fleet-status-circle-layer';
import { METRICS } from 'invipo/components/city/city-subdomain/types';
import { FleetFuelCircleLayer } from 'invipo/layers/transit/fleet-fuel-circle-layer';
import { FleetSpeedCircleLayer } from 'invipo/layers/transit/fleet-speed-circle-layer';
import { Asset, DeviceStateEnum } from 'invipo/clients/gpscockpit-client/types';
import { FleetAssetList } from 'invipo/components/city/fleet-asset-list/fleet-asset-list';
import { Helpers } from 'hiyo/helpers';

export class FleetSubdomain extends CitySubdomain<FleetSubdomainOptions> {

    public assets: Asset[];

    constructor(context: InvipoContext, options?: FleetSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Status",
                    layers: [
                        new FleetStatusCircleLayer(context),
                        new FleetSymbolLayer(context)
                    ]
                },
                {
                    name: "Speed",
                    layers: [
                        new FleetSpeedCircleLayer(context),
                    ]
                },
                {
                    name: "Fuel",
                    layers: [
                        new FleetFuelCircleLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new FleetAssetList(this.context, {
            style: "Light",
            title: "components.FleetSubdomain.assets",
            layers: []
        });

        // Call handler that will open list
        this.onListCreate(list);
    }

    // select symbol
    public selectSymbol(symbol: string): void {
        const state: DeviceStateEnum = DeviceStateEnum[symbol as any] as any;

        let list = new FleetAssetList(this.context, {
            style: "Light",
            title: "components.FleetSubdomain.assets",
            layers: [],
            deviceState: state
        });

        this.onListCreate(list);
    }

    public async extraLoad(): Promise<void> {
        this.assets = await this.context.gpsCockpit.getAssetsWithState();

        if (this.metrics.name == "Status") {
            this.legend = {
                range: null,
                symbols: [
                    {
                        symbol: "IgnitionOn",
                        count: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.IgnitionOn).length,
                        label: "enums.FleetAssetStatus.IgnitionOn",
                        percent: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.IgnitionOn).length / (this.assets.length || 1) * 100,
                        color: "#0fd170",
                        selectable: true
                    },
                    {
                        symbol: "Idling",
                        count: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.Idling).length,
                        label: "enums.FleetAssetStatus.Idling",
                        percent: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.Idling).length / (this.assets.length || 1) * 100,
                        color: "#fcb836",
                        selectable: true
                    },
                    {
                        symbol: "IgnitionOff",
                        count: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.IgnitionOff).length,
                        label: "enums.FleetAssetStatus.IgnitionOff",
                        percent: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.IgnitionOff).length / (this.assets.length || 1) * 100,
                        color: "#ff0038",
                        selectable: true
                    },
                    {
                        symbol: "ExternalPowerLost",
                        count: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.ExternalPowerLost).length,
                        label: "enums.FleetAssetStatus.ExternalPowerLost",
                        percent: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.ExternalPowerLost).length / (this.assets.length || 1) * 100,
                        color: "#c128b9",
                        selectable: true
                    },
                    {
                        symbol: "NotCommunicating",
                        count: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.NotCommunicating).length,
                        label: "enums.FleetAssetStatus.NotCommunicating",
                        percent: this.assets.filter(x => x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.NotCommunicating).length / (this.assets.length || 1) * 100,
                        color: "#7d00ff",
                        selectable: true
                    },
                    {
                        symbol: "Unknown",
                        count: this.assets.filter(x => !x.state || x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.Unknown).length,
                        label: "enums.FleetAssetStatus.Unknown",
                        percent: this.assets.filter(x => !x.state || x.state?.calculatedDeviceState.deviceState == DeviceStateEnum.Unknown).length / (this.assets.length || 1) * 100,
                        color: "#999999",
                        selectable: true
                    }
                ]
            }
        }
        else if (this.metrics.name == "Speed") {
            // Create occupancy legend
            this.legend = {
                description: "components.FleetSubdomain.speedDescription",
                range: {
                    range: METRICS.transit.fleet.speed.range,
                    colors: METRICS.transit.fleet.speed.colors,
                    value: 0,
                    count: 0,
                    unit: "km/h"
                },
                symbols: []
            }

            // Calculate average
            for (let asset of this.assets) {
                // No data?
                if (!asset.state?.currentPosition?.speed) {
                    continue;
                }

                this.legend.range.value += asset.state?.currentPosition?.speed;
                this.legend.range.count += 1;
            }

            // Set average and calculate relative position on range
            this.legend.range.value /= this.legend.range.count;
            this.legend.range.percent = Helpers.range(0, 100, METRICS.transit.fleet.speed.range[0], METRICS.transit.fleet.speed.range[1], this.legend.range.value);
        }
        else if (this.metrics.name == "Fuel") {
            // Create occupancy legend
            this.legend = {
                description: "components.FleetSubdomain.fuelDescription",
                range: {
                    range: METRICS.transit.fleet.fuel.range,
                    colors: METRICS.transit.fleet.fuel.colors,
                    value: 0,
                    count: 0,
                    unit: "%"
                },
                symbols: []
            }

            // Calculate average
            for (let asset of this.assets) {
                // No data?
                if (!asset.state?.fuel?.fuelLevelInPercentage) {
                    continue;
                }

                this.legend.range.value += asset.state?.fuel?.fuelLevelInPercentage;
                this.legend.range.count += 1;
            }

            // Set average and calculate relative position on range
            this.legend.range.value /= this.legend.range.count;
            this.legend.range.percent = Helpers.range(0, 100, METRICS.transit.fleet.speed.range[0], METRICS.transit.fleet.speed.range[1], this.legend.range.value);
        }
    }
}
