import * as template from "./passport-panel.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { ItemPassportOptions } from "./types";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { InvipoItemDocument, InvipoItemPassport, InvipoItemSchedule, InvipoItemStream } from "../../../clients/invipo-client/types";
import { ItemPassportForm } from "../../devices/item-passport-form/item-passport-form";
import { DocumentForm } from "../../devices/item-passport-form/document-form/document-form";
import { PropertyForm } from "../../devices/item-passport-form/property-form/property-form";
import { StreamForm } from "../../devices/item-passport-form/stream-form/stream-form";
import { ScheduleForm } from "../../devices/item-passport-form/schedule-form/schedule-form";

export class PassportPanel extends DetailPanel<ItemPassportOptions> {

    // Properties
    public documents: InvipoItemDocument[];
    public stream: InvipoItemStream[];
    public calendar: InvipoItemSchedule[];
    public properties: InvipoItemPassport[];

    constructor(context: InvipoContext, options?: ItemPassportOptions) {
        super(context, template, options);
    }

    public openPassports(tab?: string) {
        // Detail form
        let form = new ItemPassportForm(this.context, {
            style: "Light",
            title: null,
            itemId: this.item.id,
            tab: tab,
            overlay: true,
            closable: true,
            preventEsc: true
        });

        // Refresh on submit
        form.onClose = async () => {
            // Reload table
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openProperty() {
        // Detail form
        let form = new PropertyForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.property",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openStream() {
        // Detail form
        let form = new StreamForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.stream",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openCalendar() {
        // Detail form
        let form = new ScheduleForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.event",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openDocument() {
        // Detail form
        let form = new DocumentForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.document",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public async extraLoad(): Promise<void> {
        // Load passports
        this.documents = await this.context.invipo.getItemDocuments(this.item.id);
        this.properties = await this.context.invipo.getItemPassports(this.item.id);
        this.stream = await this.context.invipo.getItemStream(this.item.id);
        this.calendar = await this.context.invipo.getItemScheduler(this.item.id);
    }
}
