import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION, ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoHelpers } from "../../invipo-helpers";
import { DEFAULT_LANE_CAPACITY } from "../../components/traffic/traffic-counting-subdomain/traffic-counting-subdomain";

export class TrafficVolumeAreaCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            detail: "CityDetail",
            layer: {
                id: "traffic-volume-area-circle",
                type: "circle",
                maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    //"circle-blur": 0.4,
                    "circle-stroke-color": ["get", "color"],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        16, 6
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-stroke-opacity": OCCUPANCY_STROKE_AREA,
                "circle-opacity": OCCUPANCY_CIRCLE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = await this.context.invipo.getAreas("type=TrafficVolume");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            // Load area items
            let items = await this.context.invipo.getAreaItems(area.id);

            // Maximum volume and number of segments
            let peak = 0;
            let segments = 0;

            // Some data exists?
            let data = false;

            for (let item of items) {
                // No data?
                if (!item.data?.traffic) {
                    continue;
                }

                for (let data of item.data.traffic) {
                    // Hour value from data
                    let hour = data.count * (60 / this.context.config.aggregations.traffic.interval);

                    // Current segment definition
                    let segment = (<any[]>item.meta?.segments)?.find(x => x.name == data.segment);

                    // Calcualte volume as percentage of capacity
                    let volume = hour / (segment?.capacity || DEFAULT_LANE_CAPACITY) * 100;

                    // New maximum?
                    if (volume > peak) {
                        peak = volume;
                    }
                }

                // Increase total segment count
                segments += item.meta?.segments?.length || 0;

                // Yes, we have some data
                data = true;

            }

            // Color calculation from range
            let range = Helpers.range(0, METRICS.traffic.counting.volume.colors.length - 1, METRICS.traffic.counting.volume.range[0], METRICS.traffic.counting.volume.range[1], peak);
            let color = data ? METRICS.traffic.counting.volume.colors[Math.round(range)] : "#aaaaaa";

            json.features.push({
                type: "Feature",
                properties: {
                    type: "AreaSpot",
                    tooltip: area.name,
                    areaId: area.id,
                    areaName: area.name,
                    color: color,
                    scale: Helpers.range(5, 10, 0, 20, segments)
                },
                geometry: {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(area.geometry).getCenter().toArray()
                }
            })
        }

        return json;
    }

}
