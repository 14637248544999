import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";

export class FleetSymbolLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "fleet-symbol",
                type: "symbol",
                minzoom: 10,
                source: {
                    type: "geojson",
                    data: null
                },
                filter: [
                    "all",
                    ["==", ["typeof", ["get", "direction"]], "number"],
                ],
                layout: {
                    "icon-image": "16-tools-caret_up",
                    "icon-offset": [0, -12],
                    "icon-rotate": ["get", "direction"],
                    "icon-rotation-alignment": "map",
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load data
        const assets = await this.context.gpsCockpit.getAssetsWithState();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let asset of assets) {
            if (asset.state?.currentPosition && (asset.state?.calculatedIgnitionState?.isIgnitionOn || asset.state?.ignition?.state))
            json.features.push({
                type: "Feature",
                properties: {
                    direction: asset.state.currentPosition.heading
                },
                geometry: {
                    type: "Point",
                    coordinates: [asset.state.currentPosition.longitude, asset.state.currentPosition.latitude]
                }
            });
        }

        return json;
    }

}
