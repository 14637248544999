import './traffic-volume-tile.scss';
import * as template from "./traffic-volume-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficVolumeTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis, TileLegend } from "../../common/tile/types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class TrafficVolumeTile extends Tile<TrafficVolumeTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: TrafficVolumeTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Data query
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let query = `from=${from.toISOString()}&to=${to.toISOString()}`;

        // Area in query?
        if (this.options.areaId) {
            query += `&area.id=${this.options.areaId}`;
        }

        // Load data
        let data = await this.context.invipo.getQuery("traffic-by-hour", query);

        // Traffic peaks
        let peakVolume = Math.max(...data.map(x => x.count));
        let peakHour = data.find(x => x.count == peakVolume)?.timestamp;

        // Build storage kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.TrafficVolumeTile.peakVolume",
                    value: Number.isInteger(peakVolume) ? Helpers.toNumber(peakVolume) : this.context.locale.getMessage("common.noData")
                },
                {
                    label: "components.TrafficVolumeTile.peakHour",
                    value: Number.isInteger(peakVolume) ? Helpers.toShortTimeString(peakHour) : this.context.locale.getMessage("common.noData")
                }
            ]
        }

        // Calculate history data (last 2 days)
        this.chart = {
            //label: "Two days volume history",
            size: "48",
            data: []
        };

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                // Calcualte percentual volume from capacity
                let capacity = 3600 * 1;
                let volume = d.count / capacity * 100;

                // Add data
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: d.count,
                    percent: volume,
                    color: METRICS.traffic.counting.volume.colors[Math.round(Helpers.range(0, METRICS.traffic.counting.volume.colors.length - 1, METRICS.traffic.counting.volume.range[0], METRICS.traffic.counting.volume.range[1], volume))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }

        // Calculate the age of last recorded data
        let timestmap = data[data.length - 1]?.timestamp;
        let age = (new Date().getTime() - new Date(timestmap).getTime()) / (60 * 1000);

        // We have current data?
        if (age <= 2 * 60) {
            this.status = {
                icon: "Success",
                label: "components.TrafficVolumeTile.statusUpdated"
            }
        }
        // No new data less than 24 hours?
        else if (age < 24 * 60) {
            this.status = {
                icon: "Warning",
                label: this.context.locale.getMessage("components.TrafficVolumeTile.statusOutdated", Helpers.toShortDateTimeString(timestmap))
            }
        }
        // No new data more than 24 hours?
        else {
            this.status = {
                icon: "Error",
                label: "components.TrafficVolumeTile.statusNoData"
            }
        }
    }

}
