import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { AirTemperatureStatsOptions } from "./types";
import { AirTemperatureChart } from "../air-temperature-chart/air-temperature-chart";

export class AirTemperatureStats extends StatsDetail<AirTemperatureStatsOptions> {

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            value: new Date(new Date().setHours(24, 0, 0, 0)).toISOString(),
                            bright: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.selectOne",
                            itemClass: "RoadWeatherStation,EnvironmentalSensor",
                            items: [],
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.chart.filter(data);
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new AirTemperatureChart(this.context, {
            style: "Light",
            dataset: "EnviData",
            view: "Chart",
            search: this.form.getData(),
            closable: this.options.closable
        });

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

}
