import "./item-editor.scss";
import * as template from "./item-editor.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemEditorOptions } from "./types";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";
import { FilterItem, FilterTag } from "../../../../muklit/components/filter/types";
import { ItemDetail } from "../item-detail/item-detail";
import { ItemDetailOptions } from "../item-detail/types";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Search } from "../../../../muklit/components/search/search";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { MenuItem } from "../../common/detail-menu/types";

export class ItemEditor extends MuklitComponent<InvipoContext, ItemEditorOptions> {

    // Components
    public table: FilterTable;
    public detail: ItemDetail;

    constructor(context: InvipoContext, options?: ItemEditorOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/items?class=${this.context.options.classes.join(",")}`,
            http: this.context.invipo.http,
            filter: {
                title: "components.ItemManager.title",
                togglable: true,
                items: [
                    {
                        name: "Add",
                        label: "common.add"
                    },
                    {
                        name: "Reload",
                        label: "common.reload"
                    }
                ],
                /*actions: [
                    {
                        name: "Add",
                        label: "labels.add"
                    }
                ]*/
            },
            form: {
                style: "Light",
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new Search(this.context, {
                                style: "Light",
                                name: "search",
                                label: "forms.fields.search",
                                placeholderText: "forms.placeholders.itemSearch"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "class",
                                label: "item.class",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toClassItems(this.context.options.classes, (a, b) => {
                                    return this.context.locale.getMessage(a.label).localeCompare(this.context.locale.getMessage(b.label));
                                })
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Light",
                                collection: "Items",
                                name: "model",
                                label: "item.model",
                                placeholderText: "forms.placeholders.model",
                                items: [],
                                type: "Model"
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Light",
                                collection: "Items",
                                name: "producer",
                                label: "item.producer",
                                placeholderText: "forms.placeholders.producer",
                                items: [],
                                type: "Producer"
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                /*groups: {
                    property: "class",
                    formatter: (value: any, data: any) => {
                        return `classes.${value}`;
                    }
                },*/
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "name",
                        type: "String",
                        property: "name",
                        label: "item.name",
                        width: 360,
                        sortable: true,
                        ellipsis: true,
                        selected: true
                    },
                    {
                        name: "class",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`classes.${data.class}`);
                        },
                        label: "item.class",
                        ellipsis: true,
                        sortable: true,
                        width: 200
                    },
                    {
                        name: "producer",
                        type: "String",
                        property: "producer",
                        label: "item.producer",
                        ellipsis: true,
                        sortable: true,
                        width: 200,
                    },
                    {
                        name: "model",
                        type: "String",
                        property: "model",
                        label: "item.model",
                        ellipsis: true,
                        sortable: true,
                        minWidth: 200,
                    }
                    /*{
                        name: "class",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`classes.${data.class}`);
                        },
                        label: "item.class",
                        ellipsis: true,
                        sortable: true,
                        minWidth: 200
                    }*/
                ]
            }
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Handle menu
        this.table.onItemSelect = async (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                //this.openNew();
            }
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: ItemDetailOptions = {
            style: "Light",
            title: data.name,
            subtitle: `classes.${data.class}`,
            itemId: data.id,
            closable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new ItemDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.confirmDelete(this.detail.item);
            }
        }

        // Open details form
        this.detail.onSelectDetail = async (item: InvipoItem) => {
            //this.openForm(item.id);
        }

        // Open delete dialog
        this.detail.onSelectDelete = async (item: InvipoItem) => {
            this.confirmDelete(item);
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.itemId);
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public confirmDelete(item: InvipoItem): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.ItemDetail.itemDelete",
            text: this.context.locale.getMessage("components.ItemDetail.reallyDelete", item.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnConfirm handler
        dialog.onConfirm = async () => {
            // Delete item
            await this.context.invipo.deleteItem(item.id);

            // Close dialog
            dialog.close();

            // Close detail
            this.detail?.close();
        }

        // Show dialog
        dialog.attach();
    }

}
