import { InvipoContext } from "../../context/invipo-context";
import { AreaSpotLayer } from "../area-spot-layer";

const MIN_ZOOM = 15;

export class PublicTransportPreferenceAreaLabelLayer extends AreaSpotLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "PreferenceDetectionArea",
            dynamic: true,
            pointer: true,
            layer: {
                id: "public-transport-preference-area-label",
                type: "symbol",
                minzoom: MIN_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "areaName"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 14,
                    "text-offset": [0, -0.8],
                    "text-anchor": "bottom",
                    "text-max-width": 10,
                    "text-allow-overlap": true
                }
            }
        });
    }
}
