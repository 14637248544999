import "./fleet-status-detail.scss";
import * as template from "./fleet-status-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { FleetStatusDetailOptions } from "./types";
import { DataTable } from "muklit/components/data-table/data-table";
import { Asset, DeviceStateEnum } from "invipo/clients/gpscockpit-client/types";

export class FleetStatusDetail extends Detail<InvipoContext, FleetStatusDetailOptions> {

    // Properties
    public assets: Asset[];

    // Components
    public assetsTable: DataTable;

    public constructor(context: InvipoContext, options: FleetStatusDetailOptions) {
        super(context, template, options);
    }

    // Event handling methods
    public onSubmit(): void {
    }

    public onCreate(): void {
        // Create components
        this.createDocuments();

        // Register components that will be always attached
        this.registerComponent(this.assetsTable, "assets");
    }

    public createDocuments() {
        // Create component
        this.assetsTable = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            // menu: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "tables.columns.name",
                    width: 180,
                    sortable: true,
                    selected: true
                },
                {
                    name: "brand",
                    type: "String",
                    property: "brand",
                    label: "tables.columns.brand",
                    ellipsis: true,
                    sortable: true,
                    width: 120
                },
                {
                    name: "model",
                    type: "String",
                    property: "model",
                    label: "tables.columns.model",
                    ellipsis: true,
                    sortable: true,
                    width: 240
                },
                {
                    name: "color",
                    type: "String",
                    property: "color",
                    label: "tables.columns.color",
                    ellipsis: true,
                    sortable: true,
                }
            ]
        });
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.assets = await this.context.gpsCockpit.getAssetsWithState();

        if (this.options.state === DeviceStateEnum.Unknown) {
            this.assets = this.assets.filter(asset => !asset.state || asset.state.calculatedDeviceState.deviceState === DeviceStateEnum.Unknown);
        } else if (this.options.state) {
            this.assets = this.assets.filter(asset => asset.state?.calculatedDeviceState.deviceState === this.options.state);
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Set data from options to form
        this.assetsTable?.setData(this.assets);
    }
}
