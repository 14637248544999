import "./server-export-form.scss";
import * as template from "./server-export-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Log } from "hiyo/log";
import { InvipoApplication } from "../../../invipo-application";
import { Detail } from "muklit/components/detail/detail";
import { Helpers } from "hiyo/helpers";
import { ServerExportFormOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { RadioButton } from "muklit/components/radio-button/radio-button";

export class ServerExportForm extends Detail<InvipoContext, ServerExportFormOptions> {

    // Components
    public form: Form;

    public constructor(context: InvipoContext, options: ServerExportFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new RadioButton(this.context, {
                            style: this.options.style,
                            name: "export",
                            items: this.options.items
                        })
                    ]
                }
            ]
        });
    }

    public async export() {
        // Invipo application
        let app = (<InvipoApplication>this.context.application);

        // Form data
        let data = this.form.getData(true);

        // API export name
        let name = Helpers.toKebabCase(data.export);

        try {
            // Unique reference
            let ref = Helpers.createUuid();

            // Show success message
            app.toasts.showInfoToast("components.ServerExportForm.started", "components.ServerExportForm.running", ref);

            // Start export
            await this.context.invipo.getExport(name, this.options.query ? `${this.options.query}&ref=${ref}&separator=%3B` : null);
        }
        catch (e) {
            Log.e(`Error when starting export ${name} (${(e.messgae || e.response)})`);

            // Show success message
            app.toasts.showErrorToast("components.ServerExportForm.failed", "components.ServerExportForm.serverError");
        }

        // Close
        this.close();
    }
}
