import { MapLayer } from "muklit/components/basic-map/map-layer";
import { InvipoContext } from "../context/invipo-context";
import { FeatureCollection } from "geojson";
import { AreaLayerOptions } from "./types";

export abstract class AreaLayer extends MapLayer<InvipoContext, AreaLayerOptions> {

    public async load(): Promise<any> {
        let areas = await this.context.invipo.getAreas(`type=${this.options.areaType}`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            json.features.push({
                type: "Feature",
                properties: {
                    type: "Area",
                    areaId: area.id,
                    areaName: area.name,
                    areaType: area.type,
                    itemClass: area.class,
                    tooltip: this.options.tooltip,
                    card: this.options.card,
                    detail: this.options.detail,
                    pointer: this.options.pointer
                },
                geometry: area.geometry
            })
        }

        return json;
    }
}
