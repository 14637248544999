import "./fleet-asset-browser.scss";
import * as template from "./fleet-asset-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetAssetBrowserOptions } from "./types";
import { DataTable } from "muklit/components/data-table/data-table";
import { Filter } from "muklit/components/filter/filter";
import { Form } from "muklit/components/form/form";
import { Asset } from "../../../clients/gpscockpit-client/types";
import { FleetAssetDetailOptions } from "../fleet-asset-detail/types";
import { FleetAssetDetail } from "../fleet-asset-detail/fleet-asset-detail";
import { TextInput } from "muklit/components/text-input/text-input";

export class FleetAssetBrowser extends MuklitComponent<InvipoContext, FleetAssetBrowserOptions> {

    // Components
    public form: Form;
    public filter: Filter;
    public table: DataTable;
    public detail: FleetAssetDetail;

    constructor(context: InvipoContext, options?: FleetAssetBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createFilter();
        this.createTable();
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "number",
                            label: "forms.fields.number"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "name",
                            label: "forms.fields.name"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "code",
                            label: "forms.fields.assetCode"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "licensePlace",
                            label: "forms.fields.plateNumber"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "brand",
                            label: "forms.fields.brand"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "model",
                            label: "forms.fields.model"
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async () => {
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    private createFilter(): void {
        // Create component
        this.filter = new Filter(this.context, {
            style: "Light",
            title: "components.FleetAssetBrowser.title",
            togglable: true,
            items: [
                // {
                //     name: "Add",
                //     label: "labels.add"
                // },
                {
                    name: "reload",
                    label: "labels.reload"
                }
            ]
        });

        // Toggle form
        this.filter.onFormToggle = (toggled: boolean) => {
            // Assign toggled class
            this.query("div.form").classList.toggle("form-toggled", toggled);
        }

        // // Remove tag and update query
        // this.filter.onTagRemove = async (tag: TitleBarTag) => {
        //     // Reload if query
        //     if (this.options.search) {
        //         // Remove from query
        //         delete this.options.search[tag.name];

        //         // Reload data
        //         await this.load();
        //     }

        //     // Clear form field
        //     this.form.setValue(tag.name, null);
        // }

        // // Clear all form
        // this.filter.onTagsClear = async () => {
        //     // Celar search
        //     this.options.search = null;

        //     // Celar form
        //     this.form.clear();

        //     // Reload data
        //     await this.load();
        // }

        // // Handle menu
        // this.filter.onItemSelect = (item: FilterItem) => {
        //     this.onItemSelect(item);
        // }

        // // Handle actions
        // this.filter.onActionSelect = (action: FilterAction) => {
        //     this.onActionSelect(action);
        // }

        // Reload data
        this.filter.onReload = async () => {
            await this.load();
        }

        // Close panel
        this.filter.onClose = () => {
            // Handler only
            this.onClose();
        }

        // Register component
        this.registerComponent(this.filter, "filter");
    }

    private createTable(): void {
        this.table = new DataTable(this.context, {
            style: "Light",
            type: "SingleSelect",
            size: "Normal",
            height: "100%",
            autosort: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "id",
                    type: "Number",
                    property: "id",
                    label: "tables.columns.number",
                    width: 120,
                    sortable: true,
                    selected: true
                },
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "tables.columns.name",
                    width: 180,
                    sortable: true,
                },
                {
                    name: "code",
                    type: "String",
                    property: "code",
                    label: "tables.columns.code",
                    width: 180,
                    sortable: true,
                },
                {
                    name: "plateNumber",
                    type: "String",
                    property: "plateNumber",
                    label: "tables.columns.plateNumber",
                    sortable: true,
                    width: 180
                },
                {
                    name: "brand",
                    type: "String",
                    property: "brand",
                    label: "tables.columns.brand",
                    ellipsis: true,
                    sortable: true,
                    width: 120
                },
                {
                    name: "model",
                    type: "String",
                    property: "model",
                    label: "tables.columns.model",
                    ellipsis: true,
                    sortable: true,
                    // width: 240
                },
                {
                    name: "color",
                    type: "String",
                    property: "color",
                    label: "tables.columns.color",
                    ellipsis: true,
                    sortable: true,
                }
            ]
        });

        // Open detail
        this.table.onRowSelect = async (data: any) => {
            this.openDetail(data.data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private openDetail(data: Asset): void {
        // Open detail
        console.log("openDetail", data);

        // Detail options
        let options: FleetAssetDetailOptions = {
            style: "Light",
            asset: data,
            title: "components.FleetAssetDetail.title",
            subtitle: data.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new FleetAssetDetail(this.context, options);

        // Unselect table raw on detail close
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.asset.id.toString());
        }

        // Show detail
        this.detail.attach();
    }

    // public openExport(): void {
    //     // Export form to choose export type
    //     let form = new ServerExportForm(this.context, {
    //         style: "Light",
    //         title: "components.ServerExportForm.title",
    //         overlay: true,
    //         closable: true,
    //         items: [
    //             {
    //                 name: "PublicVehiclesHistoryCsv",
    //                 label: "components.ServerExportForm.types.Csv",
    //                 checked: true
    //             }
    //         ],
    //         query: this.table.getUrl(),
    //         total: this.table.pagination.options.total
    //     });

    //     // Show form
    //     form.attach();
    // }

    public async load(): Promise<void> {
        this.showLoader();

        const formData = this.form.getData();
        let data = await this.context.gpsCockpit.getAssets();

        if (formData.number) {
            const re = new RegExp(formData.number, "gi");
            data = data.filter((asset: Asset) => {
                return asset.id.toString().match(re);
            });
        }

        if (formData.name) {
            const re = new RegExp(formData.name, "gi");
            data = data.filter((asset: Asset) => {
                return asset.name.match(re);
            });
        }

        if (formData.code) {
            const re = new RegExp(formData.code, "gi");
            data = data.filter((asset: Asset) => {
                return asset.code.match(re);
            });
        }

        if (formData.licensePlace) {
            const re = new RegExp(formData.licensePlace, "gi");
            data = data.filter((asset: Asset) => {
                return asset.plateNumber.match(re);
            });
        }

        if (formData.brand) {
            const re = new RegExp(formData.brand, "gi");
            data = data.filter((asset: Asset) => {
                return asset.brand.match(re);
            });
        }

        if (formData.model) {
            const re = new RegExp(formData.model, "gi");
            data = data.filter((asset: Asset) => {
                return asset.model.match(re);
            });
        }

        this.table.setData(data);

        this.hideLoader();
    }
}
