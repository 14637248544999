import "./traffic-counter-overview.scss";
import * as template from "./traffic-counter-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { TrafficCounterOverviewOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { TrafficVolumeSegmentLineLayer } from "../../../layers/traffic/traffic-volume-segment-line-layer";
import { TrafficVolumeSegmentSymbolLayer } from "../../../layers/traffic/traffic-volume-segment-symbol-layer";
import { Point } from "geojson";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { TrafficVolumeSegmentLabelLayer } from "../../../layers/traffic/traffic-volume-segment-label-layer";

export class TrafficCounterOverview extends DetailPanel<TrafficCounterOverviewOptions> {

    // Properties
    public history: DetailHistoryValue[];

    constructor(context: InvipoContext, options?: TrafficCounterOverviewOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        if (this.item && this.map) {
            this.map.setCenter((<Point>this.item.geometry.location).coordinates);
        }
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "GoogleOrthophoto",
            center: this.context.options.home.center,
            zoom: 18,
            minZoom: 5,
            maxZoom: 20
        });

        // Add Google custom layer
        this.map.onMapLoad = () => {
            this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer);
        }

        // Register map layers
        this.map.addLayer(new TrafficVolumeSegmentLineLayer(this.context, this.options.itemId));
        this.map.addLayer(new TrafficVolumeSegmentSymbolLayer(this.context, this.options.itemId));
        this.map.addLayer(new TrafficVolumeSegmentLabelLayer(this.context, this.options.itemId));
        //this.map.addLayer(new ItemCircleLayer(this.context, [this.item], "Device"));
    }

    public async extraLoad(): Promise<void> {
        // Empty history data
        this.history = [];

        // Interval
        let from = new Date(new Date().setHours(0, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));

        // Load data
        let data = await this.context.invipo.getQuery("traffic-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Get max count to adjust chart optimal height
        let max = Math.max(...data.map(x => x.count)) * 1.1;

        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Data for hour exists?
            if (d) {
                // Total counts
                this.history.push({
                    data: "volume",
                    timestamp: d.timestamp,
                    value: d.count,
                    percent: Helpers.range(0, 100, 0, max, d.count),
                    color: "#00b4f5",
                    heatmap: "#00b4f5" + Math.round(Helpers.range(0, 100, 0, max, d.count) / 100 * 255).toString(16).padStart(2, "0")
                });
            }
            else {
                this.history.push({
                    data: "volume",
                    timestamp: from.toISOString()
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }
}
