import './street-light-subdomain.scss';
import * as template from "./street-light-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { StreetLightSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { StreetLightAreaBoundaryLayer } from "../../../layers/energy/street-light-area-boundary-layer";
import { StreetLightStateLabelLayer } from "../../../layers/energy/street-light-state-label-layer";
import { StreetLightStateCircleLayer } from "../../../layers/energy/street-light-state-circle-layer";
import { StreetLightControllerLineLayer } from "../../../layers/energy/street-light-controller-line-layer";
import { StreetLightControllerCircleLayer } from "../../../layers/energy/street-light-controller-circle-layer";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class StreetLightSubdomain extends CitySubdomain<StreetLightSubdomainOptions> {

    constructor(context: InvipoContext, options?: StreetLightSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "State",
                    itemClass: "StreetLightController",
                    layers: [
                        new StreetLightAreaBoundaryLayer(context),
                        new StreetLightStateCircleLayer(context),
                        new StreetLightStateLabelLayer(context)
                    ]
                },
                {
                    name: "Topology",
                    itemClass: "StreetLightController",
                    layers: [
                        new StreetLightAreaBoundaryLayer(context),
                        new StreetLightControllerLineLayer(context),
                        new StreetLightControllerCircleLayer(context),
                        new StreetLightStateLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string): void {
        // Get selected metrics
        let metrics = this.options.metrics.find(x => x.selected == true);

        // State metrics?
        if (metrics.name == "State") {
            this.selectDevices(this.items.filter(x => symbol == "Unknown" ? !x.data?.control?.state : x.data?.control?.state == symbol), [`enums.StreetLightControllerState.${symbol}`]);
        }

        // Topology metrics?
        if (metrics.name == "Topology") {
            this.selectDevices(this.items.filter(x => symbol == "Master" ? x.parent : !x.parent), [`components.StreetLightSubdomain.topology.${symbol}`]);
        }
    }

    public async extraLoad(): Promise<void> {
        // Create legend
        this.legend = {
            range: null,
            symbols: []
        };

        // State metrics?
        if (this.metrics.name == "State") {
            for (let i in METRICS.energy.lights.state.range) {
                let symbol = METRICS.energy.lights.state.range[i]
                let count = this.items.filter(
                    symbol == "Unknown" ?
                        x => x.data?.control?.state == null :
                        x => x.data?.control?.state != null && x.data.control.state == symbol).length;

                // Push row to legend
                this.legend.symbols.push({
                    symbol: symbol,
                    count: count,
                    label: `enums.StreetLightControllerState.${symbol}`,
                    percent: count / (this.items.length || 1) * 100,
                    color: METRICS.energy.lights.state.colors[i],
                    selectable: true
                });
            }
        }

        // Topology metrics?
        if (this.metrics.name == "Topology") {
            this.legend.symbols = [
                {
                    symbol: "Master",
                    count: this.items.filter(x => !x.parent).length,
                    label: "components.StreetLightSubdomain.topology.Master",
                    percent: this.items.filter(x => !x.parent).length / (this.items.length || 1) * 100,
                    color: METRICS.energy.lights.topology.colors[0],
                    selectable: true
                },
                {
                    symbol: "Slave",
                    count: this.items.filter(x => x.parent).length,
                    label: "components.StreetLightSubdomain.topology.Slave",
                    percent: this.items.filter(x => x.parent).length / (this.items.length || 1) * 100,
                    color: METRICS.energy.lights.topology.colors[1],
                    selectable: true
                }
            ]
        }
    }
}
