import "./basic-map.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import * as template from "./basic-map.hbs";
import * as mapbox from "mapbox-gl";
import { Feature, GeometryObject, Position } from "geojson";
import { BasicMapOptions, MapFlyToOptions, MapPulseAtOptions } from "./types";
import { MapLayer } from "./map-layer";
import { Log } from "hiyo/log";
import { EventData, GeoJSONSource, LngLatBounds, MapMouseEvent } from "mapbox-gl";
import { Context } from "hiyo/context";
import { Component } from "hiyo/component";
import { MapMarker } from "../map-marker/map-marker";
import { PulsingMarker } from "../pulsing-marker/pulsing-marker";

const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiaW5jaW5pdHkiLCJhIjoiY2thbDZ0N3g4MG5xcTJybzZnMHNxcm51NiJ9.AIdB5NT1kyQmlur8EKEm0Q";

const MAP_STYLES: { [style: string]: string } = {
    "Light": "mapbox://styles/incinity/ckov73uh701id17qns6etcljs?fresh=true",
    "Dark": "mapbox://styles/incinity/ckovf52b809bi17mh21tdc0d2?fresh=true",
    "Satellite": "mapbox://styles/incinity/ckwcb0kwp0tsp14l3yzzrq0p9?fresh=true",
    "Czechia": "mapbox://styles/incinity/cjzo45jtw0v9o1doa2soxgdg2?fresh=true",
    "HradecKraloveOrthophoto": "mapbox://styles/incinity/ckpcd3bt646nm17tpzsnhqa3y?fresh=true",
    "GoogleOrthophoto": "mapbox://styles/incinity/clgyvtatv00gs01pna2o5glix?fresh=true",
    "CuzkZtm": "mapbox://styles/incinity/clgyvtatv00gs01pna2o5glix?fresh=true",
    "CuzkOrthophoto": "mapbox://styles/incinity/clgyvtatv00gs01pna2o5glix?fresh=true",
}

export class BasicMap<T extends Context = Context, U extends BasicMapOptions = BasicMapOptions> extends Component<T, U> {

    // Properties
    public layers: { [id: string]: MapLayer };
    public markers: { [id: string]: MapMarker };
    public pulse: PulsingMarker;
    public mapboxMap: mapbox.Map;
    public mapboxLoaded: boolean;

    // Event handling methods
    public onMapLoad(): void {};
    public onMapZoom(zoom: number): void {};
    public onMapMove(center: Position): void {};
    public onFeatureEnter(layer: string, feature: Feature, event?: MouseEvent): void {};
    public onFeatureLeave(layer: string, event?: MouseEvent): void {};
    public onFeatureMove(layer: string, feature: Feature, event?: MouseEvent): void {};
    public onFeatureMouseDown(layer: string, feature: Feature, event?: MouseEvent): void {};
    public onFeatureClick(layer: string, feature: Feature, event?: MouseEvent): void {};
    public onFeatureDoubleClick(layer: string, feature: Feature, event?: MouseEvent): void {};

    // Private handlers
    private readonly onFeatureEnterHandler: (e: MapMouseEvent & EventData) => void;
    private readonly onFeatureLeaveHandler: (e: MapMouseEvent & EventData) => void;
    private readonly onFeatureMoveHandler: (e: MapMouseEvent & EventData) => void;
    private readonly onFeatureMouseDownHandler: (e: MapMouseEvent & EventData) => void;
    private readonly onFeatureClickHandler: (e: MapMouseEvent & EventData) => void;
    private readonly onFeatureDoubleClickHandler: (e: MapMouseEvent & EventData) => void;

    constructor(context: T, options: U) {
        super(context, template.toString());

        // Empty layers and markers
        this.layers = {};
        this.markers = {};

        // Merge options
        this.options = {...options};

        // Mapbox event handlers must be wrapped to single-instance callbacks
        // Enter handler wrapper with cursor change
        this.onFeatureEnterHandler = (e: MapMouseEvent & EventData) => {
            this.setCursor("pointer");
            this.onFeatureEnter(null, e.features[0], e.originalEvent);
        }

        // Leave handler wrapper
        this.onFeatureLeaveHandler = (e: MapMouseEvent & EventData) => {
            this.setCursor(null);
            this.onFeatureLeave(null, e.originalEvent);
        }

        // Move handler wrapper
        this.onFeatureMoveHandler = (e: MapMouseEvent & EventData) => {
            this.onFeatureMove(null, e.features[0], e.originalEvent);
        }

        // MouseDown handler wrapper with preventing many clicks on overfloating layers
        this.onFeatureMouseDownHandler = (e: MapMouseEvent & EventData) => {
            if (!e.defaultPrevented) {
                this.onFeatureMouseDown(null, e.features[0], e.originalEvent);
                // FIXME: Blocks dragging the map and blocks details
                e.preventDefault();
            }
        }

        // MouseClick handler wrapper with preventing many clicks on overfloating layers
        this.onFeatureClickHandler = (e: MapMouseEvent & EventData) => {
            if (!e.defaultPrevented) {
                this.onFeatureClick(null, e.features[0], e.originalEvent);
                e.preventDefault();
            }
        }

        // MouseDoubleClick handler wrapper with preventing many clicks on overfloating layers
        this.onFeatureDoubleClickHandler = (e: MapMouseEvent & EventData) => {
            console.info(e.type);
            if (!e.defaultPrevented) {
                this.onFeatureDoubleClick(null, e.features[0], e.originalEvent);
                e.preventDefault();
            }
        }
    }

    public onAttach(): void {
        // Need to put valid token here
        (<any>mapbox).accessToken = MAPBOX_ACCESS_TOKEN;

        // New map instance
        this.mapboxMap = new mapbox.Map({
            container: this.element,
            style: MAP_STYLES[this.options.style],
            center: this.options.center ? [this.options.center[0], this.options.center[1]] : [0, 0],
            zoom: this.options.zoom || 10,
            minZoom: this.options.minZoom || 0,
            maxZoom: this.options.maxZoom || 22,
            maxBounds: this.options.maxBounds || null,
            pitch: this.options.pitch || 0
        });

        // Handle load, we use style.load to cover loading new styles
        this.mapboxMap.on("style.load", () => {
            // OnMapLoad handler
            // Calling this handler must come first due to custom handling of custom layers that needs to be added first
            this.onMapLoad();

            // When Mapbox loads, we have to add all layers in evidence
            for (let id in this.layers) {
                this.addMapboxLayer(this.layers[id]);
            }

            // When Mapbox loads, we have to add all markers in evidence
            for (let id in this.markers) {
                this.addMapboxMarker(this.markers[id]);
            }

            // Loaded flag
            this.mapboxLoaded = true;
        });

        // Handle zoom
        this.mapboxMap.on("zoom", () => {
            // Remember zoom
            this.options.zoom = this.mapboxMap.getZoom();

            // OnMapZoom handler
            this.onMapZoom(this.options.zoom);
        });

        // Store center position on move
        this.mapboxMap.on("move", () => {
            // Remember center
            let center = this.mapboxMap.getCenter();
            this.options.center = [center.lng, center.lat];
        });

        // Call move callback on when move ends
        this.mapboxMap.on("moveend", () => {
            // New center
            let center = this.mapboxMap.getCenter();

            // OnMapZoomEnd handler
            this.onMapMove([center.lng, center.lat]);
        });

        // Debug
        this.mapboxMap.on("dblclick", (e) => {
            e.preventDefault();
            Log.i(this.mapboxMap.getCenter());
            Log.i(this.mapboxMap.getZoom());
            Log.i(this.mapboxMap.getStyle().layers);
        });
    }

    public onDetach(): void {
        // Remove layers from map
        for (let id in this.layers) {
            this.removeMapboxLayer(id);
        }

        // Remove markers from map
        for (let id in this.markers) {
            this.removeMapboxMarker(id);
        }

        // Mapbox clean-up
        this.mapboxMap.remove();
        this.mapboxMap = null;
        this.mapboxLoaded = false;
    }

    public setCenter(center: Position): void {
        if (this.mapboxMap) {
            this.mapboxMap.setCenter([center[0], center[1]]);
        }
    }

    public setZoom(zoom: number): void {
        if (this.mapboxMap) {
            this.mapboxMap.setZoom(zoom);
        }
    }

    public setMinZoom(minZoom: number): void {
        if (this.mapboxMap) {
            this.mapboxMap.setMinZoom(minZoom);
        }
    }

    public setMaxZoom(maxZoom: number): void {
        if (this.mapboxMap) {
            this.mapboxMap.setMaxZoom(maxZoom);
        }
    }

    public setCursor(cursor: string): void {
        this.mapboxMap.getCanvas().style.cursor = cursor;
    }

    public zoomIn(): void {
        if (this.mapboxMap) {
            this.mapboxMap.zoomIn();
        }
    }

    public zoomOut(): void {
        if (this.mapboxMap) {
            this.mapboxMap.zoomOut();
        }
    }

    public fitBounds(bounds: LngLatBounds, duration: number = 3000): void {
        if (this.mapboxMap) {
            this.mapboxMap.fitBounds(bounds, {
                padding: 32,
                duration: duration,
                maxZoom: 22,
                pitch: 0
            });
        }
    }

    public fitGeometry(geometry: GeometryObject, duration: number = 3000): void {
        // Nothing to fit
        if (!geometry) {
            return;
        }

        // New empty bounds
        let bounds = new LngLatBounds();

        // Point?
        if (geometry.type == "Point") {
            bounds.extend([geometry.coordinates[0], geometry.coordinates[1]]);
        }

        // Polygon?
        if (geometry.type == "Polygon") {
            for (let coordinates of geometry.coordinates) {
                for (let position of coordinates) {
                    bounds.extend([position[0], position[1]]);
                }
            }
        }

        // Fit to bounds
        this.fitBounds(bounds, duration);
    }

    public fitAll(): void {
        let bounds = new LngLatBounds();

        // Get bounds of all layers
        for (let id in this.layers) {
            bounds.extend(this.layers[id].getBounds());
        }

        if (!bounds.isEmpty()) {
            this.fitBounds(bounds);
        }
    }

    public resize(): void {
        if (this.mapboxMap) {
            this.mapboxMap.resize();
        }
    }

    public setStyle(style: any): void {
        // Quit silently when no style change
        if (this.options.style == style) {
            return;
        }

        // When style is changed, Mapbox will incorrectly keep all layers registered
        // but will not visualize them. So wee need to remove all layers first.

        // Remove layers from map
        for (let id in this.layers) {
            this.removeMapboxLayer(id);
        }

        // Remove markers from map
        for (let id in this.markers) {
            this.removeMapboxMarker(id);
        }

        // Write to options
        this.options.style = style;

        // Set new style in mapbox
        this.mapboxMap.setStyle(MAP_STYLES[style]);
    }

    public pulseAt(options: MapPulseAtOptions): void {
        // Pulse already active?
        if (this.pulse) {
            this.removeMarker(this.pulse.id);
        }

        // Create new pulsing marker
        this.pulse = new PulsingMarker(this.context, {
            style: "Dark",
            type: options.type,
            position: options.position
        });

        // Destroy pulser after animation ends
        this.pulse.onFinish = () => {
            this.removeMarker(this.pulse.id);
            this.pulse = null;
        }

        // Add pulser as marker to map
        this.addMarker(this.pulse);
    }

    public flyTo(options: MapFlyToOptions): void {
        if (this.mapboxMap) {
            this.mapboxMap.flyTo({
                center: [options.center[0], options.center[1]],
                zoom: options.zoom || this.options.zoom,
                duration: options.duration || 3000,
                bearing: options.bearing || null,
                pitch: 0
            });
        }
    }

    public hasLayer(id: string): boolean {
        return this.layers[id] != null;
    }

    public addLayer(layer: MapLayer): void {
        // Already added?
        if (this.hasLayer(layer.options.layer.id)) {
            Log.w(`Layer ${layer.options.layer.id} already added in ${this.id}`);
            return;
        }

        // Assign self to layer due to map updates
        layer.map = this;

        // Add to evidence
        this.layers[layer.options.layer.id] = layer;

        // Mapbox layer could be only added if map is loaded
        if (this.mapboxLoaded) {
            this.addMapboxLayer(layer);
        }

        // OnAdd handler
        layer.onAdd();
    }

    public removeLayer(id: string): void {
        // Not found?
        if (!this.hasLayer(id)) {
            Log.w(`Layer ${id} not found in ${this.id}`);
            return;
        }

        // Mapbox layer could be only removed if map is loaded
        if (this.mapboxLoaded) {
            this.removeMapboxLayer(id);
        }

        // OnRemove handler
        this.layers[id].onRemove();

        // Remove from evidence
        delete this.layers[id];
    }

    public removeLayers(): void {
        for (let id in this.layers) {
            this.removeLayer(id);
        }
    }

    private addMapboxLayer(layer: MapLayer): void {
        // Add layer to mapbox with check if the before layer exists if required
        this.mapboxMap.addLayer(layer.options.layer, this.mapboxMap.getLayer(layer.options.before)?.id);

        Log.i(`Layer ${layer.options.layer.id} added to ${this.id}`);

        // OnFeatureEnter handler
        // Due to overlaping and fact, that mouseleave event does not send properties,
        // we must register enter/leave events only in case there is a tooltip or detail logic or pointer enabled
        if (layer.options.tooltip || layer.options.card || layer.options.detail || layer.options.pointer) {
            this.mapboxMap.on("mouseenter", layer.options.layer.id, this.onFeatureEnterHandler);
        }

        // OnFeatureLeave handler
        // Due to overlaping and fact, that mouseleave event does not send properties,
        // we must register enter/leave events only in case there is a tooltip or detail logic or pointer enabled
        if (layer.options.tooltip || layer.options.card || layer.options.detail || layer.options.pointer) {
            this.mapboxMap.on("mouseleave", layer.options.layer.id, this.onFeatureLeaveHandler);
        }

        // OnFeatureMove handler
        this.mapboxMap.on("mousemove", layer.options.layer.id, this.onFeatureMoveHandler);

        // OnFeatureMouseDown handler
        this.mapboxMap.on("mousedown", layer.options.layer.id, this.onFeatureMouseDownHandler);

        // OnFeatureClick handler
        this.mapboxMap.on("click", layer.options.layer.id, this.onFeatureClickHandler);

        // OnFeatureDblclick handler
        this.mapboxMap.on("dblclick", layer.options.layer.id, this.onFeatureDoubleClickHandler);

        // Dynamic layer?
        if (layer.options.dynamic) {
            // When new layer is added, we will always force to load new data
            // even they have been already loaded before. We need to use this
            // via setInterval as this function could not be async.
            setTimeout(async () => {
                await this.updateLayerData(layer);
            });

            // Set timer if layer should refresh periodically
            if (layer.options.refreshInterval) {
                layer.timer = setInterval(async () => {
                    await this.updateLayerData(layer);
                }, layer.options.refreshInterval * 1000);
            }
        }

        // Transition effects
        if (layer.options.transitions) {
            for (let property in layer.options.transitions) {
                this.mapboxMap.setPaintProperty(layer.options.layer.id, property, layer.options.transitions[property]);
            }
        }
    }

    private removeMapboxLayer(id: string): void {
        // Layer was not attached so nothing to remove
        if (!this.mapboxMap.getLayer(id)) {
            return;
        }

        // Remove layer data polling if enabled
        if (this.layers[id].timer) {
            clearInterval(this.layers[id].timer);
            this.layers[id].timer = null;
        }

        // Remove layer from Mapbox
        this.mapboxMap.removeLayer(id);

        // Also remove custom source, that was auto-added
        this.mapboxMap.removeSource(id);

        // Unregister handlers
        this.mapboxMap.off("mouseenter", id, this.onFeatureEnterHandler);
        this.mapboxMap.off("mousemove", id, this.onFeatureMoveHandler);
        this.mapboxMap.off("mouseleave", id, this.onFeatureLeaveHandler);
        this.mapboxMap.off("mousedown", id, this.onFeatureMouseDownHandler);
        this.mapboxMap.off("click", id, this.onFeatureClickHandler);
        this.mapboxMap.off("dblclick", id, this.onFeatureDoubleClickHandler);

        Log.i(`Layer ${id} removed from ${this.id}`);
    }

    public async updateLayerData(layer: MapLayer): Promise<any> {
        // Load data
        layer.data = await layer.load();

        // No data?
        if (layer.data == null) {
            Log.w(`${layer.options.layer.id} returned empty data, check the load() function`);
            return;
        }

        // Update data in Mapbox layer
        this.setLayerData(layer, layer.data);

        Log.i(`Layer ${layer.options.layer.id} updated in ${this.id}`);
    }

    public setLayerData(layer: MapLayer, data: any): void {
        // Each layer has it own data source, we don't share it
        let source = <GeoJSONSource>this.mapboxMap?.getSource(layer.options.layer.id);

        // Source gone or not found?
        if (!source) {
            return;
        }

        // Set new data to Mapbox data soure and force Mapbox to redraw canvas and display new data
        source.setData(data);
    }

    public async reloadLayers(): Promise<void> {
        // For all layers
        for (let id in this.layers) {
            // Force reload
            await this.updateLayerData(this.layers[id]);
        }
    }

    public hasMarker(id: string): boolean {
        return this.markers[id] != null;
    }

    public addMarker(marker: MapMarker): void {
        // Already added?
        if (this.hasMarker(marker.id)) {
            Log.w(`Marker ${marker.id} already added in ${this.id}`);
            return;
        }

        // Add to evidence
        this.markers[marker.id] = marker;

        // Mapbox layer could be only added if map is loaded
        if (this.mapboxLoaded) {
            this.addMapboxMarker(marker);
        }
    }

    public removeMarker(id: string): void {
        // Not found?
        if (!this.hasMarker(id)) {
            Log.w(`Marker ${id} not found in ${this.id}`);
            return;
        }

        // Mapbox layer could be only removed if map is loaded
        if (this.mapboxLoaded) {
            this.removeMapboxMarker(id);
        }

        // Remove to evidence
        delete this.markers[id];
    }

    private addMapboxMarker(marker: MapMarker): void {
        // Attach marker
        marker.attach();

        // Create mapbox marker
        let mapboxMarker = new mapbox.Marker(marker.element, {
            offset: marker.options.offset ?? [0, 0],
            draggable: marker.options.draggable
        });

        Log.i(`Marker ${marker.id} added to ${this.id}`);

        // Assign Mapbox marker
        marker.mapboxMarker = mapboxMarker;

        // Set position
        mapboxMarker.setLngLat([marker.options.position[0], marker.options.position[1]]);

        // Selected state
        marker.element.addEventListener("click", () => {
            marker.click();
        });

        // Move marker
        mapboxMarker.on("dragend", () => {
            // Reassign marker options
            marker.options.position = mapboxMarker.getLngLat().toArray();

            // OnMove handler
            marker.onMove();
        });

        // Add to map
        mapboxMarker.addTo(this.mapboxMap);
    }

    private removeMapboxMarker(id: string): void {
        // Remove marker element
        this.markers[id].detach();

        // Remove Mapbox marker
        this.markers[id].mapboxMarker.remove();

        Log.i(`Marker ${id} removed from ${this.id}`);
    }
}
