import "./traffic-pentlogram-report.scss";
import * as template from "./traffic-pentlogram-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { TrafficPentlogramReportOptions } from "./types";
import { Form } from "../../../../muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { DEFAULT_LANE_CAPACITY } from "../traffic-counting-subdomain/traffic-counting-subdomain";
import { Helpers } from "../../../../hiyo/helpers";
import { Log } from "../../../../hiyo/log";
import { PanelChart, PanelProperties, PanelTable, PanelTableRow } from "../../common/panel/types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { Point } from "geojson";
import { ItemSingleCircleLayer } from "../../../layers/infrastructure/item-single-circle-layer";

export class TrafficPentlogramReport extends Panel<TrafficPentlogramReportOptions> {

    // Properties
    public layer: ItemSingleCircleLayer;
    public data: any[];
    public days: number;
    public hours: PanelChart;
    public table: PanelTable;
    public aadt: PanelProperties;

    // Components
    public form: Form;

    public constructor(context: InvipoContext, options?: TrafficPentlogramReportOptions) {
        super(context, template, options);

        // Set selected hour to 0
        this.options.hour = 0;
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createForm();
    }

    public onAttach(): void {
        // No item loaded
        if (!this.item) {
            return;
        }

        // Remove old layer?
        if (this.layer) {
            this.map.removeLayer(this.layer.options.layer.id)
        }

        // Add layer again
        this.map.addLayer(this.layer = new ItemSingleCircleLayer(this.context, this.options.itemId));

        // Center map
        this.map.flyTo({
            center: (<Point>this.item.geometry.location).coordinates,
            zoom: 17
        });

        // No data?
        if (!this.data?.length) {
            return;
        }

        // Rearrange pentlogram
        this.arrange();
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                            width: 320,
                            bright: true,
                            type: "Date"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            width: 320,
                            bright: true,
                            type: "Date"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.selectOne",
                            itemClass: "TrafficCounter,WimStation",
                            itemFilter: (item: InvipoItem) => {
                                return item.schema?.pentlogram;
                            },
                            items: [],
                            width: 672,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Reload data on form submit
        this.form.onSubmit = async () => {
            // Get flat data
            let data = this.form.getData(true);

            // We must assign itemId to options because the item is loaded in base class
            this.options.itemId = data.itemId;

            // Reload
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    public arrange(): void {
        // No schema?
        if (!this.item.schema?.pentlogram) {
            Log.w(`Missing item.schema.pentlogram definition for ${this.item.name}`);
            return;
        }

        // Show only mapped arms
        for (let arm of this.item.schema.pentlogram.arms) {
            // Direction title only if in direction is active
            if (arm.in) {
                this.query(`svg #${arm.name}-title`).style.display = "block";
                this.query(`svg #${arm.name}-title`).textContent = arm.label;
            }

            // Directions (in = enter intesection, out = leave intersection)
            let dirs = ["in", "out"];

            // For in and out direction
            for (let d of dirs) {
                // Total volume
                let total = 0;

                // Arms in
                if ((<any>arm)[d]) {
                    // Arrow and divier in
                    this.query(`svg #arrow-${arm.name}-${d}`).style.display = "block";
                    this.query(`svg #divider-${arm.name}-${d}`).style.display = "block";

                    for (let a of (<any>arm)[d]) {
                        // Find a segment and calculate capacity
                        let segment = (<any[]>this.item.meta?.segments)?.find(x => x.name == a.segment);
                        let capacity = (segment?.capacity || DEFAULT_LANE_CAPACITY) * (this.days > 1 ? 24 : 1);

                        // Volume
                        let volume = null;

                        // Hour data?
                        if (this.days == 1) {
                            volume = this.data.find(x => new Date(x.timestamp).getHours() == this.options.hour && x.segment == a.segment)?.count || 0;
                        }
                        // Day average data
                        else {
                            volume = (this.data.find(x => x.segment == a.segment)?.count || 0) / this.days;
                        }

                        // Increase total
                        total += volume;

                        // Visibility
                        this.query(`svg #${a.arm}`).style.display = "block";

                        // Width
                        this.query(`svg #${a.arm}`).style.strokeWidth = `${Helpers.lerp(1, 48, volume / capacity)}px`;
                        this.query(`svg #${a.arm}`).setAttribute("data-tooltip", `${a.arm} = ${Helpers.toNumber(volume)}`)

                        // Volumes
                        this.query(`svg #${a.arm}-${d}`).style.display = "block";
                        this.query(`svg #${a.arm}-${d}`).textContent = `${a.segment} = ${Helpers.toNumber(volume)}`;
                    }

                    // Direction volume
                    this.query(`svg #${arm.name}-${d}`).style.display = "block";
                    this.query(`svg #${arm.name}-${d}`).textContent = Helpers.toNumber(total);
                }
            }
        }
    }

    public async selectColumn(i: number): Promise<void> {
        // Set selected hour
        this.options.hour = i;

        // Reload
        await this.load();
    }

    public async extraLoad(): Promise<void> {
        // Reset data
        this.hours = null;
        this.table = null;
        this.aadt = null;

        // Get simplified form data
        let form = this.form.getData(true);

        // Item not selected?
        if (!form.itemId) {
            return;
        }

        // Get date objects and create from to interval
        let from = new Date(new Date(form.from).setHours(0, 0, 0, 0));
        let to = new Date(new Date(form.to ? form.to : form.from).setHours(24, 0, 0, 0));

        // Number of days
        this.days = (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24);

        // Hour data
        if (this.days == 1) {
            this.data = await this.context.invipo.getQuery("pentlogram-by-hour", `item.id=${form.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);
        }
        // Day data
        else {
            this.data = await this.context.invipo.getQuery("pentlogram-by-segment", `item.id=${form.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);
        }

        // AADT data
        let aadt = await this.context.invipo.getQuery("traffic-aadt", `item.id=${form.itemId}&year=${from.getFullYear()}`);

        // No data?
        if (!this.data?.length) {
            Log.w(`TrafficPentlogramReport: ${this.item.name} has no pentlogram data`)
            return;
        }

        // Hour mode?
        if (this.days == 1) {
            // Build hour chart
            this.hours = {
                type: "Bar",
                size: "Medium",
                label: "components.TrafficPentlogramReport.dayHours",
                selectable: true,
                series: []
            }

            // Add hourly data to chart series
            for (let h = 0; h < 24; h++) {
                // Find hour in data
                let d = this.data.filter(x => new Date(x.timestamp).getHours() == h);

                // Count vehicle intensity
                let volume = d.map(x => x.count).reduce((a, b) => {
                    return a + b
                }, 0);

                // Has data?
                if (d) {
                    this.hours.series.push(
                        [
                            {
                                timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                                valueY: volume,
                                valueX: h.toString().padStart(2, "0"),
                                percent: Helpers.range(0, 100, 0, DEFAULT_LANE_CAPACITY * 2, volume),
                                label: `${Helpers.toNumber(volume)} ${this.context.locale.getMessage("units.vehicles")}`,
                                color: this.options.hour == h ? "#d6c500" : "#00b4f5",
                                //color: (volume == morningPeak || volume == afternoonPeak) ? "#d6c500" : "#00b4f5",
                            }
                        ]
                    );
                }
                // No data
                else {
                    this.hours.series.push(
                        [
                            {
                                timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                                valueX: h.toString().padStart(2, "0")
                            }
                        ]
                    );
                }
            }
        }

        // Build categories table
        this.table = {
            name: "Table",
            label: "components.TrafficPentlogramReport.table",
            columns: [],
            rows: []
        };

        // Calcualte column width
        let width = Math.round(720 / (this.context.config.categories.length + 2) * 100) / 100;

        // Create lane column
        this.table.columns.push({
            style: "Label",
            label: "tables.columns.lane",
            width: `${width}px`
        });

        // Create category columns
        for (let c of this.context.config.categories) {
            this.table.columns.push({
                label: c.name,
                align: "Center",
                width: `${width}px`
            });
        }

        // Create count column
        this.table.columns.push({
            label: "tables.columns.total",
            style: "Bold",
            align: "Center",
            width: `${width}px`
        });

        // Add volume values
        for (let arm of this.item.schema.pentlogram.arms) {
            // Has in definition?
            if (!arm.in) {
                continue;
            }

            // Collect semgents and arms
            for (let a of arm.in) {
                // Table row
                let row: PanelTableRow = {
                    cells: []
                };

                // Put arm name
                row.cells.push(a.segment);

                // volume
                let volume = null;

                // Hour data
                if (this.days == 1) {
                    volume = this.data.find(x => new Date(x.timestamp).getHours() == this.options.hour && x.segment == a.segment);
                }
                // Day data
                else {
                    volume = this.data.find(x => x.segment == a.segment);
                }

                // No data error
                if (!volume) {
                    Log.w(`Pentlogram has no volume data for segment ${a.segment} (hour=${this.options.hour})`);
                }

                // Get categories count
                for (let c of this.context.config.categories) {
                    row.cells.push(Helpers.toNumber(((<any[]>volume?.categories)?.find(x => x.id == c.id)?.count || 0) / this.days));
                }

                // Add total count
                row.cells.push(Helpers.toNumber((volume?.count || 0) / this.days));

                // Add table row
                this.table.rows.push(row);
            }
        }

        // Sort rows by lane
        this.table.rows.sort((a, b) => {
            return a.cells[0].localeCompare(b.cells[0]);
        });

        // Build AADT stats
        this.aadt = {
            size: "Third",
            label: "RPDI",
            note: `Roční průměr denních intenzit (RPDI) je vypočten z referenčního období od ${Helpers.toDateString(aadt[0]?.interval?.from)} do ${Helpers.toDateString(aadt[0]?.interval?.to)}`,
            data: [
                {
                    label: "Všechny dny",
                    value: Helpers.toNumber(aadt[0]?.total.count)
                },
                {
                    label: "Pracovní dny (po-pá)",
                    value: Helpers.toNumber(aadt[0]?.workdays.count)
                },
                {
                    label: "Volné dny (mimo svátky)",
                    value: Helpers.toNumber(aadt[0]?.weekdays.count)
                }
            ]
        }
    }
}
