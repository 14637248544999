import "./fleet-dashboard.scss";
import * as template from "./fleet-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Component } from "hiyo/component";
import { FleetDashboardOptions } from "./types";
import { FleetDistanceTile } from "../fleet-distance-tile/fleet-distance-tile";
import { FleetMovingCountTile } from "../fleet-moving-count-tile/fleet-moving-count-tile";
import { FleetAssetStatusTile } from "../fleet-asset-status-tile/fleet-asset-status-tile";
import { FleetPreviousDayTile } from "../fleet-previous-day-tile/fleet-previous-day-tile";
import { FleetSystemTile } from "../fleet-system-tile/fleet-system-tile";

export class FleetDashboard extends Component<InvipoContext, FleetDashboardOptions> {

    // Components
    public previousDay: FleetPreviousDayTile;
    public distance: FleetDistanceTile;
    public movingCount: FleetMovingCountTile;
    public assetStatus: FleetAssetStatusTile;
    public system: FleetSystemTile;

    constructor(context: InvipoContext, options?: FleetDashboardOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTiles();
    }

    public onAttach() {
        // Attach previous day tile
        this.previousDay.attach(this.query("div.column-trips"));

        // Attach distance tile
        this.distance.attach(this.query("div.column-trips"));

        // Attach device moving count tile
        this.movingCount.attach(this.query("div.column-trips"));

        // Attach asset status tile
        this.assetStatus.attach(this.query("div.column-overview"));

        // Attach system tile
        this.system.attach(this.query("div.column-system"));
    }

    public onDetach() {
        // Detach previous day tile
        this.previousDay.detach();

        // Detach distance tile
        this.distance.detach();

        // Detach device moving count tile
        this.movingCount.detach();

        // Detach asset status tile
        this.assetStatus.detach();

        // Detach system tile
        this.system.detach();
    }

    private createTiles(): void {
        // Create previous day tile
        this.previousDay = new FleetPreviousDayTile(this.context, {});

        // Create distance tile
        this.distance = new FleetDistanceTile(this.context, {});

        // Create device moving count tile
        this.movingCount = new FleetMovingCountTile(this.context, {});

        // Create asset status tile
        this.assetStatus = new FleetAssetStatusTile(this.context, {});

        // Create system tile
        this.system = new FleetSystemTile(this.context, {});
    }

    // public async load(): Promise<void> {
    // const dashboard = await this.context.gpsCockpit.getDashboard();
    // console.log("Dashboard", dashboard);
    // }

}
