import "./traffic-daily-volume-report.scss";
import * as template from "./traffic-daily-volume-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficDailyVolumeReportOptions } from "./types";
import { Form } from "../../../../muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { Select } from "../../../../muklit/components/select/select";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelChartData, PanelKpis, PanelProperties, PanelTable, PanelTableRow } from "../../common/panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { Log } from "../../../../hiyo/log";
import { InvipoHelpers } from "../../../invipo-helpers";

export class TrafficDailyVolumeReport extends Panel<TrafficDailyVolumeReportOptions> {

    // Properties
    public hours: PanelChart;
    public properties: PanelProperties;
    public kpis: PanelKpis;
    public categories: PanelChart;
    public table: PanelTable;

    // Components
    public form: Form;

    public constructor(context: InvipoContext, options?: TrafficDailyVolumeReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    private createForm(): void {
        // Items select
        let item = new ItemSelect(this.context, {
            style: "Light",
            name: "itemId",
            label: "forms.fields.item",
            value: this.options.itemId,
            placeholderText: "forms.placeholders.all",
            itemClass: "TrafficCounter,WimStation",
            items: [],
            width: 320,
            bright: true,
            multiselect: true
        });

        // Segment select
        let segment = new Select(this.context, {
            style: "Light",
            name: "segment",
            label: "forms.fields.segment",
            //value: this.options.segment,
            placeholderText: "forms.placeholders.all",
            items: [],
            width: 320,
            bright: true,
            multiselect: true
        });

        // From select
        let timestamp = new DateInput(this.context, {
            style: "Light",
            name: "timestamp",
            label: "forms.fields.timestamp",
            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
            width: 320,
            bright: true,
            type: "Date"
        });

        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        item,
                        timestamp,
                        segment
                    ]
                }
            ]
        });

        // Disable classes if items selected
        item.onSubmit = async () => {
            // Form data
            let data = this.form.getData();

            // Reset segments
            segment.options.items = [];

            // Find all selected items
            for (let i of item.options.items.filter(x => x.selected)) {
                // Find item definition
                let found = item.items.find(x => x.name == i.label);

                // Add all segments
                for (let s of found.meta?.segments || []) {
                    segment.options.items.push({
                        name: s.name,
                        label: s.name
                    })
                }
            }

            // We must invalidate select to display new items
            segment.invalidate();

            // OnSubmit handler brakes form onSubmit so we must reload manualy
            await this.load();
        }

        // Reload data on form submit
        this.form.onSubmit = async () => {
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Query string
        let query = "";

        if (form.itemId) {
            query += `&item.id=${form.itemId}`;
        }
        if (form.segment) {
            query += `&segment=${encodeURIComponent(form.segment)}`;
        }

        // Interval
        let from = new Date(new Date(form.timestamp).setHours(0, 0, 0, 0));
        let to = new Date(new Date(form.timestamp).setHours(24, 0, 0, 0));

        // Traffic data per hour
        let traffic = await this.context.invipo.getQuery("traffic-by-hour", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build hours chart
        this.hours = {
            type: "Bar",
            size: "Tall",
            name: "Volume",
            label: "components.TrafficDailyVolumeReport.volumeHours",
            series: []
        }

        // Get max count to adjust chart optimal height
        let maxVolume = Math.max(...traffic.map(x => x.count));
        let morningPeak = Math.max(...traffic.filter(x => new Date(x.timestamp).getHours() < 12).map(x => x.count));
        let afternoonPeak = Math.max(...traffic.filter(x => new Date(x.timestamp).getHours() >= 12).map(x => x.count));

        // Add hours chart data
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = traffic.find(x => new Date(x.timestamp).getHours() == h);

            // Has data?
            if (d) {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: d.count,
                            valueX: h.toString().padStart(2, "0"),
                            percent: Helpers.range(0, 100, 0, maxVolume * 1.1, d.count),
                            label: `${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.vehicles")}`,
                            color: (d.count == morningPeak || d.count == afternoonPeak) ? "#d6c500" : "#00b4f5"
                        }
                    ]
                );
            }
            // No data
            else {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }

        // Add hours legend
        this.hours.legend = [
            {
                label: "components.TrafficDailyVolumeReport.volume",
                color: "#00b4f5"
            },
            {
                label: "components.TrafficDailyVolumeReport.peak",
                color: "#d6c500"
            }
        ];

        // Volume KPIs
        this.kpis = {
            size: "Third",
            data: [
                {
                    label: "components.TrafficDailyVolumeReport.total",
                    value: `${Helpers.toNumber(traffic.map(x => x.count).reduce((a, b) => { return a + b }, 0))} ${this.context.locale.getMessage(`units.vehicles`)}`,
                    description: Helpers.toDateString(from)
                },
                {
                    label: "components.TrafficDailyVolumeReport.morningPeak",
                    value: Number.isInteger(morningPeak) ? `${Helpers.toNumber(morningPeak)} ${this.context.locale.getMessage(`units.vehicles`)}` : this.context.locale.getMessage("common.noData"),
                    description: Helpers.toShortTimeString(traffic.find(x => x.count == morningPeak)?.timestamp)
                },
                {
                    label: "components.TrafficDailyVolumeReport.afternoonPeak",
                    value: Number.isInteger(afternoonPeak) ? `${Helpers.toNumber(afternoonPeak)} ${this.context.locale.getMessage(`units.vehicles`)}` : this.context.locale.getMessage("common.noData"),
                    description: Helpers.toShortTimeString(traffic.find(x => x.count == afternoonPeak)?.timestamp)
                }
            ]
        }

        // Build categories chart
        this.categories = {
            type: "Bar",
            size: "Tall",
            //name: "Category",
            //label: "components.TrafficDailyVolumeReport.categoriesHours",
            series: [],
            legend: []
        }

        // Add categories chart data
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = traffic.find(x => new Date(x.timestamp).getHours() == h);

            // Has data?
            if (d) {
                // Define series
                let series: PanelChartData[] = [];

                // Get series for all categories
                for (let c of d.categories) {
                    // Find category defintion
                    let category = this.context.config.categories.find(x => x.id == c.id);

                    // Push series data
                    series.push({
                        timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                        valueX: h.toString().padStart(2, "0"),
                        percent: c.count / d.count * 100,
                        label: `${c.id} ${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.vehicles")}`,
                        color: category.color
                    });
                }

                // Add all series
                this.categories.series.push(series);
            }
            // No data
            else {
                this.categories.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }

        // Add categories legend
        for (let category of this.context.config.categories) {
            this.categories.legend.push({
                label: category.name,
                color: category.color
            });
        }

        // Build categories table
        this.table = {
            name: "Table",
            label: "components.TrafficPentlogramReport.table",
            columns: [],
            rows: []
        };

        // Calcualte column width
        let width = Math.min(76, Math.round(720 / (this.context.config.categories.length + 2) * 100) / 100);

        // Create lane column
        this.table.columns.push({
            style: "Label",
            label: "tables.columns.hour",
            width: `99%`
        });

        // Create category columns
        for (let c of this.context.config.categories) {
            this.table.columns.push({
                label: c.name,
                align: "Center",
                width: `${width}px`
            });
        }

        // Create count column
        this.table.columns.push({
            label: "tables.columns.total",
            style: "Bold",
            align: "Center",
            width: `${width}px`
        });

        // Add volume values
        for (let h = 0; h < 24; h++) {
            // Table row
            let row: PanelTableRow = {
                cells: []
            };

            // Put arm name
            row.cells.push(`${h.toString().padStart(2, "0")}:00`);

            // volume
            let volume = null;

            // Hour data
            volume = traffic.find(x => new Date(x.timestamp).getHours() == h);

            // Get categories count
            for (let c of this.context.config.categories) {
                row.cells.push(Helpers.toNumber((<any[]>volume?.categories)?.find(x => x.id == c.id)?.count));
            }

            // Add total count
            row.cells.push(Helpers.toNumber(volume?.count));

            // Add table row
            this.table.rows.push(row);
        }

    }
}
