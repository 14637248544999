import "./docker-log-detail.scss";
import * as template from "./docker-log-detail.hbs";
import { Detail } from "muklit/components/detail/detail";
import { InvipoContext } from "../../../context/invipo-context";
import { DockerLogDetailOptions } from "./types";
import Convert = require("ansi-to-html");
import { Dom } from "../../../../hiyo/dom";

export class DockerLogDetail extends Detail<InvipoContext, DockerLogDetailOptions> {

    // properties
    public last: Date;

    constructor(context: InvipoContext, options: DockerLogDetailOptions) {
        super(context, template, options);
    }

    public onAttach() {
        // Scroll to bottom of log content
        let content = this.query("div.content");
        content.scrollTop = content.scrollHeight - content.clientHeight;
    }

    public setLogs(logs: string[]): void {
        // Timestamp of last line
        let timestamp = logs[logs.length - 1].substring(0, 30);

        // No new lines?
        if (this.last && this.last.getTime() >= new Date(timestamp).getTime()) {
            return;
        }

        // Build HTML
        let html = "";
        for (let l of logs) {
            // Timestamp and message to be displayed in different colors
            let timestamp = l.substring(0, 30);
            let message = l.substring(31);

            // Add only new lines
            if (!this.last || new Date(timestamp).getTime() > this.last.getTime()) {
                html += `<div class="line"><span class="timestamp">${timestamp}</span> <span class="message">${new Convert().toHtml(message)}</span></div>`;
            }
        }

        // content element displaying log lines
        let content = this.query("div.content");
        let autoscroll = (content.scrollTop >= content.scrollHeight - content.clientHeight);

        // Adds html to content
        Dom.createElement(content, html);
        //content.innerHTML += html;

        // If autoscroll we scroll to very down
        if (autoscroll) {
            content.scrollTop = content.scrollHeight - content.clientHeight;
        }

        // Assign last
        this.last = new Date(timestamp);
    }

    public async load(): Promise<void> {
        // Load all data
        let logs = await this.context.invipo.getContainerLogs(this.options.containerId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Redraw with all components
        this.setLogs(logs);

        // Auto reload
        setTimeout(async () => {
            await this.load();
        }, 400)
    }

}
