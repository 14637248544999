import "./traffic-volume-diagram.scss";
import * as template from "./traffic-volume-diagram.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { TrafficVolumeDiagramOptions } from "./types";
import { Form } from "../../../../muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { DatasetResult } from "../../../../muklit/components/query-table/types";
import { DEFAULT_LANE_CAPACITY } from "../traffic-counting-subdomain/traffic-counting-subdomain";
import { Helpers } from "../../../../hiyo/helpers";
import { Log } from "../../../../hiyo/log";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { Filter } from "../../../../muklit/components/filter/filter";
import { FilterTag } from "../../../../muklit/components/filter/types";

export class TrafficVolumeDiagram extends Panel<TrafficVolumeDiagramOptions> {

    // Properties
    public items: InvipoItem[];
    public traffic: DatasetResult;

    // Components
    public form: Form;
    public filter: Filter;

    public constructor(context: InvipoContext, options?: TrafficVolumeDiagramOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createFilter();
    }

    public onAttach(): void {
        // No data yet loaded
        if (!this.item || !this.traffic) {
            return;
        }

        // Rearrange pentlogram
        this.arrange();
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            itemClass: "TrafficCounter,WimStation",
                            items: [],
                            width: 320,
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "timestamp",
                            label: "forms.fields.timestamp",
                            value: new Date(new Date().setMinutes(-60, 0, 0)).toISOString(),
                            width: 320,
                            bright: true,
                            type: "Date"
                        })
                    ]
                }
            ]
        });

        // Reload data on form submit
        this.form.onSubmit = async () => {
            // Get flat data
            let data = this.form.getData(true);

            // We must assign itemId to options because the item is loaded in base class
            this.options.itemId = data.itemId;

            // Reload
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    private createFilter(): void {
        // Default notification form
        this.filter = new Filter(this.context, {
            style: "Light",
            title: "components.TrafficVolumeDiagram.title",
            items: [
                {
                    name: "Print",
                    label: "labels.print"
                }
            ],
            tags: [
                {
                    name: "item",
                    label: "Všechny itemy"
                },
                {
                    name: "timestamp",
                    label: "2.6.2023"
                }
            ]
        });

        // Reload data on form submit
        this.filter.onTabSelect = async (tag: FilterTag) => {
            console.info(tag);
        }

        // Register component
        this.registerComponent(this.filter, "filter");
    }

    public arrange(): void {
        // No schema?
        if (!this.item.schema?.pentlogram) {
            Log.w(`Missing item.schema.pentlogram definition for ${this.item.name}`);
            return;
        }

        // Show only mapped arms
        for (let arm of this.item.schema.pentlogram.arms) {
            // Direction title only if in direction is active
            if (arm.in) {
                this.query(`svg #${arm.name}-title`).style.display = "block";
                this.query(`svg #${arm.name}-title`).textContent = arm.label;
            }

            // Directions
            let dirs = ["in", "out"];

            // For in and out direction
            for (let d of dirs) {
                // Total volume
                let total = 0;

                // Arms in
                if ((<any>arm)[d]) {
                    // Arrow and divier in
                    this.query(`svg #arrow-${arm.name}-${d}`).style.display = "block";
                    this.query(`svg #divider-${arm.name}-${d}`).style.display = "block";

                    for (let a of (<any>arm)[d]) {
                        // Find a segment and calculate capacity
                        let segment = (<any[]>this.item.meta?.segments)?.find(x => x.name == a.segment);
                        let capacity = segment?.capacity || DEFAULT_LANE_CAPACITY;
                        let volume = this.traffic.data.filter(x => x.segment == a.segment).map(x => x.count).reduce((a, b) => { return a + b}, 0);
                        //console.info(`${d}: ${a.arm}: ${volume / capacity}`);

                        // Increase total
                        total += volume;

                        // Visibility
                        this.query(`svg #${a.arm}`).style.display = "block";

                        // Width
                        this.query(`svg #${a.arm}`).style.strokeWidth = `${Helpers.lerp(1, 48, volume / capacity)}px`;

                        // Volumes
                        this.query(`svg #${a.arm}-${d}`).style.display = "block";
                        this.query(`svg #${a.arm}-${d}`).textContent = Helpers.toNumber(volume);
                    }

                    // Direction volume
                    this.query(`svg #${arm.name}-${d}`).style.display = "block";
                    this.query(`svg #${arm.name}-${d}`).textContent = Helpers.toNumber(total);
                }

            }
        }
    }

    public selectItem(i: number): void {
        // Set item to options
        this.options.itemId = this.items[i].id;

        // Set item and date to form
        this.form.setData({
            "itemId": this.options.itemId
        })

        // Null items
        this.items = null;

        // Reload
        this.invalidate();
    }

    public async extraLoad(): Promise<void> {
        // Items screen?
        if (!this.options.itemId) {
            // Load all items to choose from
            this.items = await this.context.invipo.getItems("class=TrafficCounter,WimStation&sort=name:asc");

            // And filter items with pentlogram only
            this.items = this.items.filter(x => x.schema?.pentlogram);
        }
        // Diagram screen?
        else {
            // Get simplified form data
            let form = this.form.getData(true);

            // Get timestamp, shift to one minute before and after
            let from = new Date(new Date(form.timestamp).setMinutes(0, 0, 0));
            let to = new Date(new Date(from).getTime() + 60 * 60 * 1000);

            // Load data
            this.traffic = await this.context.invipo.getDataset("traffic-data", `item.id=${form.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);
        }
    }
}
