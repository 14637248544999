import "./violation-detail.scss";
import * as template from "./violation-detail.hbs";
import { Actor, Ticket, User } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "../../../../muklit/components/tabs/types";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { Toggle } from "../../../../muklit/components/toggle/toggle";
import { ViolationDetailOptions } from "./types";
import { OverflowMenu } from "../../../../muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { AccessDetail } from "../../safety/access-detail/access-detail";
import { NotificationForm } from "../../settings/notification-form/notification-form";
import { ViolationForm } from "../violation-form/violation-form";
import { ViolationReport } from "../violation-report/violation-report";
import { ViolationCommentForm } from "../violation-comment-form/violation-comment-form";

export class ViolationDetail extends Detail<InvipoContext, ViolationDetailOptions> {

    // Properties
    public violation: any;
    public access: AccessDetail;

    // Components
    public tabs: Tabs;
    public completed: Toggle;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: ViolationDetailOptions) {
        super(context, template, options);
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.access?.isAttached()) {
            this.access.detach();
        }
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createDisabled();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.completed, "completed");
    }

    private createTabs(): void {
        // Tabs
        let tabs: TabsItem[] = [
            {
                name: "Violation",
                label: "components.ViolationDetail.violation",
                content: "div.content-violation",
                selected: true
            },
            {
                name: "Data",
                label: "components.ViolationDetail.data",
                content: "div.content-data"
            },
            {
                name: "Stream",
                label: "components.ViolationDetail.stream",
                content: "div.content-stream"
            }
        ];

        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: tabs
        });
    }

    private createDisabled(): void {
        // Crate component
        this.completed = new Toggle(this.context, {
            style: "Light",
            size: "Small",
            name: "disabled",
            label: "components.ViolationDetail.completed"
        })

        // Disable user
        this.completed.onToggle = async (disabled: boolean) => {
            await this.complete();
        }
    }

    public selectColumn(e: MouseEvent): void {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "TopRight",
            anchor: "TopRight",
            items: []
        });

        // Push columns as items
        for (let column of this.context.config.tickets.columns) {
            menu.options.items.push(
                {
                    name: column,
                    label: column,
                    disabled: this.violation.workflow.column == column
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Show loader
            this.showLoader();

            // Update column
            await this.context.invipo.putManagement(`violations/${this.violation.id}/workflow`, {
                workflow: {
                    ...this.violation.workflow,
                    column: item.label
                }
            });

            // Hide loader and redraw
            this.hideLoader();
            await this.load();

            // OnUpdate handler
            this.onDetailUpdate();
        }

        // Show menu
        menu.show(<HTMLElement>e.target);
    }

    public async selectAssignee(e: MouseEvent): Promise<void> {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "TopRight",
            anchor: "TopRight",
            items: []
        });

        //
        let users = await this.context.invipo.getDataset("users");

        // Push languages as items
        for (let user of users.data) {
            menu.options.items.push(
                {
                    name: user.id,
                    label: user.name,
                    disabled: this.violation.workflow.assignee?.id == user.id
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Show loader
            this.showLoader();

            // Update column
            await this.context.invipo.putManagement(`violations/${this.violation.id}/workflow`, {
                workflow: {
                    ...this.violation.workflow,
                    assignee: item.name
                }
            });

            // Hide loader and redraw
            this.hideLoader();
            await this.load();

            // OnUpdate handler
            this.onDetailUpdate();
        }

        // Show menu
        menu.show(<HTMLElement>e.target);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async complete(): Promise<void> {
        // Show loader
        this.showLoader();

        // Complete call
        await this.context.invipo.putManagement(`violations/${this.violation.id}/workflow`, {
            workflow: {
                ...this.violation.workflow,
                completed: !this.violation.workflow?.completed
            }
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public async archive(): Promise<void> {
        // Show loader
        this.showLoader();

        // Archive call
        await this.context.invipo.putManagement(`violations/${this.violation.id}/workflow`, {
            workflow: {
                ...this.violation.workflow,
                archived: true
            }
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.ViolationDetail.userDelete",
            text: this.context.locale.getMessage("components.ViolationDetail.reallyDelete"),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteManagement(`violations/${this.violation.id}`);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    protected openAccess(i: number): void {
        // Hide self
        this.detach();

        // Access data
        let data = this.violation.data.access[i];

        // New detail
        this.access = new AccessDetail(this.context, {
            style: "Light",
            access: data,
            title: "components.AccessDetail.title",
            subtitle: data.item?.name || data.area?.name,
            printable: false,
            returnable: true
        });

        // Show self on close
        this.access.onClose = () => {
            this.attach();
        }

        // Open image detail
        this.access.onImageSelect = (image: any) => {
            this.openImage(image);
        }

        // Show detail
        this.access.attach();
    }

    public openImage(image: any): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: null,
            url: `${this.context.options.host}/download${image.url}`,
            closable: true,
            overlay: true
        });

        // Shoe
        detail.attach();
    }

    public openForm(): void {
        // Details form
        let form = new ViolationForm(this.context, {
            style: "Light",
            title: "components.ViolationForm.updateViolation",
            violation: this.violation,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public openReport(): void {
        let form = new ViolationReport(this.context, {
            style: "Light",
            title: "components.ViolationReport.title",
            violation: this.violation,
            overlay: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public comment(): void {
        // Dialog to confirm
        let form = new ViolationCommentForm(this.context, {
            style: "Light",
            title: "components.TicketCommentForm.title",
            violation: this.violation,
            overlay: true,
            closable: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.violation = await this.context.invipo.getManagement(`violations/${this.options.violationId}`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);

        // Manage child components
        this.completed.setValue(this.violation.workflow?.completed);
    }

}
