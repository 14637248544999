import "./parking-lot-card.scss";
import * as template from "./parking-lot-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ParkingLotCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { CardHistoryValue } from "../../city/city-card/types";
import { METRICS } from "../../city/city-subdomain/types";

export class ParkingLotCard extends CityCard<ParkingLotCardOptions> {

    // Properties
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: ParkingLotCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery("parking-occupancy-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());
            let symbol = "Unknown";

            // Has data?
            if (d?.occupancy) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(d.occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(d.occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }
            }

            let color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];

            // Add history line
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.occupancy,
                    percent: d.occupancy,
                    color: color
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }

}
