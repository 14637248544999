import { ContentView } from "../content-view";
import { MonitoringMap } from "../components/monitoring/monitoring-map/monitoring-map";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";
import { ConnectorBrowser } from "../components/monitoring/connector-browser/connector-browser";

export class Monitoring extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu);

        // Set default content
        this.setContent("MonitoringMap");
    }

    private createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Dark",
            groups: [
                {
                    name: "Maps",
                    //title: "components.DrawerMenu.maps",
                    items: [
                        {
                            name: "MonitoringMap",
                            label: "components.MonitoringMap.title",
                            selected: true
                        },
                        {
                            name: "ItemBrowser",
                            label: "components.ItemBrowser.title"
                        },
                        {
                            name: "ConnectorBrowser",
                            label: "components.ConnectorBrowser.title"
                        },
                        {
                            name: "EventBrowser",
                            label: "components.EventBrowser.title"
                        },
                        {
                            name: "RawDataBrowser",
                            label: "components.RawDataBrowser.title"
                        },
                        {
                            name: "RegisteredDataBrowser",
                            label: "components.RegisteredDataBrowser.title"
                        },
                        {
                            name: "LogBrowser",
                            label: "components.LogBrowser.title"
                        },
                        {
                            name: "DockerBrowser",
                            label: "components.DockerBrowser.title",
                            disabled: !this.context.user.boss && !this.context.user.permissions?.includes("Administrator")
                        }
                    ]
                }
            ]
        })

        // Change content component
        this.menu.onSelect = (item: DrawerItem) => {
            this.setContent(item.name);
        }
    }
}
