import { Chart, ChartTooltipModel } from "chart.js";
import { Helpers } from "hiyo/helpers";
import { DataTable } from "muklit/components/data-table/data-table";
import { InvipoHelpers } from "../../../invipo-helpers";
import { NetworkConnectionsChartOptions } from "./types";
import { DEFAULT_FONT, StatsChart } from "../../common/stats-chart/stats-chart";
import { ElementTooltip } from "../../common/element-tooltip/element-tooltip";

export class NetworkConnectionsChart extends StatsChart<NetworkConnectionsChartOptions> {

    public createLegend() {
        // Create legend
        this.legend = [
            {
                type: "Line",
                label: "tables.columns.connections",
                color: InvipoHelpers.toChartColor(1)
            }
        ];
    }

    public createHighlights(): void {
        // Reset
        this.highlights = {};

        // Count values
        let total = 0;
        for (let data of this.data) {
            total += data.connections;
        }

        // First and last
        let first = this.data[0];
        let last = this.data[this.data.length - 1];

        // Minimum and maximum
        let min = { ...first };
        let max = { ...first };

        for (let data of this.data) {
            if (data.connections < min.connections) {
                min = data;
            }
            if (data.connections > max.connections) {
                max = data;
            }
        }

        // Selected group
        let group = this.options.groups.find(x => x.selected);

        switch (group.name) {
            case "Month":
                // Minimum
                this.highlights.lowest = {
                    label: "components.StatsChart.highlights.lowest",
                    value: Helpers.toNumber(min.connections),
                    description: `${Helpers.toMonthString(new Date(new Date().setMonth(min.month - 1)))}`
                }

                // Maximum
                this.highlights.highest = {
                    label: "components.StatsChart.highlights.highest",
                    value: Helpers.toNumber(max.connections),
                    description: `${Helpers.toMonthString(new Date(new Date().setMonth(max.month - 1)))}`
                }
                break;
            case "Week":
                // Minimum
                this.highlights.lowest = {
                    label: "components.StatsChart.highlights.lowest",
                    value: Helpers.toNumber(min.connections),
                    description: `Week ${min.week}`
                }

                // Maximum
                this.highlights.highest = {
                    label: "components.StatsChart.highlights.highest",
                    value: Helpers.toNumber(max.connections),
                    description: `Week ${max.week}`
                }
                break;
            case "DayOfWeek":
                // Minimum
                this.highlights.lowest = {
                    label: "components.StatsChart.highlights.lowest",
                    value: Helpers.toNumber(min.connections),
                    description: `${Helpers.toWeekDayString(new Date(2020, 5, min.day))}`
                }

                // Maximum
                this.highlights.highest = {
                    label: "components.StatsChart.highlights.highest",
                    value: Helpers.toNumber(max.connections),
                    description: `${Helpers.toWeekDayString(new Date(2020, 5, max.day))}`
                }
                break;
            case "DayOfYear":
                // Total count
                this.highlights.total = {
                    label: "components.StatsChart.highlights.total",
                    value: Helpers.toNumber(total),
                    description: `${Helpers.toDateString(first.timestamp)} &ndash; ${Helpers.toDateString(last.timestamp)}`
                }

                // Average
                this.highlights.average = {
                    label: "components.StatsChart.highlights.average",
                    value: Helpers.toNumber(total / this.data.length),
                    description: `${Helpers.toDateString(first.timestamp)} &ndash; ${Helpers.toDateString(last.timestamp)}`
                }

                // Minimum
                this.highlights.lowest = {
                    label: "components.StatsChart.highlights.lowest",
                    value: Helpers.toNumber(min.connections),
                    description: `${Helpers.toDateString(min.timestamp)}`
                }

                // Maximum
                this.highlights.highest = {
                    label: "components.StatsChart.highlights.highest",
                    value: Helpers.toNumber(max.connections),
                    description: `${Helpers.toDateString(max.timestamp)}`
                }
                break;
            case "Hour":
                // Minimum
                this.highlights.lowest = {
                    label: "components.StatsChart.highlights.lowest",
                    value: Helpers.toNumber(min.connections),
                    description: `${min.hour}:00`
                }

                // Maximum
                this.highlights.highest = {
                    label: "components.StatsChart.highlights.highest",
                    value: Helpers.toNumber(max.connections),
                    description: `${max.hour}:00`
                }
                break;
        }
    }

    public drawChart(): void {
        // Canvas to render to
        let canvas = (this.query<HTMLCanvasElement>("div.chart canvas"));

        // Labels and data
        let labels: any[] = [];
        let connectionsData: number[] = [];


        // Selected group
        let group = this.options.groups.find(x => x.selected);

        for (let d of this.data) {
            // Add label
            switch (group.name) {
                case "Month":
                    labels.push(d.month);
                    break;
                case "Week":
                    labels.push(d.week);
                    break;
                case "DayOfWeek":
                    labels.push(d.day);
                    break;
                case "DayOfYear":
                    labels.push(d.timestamp);
                    break;
                case "Hour":
                    labels.push(d.hour);
                    break;
            }

            // Add connections
            connectionsData.push(d.connections)
        }

        // Vehicles count graph
        this.chart = new Chart(canvas,
            {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "tables.columns.connections",
                            backgroundColor: InvipoHelpers.toChartColor(1),
                            hoverBackgroundColor: InvipoHelpers.toChartColor(1),
                            data: connectionsData
                        }
                    ]
                },
                options: {
                    //...DEFAULT_ANIMATION,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    animation: {
                        duration: 0
                    },
                    tooltips: {
                        enabled: false,
                        intersect: false,
                        custom: (model: ChartTooltipModel) => {
                            // Hide tooltip if visible
                            this.tooltip?.hide();

                            // Tooltip value exists?
                            if (model.dataPoints) {
                                let data = model.dataPoints[0];

                                // New tooltip
                                this.tooltip = new ElementTooltip(this.context, {
                                    text: `${Helpers.toNumber(data.value)}`
                                });

                                // Position tooltip;
                                let element = this.query("div.chart canvas");
                                let box = element.getBoundingClientRect();
                                this.tooltip.show(model.caretX + box.x, model.caretY + box.y, 100);
                            }
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                type: "category",
                                stacked: true,
                                ticks: {
                                    ...DEFAULT_FONT,
                                    padding: 10,
                                    min: this.options.search?.from ? Object.keys(this.options.search.from)[0] : null,
                                    max: this.options.search?.to ? Object.keys(this.options.search.to)[0] : null,
                                    callback: (value: any): string | number => {
                                        switch (group.name) {
                                            case "Week":
                                                return `Week ${value}`;
                                            case "Month":
                                                return `${Helpers.toMonthString(new Date(new Date().setMonth(value - 1)))}`;
                                            case "DayOfYear":
                                                return `${Helpers.toShortDateString(value)}`;
                                            case "DayOfWeek": {
                                                return `${Helpers.toWeekDayString(new Date(2020, 5, value))}`;
                                            }
                                            case "Hour": {
                                                return `${value}:00`;
                                            }
                                        }
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            },
                        ],
                        yAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("tables.columns.connections")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    //stepSize: Helpers.calculateStepSize(Math.max(...data), 4),
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    drawBorder: false,
                                    color: "rgba(141, 141, 141, 0.1)",
                                    zeroLineColor: "rgba(141, 141, 141, 0.1)",
                                    drawOnChartArea: true
                                }
                            }
                        ]
                    }
                }
            });
    }

    public createTable(): void {
        // Selected group
        let group = this.options.groups.find(x => x.selected);

        if (group.name == "Hour") {
            this.table = new DataTable(this.context, {
                style: "Light",
                type: "Unselectable",
                size: "Short",
                autosort: true,
                data: this.data,
                rows: {
                    id: "hour"
                },
                columns: [
                    {
                        name: "hour",
                        type: "Number",
                        property: "hour",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell">${value}:00</div>`
                        },
                        label: "tables.columns.hour",
                        width: 90,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "connections",
                        type: "Number",
                        property: "connections",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return null;
                            }
                            return `<div class="cell">${Helpers.toNumber(value)}</div>`
                        },
                        label: "tables.columns.connections",
                        sortable: true
                    }
                ]
            });
        }
        else if (group.name == "DayOfYear") {
            this.table = new DataTable(this.context, {
                style: "Light",
                type: "Unselectable",
                size: "Short",
                height: "100%",
                autosort: true,
                data: this.data,
                rows: {
                    id: "timestamp"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "Date",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "connections",
                        type: "Number",
                        property: "connections",
                        label: "tables.columns.connections",
                        sortable: true
                    }
                ]
            });
        }
        else if (group.name == "DayOfWeek") {
            this.table = new DataTable(this.context, {
                style: "Light",
                type: "Unselectable",
                size: "Short",
                autosort: true,
                data: this.data,
                rows: {
                    id: "day"
                },
                columns: [
                    {
                        name: "day",
                        type: "Number",
                        property: "day",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell">${Helpers.toWeekDayString(new Date(2020, 5, value))}</div>`
                        },
                        label: "tables.columns.day",
                        width: 90,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "connections",
                        type: "Number",
                        property: "connections",
                        label: "tables.columns.connections",
                        sortable: true
                    }
                ]
            });
        }
        else if (group.name == "Week") {
            this.table = new DataTable(this.context, {
                style: "Light",
                type: "Unselectable",
                size: "Short",
                autosort: true,
                data: this.data,
                rows: {
                    id: "week"
                },
                columns: [
                    {
                        name: "week",
                        type: "Number",
                        property: "week",
                        label: "tables.columns.week",
                        width: 90,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "connections",
                        type: "Number",
                        property: "connections",
                        label: "tables.columns.connections",
                        sortable: true
                    }
                ]
            });
        }
        else if (group.name == "Month") {
            this.table = new DataTable(this.context, {
                style: "Light",
                type: "Unselectable",
                size: "Short",
                autosort: true,
                data: this.data,
                rows: {
                    id: "month"
                },
                columns: [
                    {
                        name: "month",
                        type: "Number",
                        property: "month",
                        label: "tables.columns.month",
                        width: 90,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "connections",
                        type: "Number",
                        property: "connections",
                        label: "tables.columns.connections",
                        sortable: true
                    }
                ]
            });
        }
        else {
            // Unsupported group?
            return;
        }
    }

}
