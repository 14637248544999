import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection, GeoJSON } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";

export class AccessAuthorizationAreaFillLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            card: "AccessCard",
            layer: {
                id: "access-authorization-area-fill",
                type: "fill",
                minzoom: ZOOM_AREA - 2,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "fill-color": ["get", "color"],
                    "fill-outline-color": "transparent",
                    "fill-opacity": 0.4
                }
            }
        });
    }

    public async load(): Promise<any> {
        // load areas
        let areas = await this.context.invipo.getAreas("type=Access");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            // Number of denied records
            let access = await this.context.invipo.getDataset("access-data", `from=${new Date(Date.now() - 3600000).toISOString()}&area.id=${area.id}&class=TrafficCamera,BluetoothDetector,SecurityCamera`)

            // Unknown last collection
            let color = "#aaaaaa";

            // Consumption is known?
            if (access) {
                // How many percent of access records were denied?
                let value = access.data.filter(x => x.authorization == "Denied").length / access.data.length * 100;
                let range = Helpers.range(0, METRICS.safety.access.authorization.colors.length - 1, METRICS.safety.access.authorization.range[0], METRICS.safety.access.authorization.range[1], value);
                color = METRICS.safety.access.authorization.colors[Math.round(range)];
            }

            json.features.push({
                type: "Feature",
                properties: {
                    data: "access",
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: area.id,
                    areaId: area.id,
                    areaType: area.type,
                    areaName: area.name,
                    itemClass: "TrafficCamera,BluetoothDetector,SecurityCamera",
                    color: color
                },
                geometry: area.geometry
            })
        }

        return json;
    }
}
