import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";

export class StreetLightStateLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            layer: {
                id: "street-light-state-label",
                type: "symbol",
                minzoom: ZOOM_LABEL,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1,
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 1.8],
                    "text-anchor": "top",
                    "text-allow-overlap": false,
                    "text-max-width": 15
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems(`class=StreetLightController`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Current state
            let state = item.data?.control?.state;

            json.features.push({
                type: "Feature",
                properties: {
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    label: `${item.name}\n${this.context.locale.getMessage(`enums.StreetLightControllerState.${state}`)}`
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
