import "./fleet-trip-browser.scss";
import * as template from "./fleet-trip-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { DateInput } from "muklit/components/date-input/date-input";
import { FleetTripBrowserOptions } from "./types";
import { DataTable } from "muklit/components/data-table/data-table";
import { Filter } from "muklit/components/filter/filter";
import { Form } from "muklit/components/form/form";
import { Trip } from "../../../clients/gpscockpit-client/types";
import { FleetTripDetailOptions } from "../fleet-trip-detail/types";
import { FleetTripDetail } from "../fleet-trip-detail/fleet-trip-detail";
import { Helpers } from "../../../../hiyo/helpers";
import { TextInput } from "muklit/components/text-input/text-input";

export class FleetTripBrowser extends MuklitComponent<InvipoContext, FleetTripBrowserOptions> {

    // Components
    public form: Form;
    public filter: Filter;
    public table: DataTable;
    public detail: FleetTripDetail;

    constructor(context: InvipoContext, options?: FleetTripBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createFilter();
        this.createTable();

        // TODO: add pagination
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createForm(): void {
        const previousHour = new Date();
        previousHour.setHours(previousHour.getHours() - 1, 0, 0, 0);

        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        // Create component
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: previousHour.toISOString()
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            value: nextDay.toISOString()
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "licensePlace",
                            label: "forms.fields.plateNumber"
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "driver",
                            label: "forms.fields.driver"
                        }),
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            // await this.search(data);
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    private createFilter(): void {
        // Create component
        this.filter = new Filter(this.context, {
            style: "Light",
            title: "components.FleetTripBrowser.title",
            togglable: true,
            items: [
                // {
                //     name: "Add",
                //     label: "labels.add"
                // },
                {
                    name: "reload",
                    label: "labels.reload"
                }
            ]
        });

        // Toggle form
        this.filter.onFormToggle = (toggled: boolean) => {
            // Assign toggled class
            this.query("div.form").classList.toggle("form-toggled", toggled);
        }

        // // Remove tag and update query
        // this.filter.onTagRemove = async (tag: TitleBarTag) => {
        //     // Reload if query
        //     if (this.options.search) {
        //         // Remove from query
        //         delete this.options.search[tag.name];

        //         // Reload data
        //         await this.load();
        //     }

        //     // Clear form field
        //     this.form.setValue(tag.name, null);
        // }

        // // Clear all form
        // this.filter.onTagsClear = async () => {
        //     // Celar search
        //     this.options.search = null;

        //     // Celar form
        //     this.form.clear();

        //     // Reload data
        //     await this.load();
        // }

        // // Handle menu
        // this.filter.onItemSelect = (item: FilterItem) => {
        //     this.onItemSelect(item);
        // }

        // // Handle actions
        // this.filter.onActionSelect = (action: FilterAction) => {
        //     this.onActionSelect(action);
        // }

        // Reload data
        this.filter.onReload = async () => {
            await this.load();
        }

        // Close panel
        this.filter.onClose = () => {
            // Handler only
            this.onClose();
        }

        // Register component
        this.registerComponent(this.filter, "filter");
    }

    private createTable(): void {
        this.table = new DataTable(this.context, {
            style: "Light",
            type: "SingleSelect",
            size: "Normal",
            height: "100%",
            autosort: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "assetName",
                    type: "String",
                    property: "assetName",
                    label: "tables.columns.name",
                    width: 180,
                    sortable: true,
                    selected: false
                },
                {
                    name: "plateNumber",
                    type: "String",
                    property: "plateNumber",
                    label: "tables.columns.plateNumber",
                    width: 180,
                    sortable: true,
                    selected: false
                },
                {
                    name: "beginTS",
                    type: "DateTime",
                    property: "beginTS",
                    label: "tables.columns.begin",
                    ellipsis: true,
                    sortable: true,
                    selected: false,
                    width: 180
                },
                {
                    name: "endTS",
                    type: "DateTime",
                    property: "endTS",
                    label: "tables.columns.end",
                    ellipsis: true,
                    sortable: true,
                    selected: true,
                    width: 180
                },
                {
                    name: "segmentDistanceInKilometers",
                    type: "String",
                    property: "segmentDistanceInKilometers",
                    label: "tables.columns.distance",
                    sortable: true,
                    width: 120,
                    formatter: (value: any) => {
                        return `<div class="cell cell-right">${Helpers.toNumber(value, 1)} km</div>`;
                    }
                },
                {
                    name: "driverName",
                    type: "String",
                    property: "driverName",
                    label: "tables.columns.driver",
                    ellipsis: true,
                    sortable: true,
                },
            ]
        });

        // Open detail
        this.table.onRowSelect = async (data: any) => {
            this.openDetail(data.data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private openDetail(data: Trip): void {
        // Open detail
        console.log("openDetail", data);

        // Detail options
        let options: FleetTripDetailOptions = {
            style: "Light",
            trip: data,
            title: "components.FleetTripDetail.title",
            subtitle: data.assetName,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new FleetTripDetail(this.context, options);

        // Unselect table raw on detail close
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.trip.id.toString());
        }

        // Show detail
        this.detail.attach();
    }

    // public openExport(): void {
    //     // Export form to choose export type
    //     let form = new ServerExportForm(this.context, {
    //         style: "Light",
    //         title: "components.ServerExportForm.title",
    //         overlay: true,
    //         closable: true,
    //         items: [
    //             {
    //                 name: "PublicVehiclesHistoryCsv",
    //                 label: "components.ServerExportForm.types.Csv",
    //                 checked: true
    //             }
    //         ],
    //         query: this.table.getUrl(),
    //         total: this.table.pagination.options.total
    //     });

    //     // Show form
    //     form.attach();
    // }

    public async load(): Promise<void> {
        this.showLoader();

        const formData = this.form.getData();

        // const assets = await this.context.gpsCockpit.getAssets();

        const from = Math.round(new Date(Object.keys(formData.from)[0]).getTime() / 1000);
        const to = Math.round(new Date(Object.keys(formData.to)[0]).getTime() / 1000);
        let data = await this.context.gpsCockpit.getTrips(from, to);

        if (formData.licensePlace) {
            const re = new RegExp(formData.licensePlace, "gi");
            data = data.filter((trip: Trip) => {
                return re.test(trip.assetName);
            });
        }

        if (formData.driver) {
            const re = new RegExp(formData.driver, "gi");
            data = data.filter((trip: Trip) => {
                return re.test(trip.driverName);
            });
        }

        console.log("data", data);

        this.table.setData(data);

        this.hideLoader();
    }
}
