import { FeatureCollection, GeoJSON, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { Log } from "../../../hiyo/log";

const TRANSITION_DURATION = 600;

export class StreetLightControllerLineLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            layer: {
                id: `street-light-controller-line`,
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 4
                    ],
                    "line-color": "#b3b3b3",
                    "line-opacity": 0, // 0 -> 0.9
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                //"line-opacity": 0.9
                "line-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    11.5, 0,
                    12, 0.9
                ]
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems(`class=StreetLightController`);

        // Get slaves items
        let slaves = items.filter(x => x.parent);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of slaves) {
            // Master items
            let master = items.find(x => x.id == item.parent.id);

            // Master not found?
            if (!master) {
                Log.w(`Broken reference: Parent for ${item.name} not found`);
                continue
            }

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.options.tooltip,
                    detail: this.options.detail
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        (<Point>item.geometry.location).coordinates,
                        (<Point>master.geometry.location).coordinates
                    ]
                }
            })
        }

        return json;
    }


}
