import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { VehicleVolumeDetailOptions } from "./types";
import { VehicleSelect } from "../../common/vehicle-select/vehicle-select";
import { VehicleVolumeChart } from "../vehicle-volume-chart/vehicle-volume-chart";

const CLASS_VEHICLE_COUNTER = "WimStation,TrafficCounter,BluetoothDetector";

export class VehicleVolumeStats extends StatsDetail<VehicleVolumeDetailOptions> {

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "timestamp",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date(new Date().setHours(0, 0, 0, 0) - 604800000).toISOString(),
                            bright: true,
                            type: "Date"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            bright: true,
                            type: "Date"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.all",
                            itemClass: CLASS_VEHICLE_COUNTER,
                            items: [],
                            bright: true
                        }),
                        new VehicleSelect(this.context, {
                            style: "Light",
                            type: "Lane",
                            name: "lane",
                            label: "forms.fields.lane",
                            placeholderText: "forms.placeholders.all",
                            items: [],
                            multiselect: true,
                            bright: true
                        }),
                        /*new Select(this.context, {
                            style: "Light",
                            name: "direction",
                            label: "forms.fields.direction",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.config.directions),
                            multiselect: true,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "classification.category",
                            label: "forms.fields.category",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.config.categories),
                            multiselect: true,
                            bright: true
                        })*/
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.chart.filter(data);
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new VehicleVolumeChart(this.context, {
            style: "Light",
            queryName: "VehicleVolumeByGroup",
            query: `item.class=${CLASS_VEHICLE_COUNTER}`,
            view: "Chart",
            groups: [
                {
                    name: "DayOfYear",
                    label: "components.StatsChart.groups.DayOfYear",
                    selected: true
                },
                {
                    name: "DayOfWeek",
                    label: "components.StatsChart.groups.DayOfWeek"
                },
                {
                    name: "Week",
                    label: "components.StatsChart.groups.Week"
                },
                {
                    name: "Month",
                    label: "components.StatsChart.groups.Month"
                }
            ],
            search: this.form.getData(),
            closable: this.options.closable
        });

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

}
