import { AreaSpotLayer } from "../area-spot-layer";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { InvipoHelpers } from "../../invipo-helpers";
import { DEFAULT_LANE_CAPACITY } from "../../components/traffic/traffic-counting-subdomain/traffic-counting-subdomain";

export class TrafficVolumeItemLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "traffic-volume-item-label",
                type: "symbol",
                minzoom: ZOOM_LABEL - 4,
                maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems("class=TrafficCounter,WimStation");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Load areas
        let areas = await this.context.invipo.getAreas("type=TrafficVolume");

        // If areas are activated, we will not draw anything here
        if (areas.length > 0) {
            return json;
        }

        // Push features from items
        for (let item of items) {
            // Maximum volume and number of segments
            let peak = null;

            // Has data?
            if (item.data?.traffic) {
                for (let data of item.data.traffic) {
                    // Hour value from data
                    let hour = data.count * (60 / this.context.config.aggregations.traffic.interval);

                    // Current segment definition
                    let segment = (<any[]>item.meta?.segments)?.find(x => x.name == data.segment);

                    // Calcualte volume as percentage of capacity
                    let volume = hour / (segment?.capacity || DEFAULT_LANE_CAPACITY) * 100;

                    // New maximum?
                    if (volume > peak) {
                        peak = volume;
                    }
                }
            }

            json.features.push({
                type: "Feature",
                properties: {
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: "#161616",
                    label: peak == null ? "N/A" : `${Helpers.toNumber(Helpers.clamp(peak, 0, 100))} %`
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
