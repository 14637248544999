import './category-volume-tile.scss';
import * as template from "./category-volume-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CategoryVolumeTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis, TileLegend } from "../../common/tile/types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class CategoryVolumeTile extends Tile<CategoryVolumeTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: CategoryVolumeTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Data query
        let from = new Date(new Date().setHours(-24 * 8, 0, 0, 0));
        let to = new Date(new Date().setHours(0, 0, 0, 0));
        let query = `from=${from.toISOString()}&to=${to.toISOString()}`;

        // Area in query?
        if (this.options.areaId) {
            query += `&area.id=${this.options.areaId}`;
        }

        // Load data
        let data = await this.context.invipo.getQuery("traffic-by-day", query);

        // Volume maximum and average
        let maximum = Math.max(...data.map(x => (<any[]>x.categories).find(x => x.id == this.options.categoryId).count));
        let average = data.map(x => (<any[]>x.categories).find(x => x.id == this.options.categoryId).count).reduce((a, b) => { return a + b }, 0) / data.length;

        // Build storage kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.CategoryVolumeTile.maximum",
                    value: Number.isNaN(maximum) ? this.context.locale.getMessage("common.noData") : Helpers.toNumber(maximum)
                },
                {
                    label: "components.CategoryVolumeTile.average",
                    value: Number.isNaN(average) ? this.context.locale.getMessage("common.noData") : Helpers.toNumber(average)
                }
            ]
        }

        // Calculate history data (last 2 days)
        this.chart = {
            //label: "Two days volume history",
            size: "8",
            data: []
        };

        // Get history from yesterday's midnight to today's midnight
        for (let d = 0; d < 8; d++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                // Get category count and calcualte percentual volume from capacity
                let capacity = 10000;
                let count = (<any[]>d.categories).find(x => x.id == this.options.categoryId).count;
                let volume = count / capacity * 100;

                // Add data
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: count,
                    percent: volume,
                    tooltip: `${Helpers.toDateString(from)}<br />${Helpers.toNumber(count)}`,
                    color: METRICS.traffic.counting.volume.colors[0]
                });
            }
            // No data for hour, we are in the future
            else {
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 86400000);
        }
    }

}
