import "./fleet-asset-detail.scss";
import * as template from "./fleet-asset-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetAssetDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { Asset, AssetGroup, DeviceState, DeviceStateEnum } from "../../../clients/gpscockpit-client/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { FleetStatusCircleLayer } from "invipo/layers/transit/fleet-status-circle-layer";
import { Tabs } from "muklit/components/tabs/tabs";
import { TabsItem } from "muklit/components/tabs/types";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export class FleetAssetDetail extends Detail<InvipoContext, FleetAssetDetailOptions> {

    public asset: Asset;
    public groups: AssetGroup[];

    public tabs: Tabs;
    public map: BasicMap;

    constructor(context: InvipoContext, options: FleetAssetDetailOptions) {
        super(context, template, options);

        Handlebars.registerHelper("asDeviceState", (t: number) => {
            var state = DeviceStateEnum[t];

            return this.context.locale.getMessage(`enums.FleetAssetStatus.${state}`);
        });
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createMap();
    }

    protected createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "Overview",
                    label: "components.CityDetail.overview",
                    content: "div.content-overview",
                    selected: true
                },
                {
                    name: "Groups",
                    label: "components.FleetDetail.groups",
                    content: "div.content-groups"
                }
            ]
        });

        // Cleanup on tab change
        this.tabs.onSelect = async (item: TabsItem) => {
            if (item.name == "Overview") {
                this.map.resize();
            }
        }

        this.registerComponent(this.tabs, 'tabs');
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 0,
            maxZoom: 20
        });

        this.registerComponent(this.map, 'map');
    }

    public async load(): Promise<void> {
        this.showLoader();

        this.asset = await this.context.gpsCockpit.getAssetWithState(this.options.asset.id);

        if (this.asset.state?.odometer?.gpsOdometer) {
            this.asset.state.odometer.gpsOdometer /= 1000;
        }
        if (this.asset.state?.odometer?.canBusOdometer) {
            this.asset.state.odometer.canBusOdometer /= 1000;
        }

        const groups = await this.context.gpsCockpit.getAssetGroups();
        this.groups = groups.filter(x => x.assetGroupItems.find(a => a.id == this.asset.id));
        this.groups.forEach(x => x.timestamp.includes("+04:00") ? undefined : x.timestamp += "+04:00");

        this.hideLoader();

        this.invalidate(true);

        if (this.asset.state?.currentPosition) {
            this.map.removeLayers();

            this.map.addLayer(new FleetStatusCircleLayer(this.context, this.asset));

            this.map.flyTo({
                center: [this.asset?.state.currentPosition.longitude, this.asset?.state.currentPosition.latitude],
                zoom: 15
            });
        }
    }

}
