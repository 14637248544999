import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Asset, DeviceState, DeviceStateEnum } from "invipo/clients/gpscockpit-client/types";

export class FleetStatusCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext, private asset?: Asset) {
        super(context, {
            dynamic: true,
            refreshInterval: asset ? undefined : 60,
            card: "FleetAssetCard",
            detail: "FleetDetail",
            layer: {
                id: `fleet-status-circle-layer`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        if (this.asset) {
            if (this.asset.state?.currentPosition) {
                json.features.push({
                    type: "Feature",
                    properties: {
                        tooltip: this.asset.name,
                        card: this.options.card,
                        cardId: this.asset.id,
                        detail: this.options.detail,
                        asset: this.asset,
                        assetId: this.asset.id,
                        color: this.getColor(this.asset)
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [this.asset.state.currentPosition.longitude, this.asset.state.currentPosition.latitude]
                    }
                });
            }
        } else {
            // const data = await this.context.gpsCockpit.stateLookup();
            // const states = Object.values(data.deviceStates);

            // const assetData = await this.context.gpsCockpit.getAssets();
            // const assets: Record<number, Asset> = assetData.reduce((acc: any, asset) => {
            //     acc[asset.deviceId] = asset;
            //     return acc;
            // }, {});

            const assets = await this.context.gpsCockpit.getAssetsWithState();

            for (const asset of assets) {
                if (asset.state?.currentPosition) {
                    json.features.push({
                        type: "Feature",
                        properties: {
                            tooltip: asset.name,
                            card: this.options.card,
                            cardId: asset.id,
                            detail: this.options.detail,
                            asset,
                            assetId: asset.id,
                            color: this.getColor(asset)
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [asset.state.currentPosition.longitude, asset.state.currentPosition.latitude]
                        }
                    });
                }
            }
        }


        return json;
    }

    public getColor(asset: Asset): string {
        switch (asset.state?.calculatedDeviceState.deviceState) {
            case DeviceStateEnum.IgnitionOn: return "#0fd170";
            case DeviceStateEnum.Idling: return "#fcb836";
            case DeviceStateEnum.IgnitionOff: return "#ff0038";
            case DeviceStateEnum.NotCommunicating: return "#7d00ff";
            case DeviceStateEnum.ExternalPowerLost: return "#c128b9"
            case DeviceStateEnum.Unknown:
            default:
                return "#999999";
        }
    }
}
