import { ContentView } from "../content-view";

export class Wall extends ContentView {

    public onCreate(): void {
        // Set component from invipo.json (wall)
        this.setContent(this.context.options.wall);
    }

}
