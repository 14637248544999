import "./fleet-detail.scss";
import * as template from "./fleet-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { DrawerItem } from "../../common/drawer-menu/types";
import { FleetDetailOptions } from "./types";
import { DetailMenu } from "../../common/detail-menu/detail-menu";
import { MenuItem } from "../../common/detail-menu/types";
import { ContentDetail } from "../../common/content-detail/content-detail";
import { COMPONENT_STORE } from "../../../store";
import { Log } from "../../../../hiyo/log";

export class FleetDetail<T extends FleetDetailOptions> extends ContentDetail<T> {

    // Components
    public menu: DetailMenu;

    public constructor(context: InvipoContext, options: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached
        this.registerComponent(this.menu, "menu");

        // Set component if required
        if (this.options.content) {
            this.setContent(this.options.content);
        }
    }

    private createMenu(): void {
        // Create items based on item class
        let items: MenuItem[] = [];

        items.push({
            name: "FleetAssetOverview",
            label: "components.FleetDetail.asset",
        });

        items.push({
            name: "FleetAssetTrips",
            label: "components.FleetDetail.trips",
        });

        const asset = JSON.parse(this.options.asset);

        // Title and subtitle
        let title = asset.name;
        let subtitle = `${asset.brand} ${asset.model}`;

        // Create component
        this.menu = new DetailMenu(this.context, {
            style: "Light",
            title: title,
            subtitle: subtitle,
            items: items
        });

        this.menu.onSelect = (item: DrawerItem) => {
            // Already selected?
            if (this.content?.name == item.name) {
                return;
            }

            // Change content component
            this.setContent(item.name, ["TrafficLightControllerDiagram"].includes(item.name));
        }

        // Default selection
        if (!items.some(x => x.selected) && items.length > 0) {
            items[0].selected = true;
            this.setContent(items[0].name);
        }
    }
}
