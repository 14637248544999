import "./inbox-browser.scss";
import * as template from "./inbox-browser.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { InboxBrowserOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { Search } from "muklit/components/search/search";
import { Detail } from "../../../../muklit/components/detail/detail";
import { VehicleDetail } from "../../traffic/vehicle-detail/vehicle-detail";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { Helpers } from "../../../../hiyo/helpers";
import { Select } from "../../../../muklit/components/select/select";
import { DrawerMenu } from "../../common/drawer-menu/drawer-menu";
import { DrawerItem } from "../../common/drawer-menu/types";
import { HiyoEvent } from "../../../../hiyo/event-broker";
import { MessageDetail } from "../../management/message-detail/message-detail";
import { InboxDetail } from "../inbox-detail/inbox-detail";

export class InboxBrowser extends Detail<InvipoContext, InboxBrowserOptions> {

    // Components
    public menu: DrawerMenu;
    public form: Form;
    public table: QueryTable;
    public detail: VehicleDetail;

    constructor(context: InvipoContext, options?: InboxBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
        this.createForm();
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.menu, "menu");
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
    }

    public onAttach(): void {
        // Subscribe for events
        this.context.broker.subscribe(this, ["InboxNotificationCreated", "InboxNotificationRead", "InboxNotificationsRead"]);
    }

    public onDetach(): void {
        // Unsubscribe from registered events
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent) {
        // Reload query table and self for notification count
        await this.table.load();
        await this.load();
    }

    private createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                {
                    name: "Notifications",
                    title: "components.InboxBrowser.inbox",
                    separated: true,
                    items: [
                        {
                            name: "All",
                            label: "components.InboxBrowser.all",
                            data: "",
                            selected: true
                        },
                        {
                            name: "Unread",
                            label: "components.InboxBrowser.unread",
                            data: "read=false"
                        }
                    ]
                },
                {
                    name: "Types",
                    title: "components.InboxBrowser.types",
                    items: [
                        {
                            name: "Notifications",
                            label: "components.InboxBrowser.notifications",
                            data: `source=${this.context.options.notifications.join(",")}`
                        },
                        {
                            name: "Tips",
                            label: "components.InboxBrowser.tips",
                            data: "source=Tip"
                        },
                        {
                            name: "Improvements",
                            label: "components.InboxBrowser.improvements",
                            data: "source=Improvement"
                        }
                    ]
                }
            ]
        });

        // Search on menu change
        this.menu.onSelect = async (item: DrawerItem) => {
            // Adujst url with fixed filter
            this.table.options.url = `${this.context.options.host}/api/data/datasets/inbox?${item.data}`

            // Do search
            await this.table.search(null);
        }
    }

    private createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new Search(this.context, {
                            style: "Light",
                            name: "name",
                            label: "forms.fields.subject",
                            placeholderText: "forms.placeholders.subject",
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "read",
                            label: "forms.fields.state",
                            placeholderText: "forms.placeholders.all",
                            items: [
                                {
                                    name: "false",
                                    label: "components.InboxBrowser.readOnly"
                                },
                                {
                                    name: "true",
                                    label: "components.InboxBrowser.unreadOnly"
                                }
                            ]
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to"
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.table.search(data);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/inbox`,
            http: this.context.invipo.http,
            bar: {
                title: "components.InboxBrowser.title",
                items: [
                    {
                        name: "ReadAll",
                        label: "components.InboxBrowser.readAll"
                    },
                    /*{
                        name: "RemoveAll",
                        label: "components.InboxBrowser.removeAll"
                    }*/
                ],
                closable: this.options.closable,
                reloadable: true
            },
            pagination: {
                page: 1,
                pageSize: 100
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                groups: {
                    property: "timestamp",
                    formatter: (value: any, data: any) => {
                        let today = new Date();
                        let yesterday = new Date(new Date().setHours(-24));

                        // Today?
                        if (new Date(value).toDateString() == today.toDateString()) {
                            return "common.today";
                        }
                        // Yesterday?
                        else if (new Date(value).toDateString() == yesterday.toDateString()) {
                            return "common.yesterday";
                        }
                        // Just some date in past
                        else {
                            return Helpers.toDateString(value);
                        }
                    }
                },
                rows: {
                    id: "id",
                    decorator: (data: any) => {
                        if (!data.read) {
                            return "unread";
                        }
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "name",
                        type: "String",
                        property: "subject",
                        label: "tables.columns.subject",
                        minWidth: 400,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Reset menu
        this.table.onTagRemove = (tag: TitleBarTag) => {
            // Deselect all groups and items
            this.menu.options.groups.forEach(x => {
                x.selected = false;
                x.items.forEach(y => {
                    y.selected = false;
                })
            });

            // Select first
            this.menu.options.groups[0].selected = true;
            this.menu.options.groups[0].items[0].selected = true;

            // Redraw
            this.menu.update();
        }

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.close();
        }

        // Handle menu
        this.table.onMenuSelect = async (item: MenuItem) => {
            // Read all?
            if (item.name == "ReadAll") {
                // Procced to read all
                await this.context.invipo.updateInboxRead("all");

                // Reload query table and self for notification count
                await this.table.load();
                await this.load();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            // Cleanup detail
            // This is very dirty, riht way is to keep detail instance and detach it
            this.query("div.detail").innerHTML = "";

            // Message detail?
            if (data.event.type == "TrafficMessageReceived") {
                await this.openMessageDetail(data.id, data.event.extras);
            }
            // Vehicle detail?
            else if (data.event.type == "VehicleCaptured") {
                await this.openVehicleDetail(data.id, data.event.extras);
            }
            // General inbox detail?
            else {
                await this.openInboxDetail(data.id, data);
            }

            // If not read, mark as read
            if (!data.read) {
                await this.context.invipo.updateInboxRead(data.id);
            }
        }
    }

    protected openMessageDetail(id: string, data: any): void {
        // New detail
        let detail = new MessageDetail(this.context, {
            style: "Light",
            message: data,
            title: `enums.TrafficMessageSubtype.${data.subtype}`,
            subtitle: `enums.TrafficMessageType.${data.type}`,
            printable: false,
            closable: true
        });

        // Unselect table row and null detail
        detail.onClose = () => {
            this.table.unselectRow(id);
        }

        // Show detail
        detail.attach(this.query("div.detail"));
    }

    protected openVehicleDetail(id: string, data: any): void {
        // New detail
        let detail = new VehicleDetail(this.context, {
            style: "Light",
            vehicle: data,
            title: "components.VehicleDetail.title",
            subtitle: data.item.name,
            printable: false,
            closable: true
        });

        // Unselect table row and null detail
        detail.onClose = () => {
            this.table.unselectRow(id);
        }

        // Open image detail
        detail.onImageSelect = (image: any) => {
            this.openImage(image);
        }

        // Show detail
        detail.attach(this.query("div.detail"));
    }

    protected openInboxDetail(id: string, data: any): void {
        // New detail
        let detail = new InboxDetail(this.context, {
            style: "Light",
            inbox: data,
            title: data.subject,
            subtitle: Helpers.toDateTimeString(data.timestamp),
            printable: false,
            closable: true
        });

        // Open image detail
        detail.onImageSelect = (image: any) => {
            this.openImage(image);
        }

        // Unselect table row and null detail
        detail.onClose = () => {
            this.table.unselectRow(id);
        }

        // Show detail
        detail.attach(this.query("div.detail"));
    }

    public openImage(image: any): void {
        // Prevent parent cllosing by escape key
        this.options.preventEsc = true;

        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: null,
            url: `${this.context.options.host}/download${image.url}`,
            closable: true,
            overlay: true
        });

        // Enable parent cllosing by escape key
        detail.onClose = () => {
            this.options.preventEsc = false;
        }

        // Show
        detail.attach();
    }

    public async load(): Promise<void> {
        // Load inbox notification
        let data = await this.context.invipo.getDataset("inbox", "read=false&pageSize=1");

        // Notification count changed, we need to change menu
        this.menu.options.groups[0].items[0].label = `${this.context.locale.getMessage("components.InboxBrowser.all")} (${data.total})`;
        this.menu.update();

        // Disable read all icon
        this.table.bar.options.items[0].disabled = data.total == 0;
        this.table.bar.update();
    }

}
