import "./item-manager.scss";
import * as template from "./item-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemManagerOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { Search } from "muklit/components/search/search";
import { Select } from "../../../../muklit/components/select/select";
import { ItemMap } from "../item-map/item-map";
import { InvipoHelpers } from "../../../invipo-helpers";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { ItemDetailForm } from "../item-detail-form/item-detail-form";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { GeoJsonProperties } from "geojson";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ItemPassportForm } from "../item-passport-form/item-passport-form";
import { ItemDetail } from "../../items/item-detail/item-detail";
import { ItemDetailOptions } from "../../items/item-detail/types";

export class ItemManager extends MuklitComponent<InvipoContext, ItemManagerOptions> {

    // Components
    public form: Form;
    public table: QueryTable;
    public map: ItemMap;
    public detail: ItemDetail;

    constructor(context: InvipoContext, options?: ItemManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createTable();
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
        this.registerComponent(this.map, "map");
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new Search(this.context, {
                            style: "Light",
                            name: "name",
                            label: "item.name",
                            placeholderText: "forms.placeholders.itemName"
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "class",
                            label: "item.class",
                            placeholderText: "forms.placeholders.all",
                            multiselect: true,
                            items: InvipoHelpers.toMenuItems(this.context.options.classes, "classes.", (a, b) => {
                                return this.context.locale.getMessage(a.label).localeCompare(this.context.locale.getMessage(b.label));
                            })
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "monitoringStatus.status",
                            label: "item.monitoringStatus",
                            placeholderText: "forms.placeholders.all",
                            multiselect: true,
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("monitoringStatus"))
                        }),
                        new AutocompleteSelect(this.context, {
                            style: "Light",
                            collection: "Items",
                            name: "model",
                            label: "item.model",
                            placeholderText: "forms.placeholders.model",
                            items: [],
                            type: "Model"
                        }),
                        new AutocompleteSelect(this.context, {
                            style: "Light",
                            collection: "Items",
                            name: "producer",
                            label: "item.producer",
                            placeholderText: "forms.placeholders.producer",
                            items: [],
                            type: "Producer"
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            // Filter data table
            await this.table.search(data);

            // Filter layer with the same table filter
            //await this.map.search(this.table.getQuery());
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/items`,
            http: this.context.invipo.http,
            bar: {
                title: "components.ItemManager.title"
            },
            pagination: {
                page: 1,
                pageSize: 20
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "name",
                        type: "String",
                        property: "name",
                        label: "item.name",
                        width: 480,
                        sortable: true,
                        ellipsis: true,
                        selected: true
                    },
                    {
                        name: "class",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`classes.${data.class}`);
                        },
                        label: "item.class",
                        ellipsis: true,
                        sortable: true,
                        minWidth: 200
                    },
                    /*{
                        name: "producer",
                        type: "String",
                        property: "producer",
                        label: "item.producer",
                        ellipsis: true,
                        sortable: true,
                        minWidth: 280,
                    }*/
                ]
            }
        });

        // Clear input
        this.table.onTagRemove = (tag: TitleBarTag) => {
           this.form.setValue(tag.name, null);
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Handle menu
        this.table.onMenuSelect = async (item: MenuItem) => {
            // Add new user?
            if (item.name == "Add") {
                this.openNew();
            }
        }
    }

    private createMap(): void {
        // Create component
        this.map = new ItemMap(this.context);

        // Open detail
        this.map.onFeatureClick = async (properties: GeoJsonProperties) => {
            // Editing only unlocked item is allowed
            if (!properties.itemLocked) {
                await this.openForm(properties.itemId, "Map");
            }
        }
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: ItemDetailOptions = {
            style: "Light",
            title: data.name,
            subtitle: `classes.${data.class}`,
            itemId: data.id,
            closable: true
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Navigate in map
            this.map.navigateTo(data);

            // Not continue
            return;
        }

        // New detail
        this.detail = new ItemDetail(this.context, options);

        // Open details form
        this.detail.onSelectDetail = async (item: InvipoItem) => {
            this.openForm(item.id);
        }

        // Open delete dialog
        this.detail.onSelectDelete = async (item: InvipoItem) => {
            this.confirmDelete(item);
        }

        /*this.detail.onSelectPassports = async (item: InvipoItem) => {
            this.openPassports(item.id);
        }*/

        // When detail is closed
        this.detail.onClose = () => {
            // Unselect selected row
            this.table.unselectRow(this.detail.options.itemId);

            // Force redraw map
            this.map.map.resize();
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();

        // Force redraw map
        this.map.map.resize();

        // Navigate in map
        this.map.navigateTo(data);
    }

    public openForm(itemId: string, tab?: string): void {
        // Detail form
        let form = new ItemDetailForm(this.context, {
            style: "Light",
            title: null,
            itemId: itemId,
            tab: tab,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();

            // Reload map layers
            await this.map.reloadLayers();

            // Reload detail
            await this.detail?.load();
        }

        // Show form
        form.attach();
    }

    public confirmDelete(item: InvipoItem): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.ItemDetail.itemDelete",
            text: this.context.locale.getMessage("components.ItemDetail.reallyDelete", item.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteItem(item.id);

            // Close dialog
            dialog.close();

            // Close detail
            this.detail?.close();
        }

        // Show dialog
        dialog.attach();
    }

    public openNew(): void {
        // Details form
        /*let dialog = new ItemPicker(this.context, {
            style: "Light",
            title: 'Test',
            overlay: true,
            closable: true,
            groups: [{
                name: "Group1",
                title: 'Group 1',
                items: [
                    { name: "Item1", label: "Item 1", description: "Description ..."},
                    { name: "Item2", label: "Item 2" },
                    { name: "Item3", label: "Item 3" },
                ]
            }, {
                name: "Group2",
                title: 'Group 2',
                items: [
                    { name: "Item1", label: "Item 1", description: "Description ..."},
                    { name: "Item2", label: "Item 2" },
                    { name: "Item3", label: "Item 3" },
                ]
            }]
        });

        // Show form
        dialog.attach();

        dialog.onSelect = (item) => {
            console.log(item);
            dialog.close();
        }*/
    }

    public openPassports (itemId: string) {
        // Detail form
        let form = new ItemPassportForm(this.context, {
            style: "Light",
            title: null,
            itemId: itemId,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();

            // Reload map layers
            await this.map.reloadLayers();

            // Reload detail
            await this.detail?.load();
        }

        // Show form
        form.attach();
    }
}
