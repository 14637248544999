import "./vehicle-detail.scss";
import * as template from "./vehicle-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { VehicleDetailOptions } from "./types";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";

export class VehicleDetail extends Detail<InvipoContext, VehicleDetailOptions> {

    // Components
    public tabs: Tabs;

    // Event handling methods
    public onImageSelect(image: any): void {}

    constructor(context: InvipoContext, options: VehicleDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "General",
                    label: "details.tabs.general",
                    content: "div.content-general",
                    selected: true
                }
            ]
        });

        // Has axle weight info?
        if (this.options.vehicle.weight) {
            this.tabs.options.tabs.push({
                name: "Axles",
                label: "details.tabs.axles",
                content: "div.content-axles"
            });
        }

        // Has images?
        if (this.options.vehicle.images?.length > 0) {
            this.tabs.options.tabs.push({
                name: "Images",
                label: "details.tabs.images",
                content: "div.content-images"
            });
        }
    }

    public selectImage(i: number): void {
        // OnImageSelect handler
        this.onImageSelect(this.options.vehicle.images[i]);
    }

    public invalidate(skipLoad?: boolean) {
        // Recreate and register tabs
        this.createTabs();
        this.registerComponent(this.tabs);

        // Invalidation
        super.invalidate(skipLoad);
    }
}
