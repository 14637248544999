import './items-inspector-tile.scss';
import * as template from "./items-inspector-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileKpis, TileLegend, TileList } from "../../common/tile/types";
import { ItemsInspectorTileOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class ItemsInspectorTile extends Tile<ItemsInspectorTileOptions> {

    // Properties
    public kpis: TileKpis;
    public legend: TileLegend;
    public list: TileList;

    constructor(context: InvipoContext, options?: ItemsInspectorTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        let items = await this.context.invipo.getItems();
        let problematicItems = items.filter(x => ["Error", "Disconnected", "Unreachable"].includes(x.monitoringStatus.status));

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.ItemsInspectorTile.total",
                    value: Helpers.toNumber(items.length)
                },
                {
                    label: "components.ItemsInspectorTile.problematic",
                    value: Helpers.toNumber(problematicItems.length)
                }
            ]
        }

        // Builld items legend
        this.legend = {
            flat: true,
            symbols: []
        };

        for (let i in METRICS.infrastructure.technology.status.range) {
            let symbol = METRICS.infrastructure.technology.status.range[i]
            let count = items.filter(x => x.monitoringStatus?.status == symbol).length;

            // Add row to legend
            this.legend.symbols.push({
                metrics: "State",
                symbol: symbol,
                count: count,
                label: `monitoringStatus.${symbol}`,
                percent: count / (items.length || 1) * 100,
                color: METRICS.infrastructure.technology.status.colors[i],
                selectable: true
            });
        }

        // Builld items list
        this.list = {
            items: []
        };

        for (let item of problematicItems.slice(0, 10)) {
            // Get symbol index
            let i = METRICS.infrastructure.technology.status.range.indexOf(item.monitoringStatus.status);

            this.list.items.push({
                name: item.name,
                //label: `${this.context.locale.getMessage(`monitoringStatus.${item.monitoringStatus.status}`)} ${Helpers.toShortDateTimeString(item.monitoringStatus.changed)}`,
                label: this.context.locale.getMessage(`monitoringStatus.${item.monitoringStatus.status}`),
                color: METRICS.infrastructure.technology.status.colors[i]
            })
        }

        // Create tile status
        if (problematicItems.length) {
            this.status = {
                icon: "Error",
                label: "components.ItemsInspectorTile.statusError"
            }
        }
        else {
            this.status = {
                icon: "Success",
                label: "components.ItemsInspectorTile.statusOk"
            }
        }
    }
}
