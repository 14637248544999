import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { NetworkConnectionsStatsOptions } from "./types";
import { NetworkConnectionsChart } from "../network-connections-chart/network-connections-chart";

export class NetworkConnectionsStats extends StatsDetail<NetworkConnectionsStatsOptions> {

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            bright: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.all",
                            itemClass: "WifiAccessPoint",
                            items: [],
                            multiselect: true,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.chart.filter(data);
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new NetworkConnectionsChart(this.context, {
            style: "Light",
            queryName: "NetworkConnectionsByGroup",
            view: "Chart",
            groups: [
                {
                    name: "Hour",
                    label: "components.StatsChart.groups.Hour",
                    selected: true
                },
                {
                    name: "DayOfYear",
                    label: "components.StatsChart.groups.DayOfYear",
                },
                {
                    name: "DayOfWeek",
                    label: "components.StatsChart.groups.DayOfWeek"
                },
                {
                    name: "Week",
                    label: "components.StatsChart.groups.Week"
                },
                {
                    name: "Month",
                    label: "components.StatsChart.groups.Month"
                }
            ],
            title: "components.EnergyMeteringStats.title",
            search: this.form.getData(),
            closable: this.options.closable
        });

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

}
