import "./garbage-bin-overview.scss";
import * as template from "./garbage-bin-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { GarbageBinOverviewOptions } from "./types";
import { DatasetResult } from "../../../../muklit/components/query-table/types";

export class GarbageBinOverview extends DetailPanel<GarbageBinOverviewOptions> {

    // Properties
    public history: { [data: string]: DetailHistoryValue[] };
    public collections: DatasetResult;

    constructor(context: InvipoContext, options?: GarbageBinOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load collections
        this.collections = await this.context.invipo.getDataset("waste-collections-data", `item.id=${this.options.itemId}&pageSize=5&sort=timestamp:desc`);
    }

}
