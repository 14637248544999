import { HttpMethod } from "hiyo/http";
import { Asset, AssetGroup, Dashboard, DashboardLocationCount, Driver, DriverScore, StateLookup, Trip, TripDetail } from "./types";
import { InvipoClient } from "../invipo-client/invipo-client";

export class GpsCockpitClient {
    private requestCache: Record<string, { request: Promise<any>, timestamp: Date }> = {};

    constructor(public invipo: InvipoClient) { }

    public async getAssetTypes(): Promise<any[]> {
        if (!this.requestCache.assetTypes || this.requestCache.assetTypes.timestamp <= new Date(Date.now() - 1000 * 60 * 5)) {
            this.requestCache.assetTypes = {
                request: this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=assetType/`),
                timestamp: new Date()
            }
        }

        return this.requestCache.assetTypes.request;
    }

    public async getAssetGroups(): Promise<AssetGroup[]> {
        if (!this.requestCache.assetGroups || this.requestCache.assetGroups.timestamp <= new Date(Date.now() - 1000 * 60 * 5)) {
            this.requestCache.assetGroups = {
                request: this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=assetGroup/&query=includingItems=true`),
                timestamp: new Date()
            }
        }

        return this.requestCache.assetGroups.request;
    }

    public async getAssets(): Promise<Asset[]> {
        if (!this.requestCache.assets || this.requestCache.assets.timestamp <= new Date(Date.now() - 1000 * 60 * 5)) {
            this.requestCache.assets = {
                request: this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=asset/`),
                timestamp: new Date()
            }
        }

        return this.requestCache.assets.request;
    }

    public async getGeofences(): Promise<any[]> {
        if (!this.requestCache.geofences || this.requestCache.geofences.timestamp <= new Date(Date.now() - 1000 * 60 * 5)) {
            this.requestCache.geofences = {
                request: this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=geofence/`),
                timestamp: new Date()
            }
        }

        return this.requestCache.geofences.request;
    }

    public async getAsset(id: number): Promise<Asset> {
        const req = await this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=asset/${id}`);

        return req;
    }

    public async stateLookup(deviceId?: number): Promise<StateLookup> {
        if (deviceId) {
            return this.invipo.http.request(HttpMethod.POST, `${this.invipo.options.host}/api/gpscockpit/post?resource=StateLookup/`, {
                data: {
                    deviceIds: [deviceId],
                    previousLookupTimestamp: null
                }
            });
        } else {
            const assets = await this.getAssets();
            const deviceIds = assets.map(asset => asset.deviceId);

            if (!this.requestCache.stateLookup || this.requestCache.stateLookup.timestamp <= new Date(Date.now() - 1000 * 60)) {
                this.requestCache.stateLookup = {
                    request: this.invipo.http.request(HttpMethod.POST, `${this.invipo.options.host}/api/gpscockpit/post?resource=StateLookup/`, {
                        data: {
                            deviceIds,
                            previousLookupTimestamp: null
                        }
                    }),
                    timestamp: new Date()
                }
            }

            return this.requestCache.stateLookup.request;
        }
    }

    public async getDrivers(): Promise<Driver[]> {
        if (!this.requestCache.drivers || this.requestCache.drivers.timestamp <= new Date(Date.now() - 1000 * 60 * 5)) {
            this.requestCache.drivers = {
                request: this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=driver/`),
                timestamp: new Date()
            }
        }

        return this.requestCache.drivers.request;
    }

    public async getAssetsWithState(): Promise<Asset[]> {
        const assets = await this.getAssets();
        const states = await this.stateLookup();

        for (const asset of assets) {
            asset.state = states.deviceStates[asset.deviceId.toString()];
        }

        return assets;
    }

    public async getAssetWithState(assetId: number): Promise<Asset> {
        const asset = await this.getAsset(assetId);
        const states = await this.stateLookup(asset.deviceId);

        asset.state = states.deviceStates[asset.deviceId.toString()];

        return asset;
    }

    public async getTrips(from: number, to: number, assetId?: number): Promise<Trip[]> {
        const resource = "Trip";
        const query = `${assetId ? `assetId=${assetId}` : ''}&start=${from}&end=${to}&includeEpisodes=true&includeMessages=${false}&includeJsonLocationData=true`;

        const data = await this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=${resource}&query=${encodeURIComponent(query)}`);

        for (const item of data) {
            item.beginTS += "+04:00";
            item.endTS += "+04:00";
        }

        return data;
    }

    public async getTrip(id: number): Promise<TripDetail[]> {
        const resource = `Trip/${id}`;
        const query = `includeEpisodes=true&includeMessages=${false}&includeJsonLocationData=true`;

        const data: TripDetail[] = await this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=${resource}&query=${encodeURIComponent(query)}`);

        for (const item of data) {
            item.timestamp += "+04:00";
            item.serverTimestamp += "+04:00";
            item.rtcBasedTimestamp += "+04:00";
        }

        return data;
    }

    public async getDashboard(): Promise<Dashboard> {
        const data = await this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=dashboard/`);

        return data;
    }

    public async getDashboardLocationCount(): Promise<DashboardLocationCount[]> {
        const data: DashboardLocationCount[] = await this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=dashboard/locationCount/`);

        for (const item of data) {
            item.summaryDate += "+04:00";
        }

        return data;
    }

    public async getDriverScoring(driverId: number, accoundId: number, from: Date, to: Date): Promise<DriverScore[]> {
        const start = Math.floor(from.getTime() / 1000);
        const end = Math.floor(to.getTime() / 1000);
        const query = `accountId=${accoundId}&start=${start}&end=${end}`;

        const data = await this.invipo.http.request(HttpMethod.GET, `${this.invipo.options.host}/api/gpscockpit/get?resource=driver/${driverId}/Score&query=${encodeURIComponent(query)}`);

        return data;
    }
}
