import "./detail.scss";
import * as header from "./detail.header.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { DetailOptions } from "./types";
import { Templates } from "hiyo/templates";
import { Log } from "hiyo/log";
import { MenuItem } from "../overflow-menu/types";
import { OverflowMenu } from "../overflow-menu/overflow-menu";

export abstract class Detail<T extends Context = Context, U extends DetailOptions = DetailOptions> extends MuklitComponent<T, U> {

    // Private handlers
    private readonly onKeyUpHandler: (e: KeyboardEvent) => void;

    // Event handling methods
    public onMenuSelect(item: MenuItem): void {}

    protected constructor(context: T, template: any, options: U) {
        super(context, template, options);

        // Key handler to add and remove by single instance
        this.onKeyUpHandler = (e: KeyboardEvent) => {
            // Escape pressed and not filtering ESC key?
            if (e.key == "Escape" && !this.options.preventEsc) {
                // Close self
                this.close();

                // Cancel bubbles for other ESC handlers from sub-details
                //e.stopImmediatePropagation();
            }
        }

        // Register partials
        Templates.registerPartial("detail-header", header);
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean): void {
        // Do attach
        super.attach(target, before, skipLoad);

        // Show overlay
        if (this.options?.overlay) {
            // Adjust z index
            this.element.style.zIndex = `${MuklitComponent.Z_INDEX}`;

            // Show overlay
            this.showOverlay();

            // Close detail when on overlay click
            this.onOverlayClick = () => {
                // Modal detail?
                if (!this.options.closable) {
                    this.animate({
                        "self": "keyframes-detail-shake 300ms"
                    });
                }
                // Non-modal detail that can be closed by overlay click
                else {
                    this.close();
                }
            }

            // Close with ESC key (only if closable and in dialog mode)
            if (this.options.closable) {
                document.addEventListener("keyup", this.onKeyUpHandler);
            }
        }
    }

    public detach() {
        // Unbind ESC key (only if closable and in dialog mode)
        if (this.options?.overlay && this.options?.closable) {
            document.removeEventListener("keyup", this.onKeyUpHandler);
        }

        // Hide overlay
        if (this.options?.overlay) {
            this.hideOverlay();
        }

        // Do detach
        super.detach();
    }

    public invalidate(skipLoad?: boolean): void {
        if (!this.isAttached()) {
            Log.w(`Trying to invalidate on unattached/detached detail ${this.id}`);
            return;
        }

        let parent = this.element ? this.element.parentElement : null;
        let zIndex = this.element?.style.zIndex;

        // Custom invalidation without overlay manipulation
        // This is because of flickering effect when overlay is reattached again
        super.detach();
        super.attach(parent, null,  skipLoad);

        // Assign exactly the same z index element had before invalidation
        this.element.style.zIndex = `${zIndex}`;

        Log.i(`Detail ${this.id} invalidated (was reattached)`);
    }

    public close(back?: boolean): void {
        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Detach from DOM
        this.detach();

        // OnClose handler;
        this.onClose(back);
    }

    public showMenu(): void {
        // Create overlfow menu
        let menu = new OverflowMenu(this.context, {
            style: this.options.style,
            items: this.options.menu,
            anchor: "TopRight",
            start: "BottomRight"
        });

        // OnMenuSelect handler
        menu.onSelect = (item: MenuItem) => {
            // OnMenuSelect handler
            this.onMenuSelect(item);
        }

        // Show over menu button
        menu.show(this.query("div.header div.partial-icon-menu"));
    }
}
