import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { Trip, TripDetail } from "invipo/clients/gpscockpit-client/types";
import { LngLatBounds } from "mapbox-gl";

export class FleetTripLineLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext, private tripDetail: TripDetail[]) {
        super(context, {
            dynamic: true,
            // refreshInterval: 60, // Each minute hard init
            card: "FleetTripCard",
            layer: {
                id: "fleet-trip-line-layer",
                type: "line",
                minzoom: 0,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": 4,
                    "line-color": "#008efa",
                }
            },
        });
    }

    public async load(): Promise<FeatureCollection> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: [{
                type: "Feature",
                properties: {},
                geometry: {
                    type: "LineString",
                    coordinates: this.tripDetail.map(m => [m.longitude, m.latitude])
                }
            }]
        };

        return json;
    }

    public getBounds(): LngLatBounds {
        return this.tripDetail.reduce((bounds: LngLatBounds, item: TripDetail) => {
            return bounds.extend([item.longitude, item.latitude]);
        }, new LngLatBounds());
    }
}
