import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";

export class GarbageLevelHeatmapLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "garbage-level-heatmap",
                type: "heatmap",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ['linear'],
                        ["get", "level"],
                        0, 0,
                        500, 1
                    ],
                    "heatmap-intensity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        22, 3
                    ],
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0.0, "rgba(204, 204, 204, 0)",
                        0.2, "#7cd4ff",
                        0.4, "#00acff",
                        0.6, "#008cd0",
                        0.8, "#ff9046",
                        1.0, "#c94f16",
                    ],
                    "heatmap-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        9, 10,
                        16, 60,
                        22, 300
                    ],
                    "heatmap-opacity": 0,
                    "heatmap-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "heatmap-opacity": 0.75
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems(`class=GarbageBin`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No data?
            if (item.data?.garbage?.level == null) {
                continue;
            }

            json.features.push({
                type: "Feature",
                properties: {
                    type: "Location",
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    level: item.data.garbage.level
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }

}
