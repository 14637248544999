import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";

export class BatteryStatusTemperatureCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "BatteryCard",
            detail: "CityDetail",
            layer: {
                id: "battery-status-temperature-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 12
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.9
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.9
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = await this.context.invipo.getItems(`class=Battery`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Color calculation from range
            let range = Helpers.range(0, METRICS.energy.battery.temperature.colors.length - 1, METRICS.energy.battery.temperature.range[0], METRICS.energy.battery.temperature.range[1], item.data?.battery?.temperature);
            let color = METRICS.energy.battery.temperature.colors[Math.round(range)];

            json.features.push({
                type: "Feature",
                properties: {
                    data: "temperature",
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }


}
