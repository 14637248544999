import { FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { METRICS } from "../../components/city/city-subdomain/types";
import { TrafficIncidentLayer } from "./traffic-incident-layer";
import { TrafficMessageLayer } from "./traffic-message-layer";

export class TrafficMessageCircleLayer extends TrafficMessageLayer {

    // Properties
    public message: any;

    public constructor(context: InvipoContext, message?: string) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "TrafficMessageCard",
            detail: "CityDetail",
            layer: {
                id: "traffic-message-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        14.99, 6,
                        15.0, 14,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": 2,
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.75
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });

        // Set message id
        this.message = message;
    }

    public async load(): Promise<FeatureCollection> {
        // Load messages
        let messages = [];

        // Load detail?
        if (this.message) {
            messages.push(this.message);
        }
        // Load all currently visible
        else {
            messages = await this.context.invipo.getQuery("traffic-messages-current");
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let message of messages) {
            // Get symbol color from definition
            let color = METRICS.traffic.messages.messages.colors[METRICS.traffic.messages.messages.range.indexOf(message.type)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.context.locale.getMessage(`enums.TrafficMessageSubtype.${message.subtype}`),
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: message.id,
                    messageId: message.id,
                    messageType: message.type,
                    messageSubtype: message.subtype,
                    color: color
                },
                geometry: {
                    type: "Point",
                    coordinates: (message.position.type == "LineString") ? message.position.coordinates[0] : message.position.coordinates
                }
            });
        }

        return json;
    }
}
