import { ContentView } from "../content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";

export class Settings extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu);

        // Set default content
        this.setContent("NotificationManager");
    }

    private createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                {
                    name: "Platform",
                    items: [
                        {
                            name: "NotificationManager",
                            label: "components.NotificationManager.title",
                            selected: true
                        },
                        {
                            name: "AreaManager",
                            label: "components.AreaManager.title",
                        },
                        {
                            name: "UserManager",
                            label: "components.UserManager.title"
                        }
                    ]
                }
            ]
        });

        this.menu.onSelect = (item: DrawerItem) => {
            // Already selected?
            if (this.content?.name == item.name) {
                return;
            }

            // Change content component
            this.setContent(item.name);
        }
    }

}
