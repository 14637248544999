import "./traffic-incident-detail.scss";
import * as template from "./traffic-incident-detail.hbs";

import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { TrafficIncidentDetailOptions } from "./types";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { LineString, Point } from "geojson";
import { ZOOM_LABEL } from "../../../../muklit/components/basic-map/map-layer";
import { TrafficIncidentCircleLayer } from "../../../layers/traffic/traffic-incident-circle-layer";
import { TrafficIncidentSymbolLayer } from "../../../layers/traffic/traffic-incident-symbol-layer";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class TrafficIncidentDetail extends Detail<InvipoContext, TrafficIncidentDetailOptions> {

    // Components
    public map: BasicMap;
    public layer: TrafficIncidentDetailOptions;

    constructor(context: InvipoContext, options: TrafficIncidentDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new TrafficIncidentCircleLayer(this.context, <any>this.options.incident));
        this.map.addLayer(new TrafficIncidentSymbolLayer(this.context, <any>this.options.incident));

        // Center map to first point in line
        if (this.options.incident.position.type == "LineString") {
            this.map.setCenter((<LineString>this.options.incident.position).coordinates[0]);
        }

        // Center map to coordinates
        if (this.options.incident.position.type == "Point") {
            this.map.setCenter((<Point>this.options.incident.position).coordinates);
        }
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: ZOOM_LABEL,
            minZoom: 5,
            maxZoom: 20
        });
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }
}
