import "./log-browser.scss";
import * as template from "./log-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { LogBrowserOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { Search } from "muklit/components/search/search";
import { Templates } from "hiyo/templates";
import { ItemSelect } from "../../common/item-select/item-select";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";

export class LogBrowser extends MuklitComponent<InvipoContext, LogBrowserOptions> {

    // Components
    public table: FilterTable;

    constructor(context: InvipoContext, options?: LogBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Dark",
            url: `${this.context.options.host}/api/data/datasets/items-log`,
            http: this.context.invipo.http,
            filter: {
                title: "Logs"
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new Search(this.context, {
                                style: "Dark",
                                name: "search",
                                label: "forms.fields.search",
                                placeholderText: "forms.placeholders.itemSearch",
                            }),
                            new Select(this.context, {
                                style: "Dark",
                                name: "severity",
                                label: "forms.fields.severity",
                                placeholderText: "forms.placeholders.severity",
                                multiselect: true,
                                items: [
                                    {
                                        name: "Info",
                                        label: "severity.Info"
                                    },
                                    {
                                        name: "Warn",
                                        label: "severity.Warn"
                                    },
                                    {
                                        name: "Error",
                                        label: "severity.Error"
                                    }
                                ]
                            }),
                            new ItemSelect(this.context, {
                                style: "Dark",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                itemClass: "",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "timestamp"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                        formatter: (value: any, data: any) => {
                            return Templates.renderPartial("severity-formatter", {
                                style: "Dark",
                                severity: data.severity,
                                timestamp: value
                            });
                        }
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "item.name",
                        width: 250,
                        sortable: true,
                        ellipsis: true

                    },
                    {
                        name: "message",
                        type: "String",
                        property: "message",
                        label: "tables.columns.message",
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
    }

}
