import "./item-map.scss";
import * as template from "./item-map.hbs";
import { Component, ComponentOptions } from "hiyo/component";
import { InvipoContext } from "../../../context/invipo-context";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { MapControl } from "../../common/map-control/map-control";
import { Feature, GeoJsonProperties, Point } from "geojson";
import { ItemMapOptions } from "./types";
import { ItemCircleLayer } from "../../../layers/evidence/item-circle-layer";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ItemTooltip } from "../item-tooltip/item-tooltip";
import { ItemLineLayer } from "../../../layers/evidence/item-line-layer";
import { ItemDetailForm } from "../item-detail-form/item-detail-form";

export class ItemMap extends Component<InvipoContext, ItemMapOptions> {

    // Components
    public map: BasicMap;
    public control: MapControl;
    public tooltip: ItemTooltip;

    // Event handling methods
    public onFeatureClick(properties: GeoJsonProperties): void {}

    constructor(context: InvipoContext, options?: ComponentOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createControl();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
        this.registerComponent(this.control, "control");
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 5,
            maxZoom: 20
        });

        // Handle OnDetailsAction
        this.map.onFeatureClick = (layer: string, feature: Feature) => {
            this.onFeatureClick(feature.properties);
        }

        // Show tooltip
        this.map.onFeatureEnter = (layer: string, properties: GeoJsonProperties, e: MouseEvent) => {
            this.showTooltip(properties, e);
        }

        // Reposition tooltip
        this.map.onFeatureMove = (layer: string, properties: GeoJsonProperties, e: MouseEvent) => {
            this.tooltip?.setPosition(e.pageX, e.pageY);
        }

        // Hide tooltip
        this.map.onFeatureLeave = (layer: string) => {
            this.tooltip?.hide()
        }

        // Zoom arrangements
        this.map.onMapZoom = (zoom: number) => {
            this.control.setZoomInDisabled(zoom >= this.map.options.maxZoom);
            this.control.setZoomOutDisabled(zoom <= this.map.options.minZoom);
        }

        // Add item layers to visualize location, segment and area
        this.map.addLayer(new ItemLineLayer(this.context));
        this.map.addLayer(new ItemCircleLayer(this.context));
    }

    private createControl(): void {
        // Create component
        this.control = new MapControl(this.context, {
            style: "Light"
        });

        // Zoom in map
        this.control.onZoomInSelect = () => {
            this.map.zoomIn();
        }

        // Zoom out map
        this.control.onZoomOutSelect = () => {
            this.map.zoomOut();
        }

        // Zoom to all
        this.control.onZoomAllSelect = () => {
            this.map.fitAll();
        }

        // Zoom to default position
        this.control.onZoomHomeSelect = () => {
            this.map.flyTo( {
                center: this.context.options.home.center,
                zoom: this.context.options.home.zoom,
                duration: 3000
            })
        }
    }

    public navigateTo(item: InvipoItem): void {
        // No place to navigate?
        if (!item.geometry?.location) {
            return;
        }

        this.map.pulseAt({
            type: "Selection",
            position: (<Point>item.geometry.location).coordinates,
        });

        this.map.flyTo({
            center: (<Point>item.geometry.location).coordinates,
            duration: 600
        });
    }

    public showTooltip(properties: GeoJsonProperties, e: MouseEvent): void {
        // Hide previous
        this.tooltip?.hide();

        // Create tooltip component based on properties
        this.tooltip = new ItemTooltip(this.context, {
            itemId: properties.itemId,
            itemName: properties.itemName,
            itemClass: properties.itemClass,
            itemModel: properties.itemModel
        });

        // Show tooltip
        this.tooltip.show(e.pageX, e.pageY, 100);
    }

    public async reloadLayers(): Promise<void> {
        // Force reload data of all layers
        await this.map.reloadLayers();
    }
}
