import { FeatureCollection } from "geojson";
import { HiyoEvent } from "hiyo/event-broker";
import { InvipoContext } from "invipo/context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { TrafficeEventHeatmapFilter } from "./types";

export const SYMBOL_MAP: { [type: string]: string } = {
    "Accident": "24-markers-road-accident",
    "Closure": "24-markers-road-restriction_02",
    "Roadworks": "24-markers-road-roadwork",
    "Danger": "24-markers-road-danger",
    "Info": "24-markers-road-road_non_weather"
}

export class TrafficEventHeatmapSymbolLayer extends MapLayer<InvipoContext> {
    private filter: TrafficeEventHeatmapFilter;

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            pointer: true,
            layer: {
                id: "traffic-event-heatmap-symbol",
                type: "symbol",
                minzoom: 12,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "icon-image": ["get", "symbol"],
                    "icon-offset": [0, 0],
                    "icon-allow-overlap": true,
                },
                paint: {
                    "icon-opacity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 0, // 6, 3
                        13.9, 0,
                        14.0, 1,
                        22, 1
                    ],
                }
            }
        });
    }

    public async setEventsFilter (from: string, to: string, type: string) {
        // Update filter
        this.filter = { from, to, type };
        // Load data
        this.data = await this.load();
        // Redraw map layer
        this.update();
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Do not load all events from database!
        if (!this.filter) return json;

        const events = await this.context.invipo.getDataset("traffic-events", `from=${this.filter.from}&to=${this.filter.to}&type=${this.filter.type}`);

        // Push features from items
        for (let event of events.data) {
            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.context.locale.getMessage(`enums.TrafficEventType.${event.type}`),
                    cardId: event.id,
                    eventId: event.id,
                    eventType: event.type,
                    symbol: SYMBOL_MAP[event.type]
                },
                geometry: event.position
            });
        }

        // return json;
        return json;
    }
}
