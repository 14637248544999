import "./violation-type-card.scss";
import * as template from "./violation-type-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ViolationTypeCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { CardHistoryValue } from "../../city/city-card/types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class ViolationTypeCard extends CityCard<ViolationTypeCardOptions> {

    // Properties
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: ViolationTypeCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery(`violations-by-hour`, `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 2 days)
        this.history = [];

        // Select proper metrics
        let metrics = METRICS.traffic.violations.count;

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.count,
                    percent: Helpers.range(0, 100, 0, metrics.range[1], d.count),
                    color: metrics.colors[Math.round(Helpers.range(0, metrics.colors.length - 1, metrics.range[0], metrics.range[1], d.count))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }

}
