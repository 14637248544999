import { DateInput } from "muklit/components/date-input/date-input";
import { WorkflowViolationBrowserOptions } from "./types";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { FilterTable } from "../../../../muklit/components/filter-table/filter-table";
import { FilterItem } from "../../../../muklit/components/filter/types";
import { Select } from "../../../../muklit/components/select/select";
import { ViolationDetail } from "../violation-detail/violation-detail";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { ViolationBrowser } from "../violation-browser/violation-browser";

export class WorkflowViolationBrowser extends ViolationBrowser<WorkflowViolationBrowserOptions> {

    public createTable(): void {
        // Create menu items from columns
        let columns: MenuItem[] = [];

        for (let c of this.context.config.violations.columns) {
            columns.push({
                name: c,
                label: c
            })
        }

        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.ViolationBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from"
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to"
                            }),
                            /*new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                itemClass: "TrafficCounter",
                                items: [],
                                multiselect: true
                            }),*/
                            /*new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.ViolationType"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                })
                            }),*/
                            new Select(this.context, {
                                style: "Light",
                                name: "workflow.column",
                                label: "forms.fields.status",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: columns
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Light",
                                name: "workflow.assignee.id",
                                label: "forms.fields.assignee",
                                collection: "Users",
                                type: "Name",
                                placeholderText: "forms.placeholders.nobody",
                                items: []
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "workflow.reference",
                                label: "forms.fields.reference"
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        let result = null;

                        // Archived?
                        if (data.workflow?.archived) {
                            result = "disabled"
                        }

                        // Completed?
                        if (data.workflow?.completed) {
                            result = "disabled"
                        }

                        // Overdue?
                        if (data.workflow?.due && new Date().getTime() > new Date(data.workflow.due).getTime()) {
                            result = "deleted"
                        }

                        return result;
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "titile",
                        type: "String",
                        property: "workflow.title",
                        label: "tables.columns.title",
                        width: 240,
                        sortable: true,
                        ellipsis: true
                    },
                    /*{
                        name: "value",
                        type: "String",
                        property: "extras",
                        label: "tables.columns.value",
                        formatter: (value: any, data: any) => {
                            // Speed?
                            if (value?.speed) {
                                return `<div class="cell cell-right">${Helpers.toNumber(value.speed)} ${this.context.locale.getMessage("units.kmph")}</div>`
                            }
                        },
                        width: 120,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },*/
                    {
                        name: "column",
                        type: "String",
                        property: "workflow.column",
                        label: "tables.columns.status",
                        width: 120,
                        sortable: true,
                        ellipsis: true
                    },
                    /*{
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }*/
                    {
                        name: "reference",
                        type: "String",
                        property: "workflow.reference",
                        label: "tables.columns.reference",
                        width: 120,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "assignee",
                        type: "String",
                        property: "workflow.assignee.name",
                        label: "tables.columns.assignee",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }
}
