import "./item-card.scss";
import * as template from "./item-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";

export class ItemCard extends CityCard<ItemCardOptions> {
    
    constructor(context: InvipoContext, options: ItemCardOptions) {
        super(context, template, options);
    }
}
