import './fleet-asset-list.scss';
import * as template from "./fleet-asset-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FleetAssetListOptions } from "./types";
import { CityList } from "../city-list/city-list";
import { Asset, DeviceState, DeviceStateEnum } from 'invipo/clients/gpscockpit-client/types';

export class FleetAssetList extends CityList<FleetAssetListOptions> {

    // Properties
    public assets: Asset[];

    constructor(context: InvipoContext, options?: FleetAssetListOptions) {
        super(context, template, options);
    }

    public selectItem(i: number): void {
        // Get item
        let asset = this.assets[i];

        if (asset?.state?.currentPosition) {
            // Call handler
            this.onPositionNavigate([asset.state.currentPosition.longitude, asset.state.currentPosition.latitude]);
        }
    }

    public async extraLoad(): Promise<void> {
        this.assets = await this.context.gpsCockpit.getAssetsWithState();

        console.log(1, this.assets, this.options.deviceState);

        if (this.options.deviceState === DeviceStateEnum.Unknown) {
            this.assets = this.assets.filter(asset => !asset.state || asset.state.calculatedDeviceState.deviceState === DeviceStateEnum.Unknown);
        } else if (this.options.deviceState) {
            this.assets = this.assets.filter(asset => asset.state?.calculatedDeviceState.deviceState === this.options.deviceState);
        }

        console.log(2, this.assets);
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.assets) {
            return;
        }

        // Load handilig by CityList
        await super.load();
    }
}
